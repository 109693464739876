<template>
  <ion-page>
    <MobileHeader></MobileHeader>
    <ion-content>
      <DesktopLocaleSelector></DesktopLocaleSelector>
      <ContentBanner
        :title="pageTitle"
        :message="$t('tools.bannerWelcomeText')"
        :imagePath="require('@/assets/em-toolsGuide-banner.svg')"
        imageWidthDesktop="180px"
        imageMarginLeftDesktop="35px"
        data-test="tools-banner"
      ></ContentBanner>
      <div class="page-wrapper" id="tools-home-main-content" tabindex="-1">
        <div
          v-if="
            featureFlagStore.isFlagEnabled('release-component-resume', false)
          "
          class="content-frame-wrapper"
          data-test="resume-wrapper"
        >
          <div class="title-wrapper">
            <h2 class="page-title">{{ $t("resume.title") }}</h2>
            <p class="page-description">
              {{ $t("resume.description") }}
            </p>
          </div>
          <CardPortrait
            :content="resumeBuilderCTA"
            :statusBadge="$t('resume.resumeCard.badgeText')"
            url="ResumeOverview"
            :isLoaded="true"
            data-test="resume-cta-card"
          ></CardPortrait>
        </div>
        <div class="content-frame-wrapper" v-if="!isAssessmentsLoaded">
          <div class="headings-skeleton-wrapper">
            <TitleSkeleton></TitleSkeleton>
            <DescriptionSkeleton></DescriptionSkeleton>
          </div>
          <div class="cards-skeleton-wrapper">
            <CardSkeleton
              class="cards-skeleton"
              v-for="index in 2"
              :key="index"
            />
          </div>
        </div>
        <div class="content-frame-wrapper" v-else-if="assessmentsExist">
          <div class="title-wrapper">
            <h2 class="page-title">{{ $t("tools.pageTitle") }}</h2>
            <p class="page-description">
              {{ $t("tools.pageDescription") }}
            </p>
          </div>
          <AssessmentCards data-test="assessment-card"></AssessmentCards>
        </div>
        <div
          class="content-frame-wrapper"
          v-else-if="
            isAssessmentsLoaded &&
            !featureFlagStore.isFlagEnabled('release-component-resume', false)
          "
          data-test="empty-tools-page"
        >
          <h2 id="page-title-empty" class="page-title">
            {{ $t("tools.pageTitleEmpty") }}
          </h2>
          <p id="page-description-empty" class="page-description">
            {{ $t("tools.pageDescriptionEmpty") }}
          </p>
          <div class="button-wrapper">
            <AppButton
              size="default"
              type="primary"
              :label="$t('tools.returnHome')"
              :ariaLabel="$t('tools.returnHome')"
              data-test="tools-home-button"
              @click="
                $router.push({
                  name: 'Home',
                })
              "
            />
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>
<script setup lang="ts">
import { IonPage, IonContent, onIonViewDidEnter } from "@ionic/vue";
import MobileHeader from "@/components/Common/MobileHeader/MobileHeader.vue";
import DesktopLocaleSelector from "@/components/Common/LocaleSelector/DesktopLocaleSelector.vue";
import ContentBanner from "@/components/Common/ContentBanner/ContentBanner.vue";
import { useAssessmentStore, useFeatureFlagStore } from "@/store";
import AssessmentCards from "@/components/Assessment/AssessmentCards/AssessmentCards.vue";
import CardSkeleton from "@/components/Skeletons/CardSkeleton/CardSkeleton.vue";
import DescriptionSkeleton from "@/components/Skeletons/DescriptionSkeleton/DescriptionSkeleton.vue";
import TitleSkeleton from "@/components/Skeletons/TitleSkeleton/TitleSkeleton.vue";
import { computed, onMounted, ref } from "vue";
import AppButton from "@/components/Common/AppButton/AppButton.vue";
import CardPortrait from "@/components/Common/CardPortrait/CardPortrait.vue";
import { CardContent } from "@/store/models/card.models";
import { documentTextOutline } from "ionicons/icons";
import { useRoute } from "vue-router";
//TODO: Maybe we move this somewhere global??
import { useI18n } from "vue-i18n";
const { t } = useI18n<{}>({
  useScope: "global",
});

const resumeBuilderCTA: CardContent = {
  title: t("resume.resumeCard.title"),
  description: t("resume.resumeCard.description"),
  icon: documentTextOutline,
};

const featureFlagStore = useFeatureFlagStore();
const assessmentStore = useAssessmentStore();
const route = useRoute();
const pageTitle = ref((route?.meta.title as string) || "");

onIonViewDidEnter(() => {
  pageTitle.value = route?.meta.title || "";
});

const isAssessmentsLoaded = computed(() => {
  return assessmentStore.isAssessmentsLoaded;
});
const assessmentsExist = computed(() => {
  if (!featureFlagStore.isFlagEnabled("release-component-assessments", false)) {
    return false;
  }
  return assessmentStore.getAssessments.length > 0;
});
onMounted(async (): Promise<void> => {
  await assessmentStore.requestAssessments();
});
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";

.page-wrapper {
  z-index: 9;
  box-shadow: none;
  border-radius: unset;
}

.content-frame-wrapper {
  max-width: 700px;
  //Bottom padding is 24 + 24 break between elements
  padding: var(--spacing-0) var(--spacing-0) var(--spacing-48) var(--spacing-0);
}

.page-title {
  line-height: 32px;
  margin-top: 0px;
}

#page-title-empty {
  margin-bottom: var(--spacing-24);
}

.title-wrapper {
  text-align: left;
  font-family: Roboto;
  font-size: 24px;
  line-height: 32px;
}

.page-description {
  color: var(--dark-grey);
  letter-spacing: 0.5px;
  margin: var(--spacing-16) var(--spacing-0) var(--spacing-24);
  text-align: left;
}

#page-description-empty {
  margin-bottom: var(--spacing-48);
}

.button-wrapper {
  float: left;
}
// Overrides for loading content cards to be a larger size.
.cards-skeleton-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 16px;
  .cards-skeleton {
    width: 252px;
    height: 262px;
    margin: 0;
  }
}

.headings-skeleton-wrapper {
  display: contents;

  @include breakpoint(medium) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}
</style>
