<template>
  <ResumeBase
    :breadcrumbs="[
      {
        route: 'personal-statement',
        label: $t('resume.breadcrumbs.personalStatement'),
      },
    ]"
    mainContentId="resume-personal-statement-main-content"
    :formSaveRetryFunction="saveForm"
    :skipOnRouteBeforeLeave="skipOnRouteBeforeLeave"
  >
    <template v-slot:title>
      {{ $t("resume.personalStatementForm.title") }}
    </template>
    <template v-slot:description>
      {{ $t("resume.personalStatementForm.description") }}
    </template>
    <template v-slot:main-content>
      <form :aria-label="t('resume.personalStatementForm.title')">
        <TextAreaInput
          :value="resumeStore.resumePersonalStatement || ''"
          :labelText="
            $t('resume.personalStatementForm.personalStatement.label')
          "
          :placeholderText="
            $t('resume.personalStatementForm.personalStatement.placeholder')
          "
          :errorText="errorMessageMap.get('personalStatement')"
          :autogrow="autogrowEnabled"
          :startingHeight="textareaHeight"
          @onChange="
            (event) => {
              onInputChange(event);
              validatePersonalStatement(event);
            }
          "
          :isLoading="resumeStore.isResumeLoading"
          id="text-area-input"
          data-test="text-area-input"
        ></TextAreaInput>
        <div class="buttons-container">
          <AppButton
            size="default"
            type="primary"
            :label="$t('resume.personalStatementForm.saveButtonText')"
            :ariaLabel="$t('resume.personalStatementForm.saveButtonText')"
            @click="saveForm"
            data-test="save-form-btn"
            class="save-form-btn"
            width="100%"
            :isLoading="isFormSaving"
            :disabled="!isFormSavingEnabled"
          />
          <AppButton
            size="default"
            type="textOnly"
            :label="t('resume.personalStatementForm.closeButtonText')"
            :ariaLabel="t('resume.personalStatementForm.closeButtonText')"
            data-test="close-form-btn"
            class="close-form-btn"
            id="closeBtn"
            @click="returnToPage"
            width="100%"
          />
        </div>
      </form>
    </template>
    <template v-slot:resume-helper> </template>
  </ResumeBase>
</template>
<script setup lang="ts">
import ResumeBase from "@/Layouts/ResumeBase/ResumeBase.vue";
import TextAreaInput from "@/components/Common/Inputs/TextAreaInput/TextAreaInput.vue";
import AppButton from "@/components/Common/AppButton/AppButton.vue";
import { useResumeStore, useErrorStore } from "@/store";
import { onMounted, onUnmounted, ref, computed } from "vue";
import { useRouter, onBeforeRouteLeave, useRoute } from "vue-router";
import { onIonViewDidEnter } from "@ionic/vue";
import { handleBeforeUnload, focusOnMainContent } from "@/helpers";
import {
  IonTextareaCustomEvent,
  TextareaChangeEventDetail,
  IonInputCustomEvent,
} from "@ionic/core/components";
import { useI18n } from "vue-i18n";
import { Resume } from "@/store/models/resume.models";
const { t } = useI18n<{}>({
  useScope: "global",
});

const resumeStore = useResumeStore();
const errorStore = useErrorStore();
const router = useRouter();
const route = useRoute();

const skipOnRouteBeforeLeave = ref(false);

onMounted(async () => {
  if (!resumeStore.resumeId) {
    await resumeStore.requestResume();
    if (!resumeStore.resumeId) {
      await resumeStore.createResume();
    }
  }
  window.addEventListener("resize", handleResize);
  handleResize();
  initValidationMap();
});

onUnmounted(async () => {
  window.removeEventListener("resize", handleResize);
});

onBeforeRouteLeave(() => {
  window.removeEventListener("beforeunload", handleBeforeUnload);
});
onIonViewDidEnter(() => {
  focusOnMainContent(route?.meta?.mainContentId);
  skipOnRouteBeforeLeave.value = false;
  window.addEventListener("beforeunload", handleBeforeUnload);
});

const autogrowEnabled = ref(false);
const handleResize = () => {
  const textHeight =
    document.querySelector<HTMLElement>("#text-area-input .textarea-input")
      ?.offsetHeight || 0;

  if (window.innerWidth <= 769 && textHeight >= 344) {
    autogrowEnabled.value = false;
  } else autogrowEnabled.value = true;
};

const textareaHeight = computed(() => {
  if (window.innerWidth > 769) {
    return 250;
  } else {
    return 152;
  }
});

const isFormSaving = ref(false);

const onInputChange = (
  event: IonTextareaCustomEvent<TextareaChangeEventDetail>
) => {
  if (event.detail?.value === undefined || event.detail?.value === null) return;
  resumeStore.setPersonalStatementValue(event.detail.value);
  if (autogrowEnabled.value) {
    handleResize();
  }
};

const saveForm = async () => {
  skipOnRouteBeforeLeave.value = true;
  errorStore.setSaveFormError(false);
  try {
    isFormSaving.value = true;
    await resumeStore.updatePersonalStatement();
    router.push({
      name: "ResumeOverview",
    });
  } catch (error) {
    console.error("An error occured saving the form: ", error);
    errorStore.setSaveFormError(true);
  } finally {
    isFormSaving.value = false;
  }
};
const returnToPage = () => {
  router.push({
    name: "ResumeOverview",
  });
};

/* VALIDATION */

const rules = {
  personalStatement: {
    invalidCharacters: t(
      "resume.personalStatementForm.personalStatement.invalidCharactersError"
    ),
    characterLimit: t(
      "resume.personalStatementForm.personalStatement.characterLimitError"
    ),
  },
};

const validationMap = ref<Map<keyof Resume, boolean>>(new Map([]));
const initValidationMap = () => {
  validationMap.value.set("personalStatement", true);
};
const errorMessageMap = ref<Map<keyof Resume, string>>(
  new Map([["personalStatement", ""]])
);

const isFormSavingEnabled = computed(() => {
  for (let isValid of validationMap.value.values()) {
    if (!isValid) return false;
  }
  return true;
});

const validatePersonalStatement = (event: IonInputCustomEvent<FocusEvent>) => {
  const itemEl = event.target.parentElement;
  itemEl?.classList.remove("ion-invalid");
  const personalStatement = event.target.value as string;
  if (personalStatement && personalStatement.length >= 1200) {
    itemEl?.classList.add("ion-invalid");
    errorMessageMap.value.set(
      "personalStatement",
      rules.personalStatement.characterLimit
    );
  }
};
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";
.buttons-container {
  margin-top: var(--spacing-48);
  max-width: 312px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
}
</style>
