export function setChatIframeVisibility(value) {
  const chatIframe = document.querySelector(
    "iframe[src^='https://oc-cdn-public-oce.azureedge.net/livechatwidget/']"
  );

  if (!chatIframe) {
    return;
  }
  chatIframe.style.display = value;
}
