<template>
  <ion-card data-test="card-skeleton">
    <h5>
      <ion-skeleton-text
        :animated="true"
        style="width: 100%; height: 20px"
      ></ion-skeleton-text>
    </h5>
    <p>
      <ion-skeleton-text
        :animated="true"
        style="width: 100%"
      ></ion-skeleton-text>
    </p>
    <p>
      <ion-skeleton-text
        :animated="true"
        style="width: 100%"
      ></ion-skeleton-text>
    </p>
  </ion-card>
</template>
<script>
import { IonCard, IonSkeletonText } from "@ionic/vue";
export default {
  name: "CarouselCardSkeleton",
  components: {
    IonCard,
    IonSkeletonText,
  },
};
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";

ion-card {
  width: 100%;
  height: 127px;
  padding: 20px;
  border-radius: 16px;
  margin: 0 0 16px 0;
}

@include breakpoint(medium) {
  ion-card {
    width: 100%;
    max-width: 295px;
  }
}
</style>
