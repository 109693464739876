<template>
  <div class="question-index" data-test="question-index" tabindex="0">
    {{
      $t("assessment.questionNumber", {
        index: questionIndex,
        count: questionCount,
      })
    }}
  </div>
  <ion-progress-bar
    :value="progress"
    data-test="progress-bar"
    aria-label="quiz progress bar"
    tabindex="0"
  />
  <ion-card>
    <ion-card-content>
      <div id="question" data-test="question-text" tabindex="0">
        <SanityBlocks :blocks="assessmentItem.item" />
      </div>
      <div class="help-text" data-test="help-text" tabindex="0">
        {{ $t("assessment.selectYourResponse") }}:
      </div>
      <ion-list lines="none">
        <ion-radio-group
          :value="selectedResponse"
          @ionChange="selectResponse($event)"
        >
          <ion-item
            class="response"
            v-for="(response, index) in assessmentItem.responses"
            :key="response.score"
          >
            <ion-label :data-test="`response-${index}`">{{
              response.response
            }}</ion-label>
            <ion-radio
              class="response-radio"
              label-placement="start"
              :value="response.score"
              :name="`response-${index}`"
              :data-test="`radio-${index}`"
              v-on:keyup.enter="selectResponse(response.score)"
            >
            </ion-radio>
          </ion-item>
        </ion-radio-group>
      </ion-list>
    </ion-card-content>
  </ion-card>
  <div class="button-container" id="button-container">
    <AppButton
      size="default"
      type="primary"
      :label="
        questionIndex < questionCount
          ? $t('assessment.next')
          : $t('assessment.completeQuiz')
      "
      :ariaLabel="
        questionIndex < questionCount
          ? $t('assessment.next')
          : $t('assessment.completeQuiz')
      "
      :icon="chevronForwardOutline"
      iconPosition="end"
      :disabled="!questionAnswered"
      :isLoading="isNextLoading"
      @click="nextQuestionHandler"
      data-test="next-button"
    />

    <AppButton
      v-if="questionIndex > 1"
      size="default"
      type="secondary"
      :label="$t('assessment.previous')"
      :ariaLabel="$t('assessment.previous')"
      :icon="chevronBackOutline"
      iconPosition="start"
      :isLoading="isPrevLoading"
      @click="emit('prev-click')"
      data-test="prev-button"
    />
  </div>
</template>
<script setup lang="ts">
import {
  IonRadioGroup,
  IonRadio,
  IonList,
  IonLabel,
  IonItem,
  IonCard,
  IonCardContent,
  IonProgressBar,
} from "@ionic/vue";
import { SanityBlocks } from "sanity-blocks-vue-component";
import { chevronForwardOutline, chevronBackOutline } from "ionicons/icons";
import AppButton from "@/components/Common/AppButton/AppButton.vue";
import {
  defineProps,
  defineEmits,
  computed,
  onMounted,
  ref,
  onBeforeMount,
  nextTick,
} from "vue";
import isNumber from "lodash/isNumber";
import { SanityAssessmentItem } from "@/store";

interface Props {
  assessmentItem: SanityAssessmentItem;
  questionIndex: number;
  questionCount: number;
  previousResponse?: number;
  isNextLoading: boolean;
  isPrevLoading: boolean;
}

const props = defineProps<Props>();

const emit = defineEmits(["next-click", "prev-click", "save-response"]);

const selectedResponse = ref();

const progress = computed(() => {
  return props.questionIndex / props.questionCount;
});

const questionAnswered = computed(() => {
  return selectedResponse.value !== undefined;
});

const selectResponse = (ev: CustomEvent | number | undefined) => {
  selectedResponse.value = isNumber(ev) ? ev : ev?.detail.value;
  const label = props.assessmentItem.responses.find(
    (resp: any) => resp.score === selectedResponse.value
  )?.response;
  emit("save-response", {
    index: props.questionIndex - 1,
    score: selectedResponse.value,
    responseLabel: label,
  });
};

onBeforeMount(() => {
  selectedResponse.value = props.previousResponse;
});

onMounted(async () => {
  await nextTick(() => {
    scrollToButtons();
  });
});

const scrollToButtons = (): void => {
  const buttonContainer = document.getElementById("button-container");
  if (buttonContainer) {
    setTimeout(() => {
      buttonContainer.scrollIntoView();
    }, 50);
  }
};

const nextQuestionHandler = () => {
  /* Check that we aren't already loading another question */
  if (!props.isNextLoading) {
    emit("next-click");
  }
};
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";

.question-index {
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  margin-bottom: 16px;

  &:focus {
    outline: 0;
  }
}

ion-progress-bar {
  margin-bottom: 48px;
  border-radius: 6px;

  &::part(track) {
    border-radius: 6px;
  }

  &::part(progress) {
    border-radius: 6px;
  }

  &:focus {
    outline: 0;
  }
}

ion-card {
  margin: 16px 0 0;
  border-radius: 16px;
  border: 2px solid var(--lighter-grey);
  text-align: left;
  box-shadow: none;
}

ion-card-content {
  background-color: var(--background-white);
}
#question {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin: 0 0 24px 16px;

  :deep(p) {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.027px;
    color: var(--button-text-primary);
  }

  &:focus {
    outline: 0;
  }
}

ion-list {
  background-color: var(--background-white);
}

.help-text {
  color: var(--dark-grey);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-left: 16px;
}

.response {
  color: var(--dark-grey);
  font-weight: 500;
  border-radius: 10px;
  margin-bottom: 16px;
  border: solid 2px var(--lighter-grey);
  background-color: var(--background-white);

  &:hover {
    border: 2px solid var(--ion-color-primary);
    background-color: var(--ion-color-secondary-hover);
  }

  &:focus {
    border: 2px solid var(--ion-color-primary);
    background-color: var(--ion-color-secondary-hover);
  }

  &::part(native) {
    background-color: unset;
  }

  &.item-radio-checked {
    &::part(native) {
      color: var(--button-text-primary);
    }
    border: 2px solid var(--ion-color-primary);
    background-color: var(--ion-color-secondary-hover);
  }
}

.response-radio {
  width: 22px;
  height: 22px;

  &::part(container) {
    font-weight: 500;
    border-radius: 0;
    border: 2px solid var(--dark-grey);
    background-color: var(--background-white);
    border-radius: 4px;
  }

  &::part(mark) {
    background: none;
    transition: none;
    transform: none;
    border-radius: 0;
  }

  &.radio-checked {
    font-weight: 500;

    &::part(container) {
      border: 2px solid var(--ion-color-primary);
      background-color: var(--ion-color-primary);
    }

    &::part(mark) {
      width: 6px;
      height: 10px;
      border-width: 0px 2px 2px 0px;
      border-style: solid;
      border-color: #fff;
      transform: rotate(45deg);
    }
  }
}

.button-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: var(--spacing-16) var(--spacing-0);
  gap: var(--spacing-16);

  @include breakpoint(small) {
    flex-direction: row-reverse;
  }
}
</style>
