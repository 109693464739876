<template>
  <div class="skeleton-wrapper">
    <ion-skeleton-text
      class="header"
      :animated="true"
      data-test="header"
    ></ion-skeleton-text>
    <div class="content-description" data-test="description">
      <ion-skeleton-text :animated="true"></ion-skeleton-text>
    </div>
    <div class="card-wrapper" data-test="card-wrapper">
      <div
        v-for="index in 3"
        :key="index"
        class="swiper-card"
        :animated="true"
        :data-test="`card-${index}`"
      >
        <ion-skeleton-text
          :animated="true"
          class="left-side"
        ></ion-skeleton-text>
        <div class="right-side">
          <ion-skeleton-text :animated="true" class="title"></ion-skeleton-text>
          <ion-skeleton-text :animated="true" class="row-1"></ion-skeleton-text>
          <ion-skeleton-text :animated="true" class="row-2"></ion-skeleton-text>
          <div class="button-row">
            <ion-skeleton-text
              :animated="true"
              class="button"
            ></ion-skeleton-text>
            <ion-skeleton-text
              :animated="true"
              class="button"
            ></ion-skeleton-text>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";

.skeleton-wrapper {
  display: inline-flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  max-width: 700px;
  @include breakpoint(medium) {
    width: calc(100% - 48px);
  }

  @include breakpoint(large) {
    width: 100%;
    margin: auto;
    padding: 0;
  }
}

.header {
  height: 36px;
}

.content-description {
  height: 80px;
  margin-top: 24px;
}

.card-wrapper {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
  margin-bottom: 48px;
}

.swiper-card {
  min-width: 312px;
  height: 176px;
  border-radius: 16px;
  background-color: var(--background-white);
  display: flex;
  flex-direction: row;

  ion-skeleton-text {
    margin: 0;
  }

  .left-side {
    height: 100%;
    width: 40%;
    border-radius: 16px 0 0 16px;
  }
  .right-side {
    height: 100%;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px;

    .title {
      width: 80%;
      height: 36px;
      margin-bottom: 8px;
    }

    .row-1,
    .row-2 {
      width: 100%;
      height: 16px;
      margin-bottom: 8px;
    }

    .button-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      height: 16px;
      margin-top: 48px;
      background-color: var(--background-white);

      .button {
        height: 16px;
        width: 40px;
        margin-right: 8px;
        border-radius: 8px;
      }
    }
  }
}
</style>
