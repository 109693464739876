<template>
  <div id="chat-widget"></div>

  <Transition name="fade">
    <button
      v-if="showChatIcon"
      @click.stop.prevent="openChat"
      @focus="focus = true"
      @blur="focus = false"
      class="container"
      data-test="chat-button"
    >
      <div class="background"></div>
      <img :src="chatLogo" class="icon" :alt="$t('chat.altText')" />
      <div v-if="notificationCount > 0" class="notification">
        <span class="notificationCount">{{ notificationCount }}</span>
      </div>
      <div v-if="focus" class="focus"></div>
    </button>
  </Transition>
</template>
<script>
import EnvironmentConfig from "@/EnvironmentConfig";
import chatLogo from "@/assets/message.svg";

export default {
  name: "ChatIcon",
  mounted() {
    const widgetScript = document.createElement("script");
    widgetScript.setAttribute("id", "Microsoft_Omnichannel_LCWidget");

    widgetScript.setAttribute("data-hide-chat-button", "true");

    widgetScript.setAttribute(
      "src",
      "https://oc-cdn-public-oce.azureedge.net/livechatwidget/scripts/LiveChatBootstrapper.js"
    );
    widgetScript.setAttribute(
      "data-app-id",
      EnvironmentConfig.ChatWidget.AppId
    );
    widgetScript.setAttribute("data-lcw-version", "prod");
    widgetScript.setAttribute(
      "data-org-id",
      EnvironmentConfig.ChatWidget.OrgId
    );
    widgetScript.setAttribute(
      "data-org-url",
      EnvironmentConfig.ChatWidget.OrgUrl
    );
    widgetScript.setAttribute("data-color-override", "#1f355e");

    document.getElementById("chat-widget").appendChild(widgetScript);

    window.addEventListener("lcw:ready", () => {
      // eslint-disable-next-line no-undef
      Microsoft.Omnichannel.LiveChatWidget.SDK.setAuthTokenProvider(
        function chatDynamicsGetAuthToken(callback) {
          const token = JSON.parse(window.localStorage.getItem("idToken"));
          callback(token);
        }
      );

      this.showChatIcon = true;

      const showIcon = () => {
        this.showChatIcon = true;
        this.notificationCount = 0;
      };

      const hideIcon = () => {
        this.showChatIcon = false;
        this.notificationCount = 0;
      };

      window.addEventListener("lcw:onMaximize", () => {
        hideIcon();
      });

      window.addEventListener("lcw:chatRetrieved", () => {
        hideIcon();
      });

      window.addEventListener("lcw:onClose", () => {
        showIcon();
      });

      window.addEventListener("lcw:onMinimize", () => {
        showIcon();
      });

      window.addEventListener("lcw:closeChat", () => {
        showIcon();
      });

      window.addEventListener("lcw:onMessageReceived", () => {
        this.notificationCount++;
      });

      // TODO: Handle errors https://learn.microsoft.com/en-us/dynamics365/customer-service/developer/reference/error-code-reference
      // window.addEventListener("lcw:error", (errorEvent) => {
      // const errorDetail = errorEvent.detail; // Object containing details about the error event
      // const errorCode = errorEvent.detail.errorCode; // Error code corresponding to the error event
      // const errorDescription = errorEvent.detail.errorDescription; // Description of the error
      // });
    });
  },
  onUnmounted() {
    // remove all event listeners
    window.removeEventListener("lcw:ready", () => {});
    window.removeEventListener("lcw:onMaximize", () => {});
    window.removeEventListener("lcw:chatRetrieved", () => {});
    window.removeEventListener("lcw:onClose", () => {});
    window.removeEventListener("lcw:onMinimize", () => {});
    window.removeEventListener("lcw:closeChat", () => {});
    window.removeEventListener("lcw:onMessageReceived", () => {});
  },

  data() {
    return {
      showChatIcon: false,
      notificationCount: 0,
      focus: false,
      chatLogo,
    };
  },
  methods: {
    openChat() {
      // eslint-disable-next-line no-undef
      Microsoft.Omnichannel.LiveChatWidget.SDK.startChat({
        inNewWindow: false,
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/common/theme/breakpoints.scss";

.BottomRight {
  bottom: 20px !important;

  @media (max-width: 769px) {
    bottom: 82px !important;
  }

  @media (max-width: 470px) {
    bottom: 0 !important;
  }
}
</style>

<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";

.container {
  cursor: pointer;
  position: absolute;
  background-color: white;
  box-shadow: var(--shadow-1);
  border-radius: 50%;

  width: 64px;
  height: 64px;

  bottom: 32px;
  right: 6px;
  margin-right: 6px;
  transition: box-shadow var(--transition-time);

  &:hover {
    box-shadow: var(--shadow-2);
  }

  &:active,
  &:focus {
    outline: none;
    box-shadow: var(--shadow-1);
  }

  @include breakpoint(medium) {
    margin-right: 12px;
  }
}

.background {
  cursor: pointer;
  position: absolute;
  background-color: var(--ion-color-secondary);
  border-radius: 50%;

  width: 64px;
  height: 64px;

  bottom: 0;
  left: -6px;

  margin-right: 12px;

  @include breakpoint(medium) {
    margin-right: 12px;
  }
}

.notification {
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c64f25;
  border-radius: 50%;

  width: 26px;
  height: 26px;

  top: 0;
  left: -6px;

  margin-right: 6px;

  @include breakpoint(medium) {
    margin-right: 12px;
  }
}

.notificationCount {
  cursor: pointer;
  font-weight: 700;
  color: white;
  font-size: 14px;
  line-height: 20px;
}

.icon {
  cursor: pointer;
  position: absolute;

  width: 32px;
  height: 32px;

  top: 16px;
  right: 16px;

  margin-right: 6px;
}

.focus {
  position: absolute;
  width: 86px;
  height: 86px;
  border: 2px solid var(--ion-color-secondary);
  border-radius: 8px;
  bottom: -12px;
  left: -15px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from {
  opacity: 0;
}
.fade-leave-to {
  transition: none;
  opacity: 0;
}
</style>
