import { createI18n } from "vue-i18n";
import EnvironmentConfig from "./EnvironmentConfig";
import { locales } from "@/common/locales";
import VueCookies from "vue-cookies";
/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
  const locales = require.context(
    "./common/locales_data",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default;
    }
  });
  return messages;
}
const i18n = createI18n({
  legacy: false,
  locale:
    VueCookies.get("locale") ??
    locales.filter(
      (x) =>
        x.description.toLowerCase() ===
        EnvironmentConfig.Locale.Region.toLowerCase()
    )[0]?.code,
  messages: loadLocaleMessages(),
});

export default i18n;
