<template>
  <img
    :src="this.parseImageUrl(this, 800, 1600)"
    :alt="altText ?? asset.altText"
    data-test="resource-image"
  />
</template>

<script>
import { parseImageUrl } from "@/helpers";

export default {
  props: ["asset", "altText"],
  methods: {
    parseImageUrl,
  },
};
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";

img {
  height: 360px;
  // This makes the image exceed the div its in
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  position: relative;
  right: 50%;
  width: 100vw;

  object-fit: cover;
  display: block;
  margin-top: 32px; //accounting for 16px padding around text on sanity
  margin-bottom: 32px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
}

//Desktop
@include breakpoint(medium) {
  img {
    height: 400px;
    position: static;
    border-radius: 8px;
    max-width: 800px;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    left: unset;
    right: unset;
  }
}
</style>
