<template>
  <ion-card
    :class="smallCard ? 'small-card' : ''"
    data-test="course-card-skeleton"
  >
    <IonCardHeader>
      <h5>
        <ion-skeleton-text
          :animated="true"
          style="width: 40px"
        ></ion-skeleton-text>
      </h5>
      <h5>
        <ion-skeleton-text
          :animated="true"
          style="width: 80px"
        ></ion-skeleton-text>
      </h5>
      <p>
        <ion-skeleton-text
          :animated="true"
          style="width: 60%"
        ></ion-skeleton-text>
      </p>
      <div class="badge-wrapper">
        <ion-skeleton-text
          :animated="true"
          style="width: 10%"
        ></ion-skeleton-text>

        <ion-skeleton-text
          :animated="true"
          style="width: 10%"
        ></ion-skeleton-text>
      </div>
    </IonCardHeader>
    <IonCardContent>
      <ion-skeleton-text
        :animated="true"
        style="width: 100%, height: 100%"
      ></ion-skeleton-text>
    </IonCardContent>
  </ion-card>
</template>
<script>
import {
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonSkeletonText,
} from "@ionic/vue";
export default {
  name: "CardSkeleton",
  props: {
    smallCard: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonSkeletonText,
  },
};
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";
ion-card-header {
  padding: 10px;
  padding-bottom: 8px;
  text-align: left;
  width: 100%;
}
ion-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 0px;
  background-color: var(--background-white);
  box-shadow: var(--shadow-1);
  border-radius: 8px;
  height: 248px;
}
ion-card-content {
  padding: 0;
  height: 100%;
  width: 100%;
}
.small-card {
  width: 312px;
  height: 115px;
}

@include breakpoint(medium) {
  ion-card {
    width: 302px;
  }
}
</style>
