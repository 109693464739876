<template>
  <header role="banner">
    <ion-header role="">
      <div
        class="mobile-locale-selector"
        v-if="localeStore.getShowLocaleSwitcher"
      >
        <LocaleSelector
          data-test="mobile-locale-selector"
          :selectedLocaleCode="localeStore.currentLocale"
          :allowedLocaleCodes="localeStore.getAllowedLocaleCodes"
        ></LocaleSelector>
      </div>
    </ion-header>
  </header>
</template>
<script setup lang="ts">
import { IonHeader } from "@ionic/vue";
import LocaleSelector from "@/components/Common/LocaleSelector/LocaleSelector.vue";
import { useLocaleStore } from "@/store";
const localeStore = useLocaleStore();
</script>

<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";

ion-header {
  background-color: var(--background-white);
  border-bottom: 5px solid var(--ion-color-primary);
  min-height: 61px;
}

.header-md::after {
  background-image: none;
}

.mobile-locale-selector {
  position: absolute;
  top: 8px;
  right: 2px;
  z-index: var(--z-index-locale-selector);
}

/* Desktop layout */
@include breakpoint(medium) {
  header {
    display: none;
  }
}
</style>
