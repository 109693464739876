import { defineStore } from "pinia";

export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    // initialize state from local storage to enable user to stay logged in
    userInfo: JSON.parse(window.localStorage.getItem("userInfo")),
    idToken: JSON.parse(window.localStorage.getItem("idToken")),
    isConsultant: JSON.parse(window.localStorage.getItem("isConsultant")),
  }),
  actions: {
    storeIsConsultant(isConsultant = false) {
      this.isConsultant = isConsultant;
      window.localStorage.setItem("isConsultant", isConsultant);
    },
    storeAuthInfo(userInfo, idToken) {
      // TODO: update user token/auth object
      this.userInfo = userInfo;
      this.idToken = idToken;
      // store user details and jwt in local storage to keep user logged in between page refreshes
      window.localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
      window.localStorage.setItem("idToken", JSON.stringify(this.idToken));
    },
    clearAuthInfo() {
      this.userInfo = null;
      this.idToken = null;
      window.localStorage.removeItem("userInfo");
      window.localStorage.removeItem("idToken");
      window.sessionStorage.removeItem("returnUrl");
      window.localStorage.removeItem("termsAcceptanceRequired");
      window.localStorage.removeItem("isConsultant");
    },
  },
  getters: {
    getIsConsultant(state) {
      return state?.isConsultant ?? false;
    },
    getCurrentUserId(state) {
      return state?.userInfo ?? null;
    },
    getCurrentUserIdToken(state) {
      return state?.idToken ?? null;
    },
  },
});
