<template>
  <div v-if="isLoading" class="iframe-wrapper">
    <ion-skeleton-text :animated="true"></ion-skeleton-text>
  </div>
  <div v-else class="video-content">
    <div class="iframe-wrapper" v-html="document?.embedlink"></div>

    <alternative-activity
      id="alternative-activity"
      :show="!!document?.transcript"
      :labelExpanded="t('resource.video.transcriptExpanded')"
      :labelCollapsed="t('resource.video.transcriptCollapsed')"
    >
      <SanityBlocks :blocks="document?.transcript" />
    </alternative-activity>
  </div>
</template>

<script setup lang="ts">
import { defineProps, computed, onMounted } from "vue";
import { IonSkeletonText } from "@ionic/vue";
import AlternativeActivity from "@/components/Common/AlternativeActivity/AlternativeActivity.vue";
import { useSanityStore } from "@/store";
import { SanityBlocks } from "sanity-blocks-vue-component";
import { useI18n } from "vue-i18n";

const { t } = useI18n<{}>({
  useScope: "global",
});

const sanityStore = useSanityStore();

interface Props {
  _ref: string;
}
const props = defineProps<Props>();

const isLoading = computed(() => {
  return sanityStore.getLoadingFor(props._ref);
});

const document = computed(() => {
  // Using type any here until a type for a Sanity Video is deinfed and the SanityStore is migrated to composition API and typescript
  return sanityStore.getDocument(props._ref) as any;
});

onMounted(async () => {
  await sanityStore.requestDocument(props._ref);
});
</script>

<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";

.video-content {
  display: flex;
  flex-direction: column;
}

.iframe-wrapper {
  position: relative;
  width: 100%;
  background-color: var(--background-white);
  height: fit-content;

  @include breakpoint(xxsmall) {
    height: 180px;
  }
  @include breakpoint(xsmall) {
    height: 240px;
  }
  @include breakpoint(small) {
    height: 320px;
  }

  @include breakpoint(medium) {
    height: 320px;
  }
  @include breakpoint(large) {
    height: 395px;
  }
}
</style>
