<template>
  <ResumeBase
    :breadcrumbs="[
      {
        route: 'my-qualifications',
        label: t('resume.breadcrumbs.qualifications'),
        type: 'parent',
      },
      {
        label: t('resume.breadcrumbs.qualificationDetails'),
      },
    ]"
    mainContentId="resume-qualification-detail-main-content"
    :formSaveRetryFunction="saveForm"
    :skipOnRouteBeforeLeave="skipOnRouteBeforeLeave"
    :formDeleteFunction="deleteQualification"
    :triggerDeleteModal="triggerDeleteModal"
    :dismissDeleteModal="dismissDeleteModal"
  >
    <template v-slot:title>
      {{ t("resume.qualificationForm.title") }}
    </template>
    <template v-slot:description>
      {{ t("resume.qualificationForm.description") }}
    </template>
    <template v-slot:main-content>
      <form :aria-label="t('resume.qualificationForm.title')">
        <TextInput
          data-test="name-input"
          :errorText="errorMessageMap.get('name')"
          :labelText="t('resume.qualificationForm.nameInput.label')"
          :placeholderText="t('resume.qualificationForm.nameInput.placeholder')"
          :value="name || ''"
          :isLoading="resumeStore.isResumeLoading"
          :required="true"
          :errorIdPrefix="errorIdPrefix"
          @onChange="
            (event) => {
              validateName(event);
              setValue('name', event.detail.value);
            }
          "
          @onBlur="validateName"
          @onUpdate="updateErrorDescription"
        />
        <TextInput
          data-test="institution-input"
          :labelText="t('resume.qualificationForm.institutionInput.label')"
          :placeholderText="
            t('resume.qualificationForm.institutionInput.placeholder')
          "
          :value="institution || ''"
          :isLoading="resumeStore.isResumeLoading"
          @onChange="(event) => setValue('institution', event.detail.value)"
        />
        <CheckboxInput
          data-test="is-completed-input"
          :checked="!isQualificationComplete || false"
          :labelText="t('resume.qualificationForm.isCompletedInput.label')"
          :isLoading="resumeStore.isResumeLoading"
          @onChange="(checked) => onQualificationCompleteChange(checked)"
        />
        <TextInput
          data-test="end-date-input"
          class="end-date-input"
          :errorText="errorMessageMap.get('endDate')"
          :labelText="t('resume.qualificationForm.endDateInput.label')"
          :placeholderText="
            t('resume.qualificationForm.endDateInput.placeholder')
          "
          :value="endDate || ''"
          :isLoading="resumeStore.isResumeLoading"
          :disabled="!isQualificationComplete"
          :errorIdPrefix="errorIdPrefix"
          @onChange="(event) => setValue('endDate', event.detail.value)"
          @onBlur="validateEndDate"
          @onUpdate="updateErrorDescription"
        />
        <TextAreaInput
          data-test="description-input"
          :labelText="t('resume.qualificationForm.highlightsInput.label')"
          :errorText="errorMessageMap.get('highlights')"
          :maxLength="600"
          :placeholderText="
            t('resume.qualificationForm.highlightsInput.placeholder')
          "
          :autogrow="true"
          :value="highlights || ''"
          :isLoading="resumeStore.isResumeLoading"
          @onChange="
            (event) => {
              setValue('highlights', event.detail.value);
              validateHighlights(event);
            }
          "
        />
        <div class="buttons-container">
          <AppButton
            size="default"
            type="primary"
            :label="t('resume.qualificationForm.saveBtnLabel')"
            :ariaLabel="t('resume.qualificationForm.saveBtnLabel')"
            @click="saveForm"
            data-test="save-form-btn"
            class="save-form-btn"
            width="100%"
            :isLoading="isFormSaving"
            :disabled="!isFormSavingEnabled"
            :ariaRoleDescription="saveButtonDescription"
          />
          <AppButton
            size="default"
            type="secondary"
            :label="t('resume.qualificationForm.deleteBtnLabel')"
            :ariaLabel="t('resume.qualificationForm.deleteBtnLabel')"
            data-test="delete-form-btn"
            class="delete-form-btn"
            @click="triggerDelete"
            :isLoading="isQualificationDeleting"
            :disabled="!qualificationId"
            width="100%"
          />
          <AppButton
            size="default"
            type="textOnly"
            :label="t('resume.qualificationForm.closeBtnLabel')"
            :ariaLabel="t('resume.qualificationForm.closeBtnLabel')"
            data-test="close-form-btn"
            class="close-form-btn"
            id="closeBtn"
            @click="returnToPage"
            width="100%"
          />
        </div>
      </form>
    </template>
    <template v-slot:resume-helper> </template>
  </ResumeBase>
</template>
<script setup lang="ts">
import ResumeBase from "@/Layouts/ResumeBase/ResumeBase.vue";
import TextInput from "@/components/Common/Inputs/TextInput/TextInput.vue";
import TextAreaInput from "@/components/Common/Inputs/TextAreaInput/TextAreaInput.vue";
import CheckboxInput from "@/components/Common/Inputs/CheckboxInput/CheckboxInput.vue";
import AppButton from "@/components/Common/AppButton/AppButton.vue";
import { useResumeStore, useErrorStore } from "@/store";
import { Qualification } from "@/store/models/resume.models";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, onBeforeRouteLeave, useRoute } from "vue-router";
import { onIonViewDidEnter } from "@ionic/vue";
import { handleBeforeUnload, focusOnMainContent } from "@/helpers";
const { t } = useI18n<{}>({
  useScope: "global",
});
import { IonInputCustomEvent } from "@ionic/core/components";
import { collateErrorsInDescription, validateDateFormat } from "@/helpers";

const resumeStore = useResumeStore();
const errorStore = useErrorStore();
const router = useRouter();
const route = useRoute();

const skipOnRouteBeforeLeave = ref(false);
const triggerDeleteModal = ref(false);

onBeforeRouteLeave(() => {
  resumeStore.setQualificationFormInit(false);
  window.removeEventListener("beforeunload", handleBeforeUnload);
});

onIonViewDidEnter(() => {
  focusOnMainContent(route?.meta?.mainContentId);
  skipOnRouteBeforeLeave.value = false;
  window.addEventListener("beforeunload", handleBeforeUnload);
});

onMounted(async () => {
  if (!resumeStore.resumeId) {
    await resumeStore.requestResume();
    if (!resumeStore.resumeId) {
      await resumeStore.createResume();
    }
  }
  const qualificationId = router.currentRoute.value.params.id as string;
  qualificationId !== "new"
    ? resumeStore.initQualificationForm(qualificationId)
    : resumeStore.initQualificationForm();
  initValidationMap();
});

const qualificationId = computed(() => {
  return resumeStore.qualificationFormData?.id;
});

const institution = computed(() => {
  return resumeStore.qualificationFormData?.institution;
});

const name = computed(() => {
  return resumeStore.qualificationFormData?.name;
});

const isQualificationComplete = computed(() => {
  return resumeStore.qualificationFormData?.isQualificationComplete;
});

const endDate = computed(() => {
  return resumeStore.qualificationFormData?.endDate;
});

const highlights = computed(() => {
  return resumeStore.qualificationFormData?.highlights;
});

const isFormSaving = ref(false);
const isQualificationDeleting = ref(false);

const setValue = <K extends keyof Qualification>(
  property: K,
  value: Qualification[K] | null | undefined
) => {
  if (value == null || !property) return;
  resumeStore.setQualificationFormValue(property, value);
};

const saveForm = async () => {
  skipOnRouteBeforeLeave.value = true;
  errorStore.setSaveFormError(false);
  try {
    isFormSaving.value = true;
    if (qualificationId.value) {
      await resumeStore.updateQualification(qualificationId.value);
    } else {
      await resumeStore.createQualification();
      // TODO: When do we declare that qualifications are completed?
      await resumeStore.updateResumeProperty("isQualificationsCompleted", true);
    }
    router.push({
      name: "ResumeQualifications",
    });
  } catch (error) {
    console.error("An error occured saving the form: ", error);
    errorStore.setSaveFormError(true);
  } finally {
    isFormSaving.value = false;
  }
};

const triggerDelete = () => {
  triggerDeleteModal.value = true;
};

const deleteQualification = async () => {
  try {
    skipOnRouteBeforeLeave.value = true;
    isQualificationDeleting.value = true;
    if (qualificationId.value) {
      await resumeStore.deleteQualification(qualificationId.value);
    } else {
      throw new Error("Something went wrong");
    }
    router.push({
      name: "ResumeQualifications",
    });
  } finally {
    isQualificationDeleting.value = false;
    triggerDeleteModal.value = false;
  }
};

const dismissDeleteModal = () => {
  triggerDeleteModal.value = false;
};

const onQualificationCompleteChange = (checked: boolean) => {
  setValue("isQualificationComplete", !checked);
  if (checked) {
    setValue("endDate", "");
    validationMap.value.set("endDate", true);
    const itemEl = document.querySelector(".end-date-input ion-item");
    if (itemEl) {
      itemEl.classList.remove("ion-invalid");
    }
    return;
  }
};

const errorIdPrefix = ref("error-message-disabler");
const saveButtonDescription = ref("");
const updateErrorDescription = () => {
  saveButtonDescription.value = collateErrorsInDescription(
    document,
    errorIdPrefix.value
  );
};

const returnToPage = () => {
  router.push({
    name: "ResumeQualifications",
  });
};
/* VALIDATION */

const rules = {
  name: {
    required: t("resume.qualificationForm.nameInput.isRequiredError"),
  },
  endDate: {
    invalidFormat: t(
      "resume.qualificationForm.endDateInput.invalidFormatError"
    ),
  },
  highlights: {
    characterLimit: t(
      "resume.qualificationForm.highlightsInput.characterLimitError"
    ),
  },
};

const validationMap = ref<Map<keyof Qualification, boolean>>(new Map([]));
const initValidationMap = () => {
  validationMap.value
    .set("name", name.value !== undefined && name.value.length > 0)
    .set("endDate", true);
};

const errorMessageMap = ref<Map<keyof Qualification, string>>(
  new Map([
    ["name", ""],
    ["endDate", ""],
    ["highlights", ""],
  ])
);

const setValidity = (
  property: keyof Qualification,
  valid: boolean,
  error?: string
): void => {
  validationMap.value.set(property, valid);
  errorMessageMap.value.set(property, error ?? "");
  return;
};

const isFormSavingEnabled = computed(() => {
  for (let isValid of validationMap.value.values()) {
    if (!isValid) return false;
  }
  return true;
});

const validateName = (event: IonInputCustomEvent<FocusEvent>) => {
  const itemEl = event.target.parentElement;
  itemEl?.classList.remove("ion-invalid");
  const name = event.target.value;
  if (!name) {
    itemEl?.classList.add("ion-invalid");
    setValidity("name", false, rules.name.required);
  } else {
    setValidity("name", true);
  }
};

const validateEndDate = (event: IonInputCustomEvent<FocusEvent>) => {
  const itemEl = event.target.parentElement;
  itemEl?.classList.remove("ion-invalid");
  if (!isQualificationComplete.value) {
    setValue("endDate", "");
    setValidity("endDate", true);
    return;
  }
  const date = event.target.value as string;
  if (!date) {
    setValidity("endDate", true);
    return;
  }
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  if (!validateDateFormat(date)) {
    itemEl?.classList.add("ion-invalid");
    setValidity("endDate", false, rules.endDate.invalidFormat);
  } else {
    setValidity("endDate", true);
  }
};

const validateHighlights = (event: IonInputCustomEvent<FocusEvent>) => {
  const itemEl = event.target.parentElement;
  itemEl?.classList.remove("ion-invalid");
  const highlights = event.target.value as string;
  if (highlights && highlights.length >= 600) {
    itemEl?.classList.add("ion-invalid");
    errorMessageMap.value.set("highlights", rules.highlights.characterLimit);
  }
};
</script>

<style lang="scss" scoped>
.save-form-btn {
  margin-top: var(--spacing-16);
}

.text-input,
.checkbox-input {
  margin-bottom: var(--spacing-24);
}

.checkbox-input {
  margin-left: var(--spacing-4);
}

.text-input.location-input {
  margin-bottom: var(--spacing-4);
}

.buttons-container {
  max-width: 312px;
  display: flex;
  margin: auto;
  flex-direction: column;
  gap: var(--spacing-16);
  margin-top: var(--spacing-48);
}
</style>
