<template>
  <ion-page>
    <div class="content">
      <div
        class="feature-image"
        data-test="feature-image"
        :style="backgroundImageStyle"
      ></div>
      <div id="onboardingSlidesContainer">
        <ion-header class="ion-no-border">
          <ion-toolbar>
            <AppButton
              size="default"
              class="skip-button"
              type="textOnly"
              :label="$t('onboardingSlides.skipBtn')"
              :ariaLabel="$t('onboardingSlides.skipBtn')"
              @click="completeSlides"
              data-test="skip-button"
            />
            <div
              class="locale-selector"
              v-if="localeStore.getShowLocaleSwitcher"
            >
              <LocaleSelector
                :selectedLocaleCode="localeStore.currentLocale"
                :allowedLocaleCodes="localeStore.getAllowedLocaleCodes"
              ></LocaleSelector>
            </div>
          </ion-toolbar>
        </ion-header>
        <ion-content :forceOverscroll="false">
          <div class="content-container">
            <div
              class="character"
              :class="{ 'is-loading': onboardingSlidesStore.areSlidesLoading }"
            >
              <ion-skeleton-text
                v-if="!slides || onboardingSlidesStore.areSlidesLoading"
                :animated="true"
                style="width: 100%; height: 100%"
              ></ion-skeleton-text>
              <img
                v-else
                data-test="character"
                :src="slides[activeSlideIndex]?.character"
                :alt="$t('onboardingSlides.imageAlt')"
              />
            </div>
            <div class="slide-card">
              <div class="slide-text">
                <div>
                  <ion-skeleton-text
                    class="title"
                    v-if="!slides || onboardingSlidesStore.areSlidesLoading"
                    :animated="true"
                    style="width: 40%; height: 24px; margin-bottom: 16px"
                  ></ion-skeleton-text>
                  <h1
                    v-else
                    class="title"
                    id="slide-title"
                    data-test="title"
                    tabindex="-1"
                  >
                    {{ slides[activeSlideIndex]?.title }}
                  </h1>
                </div>
                <div class="main-text">
                  <div v-if="!slides || onboardingSlidesStore.areSlidesLoading">
                    <ion-skeleton-text
                      v-for="item in 3"
                      :key="item"
                      :animated="true"
                      style="width: 75%; height: 16px"
                    ></ion-skeleton-text>
                  </div>
                  <SanityBlocks
                    v-else
                    data-test="main-text"
                    :blocks="slides[activeSlideIndex]?.main_text"
                  />
                </div>
              </div>
              <div class="slide-action">
                <ProgressEllipsis
                  v-if="
                    onboardingSlidesStore.areSlidesLoading || slidesLength > 1
                  "
                  :isLoading="onboardingSlidesStore.areSlidesLoading"
                  :quantity="slidesLength"
                  :activeIndex="activeSlideIndex"
                  :aria-label="`${$t(
                    'onboardingSlides.progressEllipsis.page'
                  )} ${activeSlideIndex + 1} ${$t(
                    'onboardingSlides.progressEllipsis.of'
                  )} ${slidesLength}`"
                  class="ellipsis"
                  data-test="progress-ellipsis"
                />
                <AppButton
                  size="default"
                  width="100%"
                  class="next-button"
                  :showLoadingSkeleton="onboardingSlidesStore.areSlidesLoading"
                  type="primary"
                  :label="
                    activeSlideIndex + 1 === slidesLength
                      ? $t('onboardingSlides.completeBtn')
                      : $t('onboardingSlides.nextBtn')
                  "
                  :ariaLabel="
                    activeSlideIndex + 1 === slidesLength
                      ? $t('onboardingSlides.completeBtn')
                      : $t('onboardingSlides.nextBtn')
                  "
                  @click="nextSlide"
                  data-test="next-button"
                />
                <AppButton
                  :style="{
                    visibility: activeSlideIndex > 0 ? 'visible' : 'hidden',
                  }"
                  size="default"
                  class="back-button"
                  type="textOnly"
                  :label="$t('onboardingSlides.backBtn')"
                  :ariaLabel="$t('onboardingSlides.backBtn')"
                  @click="previousSlide"
                  data-test="back-button"
                />
              </div>
            </div>
          </div>
        </ion-content>
      </div>
    </div>
  </ion-page>
</template>

<script setup lang="ts">
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonSkeletonText,
} from "@ionic/vue";
import LocaleSelector from "@/components/Common/LocaleSelector/LocaleSelector.vue";
import AppButton from "@/components/Common/AppButton/AppButton.vue";
import ProgressEllipsis from "@/components/ProgressEllipsis/ProgressEllipsis.vue";
import { useLocaleStore, useOnboardingSlidesStore } from "@/store";
import { computed, nextTick, onBeforeMount, ref } from "vue";
import { OnboardingSlide } from "@/store/models/onboardingSlide.models";
import { SanityBlocks } from "sanity-blocks-vue-component";
import { useRouter } from "vue-router";

const localeStore = useLocaleStore();
const onboardingSlidesStore = useOnboardingSlidesStore();
const router = useRouter();

const slides = ref<Array<OnboardingSlide> | undefined | null>();
const activeSlideIndex = ref(0);

const slidesLength = computed(() => {
  return slides.value?.length ?? 0;
});

onBeforeMount(async () => {
  await onboardingSlidesStore.getOnboardingSlides();
  slides.value = onboardingSlidesStore.getIncompleteSlides;
  if (!slides.value || !(slides.value.length > 0)) {
    router.push({ name: "Home", replace: true });
  } else {
    await preloadImages(slides.value);
  }
});

const themeName = computed(() => {
  return localeStore.getCurrentLocaleTheme.toLowerCase();
});

const backgroundImageStyle = computed(() => {
  // TODO: this seems to be outdated. Update with all existing locales
  const hasImgAsset = ["global", "ca", "sg", "au", "nz"].includes(
    themeName.value
  );
  if (!hasImgAsset) {
    return {
      backgroundColor: "rgba(var(--ion-color-secondary-rgb), 0.2)",
    };
  }
  const img = require(`@/common/assets/welcome/feature-image/${themeName.value}.webp`);
  const backgroundImageStyle = { backgroundImage: `url(${img})` };
  return backgroundImageStyle;
});

const preloadImages = async (slides: Array<OnboardingSlide>) => {
  for (const slide of slides) {
    const image = new Image();
    image.src = slide.character;
  }
};

const nextSlide = () => {
  if (activeSlideIndex.value + 1 === slidesLength.value) {
    completeSlides();
    return;
  }
  activeSlideIndex.value += 1;
  focusOnTitle();
};

const previousSlide = () => {
  activeSlideIndex.value -= 1;
  focusOnTitle();
};

const focusOnTitle = async () => {
  await nextTick(() => {
    const titleEl = document?.querySelector("#slide-title") as HTMLElement;
    if (titleEl) {
      titleEl.focus();
    }
  });
};

const completeSlides = async () => {
  const slidesIds = slides.value?.map((slide) => slide._id);
  if (slidesIds) {
    await onboardingSlidesStore.updateOnboardingSlides(slidesIds);
  }
  router.push({ name: "Home", replace: true });
};
</script>

<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";

ion-toolbar {
  --min-height: 70px;
  --background: none;
}

ion-content {
  --background: none;
}

.locale-selector {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: var(--z-index-locale-selector);
  border: 1px solid var(--lighter-grey);
  border-radius: 8px;
  background: var(--background-white);
}

.skip-button {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: var(--z-index-locale-selector);
}

.content {
  display: flex;
  height: 100vh;
  width: 100vw;
  @include breakpoint(medium) {
    display: block;
    position: relative;
  }
}

.feature-image {
  visibility: hidden;

  @include breakpoint(medium) {
    visibility: visible;
    position: absolute;
    width: 55vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

#onboardingSlidesContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  right: 0;

  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  background-color: var(--ion-color-primary-banner-bg, #fff);

  box-shadow: -8px 0px 16px 0px #0000003d;

  @include breakpoint(medium) {
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    width: 50vw;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    background-color: var(--background-white);
  }
}

.content-container {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  @include breakpoint(medium) {
    border-bottom-left-radius: 16px;
  }
  .character {
    overflow: hidden;
    &.is-loading {
      height: 40vh;
      margin-bottom: 10vh;
      width: 50%;
    }
    @include breakpoint(medium) {
      min-height: 417px;
      &.is-loading {
        margin-bottom: 0;
      }
    }
  }
  .slide-card {
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 348px;
    padding: var(--spacing-24);
    border-radius: var(--spacing-24) var(--spacing-24) var(--spacing-0)
      var(--spacing-0);
    background: #fff;
    box-shadow: 0px -3px 5px 0px rgba(0, 0, 0, 0.1);
    @include breakpoint(medium) {
      box-shadow: none;
      border-radius: 0;
      padding: var(--spacing-48);
    }
    .slide-text,
    .slide-action {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .slide-text {
      text-align: -webkit-center;
      max-width: 584px;
      & > div {
        width: 100%;
      }
      #slide-title:focus-visible {
        outline: none;
      }
    }
    .title {
      color: var(--text-primary);
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
    }
    .main-text {
      color: var(--text-tertiary);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
      padding-bottom: var(--spacing-24);
    }
    .ellipsis {
      margin-bottom: var(--spacing-24);
    }
    .next-button {
      max-width: 312px;
      margin-bottom: var(--spacing-8);
    }
    .back-button {
      padding: var(--spacing-12) var(--spacing-16);
    }
  }
}
</style>
