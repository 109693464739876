import { useFeatureFlagStore } from "@/store";
import EnvironmentConfig from "@/EnvironmentConfig";

export function getAzureAuthority() {
  const featureFlagStore = useFeatureFlagStore();
  if (!featureFlagStore.isInitialized) {
    featureFlagStore.initialize();
  }
  let mfaEnabled = featureFlagStore.isFlagEnabled(
    "configure-component-b2c-mfa",
    true
  );
  if (mfaEnabled) {
    return EnvironmentConfig.Azure.AuthorityMfa;
  } else {
    return EnvironmentConfig.Azure.Authority;
  }
}
