import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";
import EnvironmentConfig from "./EnvironmentConfig";

/**
 * This is needed given that analytics.plugins.disable() does not work as expected.
 * Only desired plugins need to be loaded on init
 */
const plugins = [];
if (EnvironmentConfig.Analytics.Google.Enabled === "true") {
  const gaPlugin = googleAnalytics({
    measurementIds: [EnvironmentConfig.Analytics.Google.TrackingId],
  });
  plugins.push(gaPlugin);
}

const analytics = Analytics({
  app: "employableme",
  plugins: plugins,
});

export default analytics;
