<template>
  <ion-page data-test="error-page">
    <MobileHeader></MobileHeader>
    <ion-content>
      <DesktopLocaleSelector></DesktopLocaleSelector>
      <div class="page-wrapper" id="error-page-main-content" tabindex="0">
        <div class="page-content">
          <div class="character-wrapper">
            <div class="hero-character-confused"></div>
            <SpeechBubble class="speech-bubble-mobile">
              {{ $t(`error.${errorType}.friendlyText`) }}
            </SpeechBubble>
          </div>
          <div class="error-text-wrapper">
            <SpeechBubble class="speech-bubble-desktop" pageType="sign-in-md">
              {{ $t(`error.${errorType}.friendlyText`) }}
            </SpeechBubble>
            <h4 data-test="error-header">
              {{ $t(`error.${errorType}.name`) }}
            </h4>
            <p>{{ $t(`error.${errorType}.description`) }}</p>
            <p>{{ $t(`error.${errorType}.solutionText`) }}</p>
            <ion-button
              color="primary"
              size="default"
              expand="block"
              @click="
                $router.push({
                  name: 'Home',
                })
              "
              data-test="error-home-button"
              >{{ $t("navigation.home") }}</ion-button
            >
            <p id="support-text">
              {{ $t("error.supportText") }}
              <a
                :href="'mailto:' + $t('supportEmail')"
                data-test="error-support-link"
                >{{ $t("supportEmail") }}</a
              >
            </p>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonButton } from "@ionic/vue";
import MobileHeader from "@/components/Common/MobileHeader/MobileHeader.vue";
import SpeechBubble from "@/components/Common/SpeechBubble/SpeechBubble.vue";
import DesktopLocaleSelector from "@/components/Common/LocaleSelector/DesktopLocaleSelector.vue";

export default {
  name: "error-page",
  components: {
    IonPage,
    IonContent,
    IonButton,
    MobileHeader,
    SpeechBubble,
    DesktopLocaleSelector,
  },
  props: {
    errorType: { type: String },
  },
};
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";
.page-wrapper {
  box-shadow: var(--shadow-0);
  border-radius: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  padding-left: 12px;
  padding-right: 12px;
}

.page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 260px;
  width: 100%;
  margin: auto;
}

h4 {
  margin-top: 0px;
  color: var(--button-text-primary);
}

.error-text-wrapper {
  width: 100%;
  text-align: left;
  margin-top: 19px;
  color: var(--dark-grey);
}

#support-text {
  text-align: center;
}

.welcome::before {
  border: 0.5px solid rgba(var(--ion-color-darkgrey-rgb), 0.4);
  left: -10px;
}

.character-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
}

.hero-character-confused {
  background: url("@/assets/em-platformGuide-confused.svg");
  height: 100%;
  width: 170px;
  margin-right: 18px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  clip-path: polygon(
    50% 0%,
    100% 0,
    100% 43%,
    100% 96%,
    68% 98%,
    32% 98%,
    0 96%,
    0% 43%,
    0 0
  );
}

.speech-bubble-mobile,
.speech-bubble-desktop {
  width: 50%;
  height: fit-content;
  box-shadow: 4px 4px 0px 0px rgba(var(--ion-color-darkgrey-rgb), 0.4);
  border-color: rgba(var(--ion-color-darkgrey-rgb), 0.4);
  &:after {
    border-color: rgba(var(--ion-color-darkgrey-rgb), 0.4);
  }
  // Override speech bubble
  :deep(.message) {
    /* H6 Reg */
    font-size: 18px;
    line-height: 21px;
    color: var(--button-text-primary);
  }
}

.speech-bubble-desktop {
  display: none;
}

@include breakpoint(small) {
  .speech-bubble-mobile:deep(.message) {
    /* H5 Reg */
    font-size: 24px;
    line-height: 28px;
  }
}

@include breakpoint(medium) {
  .page-wrapper {
    padding-left: 50px;
    padding-right: 50px;
  }

  .page-content {
    max-width: 556px;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
  }

  .error-text-wrapper {
    max-width: 260px;
    width: 60%;
  }

  h4 {
    margin-top: 27px;
  }

  .speech-bubble-mobile {
    display: none;
  }
  .speech-bubble-desktop {
    display: block;
    padding-left: 0px;
    margin-left: 0px;
    width: 100%;
    margin-top: 27px;
    margin-bottom: 0px;

    // Override speech bubble
    :deep(.message) {
      /* H4 Reg */
      font-size: 34px;
      line-height: 40px;
    }
  }

  .character-wrapper {
    margin: 0px 20px 0px 0px;
    height: 650px;
    width: 35%;
    max-width: 170px;
    justify-content: flex-end;
  }

  .hero-character-confused {
    max-height: unset;
    clip-path: unset;
    background-size: contain;
    margin-right: 0;
  }
}
</style>
