import { checkmarkCircleOutline } from "ionicons/icons";
import EnvironmentConfig from "@/EnvironmentConfig";
import { locales } from "@/common/locales";
import { Course } from "@/store";
import { CourseProgress } from "./models/courseProgress";

const locale = locales.filter(
  (x) =>
    x.description.toLowerCase() ===
    EnvironmentConfig.Locale.Region?.toLowerCase()
)[0];
const progressIcon = require(`@/assets/progressEllipse/progress-ellipse-${locale.theme}.svg`);

const courseProgressMap = {
  inProgress: {
    text: "statusLabel.inProgress",
    icon: progressIcon,
  },
  complete: {
    text: "statusLabel.completed",
    icon: checkmarkCircleOutline,
  },
};

export function getCourseProgress(course: Course): CourseProgress | undefined {
  if (
    course.totalResourceCount > 0 &&
    course.totalResourceCount == course.completedResourceCount
  ) {
    return courseProgressMap.complete;
  } else if (course.completedResourceCount > 0) {
    return courseProgressMap.inProgress;
  } else {
    return undefined;
  }
}
