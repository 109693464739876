<template>
  <ion-card>
    <IonCardHeader>
      <ion-badge
        v-if="courseStatus"
        class="status-badge"
        :class="{
          'complete-label': isCourseComplete,
          'in-progress-label': !isCourseComplete,
        }"
        data-test="status-badge"
      >
        <ion-icon :icon="courseStatusIcon" aria-hidden="true"></ion-icon>
        <span>
          <p class="title">{{ $t(courseStatus) }}</p>
        </span>
      </ion-badge>
      <h4
        v-if="title"
        :class="
          this.type === 'course'
            ? ' title inner-title truncate'
            : 'title truncate'
        "
      >
        {{ title }}
      </h4>
      <p v-if="description" class="description truncate">{{ description }}</p>
      <div v-if="!resourceType" class="badge-wrapper">
        <ion-badge data-test="type-badge" color="secondary">{{
          $t("course.label")
        }}</ion-badge>
      </div>
      <div v-else class="badge-wrapper">
        <ion-badge
          data-test="type-badge"
          v-for="type in resourceType"
          :key="type"
          color="secondary"
          >{{ $t(mapResourceType(type)) }}</ion-badge
        >
      </div>
    </IonCardHeader>
    <IonCardContent>
      <img :src="getThumbnail" alt="" data-test="thumbnail" />
    </IonCardContent>
  </ion-card>
</template>
<script>
import {
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonIcon,
  IonBadge,
} from "@ionic/vue";
import { timeOutline } from "ionicons/icons";
import { mapResourceType } from "@/helpers";
export default {
  name: "CourseOrCollectionCard",
  components: {
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonIcon,
    IonBadge,
  },
  props: {
    type: { type: String },
    title: { type: String },
    description: { type: String },
    resourceType: { type: Array, required: false },
    thumbnail: { type: String },
    placeholderImg: { type: String },
    loading: { type: Boolean },
    courseStatus: { type: String },
    courseStatusIcon: { type: String },
  },
  data() {
    return {
      time: timeOutline,
    };
  },
  computed: {
    getThumbnail() {
      if (this.thumbnail) {
        return this.thumbnail;
      }
      return this.placeholderImg;
    },
    isCourseComplete() {
      return this.courseStatus === "statusLabel.completed" ? true : false;
    },
  },
  methods: {
    mapResourceType,
  },
};
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";
ion-card-header {
  padding: 10px;
  padding-bottom: 8px;
  text-align: left;
  width: 100%;
}
ion-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 250px;
  margin-left: 0px;
  background-color: var(--background-white);
  box-shadow: var(--shadow-1);
  border-radius: 8px;
}
ion-card-content {
  padding: 0;
  height: 100%;
  width: 100%;
}
.title {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  letter-spacing: 0.012em;
  color: var(--text-black);
  margin-bottom: 5px;
  margin-top: 8px;
}
.collection-card .title {
  margin-top: 0px;
}
.inner-title {
  font-size: 18px;
  margin: 0px;
}
.description {
  margin-top: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--dark-grey);
  padding-left: 0;
  margin-left: 0;
  margin-bottom: 8px;
}
img {
  width: inherit;
  height: 100%;
  object-fit: cover;
}

.truncate {
  /* Truncate text to 2 lines */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

ion-icon {
  vertical-align: middle;
  font-size: 14px;
  margin-right: 2px;
}
</style>
