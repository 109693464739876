import canadaIcon from "./assets/locales/canada.svg";
import australiaIcon from "./assets/locales/australia.svg";
import singaporeIcon from "./assets/locales/singapore.svg";
import newZealandIcon from "./assets/locales/newzealand.svg";
import unitedKingdomIcon from "./assets/locales/unitedkingdom.svg";

const australia = [
  {
    code: "en-AU",
    displayCode: "ENG",
    displayName: "English",
    description: "Australia",
    icon: australiaIcon,
    theme: "au",
    sanityLocaleId: "__i18n_en_AU",
  },
];

const canada = [
  {
    code: "en-CA",
    displayCode: "ENG",
    displayName: "English",
    description: "Canada",
    icon: canadaIcon,
    theme: "ca",
    sanityLocaleId: "__i18n_en_CA",
  },
  {
    code: "fr-CA",
    displayCode: "FRE",
    displayName: "Français",
    description: "Canada",
    icon: canadaIcon,
    theme: "ca",
    sanityLocaleId: "__i18n_fr_CA",
  },
];

const newZealand = [
  {
    code: "en-NZ",
    displayCode: "ENG",
    displayName: "English",
    description: "New Zealand",
    icon: newZealandIcon,
    theme: "nz",
    sanityLocaleId: "__i18n_en_NZ",
  },
];

const singapore = [
  {
    code: "en-SG",
    displayCode: "ENG",
    displayName: "English",
    description: "Singapore",
    icon: singaporeIcon,
    theme: "sg",
    sanityLocaleId: "__i18n_en_SG",
  },
];

const global = [
  {
    code: "en",
    displayCode: "ENG",
    displayName: "English",
    description: "Global",
    icon: unitedKingdomIcon,
    theme: "global",
    sanityLocaleId: "",
  },
];

const locales = [
  ...global,
  ...australia,
  ...canada,
  ...newZealand,
  ...singapore,
];
const localeCodes = locales.map((x) => x.code);

export {
  locales,
  localeCodes,
  australia,
  canada,
  global,
  newZealand,
  singapore,
};
