<template>
  <ion-page>
    <MobileHeader></MobileHeader>
    <ion-content data-test="learn-detail" class="page-wrapper">
      <DesktopLocaleSelector></DesktopLocaleSelector>
      <PageBanner
        :isImageLoading="!isAssessmentLoaded"
        :imagePath="bannerImage"
      ></PageBanner>
      <div v-if="!isAssessmentLoaded" class="page-wrapper">
        <AssessmentDetailSkeleton></AssessmentDetailSkeleton>
      </div>
      <div
        v-else
        class="page-wrapper"
        id="assessment-detail-main-content"
        tabindex="-1"
      >
        <div class="breadcrumb-wrapper">
          <Breadcrumbs
            v-if="assessment"
            tab="tools"
            :doc="assessment.title"
            class="breadcrumbs"
          ></Breadcrumbs>
        </div>
        <div class="content-frame-wrapper">
          <div class="header-wrapper">
            <ion-badge v-if="assessmentScoresExist" id="completion-badge">
              <ion-icon
                :icon="checkmarkCircleOutline"
                aria-hidden="true"
              ></ion-icon>
              {{ $t("assessment.quizCompleted") }}
            </ion-badge>
            <h1
              role="heading"
              aria-level="1"
              class="page-title"
              data-test="title"
            >
              {{ assessment.title }}
            </h1>
            <LastCompleted
              v-if="assessmentCompletedDate"
              :last-completed="assessmentCompletedDate"
              :text="$t('assessment.lastCompleted')"
            />
          </div>
          <div class="badge-wrapper">
            <AssessmentBadges
              :label="assessment.badge"
              :time="assessment.time"
              :timeUnit="$t('assessment.mins')"
            />
          </div>
          <div v-if="assessmentScoresExist" class="results-wrapper">
            <AssessmentScores />
          </div>

          <div
            v-if="!isAssessmentRecommendationsLoaded"
            class="assessment-recommendations-wrapper"
            data-test="assessment-recommendations-skeleton"
          >
            <AssessmentRecommendationsSkeleton />
          </div>
          <div
            v-if="assessmentRecommendationsExist"
            class="assessment-recommendations-outer-wrapper"
          >
            <div
              class="assessment-recommendations-wrapper"
              data-test="assessment-recommendations"
            >
              <AssessmentRecommendations />
            </div>
          </div>
          <div class="assessment-detail-wrapper">
            <AssessmentDetailContent
              :assessment="assessment"
              :assessmentScoresExist="assessmentScoresExist"
              :incompleteAssessmentExist="incompleteAssessmentExist"
            />
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import { ref, computed, defineProps } from "vue";
import { IonPage, IonContent, IonBadge, IonIcon } from "@ionic/vue";
import MobileHeader from "@/components/Common/MobileHeader/MobileHeader.vue";
import { checkmarkCircleOutline } from "ionicons/icons";
import { useAssessmentStore, useErrorStore, useLocaleStore } from "@/store";
import PageBanner from "@/components/Common/PageBanner/PageBanner.vue";
import Breadcrumbs from "@/components/Common/Breadcrumbs/Breadcrumbs.vue";
import AssessmentDetailSkeleton from "@/components/Skeletons/AssessmentDetailSkeleton/AssessmentDetailSkeleton.vue";
import DesktopLocaleSelector from "@/components/Common/LocaleSelector/DesktopLocaleSelector.vue";
import { createLocaleSpecificSanityId } from "@/helpers/CreateLocaleSpecificSanityId.js";
import AssessmentDetailContent from "@/components/Assessment/AssessmentDetailContent/AssessmentDetailContent.vue";
import AssessmentScores from "@/components/Assessment/AssessmentScores/AssessmentScores.vue";
import AssessmentRecommendations from "@/components/Assessment/AssessmentRecommendations/AssessmentRecommendations.vue";
import AssessmentBadges from "@/components/Assessment/AssessmentBadges/AssessmentBadges.vue";
import LastCompleted from "@/components/Assessment/LastCompleted/LastCompleted.vue";
import { onIonViewDidEnter } from "@ionic/vue";
import { parseImageUrl } from "@/helpers";
import isEmpty from "lodash/isEmpty";
import AssessmentRecommendationsSkeleton from "@/components/Skeletons/AssessmentRecommendationsSkeleton/AssessmentRecommendationsSkeleton.vue";

const assessmentStore = useAssessmentStore();
const localeStore = useLocaleStore();
const assessment = computed(() => assessmentStore.getAssessmentDetail ?? null);
const props = defineProps({
  id: { type: String, required: true },
});

const isAssessmentLoaded = computed(
  () =>
    assessmentStore.isAssessmentDetailLoaded &&
    assessment?.value.id &&
    assessment?.value.baseId === props.id &&
    assessmentStore.isAssessmentResultsLoaded
);

const assessmentCompletedDate = computed(() => {
  return assessmentStore.getAssessmentResults?.data?.completedDate;
});

const bannerImage = ref(require("@/assets/course-thumbnail-placeholder.png"));

const assessmentScoresExist = computed(
  () => !isEmpty(assessmentStore.getAssessmentResults?.data?.totalScore)
);

const incompleteAssessmentExist = computed(
  () => assessmentStore.getIncompleteAssessment !== null
);

const isAssessmentRecommendationsLoaded = computed(() => {
  return assessmentStore.isAssessmentRecommendationsLoaded;
});

const assessmentRecommendationsExist = computed(
  () => !isEmpty(assessmentStore.getAssessmentRecommendations)
);

const updateBannerImage = (): void => {
  if (assessment.value.featureImage) {
    bannerImage.value = parseImageUrl(
      assessment?.value.featureImage,
      1000,
      2500
    );
  }
};

const loadAssessment = async (id: string): Promise<void> => {
  await assessmentStore.requestAssessmentDetail(id);
  updateBannerImage();
  const baseSanityId = assessmentStore.getAssessmentDetail.baseId;
  await assessmentStore.requestAssessmentResults(baseSanityId);
};

onIonViewDidEnter(async (): Promise<void> => {
  if (assessment?.value.id && assessment?.value.baseId === props.id) {
    // Assessment is already loaded
    return;
  }
  //load the resource using the sanity ID with the correct locale suffix added.
  const sanityId = createLocaleSpecificSanityId(props.id);
  loadAssessment(sanityId).catch((error) => {
    if (
      //if the resource specific to the locale selected cannot be accessed, and the locale selected is an english locale, load the global english version.
      error.response.status === 403 &&
      localeStore.getCurrentLocaleCode.includes("en")
    ) {
      loadAssessment(props.id as string);
    } else {
      //otherwise, if the locale selected is not an english locale and the non english locale version cannot be accessed, load an error state.
      assessmentStore.$reset();
      const errorStore = useErrorStore();
      errorStore.setGenericError(true);
    }
  });
});
</script>

<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";

// Do not add left, right padding to page-wrapper. The carousel has to have 100% width
.page-wrapper {
  z-index: 9;
  padding-left: 0;
  padding-right: 0;
}

.breadcrumb-wrapper {
  padding: 0 48px;
}

.content-frame-wrapper {
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--dark-grey);
}

.header-wrapper,
.badge-wrapper,
.results-wrapper,
.assessment-detail-wrapper {
  text-align: left;
  width: 100%;
  padding: 0 24px;

  @include breakpoint(medium) {
    max-width: 700px;
    width: calc(100% - 96px);
    padding: 0;
  }

  @include breakpoint(large) {
    margin: auto;
    padding: 0;
  }
}

// Adjust margin-bottom to 8px to cater for marnig top of <p> tags in sanity data
.badge-wrapper {
  margin: 24px 0 8px;
}

.results-wrapper {
  margin-bottom: 48px;
}

.assessment-recommendations-outer-wrapper {
  width: 100%;
  background-color: var(--lighter-grey);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.assessment-recommendations-wrapper {
  max-width: calc(100% - 48px);
  margin: 48px 0;
}

.assessment-detail-wrapper {
  margin-top: 48px;
}

ion-badge > ion-icon {
  vertical-align: middle;
  font-size: 14px;
  margin-right: 2px;
}

ion-badge {
  vertical-align: middle;
  line-height: 14px;
}

#completion-badge {
  vertical-align: middle;
  line-height: 14px;
  display: flex;
  padding: 9px 19px 9px 10px;
  align-items: center;
  gap: 8px;
  border-radius: 42px;
  color: var(--background-white);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.25px;
  border: 1px solid var(--ion-color-primary-banner-bg);
  background: var(--ion-color-primary);
  width: fit-content;
  margin-bottom: 24px;
  ion-icon {
    font-size: 24px;
  }
}

.page-title {
  font-size: 36px;
  color: var(--button-text-primary);
  line-height: 44px;
  font-weight: 500;
  margin: 0 0 8px;
}

//Desktop
@include breakpoint(medium) {
  ion-header {
    height: 0;
  }

  ion-button {
    max-width: 312px;
    min-width: 312px;
  }

  .breadcrumbs {
    margin-bottom: 24px;
  }

  // Do not add left, right padding to page-wrapper. The carousel has to have 100% width
  .page-wrapper {
    padding: 24px 0 50px;
  }
}
@include breakpoint(large) {
  .content-frame-wrapper {
    padding: auto;
  }
}
</style>
