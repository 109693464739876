<template>
  <ion-modal
    ref="modal"
    :is-open="isOpen"
    :backdrop-dismiss="false"
    @didPresent="setChatIframeVisibility('none')"
    @didDismiss="setChatIframeVisibility('block')"
    aria-labelledby="modal-name"
    :aria-label="this.title"
    role="dialog"
  >
    <p id="modal-name" style="display: none">terms and conditions</p>
    <div class="content-wrapper">
      <ion-title id="termsTitle" class="termsTitle" size="small">{{
        this.title
      }}</ion-title>
      <p class="termsDescription">
        <SanityBlocks :blocks="this.intro" :serializers="serializers" />
      </p>
      <ion-button
        data-test="terms_conditions_btn"
        expand="block"
        color="primary"
        size="default"
        @click="acceptTermsAndConditions"
      >
        {{ $t("TermsAndConditionsModal.acceptButton") }}
      </ion-button>
      <ion-button
        data-test="signout_btn"
        expand="block"
        fill="outline"
        color="secondary"
        size="default"
        @click="signOut"
        >{{ $t("TermsAndConditionsModal.signOutButton") }}
      </ion-button>
    </div>
  </ion-modal>
</template>

<script>
import { IonButton, IonModal, IonTitle } from "@ionic/vue";
import { SanityBlocks } from "sanity-blocks-vue-component";
import LinkSerializer from "@/components/Serializers/LinkSerializer.vue";
import { setChatIframeVisibility } from "@/helpers";
export default {
  name: "terms-and-conditions",
  components: {
    IonModal,
    IonButton,
    IonTitle,
    SanityBlocks,
  },
  props: {
    isOpen: { type: Boolean },
    title: { type: String },
    intro: {
      type: Array,
    },
  },
  data() {
    return {
      serializers: {
        marks: {
          link: LinkSerializer,
        },
      },
    };
  },
  methods: {
    acceptTermsAndConditions() {
      this.$emit("onAcceptTC");
      this.$refs.modal.$el.dismiss();
    },
    signOut() {
      this.$emit("onSignOut");
      this.$refs.modal.$el.dismiss();
    },
    setChatIframeVisibility,
  },
};
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";
/* T&C's modal */
ion-modal {
  --height: auto;
  --width: auto;
  --border-radius: 16px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  --max-width: 470px;
}

ion-button {
  margin-top: 24px;
}

ion-title {
  /* Replicating old H5 properties */
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.012em;
  font-size: 24px;
  line-height: 28px;
}
.content-wrapper {
  padding: 24px;
  text-align: left;
}

.termsTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  color: var(--ion-color-secondary);
  margin-top: 0;
}
.termsDescription {
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-bottom: 24px;
}
/* Galaxy fold device */
@include breakpoint(xxsmall) {
  ion-modal {
    padding-left: 12px;
    padding-right: 12px;
  }
}
</style>
<!-- This is to override the stylings from the SanityBlocks component -->
<style lang="scss">
.termsDescription a {
  color: var(--hyperlink-blue);
  font-size: 16px;
  text-decoration-line: underline;
  padding: 1px 2px;

  &:hover {
    color: var(--hyperlink-hover-blue);
  }

  &:focus-visible {
    color: var(--hyperlink-hover-blue);
    outline: 2px solid var(--ion-color-secondary);
    border-radius: 3px;
  }
}
</style>
