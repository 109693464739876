<template>
  <div v-if="isLoading" class="skeleton-wrapper" data-test="skeleton-wrapper">
    <ion-skeleton-text :animated="true" class="skeleton-text" />
    <ion-skeleton-text
      :animated="true"
      class="skeleton-input"
      :style="{ height: `${startingHeight}px` }"
    />
    <ion-skeleton-text :animated="true" class="skeleton-text right" />
    <ion-skeleton-text :animated="true" class="skeleton-text full" />
    <ion-skeleton-text :animated="true" class="skeleton-text full" />
    <ion-skeleton-text :animated="true" class="skeleton-text long" />
  </div>
  <div v-else class="textarea-input-wrapper">
    <ion-item class="textarea-container" lines="none" data-test="input-item">
      <ion-label
        position="stacked"
        aria-hidden="true"
        class="textarea-label"
        data-test="input-label"
        >{{ labelText }}</ion-label
      >
      <ion-textarea
        :value="value"
        @ionChange="handleOnChange"
        @ionBlur="emit('onBlur', $event)"
        @ionFocus="emit('onFocus', $event)"
        :placeholder="placeholderText"
        :maxlength="maxLength"
        class="textarea-input"
        :spellcheck="true"
        :auto-grow="autogrow"
        :style="{ minHeight: `${startingHeight}px` }"
        data-test="textarea-input"
        :aria-describedby="`char-counter-${uid} ${errorIdPrefix}-${uid}`"
      ></ion-textarea>
      <div
        class="char-counter"
        data-test="char-counter"
        aria-hidden="true"
        :id="`char-counter-${uid}`"
      >
        {{ textValue.length }}/{{ maxLength }}
        {{ $t("form.textAreaInput.charCounterText") }}
      </div>
      <ion-note
        v-if="errorText"
        aria-hidden="true"
        :id="`${errorIdPrefix}-${uid}`"
        slot="error"
        class="error-text"
        data-test="error-text"
        ><div class="error-helper-text-wrapper">
          {{ errorText }}
          <span
            v-if="showErrorHelper"
            class="helper-text"
            data-test="embedded-helper-text"
          >
            {{ helperText }}</span
          >
        </div></ion-note
      >
      <ion-note
        v-if="helperText"
        slot="helper"
        class="helper-text"
        data-test="helper-text"
        >{{ helperText }}</ion-note
      >
    </ion-item>
  </div>
</template>

<script setup lang="ts">
import {
  IonTextarea,
  IonLabel,
  IonItem,
  IonNote,
  IonSkeletonText,
} from "@ionic/vue";
import {
  ref,
  defineProps,
  withDefaults,
  defineEmits,
  onUpdated,
  onMounted,
  getCurrentInstance,
} from "vue";
import {
  IonTextareaCustomEvent,
  TextareaChangeEventDetail,
} from "@ionic/core/components";

interface Props {
  value: string;
  labelText: string;
  placeholderText?: string;
  maxLength?: number;
  helperText?: string;
  errorText?: string;
  autogrow?: boolean;
  startingHeight?: number;
  showErrorHelper?: boolean;
  isLoading?: boolean;
  errorIdPrefix?: string;
}

const props = withDefaults(defineProps<Props>(), {
  maxLength: 1200,
  autogrow: false,
  startingHeight: 152,
  showErrorHelper: false,
  isLoading: false,
  errorIdPrefix: "error-message",
});

const uid = ref<number | null | undefined>(null);
onMounted(() => {
  uid.value = getCurrentInstance()?.uid;
});

const emit = defineEmits(["onChange", "onBlur", "onFocus"]);

const textValue = ref(props.value);

function handleOnChange(
  event: IonTextareaCustomEvent<TextareaChangeEventDetail>
) {
  textValue.value = event.detail?.value?.substring(0, props.maxLength) || "";
  emit("onChange", event);
}

onUpdated(async () => {
  textValue.value = props.value.substring(0, props.maxLength);
});
</script>

<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";
ion-label.textarea-label {
  margin-top: var(--spacing-0);
  margin-bottom: var(--spacing-8);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: var(--text-primary);
}
ion-textarea.textarea-input {
  --padding-start: var(--spacing-20);
  --padding-end: var(--spacing-20);
  --padding-top: var(--spacing-16);
  --padding-bottom: var(--spacing-16);
  --background: white;
  border: 2px solid var(--grey-400);
  border-radius: 8px;
  --color: var(--text-primary);
  --background: var(--background-white);
  --placeholder-color: var(--text-tertiary);
  --placeholder-opacity: 100;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
}
ion-item.textarea-container {
  --padding-start: var(--spacing-0);
  --inner-padding-end: var(--spacing-0);
  &.item-has-focus {
    ion-textarea.textarea-input {
      border-color: var(--ion-color-primary);
    }
  }
  &.ion-invalid {
    ion-textarea.textarea-input {
      border-color: var(--error);
      --placeholder-color: var(--error);
    }
    .char-counter {
      color: var(--error);
    }
  }
}
ion-note.helper-text,
ion-note.error-text {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;
  margin-top: var(--spacing-16);
  padding: var(--spacing-0);
}
ion-note.error-text {
  --color: var(--error);
  margin-top: var(--spacing-16);
}
ion-note.helper-text {
  --color: var(--text-secondary);
  margin-top: var(--spacing-16);
}
div.error-helper-text-wrapper {
  display: flex;
  flex-direction: column;
}
span.helper-text {
  margin-top: var(--spacing-16);
  color: var(--text-secondary);
}
.char-counter {
  margin: var(--spacing-16) var(--spacing-0);
  margin-bottom: var(--spacing-0);
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: var(--text-secondary);
}
.skeleton-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
}
.skeleton-wrapper ion-skeleton-text {
  margin-top: var(--spacing-0);
  margin-bottom: var(--spacing-0);
}
.skeleton-text {
  width: 120px;
  height: 20px;
}
.skeleton-text.long {
  width: 213px;
}
.skeleton-text.full {
  width: 100%;
  margin-bottom: var(--spacing-2);
}
.skeleton-text.right {
  align-self: flex-end;
  margin-top: var(--spacing-8);
  margin-bottom: var(--spacing-8);
}
.skeleton-input {
  border: 2px solid var(--skeleton-border);
  border-radius: 8px;
}
</style>
