<template>
  <div class="carousel-wrapper">
    <TitleSkeleton v-if="programStore.isProgramCarouselLoading"></TitleSkeleton>
    <h5 v-else-if="programCarousel?.topics?.length > 0" class="page-title">
      {{ programCarousel.title }}
    </h5>
    <swiper
      v-if="programStore.isProgramCarouselLoading"
      width="312"
      :spaceBetween="16"
      :initialSlide="0"
      :slidesOffsetAfter="48"
      :slidesOffsetBefore="48"
    >
      <swiper-slide v-for="index in 4" :key="index">
        <CarouselCardSkeleton class="carousel-cards"></CarouselCardSkeleton>
      </swiper-slide>
    </swiper>
    <swiper
      v-else
      @swiper="onSwiper"
      :initialSlide="0"
      slidesPerView="auto"
      :spaceBetween="16"
      :slidesOffsetAfter="48"
      :slidesOffsetBefore="48"
      :navigation="true"
      :modules="modules"
      :watchSlidesProgress="true"
    >
      <swiper-slide
        v-for="(topic, index) in programCarousel?.topics"
        :key="topic._id"
        data-test="program-carousel"
      >
        <router-link
          class="card-wrapper"
          :to="{ name: 'ResourceDetail', params: { id: topic.base_id } }"
          @focus="onSwiperCardFocus(index, swiper)"
        >
          <ResourceCard
            data-test="carousel-card"
            class="carousel-card"
            :document="topic"
            :completed="topic.completed"
          />
        </router-link>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation } from "swiper";
import { RouterLink } from "vue-router";
import CarouselCardSkeleton from "../Skeletons/CarouselCardSkeleton/CarouselCardSkeleton.vue";
import TitleSkeleton from "../Skeletons/TitleSkeleton/TitleSkeleton.vue";
import ResourceCard from "../Resource/ResourceCard/ResourceCard.vue";
import { onSwiperCardFocus } from "@/helpers";
import { useProgramStore } from "@/store";
import { computed, onMounted, ref } from "vue";

SwiperCore.use(Navigation);

export default {
  name: "ProgramCarousel",

  components: {
    Swiper,
    SwiperSlide,
    ResourceCard,
    RouterLink,
    CarouselCardSkeleton,
    TitleSkeleton,
  },
  setup() {
    const swiper = ref(null);
    const programStore = useProgramStore();

    onMounted(() => {
      programStore.requestProgramCarousel();
    });

    const onSwiper = (swiperInstance) => {
      swiper.value = swiperInstance;
    };

    const programCarousel = computed(() => {
      return programStore.getProgramCarousel;
    });

    return {
      modules: Navigation,
      swiper,
      onSwiper,
      onSwiperCardFocus,
      programStore,
      programCarousel,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";
@import "@/common/theme/swiper.scss";

.carousel-wrapper {
  margin: 22px 0 16px 0;
}
.swiper {
  margin-right: -24px;
  margin-left: -48px;
  position: relative;
}
.card-wrapper {
  display: flex;
  margin: 16px 0 16px 0;
  box-shadow: var(--shadow-1);
  border-radius: 16px;
  text-decoration: none;
  transition: box-shadow var(--transition-time);
}

.carousel-card {
  width: inherit;
  height: inherit;
  margin: 0;
}
@include breakpoint(medium) {
  // handles focus on safari
  *:focus {
    outline-width: thin;
    outline-color: var(--ion-color-secondary);
    overflow: hidden;
  }

  .card-wrapper {
    &:focus-visible {
      outline: 2px solid var(--ion-color-secondary);
      box-shadow: inset 0px 0px 0px 1px var(--background-white);
    }
  }

  .carousel-card {
    transition: box-shadow var(--transition-time);
    &:hover {
      box-shadow: var(--shadow-2);
    }
    &:focus-visible {
      outline: 2px solid var(--ion-color-secondary);
      box-shadow: inset 0px 0px 0px 1px var(--background-white);
    }
  }

  .swiper :deep(.swiper-button-prev),
  .swiper :deep(.swiper-button-next) {
    height: 129px;
    top: calc(100% - 144px);
  }
}
</style>
