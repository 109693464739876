<template>
  <ion-page>
    <ion-content data-test="assessment-question" class="page-wrapper">
      <PageBanner
        :imagePath="bannerImage"
        data-test="banner-image"
      ></PageBanner>
      <div
        class="page-wrapper"
        id="assessment-question-main-content"
        tabindex="-1"
      >
        <Breadcrumbs
          tab="tools"
          parentType="quiz"
          :parent="assessment.baseId"
          :parentTitle="assessment?.title"
          doc="Quiz"
          data-test="breadcrumbs"
        ></Breadcrumbs>
        <div class="assessment-wrapper">
          <h1
            v-if="assessment?.title"
            role="heading"
            id="assessment-title"
            tabindex="0"
            aria-level="1"
            class="page-title"
            data-test="title"
          >
            {{ assessment?.title }}
          </h1>
          <div id="question-wizard" data-test="question-wizard">
            <QuestionWizard
              v-if="assessmentItem"
              :assessmentItem="assessmentItem"
              :questionIndex="assessmentItemIndex + 1"
              :questionCount="questionCount"
              :previousResponse="savedResponse"
              @prev-click="previousQuestion()"
              @next-click="nextQuestion()"
              @save-response="saveResponse($event)"
              :key="assessmentItemIndex + 1"
              :isNextLoading="isNextLoading"
              :isPrevLoading="isPrevLoading"
            ></QuestionWizard>
          </div>
        </div>
      </div>

      <ion-modal
        id="saving-in-progress-modal"
        :is-open="displaySavingInProgressModal"
      >
        <ion-content class="ion-padding">
          <h1>{{ $t("assessmentSavingInProgressModal.title") }}</h1>
          <ion-progress-bar type="indeterminate"></ion-progress-bar>
        </ion-content>
      </ion-modal>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import { IonPage, IonContent, IonModal, IonProgressBar } from "@ionic/vue";
import { useAssessmentStore, UserQuestionResponse } from "@/store";
import PageBanner from "@/components/Common/PageBanner/PageBanner.vue";
import Breadcrumbs from "@/components/Common/Breadcrumbs/Breadcrumbs.vue";
import QuestionWizard from "@/components/Assessment/QuestionWizard/QuestionWizard.vue";
import { computed, ref, onMounted, onUpdated, nextTick } from "vue";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import { parseImageUrl } from "@/helpers";
import debounce from "lodash/debounce";

const displaySavingInProgressModal = ref(false);
const assessmentStore = useAssessmentStore();
const router = useRouter();

const assessmentItemIndex = computed<number>(() => {
  return assessmentStore.getCurrentItemIndex || 0;
});

const savedResponses = computed<Array<number>>(() => {
  return assessmentStore.getCurrentSavedResponses;
});

const isNextLoading = ref(false);
const isPrevLoading = ref(false);

const bannerImage = ref(require("@/assets/course-thumbnail-placeholder.png"));

onMounted(async () => {
  if (assessment.value.featureImage) {
    bannerImage.value = parseImageUrl(
      assessment?.value.featureImage,
      1000,
      2500
    );
  }
});

onUpdated(async () => {
  const { isAssessmentDetailLoaded, isAssessmentStarted } = assessmentStore;
  const { fullPath } = router.currentRoute.value;
  if (
    (!isAssessmentDetailLoaded || !isAssessmentStarted) &&
    fullPath?.startsWith("/tabs/tools/") &&
    fullPath?.endsWith("/questions")
  ) {
    // Assessment is not loaded navigate to tools home page
    router.push({ name: "Tools", replace: true });
    return;
  }

  await focusOnTitle();
});

onBeforeRouteLeave((to, from, next) => {
  const assessmentStore = useAssessmentStore();
  assessmentStore.initialiseAssessment();
  next();
});

const assessment = computed(() => {
  return assessmentStore.getAssessmentDetail ?? null;
});

const assessmentItem = computed(() => {
  const items = assessment.value?.items;
  if (
    !items ||
    assessmentItemIndex.value < 0 ||
    assessmentItemIndex.value >= items.length
  ) {
    return undefined;
  }
  return items[assessmentItemIndex.value];
});

const savedResponse = computed(() => {
  if (
    assessmentItem.value &&
    assessmentItemIndex.value >= 0 &&
    assessmentItemIndex.value <= savedResponses.value.length - 1
  ) {
    return savedResponses.value[assessmentItemIndex.value];
  }
  return undefined;
});

const questionCount = computed(() => {
  return assessment.value.items?.length || 0;
});

const saveResponse = (event: UserQuestionResponse): void => {
  assessmentStore.saveUserQuestionResponse(event);
};

const startSavingInProgressTimer = debounce(() => {
  displaySavingInProgressModal.value = true;
}, 5000);

const cancelSavingInProgressTimer = () => {
  startSavingInProgressTimer.cancel();
  displaySavingInProgressModal.value = false;
};

const nextQuestion = async (): Promise<void> => {
  startSavingInProgressTimer();
  if (assessmentItemIndex.value === assessment.value.items.length - 1) {
    completeAssessment();
    return;
  }
  try {
    isNextLoading.value = true;
    await assessmentStore.saveItemAndContinue();
    assessmentStore.setCurrentItem(assessmentItemIndex.value + 1);
  } finally {
    cancelSavingInProgressTimer();
    isNextLoading.value = false;
    await focusOnTitle();
  }
};

const completeAssessment = async () => {
  try {
    isNextLoading.value = true;
    await assessmentStore.completeAssessment();
    if (assessmentStore.isAssessmentCompleted) {
      const assessmentId = assessment.value.baseId;
      resetQuiz();
      router.push({
        name: "AssessmentDetail",
        params: { id: assessmentId },
      });
    }
  } finally {
    cancelSavingInProgressTimer();
    isNextLoading.value = false;
  }
};

const previousQuestion = async (): Promise<void> => {
  if (assessmentItemIndex.value > 0) {
    try {
      isPrevLoading.value = true;
      startSavingInProgressTimer();
      await assessmentStore.saveItemAndContinue();
      assessmentStore.setCurrentItem(assessmentItemIndex.value - 1);
    } finally {
      cancelSavingInProgressTimer();
      isPrevLoading.value = false;
      await focusOnTitle();
    }
  }
};

const focusOnTitle = async (): Promise<void> => {
  await nextTick(() => {
    const title = document.getElementById("assessment-title");
    if (title) {
      title.focus();
    }
  });
};

const resetQuiz = (): void => {
  assessmentStore.initialiseAssessment();
};
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";

.page-wrapper {
  z-index: 9;
}

.assessment-wrapper {
  width: 100%;
  max-width: 700px;
  margin: 24px auto;
}

.page-title {
  font-size: 24px;
  color: var(--button-text-primary);
  line-height: 32px;
  font-weight: 500;
  margin: 0 0 24px;
  text-align: left;
  max-width: 700px;

  &:focus {
    outline: 0;
  }
}

.content-wrapper {
  color: var(--dark-grey);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.button-wrapper {
  justify-content: space-between;
  overflow: hidden;
  clear: both;
}

#warning-modal,
#saving-in-progress-modal {
  text-align: left;
  --border-radius: 24px;
  --ion-padding: 24px;

  h1 {
    color: var(--text-black);
    font-size: 22px;
    font-weight: 500;
    line-height: 28px;
    margin: 0 0 16px;
  }

  .modal-text {
    color: var(--dark-grey);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 16px 5px 0 0;
  }

  ion-progress-bar {
    margin-top: 16px;
  }
}

#warning-modal {
  --max-width: 350px;
  --height: 232px;
}

#saving-in-progress-modal {
  --max-width: 315px;
  --height: 96px;
}
#question-wizard {
  ion-card-content.card-content-md p {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.027px;
    color: var(--button-text-primary);
  }
}
</style>
