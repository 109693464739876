<template>
  <ion-card class="card" data-test="info-card">
    <ion-card-header>
      <ion-card-title class="title">{{ $t(title) }}</ion-card-title>
    </ion-card-header>
    <ion-card-content class="body" data-test="info-body">
      <p>
        {{
          profileInfo.name ||
          profileInfo.givenName + " " + profileInfo.familyName
        }}
      </p>
      <p>{{ profileInfo.mobile || profileInfo.work_phone }}</p>
      <p>{{ profileInfo.email }}</p>
    </ion-card-content>
  </ion-card>
</template>

<script>
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from "@ionic/vue";

export default {
  components: {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
  },
  props: {
    profileInfo: {
      type: Object,
      required: true,
    },
    title: { type: String },
  },
};
</script>

<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";
.card {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--background-white);
  /* Base02 */

  box-shadow: var(--shadow-1);
  border-radius: 16px;
  margin: 0 0 16px 0;
  max-width: 100%;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0015em;
  color: var(--button-text-primary);
}

.body {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.001em;
  color: var(--button-text-primary);
  text-align: left;
}

.body p {
  margin-bottom: 8px;
}

@include breakpoint(medium) {
  .card {
    margin-right: 12px;
    max-width: 312px;
  }
}
</style>
