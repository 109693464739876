<template>
  <div class="card-holder loading-holder" v-if="!isAssessmentsLoaded">
    <card-skeleton class="assessment-card" v-for="index in 2" :key="index" />
  </div>
  <div v-else-if="assessmentsExist" class="card-holder">
    <router-link
      v-for="item in assessments"
      :to="{
        name: 'AssessmentDetail',
        params: { id: item.baseId },
      }"
      :key="item.id"
      class="card-wrapper"
      data-test="router-link"
    >
      <AssessmentCard
        data-test="card-holder-assessment"
        class="assessment-card"
        :assessment="item"
        :thumbnail="parseFeatureImage(item.featureImage)"
        :placeholderImg="require('@/assets/course-thumbnail-placeholder.png')"
      />
    </router-link>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import AssessmentCard from "@/components/Assessment/AssessmentCard/AssessmentCard.vue";
import "swiper/css";
import { SanityAssessment, useAssessmentStore } from "@/store";
import { RouterLink } from "vue-router";
import { parseImageUrl } from "@/helpers";
import CardSkeleton from "@/components/Skeletons/CardSkeleton/CardSkeleton.vue";

const assessmentStore = useAssessmentStore();

const isAssessmentsLoaded = computed(() => assessmentStore.isAssessmentsLoaded);
const assessmentsExist = computed(
  () => assessmentStore.getAssessments.length > 0
);
const assessments = computed<Array<SanityAssessment>>(
  () => assessmentStore.getAssessments
);

const parseFeatureImage = (image: any) => {
  return parseImageUrl(image, 300, 300) || undefined;
};
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";
.page-wrapper {
  z-index: 9;
}

.title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.collection-card {
  padding: 0px;
  height: 300px;
  left: 0px;
  top: 0px;
  background: var(--background-white);
  border-radius: 16px;
  z-index: -1;
  box-shadow: var(--shadow-0);
}

.swiper {
  margin-right: -24px;
  margin-left: -24px;
}
.card-holder {
  margin-right: 12px;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 16px;
}
.card-wrapper {
  max-height: 262px;
}
// handles focus on safari
*:focus {
  outline-width: thin;
  outline-color: var(--ion-color-secondary);
  overflow: hidden;
}

.collection-wrapper {
  border-radius: 16px;
  margin-bottom: 12px;
  margin-right: 14px;
  position: relative;
  text-decoration: none;
  transition: box-shadow var(--transition-time);
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1), 6px 6px 0px #ffffff,
    8px 8px 3px rgba(0, 0, 0, 0.1), 12px 12px 0px #ffffff,
    14px 15px 2px rgba(0, 0, 0, 0.1);
  &:hover {
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1), 6px 6px 0px #ffffff,
      8px 8px 3px rgba(0, 0, 0, 0.1), 12px 12px 0px #ffffff,
      18px 18px 14px rgba(0, 0, 0, 0.2);
  }
  &:focus-visible,
  &:focus {
    border-radius: 16px !important;
    outline-color: var(--ion-color-secondary);
    overflow: unset !important;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1), 6px 6px 0px #ffffff,
      8px 8px 3px rgba(0, 0, 0, 0.1), 12px 12px 0px #ffffff,
      14px 15px 2px rgba(0, 0, 0, 0.1);
  }
  &:active {
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1), 6px 6px 0px #ffffff,
      8px 8px 3px rgba(0, 0, 0, 0.1), 12px 12px 0px #ffffff,
      14px 15px 2px rgba(0, 0, 0, 0.1);
  }
}

.card-wrapper {
  border-radius: 8px;
  text-decoration: none;
  transition: box-shadow var(--transition-time);

  &:focus-visible,
  &:focus {
    outline-color: var(--ion-color-secondary);
    overflow: unset !important;
    box-shadow: var(--shadow-0);
  }
  &:hover {
    box-shadow: var(--shadow-2);
  }
  &:active {
    box-shadow: var(--shadow-1);
  }
  width: inherit;
}

.content-pack-wrapper {
  height: 100%;
  border-radius: 16px;
}

@include breakpoint(medium) {
  .swiper-cards {
    display: none;
  }
  .collection-card,
  .content-pack-card {
    max-width: 302px;
  }
  .collection-card {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    border-radius: 16px;
  }
  .collection-wrapper {
    margin-right: 16px;
  }
  .content-pack-card {
    height: 100%;
    margin: 0;
  }
  .card-holder {
    display: flex;
    flex-wrap: wrap;
    & a {
      text-decoration: none;
    }
    gap: 16px;
  }
  .title-md {
    margin-bottom: 14px;
  }
}
</style>
