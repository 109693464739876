<template>
  <div v-if="show" data-test="course-cards">
    <div class="card-holder loading-holder" v-if="courseStore.isCoursesLoading">
      <CardSkeleton
        class="course-card"
        v-for="index in 5"
        :key="index"
      ></CardSkeleton>
    </div>
    <div v-else class="card-holder">
      <router-link
        v-for="item in courseStore.getCourses.slice(0, 5)"
        :to="{
          name: 'CourseDetail',
          params: { id: item.base_id },
        }"
        :key="item._id"
        class="card-wrapper"
      >
        <CourseOrCollectionCard
          data-test="card-holder-course"
          class="course-card"
          type="course"
          :title="item.title"
          :description="item.description"
          :thumbnail="this.parseFeatureImage(item.feature_image)"
          :placeholderImg="require('@/assets/course-thumbnail-placeholder.png')"
          :courseStatus="this.getCourseProgress(item)?.text"
          :courseStatusIcon="this.getCourseProgress(item)?.icon"
        ></CourseOrCollectionCard>
      </router-link>
    </div>
    <swiper
      class="swiper-cards"
      :spaceBetween="16"
      :slidesPerView="1.6"
      :initialSlide="0"
      :slidesOffsetAfter="24"
      :slidesOffsetBefore="24"
      v-if="courseStore.isCoursesLoading"
    >
      <swiper-slide v-for="index in 5" :key="index">
        <CardSkeleton
          class="course-card"
          data-test="course-card-skeleton"
        ></CardSkeleton
      ></swiper-slide>
    </swiper>
    <swiper
      class="swiper-cards"
      :spaceBetween="16"
      :slidesPerView="1.6"
      :initialSlide="0"
      :slidesOffsetAfter="24"
      :slidesOffsetBefore="24"
      v-else
    >
      <swiper-slide
        class="course-slider"
        v-for="item in courseStore.getCourses.slice(0, 5)"
        :key="item._id"
        data-test="swiper-cards-course"
      >
        <router-link
          :to="{
            name: 'CourseDetail',
            params: { id: item.base_id },
          }"
          class="card-wrapper"
        >
          <CourseOrCollectionCard
            class="course-card"
            type="course"
            :title="item.title"
            :description="item.description"
            :thumbnail="this.parseFeatureImage(item.feature_image)"
            :placeholderImg="
              require('@/assets/course-thumbnail-placeholder.png')
            "
            :courseStatus="this.getCourseProgress(item)?.text"
            :courseStatusIcon="this.getCourseProgress(item)?.icon"
          ></CourseOrCollectionCard>
        </router-link>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import CourseOrCollectionCard from "@/components/CourseOrCollectionCard/CourseOrCollectionCard.vue";
import CardSkeleton from "@/components/Skeletons/CardSkeleton/CardSkeleton.vue";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/vue";
import { useSanityStore, useCourseStore } from "@/store";
import { RouterLink } from "vue-router";
import { parseImageUrl, getCourseProgress } from "@/helpers";

export default {
  name: "courseCards",
  components: {
    CourseOrCollectionCard,
    Swiper,
    SwiperSlide,
    RouterLink,
    CardSkeleton,
  },
  setup() {
    const store = useSanityStore();
    const courseStore = useCourseStore();
    return { store, courseStore };
  },
  props: {
    show: { type: Boolean },
  },
  methods: {
    getCourseProgress,
    parseImageUrl,
    parseFeatureImage(image) {
      return this.parseImageUrl(image, 300, 300);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";
.page-wrapper {
  z-index: 9;
}

.page-bg {
  background: var(
    --ion-color-primary-banner-bg
  ); // this is to prevent the grey corners under the banner
}
.title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.course-card {
  height: 250px;
  max-width: inherit;
  background: var(--background-white);
  box-shadow: var(--shadow-1);
  border-radius: 8px;
  margin-left: 0;
  margin-right: 0;
}

.collection-card {
  padding: 0px;
  height: 300px;
  left: 0px;
  top: 0px;
  background: var(--background-white);
  border-radius: 16px;
  z-index: -1;
  box-shadow: var(--shadow-0);
}

.swiper {
  margin-right: -24px;
  margin-left: -24px;
}
.card-holder {
  display: none;
  margin-right: 12px;
}
// handles focus on safari
*:focus {
  outline-width: thin;
  outline-color: var(--ion-color-secondary);
  overflow: hidden;
}

.collection-wrapper {
  border-radius: 16px;
  margin-bottom: 12px;
  margin-right: 14px;
  position: relative;
  text-decoration: none;
  transition: box-shadow var(--transition-time);
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1), 6px 6px 0px #ffffff,
    8px 8px 3px rgba(0, 0, 0, 0.1), 12px 12px 0px #ffffff,
    14px 15px 2px rgba(0, 0, 0, 0.1);
  &:hover {
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1), 6px 6px 0px #ffffff,
      8px 8px 3px rgba(0, 0, 0, 0.1), 12px 12px 0px #ffffff,
      18px 18px 14px rgba(0, 0, 0, 0.2);
  }
  &:focus-visible,
  &:focus {
    border-radius: 16px !important;
    outline-color: var(--ion-color-secondary);
    overflow: unset !important;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1), 6px 6px 0px #ffffff,
      8px 8px 3px rgba(0, 0, 0, 0.1), 12px 12px 0px #ffffff,
      14px 15px 2px rgba(0, 0, 0, 0.1);
  }
  &:active {
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1), 6px 6px 0px #ffffff,
      8px 8px 3px rgba(0, 0, 0, 0.1), 12px 12px 0px #ffffff,
      14px 15px 2px rgba(0, 0, 0, 0.1);
  }
}

.card-wrapper {
  border-radius: 8px;
  text-decoration: none;
  transition: box-shadow var(--transition-time);

  &:focus-visible,
  &:focus {
    outline-color: var(--ion-color-secondary);
    overflow: unset !important;
    box-shadow: var(--shadow-0);
  }
  &:hover {
    box-shadow: var(--shadow-2);
  }
  &:active {
    box-shadow: var(--shadow-1);
  }
  width: inherit;
}

.content-pack-wrapper {
  height: 100%;
  border-radius: 16px;
}

@include breakpoint(medium) {
  .swiper-cards {
    display: none;
  }
  .collection-card,
  .content-pack-card {
    max-width: 302px;
  }
  .collection-card {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    border-radius: 16px;
  }
  .collection-wrapper {
    margin-right: 16px;
  }
  .content-pack-card {
    height: 100%;
    margin: 0;
  }
  .card-holder {
    display: flex;
    flex-wrap: wrap;
    & a {
      text-decoration: none;
    }
    gap: 12px;
  }
  .course-card {
    max-width: 228px;
    margin: 0px;
  }
  .title-md {
    margin-bottom: 14px;
  }
}
// Overrides for loading content cards to be a larger size.
.loading-holder > ion-card {
  width: 302px;
  height: 248px;
}
</style>
