<template>
  <ResumeBase
    :breadcrumbs="[
      {
        route: 'licences',
        label: $t('resume.breadcrumbs.licences'),
      },
    ]"
    mainContentId="resume-licences-main-content"
    :skipOnRouteBeforeLeave="true"
  >
    <template v-slot:title>
      {{ $t("resume.licences.title") }}
    </template>
    <template v-slot:description>
      {{ $t("resume.licences.description") }}
    </template>
    <template v-slot:main-content>
      <div class="card-wrapper" v-if="resumeStore.isResumeLoading">
        <div class="card-item" v-for="item in 2" :key="item">
          <PreviewCard :isLoading="true"></PreviewCard>
        </div>
      </div>
      <div class="card-wrapper" v-else>
        <div
          class="card-item"
          v-for="licence in sortLicences(resumeStore?.resume?.licences)"
          :key="licence.id"
        >
          <PreviewCard
            :id="licence.id"
            :title="licence.name"
            :isLoading="resumeStore.isResumeLoading"
            data-test="preview-card"
            :button-text="$t('resume.licences.editButton')"
            @click="editLicences(licence.id)"
            ><template v-slot:metadata>
              <p>{{ licence.issuer }}</p>
              <p>
                {{ convertToMonthYear(licence.issueDate, $t) }}
                <span v-if="licence.issueDate && licence.expiryDate"> - </span>
                {{ convertToMonthYear(licence.expiryDate, $t) }}
              </p>
            </template>
            <template v-if="licence.description" v-slot:description
              ><p>{{ licence.description }}</p></template
            >
          </PreviewCard>
        </div>
      </div>
      <div class="buttons-container">
        <AppButton
          size="default"
          type="primary"
          :label="$t('resume.licences.addButton')"
          :ariaLabel="$t('resume.licences.addButton')"
          data-test="add-btn"
          @click="addLicence"
          width="100%"
          :showLoadingSkeleton="resumeStore.isResumeLoading"
        />
        <AppButton
          size="default"
          type="secondary"
          :label="$t('resume.licences.returnButtonText')"
          :ariaLabel="$t('resume.licences.returnButtonText')"
          data-test="return-btn"
          @click="$router.push({ name: 'ResumeOverview' })"
          width="100%"
          :showLoadingSkeleton="resumeStore.isResumeLoading"
        />
      </div>
    </template>
    <template v-slot:resume-helper> </template>
  </ResumeBase>
</template>
<script setup lang="ts">
import ResumeBase from "@/Layouts/ResumeBase/ResumeBase.vue";
import AppButton from "@/components/Common/AppButton/AppButton.vue";
import PreviewCard from "@/components/Resume/PreviewCard/PreviewCard.vue";
import { useResumeStore } from "@/store";
import { onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { onIonViewDidEnter } from "@ionic/vue";
import {
  convertToMonthYear,
  sortLicences,
  focusOnMainContent,
} from "@/helpers";
const resumeStore = useResumeStore();
const router = useRouter();
const route = useRoute();

onIonViewDidEnter(() => {
  focusOnMainContent(route?.meta?.mainContentId);
});

onMounted(async () => {
  if (!resumeStore.resumeId) {
    await resumeStore.requestResume();
    if (!resumeStore.resumeId) {
      await resumeStore.createResume();
    }
  }
});

function addLicence() {
  resumeStore.initLicenceForm();
  router.push({
    name: "ResumeLicenceDetail",
    params: { id: "new" },
  });
}

function editLicences(id: string) {
  resumeStore.initLicenceForm(id);
  router.push({
    name: "ResumeLicenceDetail",
    params: { id: id },
  });
}
</script>
<style scoped lang="scss">
.buttons-container {
  max-width: 312px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);
}

.card-wrapper {
  margin-bottom: var(--spacing-24);
  .card-item {
    margin-bottom: var(--spacing-16);
  }
}
</style>
