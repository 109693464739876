import { defineStore } from "pinia";
import { ref, computed } from "vue";
import http from "../http-common";
import { useLocaleStore } from "@/store";
import { Course } from "./models/course.models";

export const useCourseStore = defineStore("course", () => {
  // State
  const courseDetail = ref({
    loading: false,
    document: {} as Course,
  });
  const courses = ref({
    loading: false,
    documents: [] as Array<Course>,
  });
  const coursesInProgress = ref({
    loaded: false,
    documents: [] as Array<Course>,
  });

  // Getters:
  const isCourseDetailLoading = computed(() => {
    return courseDetail.value.loading;
  });
  const getCourseDetail = computed(() => {
    return courseDetail.value.document;
  });
  const isCoursesLoading = computed(() => {
    return courses.value.loading;
  });
  const getCourses = computed(() => {
    return courses.value.documents;
  });
  const getCoursesInProgress = computed(() => {
    return coursesInProgress.value.documents;
  });
  const isCoursesInProgressLoaded = computed(() => {
    return coursesInProgress.value.loaded;
  });

  // Actions
  const requestCourseDetail = async (
    sanityDocumentId: string
  ): Promise<void> => {
    courseDetail.value.loading = true;
    return http
      .get(`course/${sanityDocumentId}`)
      .then((response) => {
        courseDetail.value.document = {
          _id: response.data.sanityData[0]._id,
          base_id: response.data.sanityData[0].base_id,
          description: response.data.sanityData[0].description,
          feature_image: response.data.sanityData[0].feature_image,
          name: response.data.sanityData[0].name,
          title: response.data.sanityData[0].title,
          _type: response.data.sanityData[0]._type,
          topics: response.data.sanityData[0].topics,
          parent: response.data.sanityData[0].parent,
          completedResourceCount: response.data.completedResourceCount,
          totalResourceCount: response.data.totalResourceCount,
        };
      })
      .catch((e) => {
        throw e;
      })
      .finally(() => {
        courseDetail.value.loading = false;
      });
  };

  const requestCourses = async (): Promise<void> => {
    courses.value.loading = true;
    const path = `courses?locale=${useLocaleStore().getCurrentLocaleCode}`;

    return http
      .get(path)
      .then((response) => {
        response.data.data.forEach((document: any) => {
          const docIndex = courses.value.documents.findIndex(
            (doc: any) => doc._id == document._id
          );
          if (docIndex === -1) {
            courses.value.documents.push(mapResponseToCourse(document));
          } else {
            courses.value.documents[docIndex] = mapResponseToCourse(document);
          }
        });
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        courses.value.loading = false;
      });
  };

  const requestCoursesInProgress = async (orderBy = ""): Promise<void> => {
    coursesInProgress.value.loaded = false;
    coursesInProgress.value.documents = [];
    const path = `courses?locale=${
      useLocaleStore().getCurrentLocaleCode
    }&selfProgress=true&orderBy=${orderBy}`;

    return http
      .get(path)
      .then((response) => {
        coursesInProgress.value.documents = response.data.data.map(
          (document: any) => mapResponseToCourse(document)
        );
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        coursesInProgress.value.loaded = true;
      });
  };

  const updateResourceCompletedCount = (resourceCompleted: boolean) => {
    // Update resource completed count for individual course
    courseDetail.value.document.completedResourceCount += resourceCompleted
      ? 1
      : -1;

    // Update resource completed count within courses
    // If the course is found, update its completedResourceCount
    const courseIndex = courses.value.documents.findIndex(
      (course) => course._id === courseDetail.value.document._id
    );
    if (courseIndex !== -1) {
      courses.value.documents[courseIndex].completedResourceCount +=
        resourceCompleted ? 1 : -1;
    }
  };

  const updateResourceCompletionStatus = (
    resourceCompleted: boolean,
    resourceId: string
  ) => {
    const resource = courseDetail.value.document.topics?.find(
      (topic) => topic.base_id === resourceId
    );

    if (resource) {
      resource.completed = resourceCompleted;
    }
  };

  const mapResponseToCourse = (data: any): Course => {
    return {
      _id: data.sanityData._id,
      base_id: data.sanityData.base_id,
      description: data.sanityData.description,
      feature_image: data.sanityData.feature_image,
      name: data.sanityData.name,
      title: data.sanityData.title,
      _type: data.sanityData._type,
      topics: data.sanityData.topics,
      parent: data.sanityData.parent,
      completedResourceCount: data.completedResourceCount,
      totalResourceCount: data.totalResourceCount,
    };
  };

  return {
    courseDetail,
    courses,
    coursesInProgress,
    isCourseDetailLoading,
    getCourseDetail,
    isCoursesLoading,
    getCourses,
    getCoursesInProgress,
    isCoursesInProgressLoaded,
    requestCourseDetail,
    requestCourses,
    requestCoursesInProgress,
    updateResourceCompletedCount,
    updateResourceCompletionStatus,
  };
});
