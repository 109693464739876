import { defineStore } from "pinia";
import { ref, computed } from "vue";
import http from "../http-common";
import { useCourseStore } from "@/store";
import { Resource } from "./models/resource.models";
import { RetrieveResourceDetail } from "@/helpers";

export const useResourceStore = defineStore("resource", () => {
  // State
  const resource = ref({
    loaded: false,
    document: {} as Resource,
  });

  // Getters
  const isResourceLoaded = computed(() => {
    return resource.value.loaded;
  });

  const getResource = computed(() => {
    return resource.value.document;
  });

  // Actions
  const requestResource = async (sanityDocumentId: string): Promise<void> => {
    resource.value.loaded = false;
    return RetrieveResourceDetail(sanityDocumentId)
      .then((response) => {
        resource.value.document = {
          _id: response._id,
          base_id: response.base_id,
          name: response.name,
          title: response.title,
          description: response.description,
          resource_type: response.resource_type,
          time: response.time,
          feature_image: response.feature_image,
          body: response.body,
          completed: response.completed,
          parent: response.parent,
          resourceSiblings: response.resourceSiblings,
        };
      })
      .catch((e) => {
        throw e;
      })
      .finally(() => {
        resource.value.loaded = true;
      });
  };
  const markResourceComplete = async (isComplete: boolean): Promise<void> => {
    const sanityResourceId = resource.value.document._id;
    const sanityResourceName = resource.value.document.name;

    http
      .put(`resource/${sanityResourceId}`, {
        sanityResourceName: sanityResourceName,
        completed: isComplete,
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const updateResourceCompletion = (isComplete: boolean): void => {
    const sanityResourceId = resource.value.document._id;
    const courseStore = useCourseStore();
    courseStore.updateResourceCompletedCount(isComplete);
    courseStore.updateResourceCompletionStatus(isComplete, sanityResourceId);
  };

  const $reset = () => {
    resource.value = {
      loaded: false,
      document: {} as Resource,
    };
  };

  return {
    resource,
    isResourceLoaded,
    getResource,
    requestResource,
    markResourceComplete,
    updateResourceCompletion,
    $reset,
  };
});
