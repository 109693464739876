import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const useErrorStore = defineStore("error", () => {
  /* State */
  const errors = ref({
    genericError: false,
    assessmentsError: false,
    saveFormError: false,
    downloadResumeError: false,
  });

  /* Getters */
  const hasGenericError = computed(() => {
    return errors.value.genericError;
  });

  const hasAssessmentsError = computed(() => {
    return errors.value.assessmentsError;
  });

  const hasSaveFormError = computed(() => {
    return errors.value.saveFormError;
  });

  const hasDownloadResumeError = computed(() => {
    return errors.value.downloadResumeError;
  });

  const hasNonGenericError = computed(() => {
    const errorChecks = [
      hasAssessmentsError,
      hasSaveFormError,
      hasDownloadResumeError,
    ];
    return errorChecks.some((errorCheck) => errorCheck.value);
  });

  /* Setters */
  const setGenericError = (value: boolean) => {
    errors.value.genericError = value;
  };

  const setAssessmentsError = (value: boolean) => {
    if (!value) setGenericError(false);
    errors.value.assessmentsError = value;
  };

  const setSaveFormError = (value: boolean) => {
    if (!value) setGenericError(false);
    errors.value.saveFormError = value;
  };

  const setDownloadResumeError = (value: boolean) => {
    if (!value) setGenericError(false);
    errors.value.downloadResumeError = value;
  };

  return {
    errors,
    hasGenericError,
    hasAssessmentsError,
    hasSaveFormError,
    hasDownloadResumeError,
    hasNonGenericError,
    setGenericError,
    setAssessmentsError,
    setSaveFormError,
    setDownloadResumeError,
  };
});
