export default {
  "verificationPage": {
    "regionLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APM Employable Me Logo"])}
  },
  "signInHome": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in to Employable Me"])},
    "welcome1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to"])},
    "welcome2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Employable Me"])},
    "phonePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+64 411 223 344"])},
    "signInCta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click Sign in to access your account."])},
    "t&c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and conditions"])},
    "signInStaff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in for Staff"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])},
    "footerCredits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Built by the APM Group"])}
  },
  "tabs": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "learn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn"])},
    "tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])}
  },
  "pageTitles": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "learn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn and Grow"])},
    "jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobs"])},
    "tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "t&c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and conditions"])}
  },
  "dashboard": {
    "contentBannerWelcomeText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hi <b>", _interpolate(_named("name")), "</b> and welcome to your program dashboard. Start by exploring Learn and Grow."])},
    "callToActionTitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
    "callToActionTitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn and Grow"])},
    "callToActionBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Build your skills and knowledge by completing activities and courses."])},
    "callToActionButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Learning"])},
    "continueCourseInProgressButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "courseProgressText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("completedResource")), " of ", _interpolate(_named("totalResource")), " activities completed"])},
    "courseInProgressTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue learning"])},
    "courseInProgressDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["You have ", _interpolate(_named("count")), " course currently in progress."]), _normalize(["You have ", _interpolate(_named("count")), " courses currently in progress."])])}
  },
  "loadingScreen": {
    "poweredBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powered By"])},
    "toastSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in successful"])},
    "toastError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in failed, please try again."])}
  },
  "categoryCard": {
    "courseBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["course"])},
    "coursesBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["courses"])},
    "collectionBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["collection"])},
    "collectionsBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["collections"])}
  },
  "statusLabel": {
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
    "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Progress"])}
  },
  "learnHome": {
    "bannerWelcomeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi and welcome to Learn and Grow. Consider this space your hub for upskilling with activities, collections and courses."])},
    "categoryTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browse content"])},
    "courseTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore courses"])},
    "collectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore collections"])},
    "seeAllContentLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all"])}
  },
  "course": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses offer a range of activities to build your skills and knowledge around various topics."])},
    "courseComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course completed"])},
    "courseInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course in progress"])},
    "activitiesCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activities completed"])},
    "activitiesToComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities to complete"])}
  },
  "collection": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collections"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collections contain sets of similar activities for you to explore at your own pace."])}
  },
  "resource": {
    "activityCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity completed"])},
    "activityInComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as completed"])},
    "video": {
      "transcriptExpanded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide video transcript"])},
      "transcriptCollapsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View video transcript"])}
    },
    "activity": {
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next activity"])},
      "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
      "alternativeExpanded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide alternative activity"])},
      "alternativeCollapsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View alternative activity"])}
    },
    "interactive": {
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Button"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])}
    },
    "readingTimeMins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mins"])},
    "types": {
      "Article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article"])},
      "Video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
      "Game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game"])},
      "Interactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interactive"])},
      "Checklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checklist"])},
      "Audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio"])},
      "Quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])}
    }
  },
  "info": {
    "profileTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Details"])},
    "siteTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site Details"])},
    "consultantTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ConsultantDetails"])}
  },
  "TermsAndConditionsModal": {
    "acceptButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept & Continue"])},
    "signOutButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out"])}
  },
  "signout": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out"])},
    "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to sign out"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to do this?"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
  },
  "navigation": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return"])},
    "reloadPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Home"])}
  },
  "error": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, this page didn't load correctly"])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try reloading this page or return to the previous page."])},
    "supportText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Having trouble finding what you need? For help contact support at"])},
    "pageNotFound": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page not found"])},
      "friendlyText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops, the page you were looking for could not be found."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, the link to this page may be incorrect."])},
      "solutionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check that you've got the right link or return Home to try to find what you were looking for."])}
    }
  },
  "privacyPolicyUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://apm-nz.co.nz/privacy-policy"])},
  "supportEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["myapmsupport", "@", "apm.co.nz"])},
  "chat": {
    "altText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APM chat"])}
  },
  "locale": {
    "popoverTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a language"])}
  },
  "b2cSignIn": {
    "signInTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
    "verificationCodeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification code"])},
    "disclaimerHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Having trouble signing in?"])},
    "disclaimerContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For help, contact support at:"])}
  },
  "keyboardShortcuts": {
    "menuTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip to:"])},
    "mainContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Content"])}
  },
  "breadcrumb": {
    "showMoreBreadcrumbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show more breadcrumbs"])}
  },
  "tools": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My self-discovery"])},
    "pageDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore more about your career interests, values, and wellbeing with these quizzes."])},
    "pageTitleEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops, the tools page is unavailable."])},
    "pageDescriptionEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have a range of other resources to support you. Return to the Home page to explore these."])},
    "returnHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Home"])},
    "bannerWelcomeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These tools and quizzes can help you with different stages of your career journey."])}
  },
  "assessment": {
    "aboutAccordion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About this quiz"])},
    "startQuiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start quiz"])},
    "continueQuiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue quiz"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
    "mins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mins"])},
    "questionNumber": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Question ", _interpolate(_named("index")), " of ", _interpolate(_named("count"))])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
    "completeQuiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete quiz"])},
    "selectYourResponse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your response (required)"])},
    "returnToQuiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to quiz"])},
    "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit"])},
    "quizCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz completed"])},
    "lastCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last completed"])},
    "lessThan1MinuteAgo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["less than 1 minute ago"])},
    "minuteAgo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minute ago"])},
    "minutesAgo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes ago"])},
    "hourAgo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hour ago"])},
    "hoursAgo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hours ago"])},
    "dayAgo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["day ago"])},
    "daysAgo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days ago"])},
    "weekAgo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["week ago"])},
    "weeksAgo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["weeks ago"])},
    "monthAgo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["month ago"])},
    "monthsAgo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["months ago"])},
    "yearAgo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["year ago"])},
    "yearsAgo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["years ago"])},
    "retake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retake"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])}
  },
  "assessmentExitWarningModal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit quiz"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By leaving the quiz now without completing it, your answers will be lost. Do you wish to exit?"])}
  },
  "assessmentSavingInProgressModal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving your response"])}
  },
  "resume": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My career tools"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prepare for job applications using this résumé builder."])},
    "resumeCard": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résumé builder"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create and download a professional résumé you can use to apply for jobs."])},
      "badgeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To do"])}
    },
    "overview": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My résumé"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your résumé by entering your details in the sections below."])},
      "downloadMyResume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download my résumé"])}
    },
    "resumeContentCard": {
      "contactDetails": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My details"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your contact details and add an optional LinkedIn URL."])},
        "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add my details"])},
        "editButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit my details"])}
      },
      "experience": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My experience"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your employment history. Include any volunteer work or other experience you would like to include in your résumé."])},
        "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add my experience"])},
        "editButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit my experience"])}
      },
      "qualifications": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My qualifications"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter any certificates, training, degrees, or courses you have done or are completing. "])},
        "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add my qualifications"])},
        "editButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit my qualifications"])}
      },
      "licences": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My licences"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter any licences, checks, or tickets you have."])},
        "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add my licenses"])},
        "editButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit my licenses"])}
      },
      "personalStatement": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My profile statement"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write a short introduction about you, your skills, strengths, or career goals. "])},
        "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add my profile statement"])},
        "editButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit my profile statement"])}
      }
    },
    "breadcrumbs": {
      "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My résumé"])},
      "contactDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact details"])},
      "workExperiences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My experience"])},
      "workExperienceDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience"])},
      "qualifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My qualifications"])},
      "qualificationDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualification"])},
      "licences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My licences"])},
      "personalStatement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My profile statement"])}
    },
    "contactDetailsForm": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My details"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your contact details are correct and add an optional LinkedIn URL below."])},
      "additionalNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact your Employment Consultant if you need to change or add your name, email address, phone number, or location."])},
      "nameInputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My name"])},
      "emailInputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My email address"])},
      "phoneInputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My phone number"])},
      "locationInputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My location"])},
      "locationInputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not supplied"])},
      "hideLocationInputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide my location on my résumé"])},
      "linkedinInputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My LinkedIn profile URL (optional)"])},
      "linkedinInputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.linkedin.com/in/myprofile"])},
      "linkedinInputError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check that the LinkedIn profile URL you have entered is correct."])},
      "saveButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save and return"])},
      "closeButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return without saving"])}
    },
    "workExperiences": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My experience"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your employment history. Include volunteering work and any other experience you want included in your résumé."])},
      "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add an experience"])},
      "editButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit my experience"])},
      "presentLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["present"])},
      "returnButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to My résumé"])}
    },
    "workExperienceForm": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your employment history. Include volunteering work and any other experience you want included in your résumé."])},
      "roleInput": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My job or role title"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. Receptionist"])},
        "isRequiredError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A job or role title is required. Please enter text here."])}
      },
      "businessInput": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation name"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. Myer"])}
      },
      "startDateInput": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date started"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YYYY or MM/YYYY"])},
        "invalidFormatError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid date started. Please enter a date in a valid format, year (e.g. 2024) or month and year (e.g. 02/2024)."])},
        "noFutureDateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid date started. The date cannot be a future date."])}
      },
      "isCurrentRoleInput": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am still in this role"])}
      },
      "endDateInput": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date finished"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YYYY or MM/YYYY"])},
        "invalidFormatError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid date finished. Please enter a date in a valid format, year (e.g. 2024) or month and year (e.g. 02/2024)."])},
        "noFutureDateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid date finished. The date cannot be a future date."])},
        "noBeforeStartDateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid date started or date finished. The date finished cannot be before the date started."])}
      },
      "businessDescriptionInput": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the organisation"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write an optional description about the organisation here…"])},
        "characterLimitError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the character limit for About organisation."])}
      },
      "impactInput": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My responsibilities and achievements"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• Write about any responsibilities, tasks, or achievements you had for this role here…"])},
        "characterLimitError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the character limit for My responsibilities and achievements."])}
      },
      "saveBtnLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save and return"])},
      "deleteBtnLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete this experience"])},
      "closeBtnLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return without saving"])}
    },
    "qualificationsOverview": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My qualifications"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter any certificates, training, degrees, or courses you have done or are completing."])},
      "editButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit my qualification"])},
      "addQualificationBtnLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a qualification"])},
      "returnBtnLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to My résumé"])},
      "inProgressLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In progress"])}
    },
    "qualificationForm": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualification"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the details about your course or qualification."])},
      "nameInput": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course or qualification"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. Certificate III in Horticulture"])},
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ensure to include the level of qualification in the title (e.g. 'Certificate III')"])},
        "isRequiredError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A course or qualification is required. Please enter text here."])}
      },
      "institutionInput": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. TAFE NSW"])}
      },
      "isCompletedInput": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am still completing this"])}
      },
      "endDateInput": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date completed"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YYYY or MM/YYYY"])},
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date format (YYYY or MM/YYYY)"])},
        "invalidFormatError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid date completed. Please enter a date in a valid format, year (e.g. 2024) or month and year (e.g. 02/2024)."])}
      },
      "highlightsInput": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write an optional description about your qualification, grades, or achievements here…"])},
        "characterLimitError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the character limit for the Description."])}
      },
      "saveBtnLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save and return"])},
      "deleteBtnLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete this qualification"])},
      "closeBtnLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return without saving"])}
    },
    "licences": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My licences"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter any licences, checks, or tickets you have."])},
      "additionalNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a licence"])},
      "editButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit my licence"])},
      "returnButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to My résumé"])}
    },
    "licencesForm": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licence"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the details about your licence, check, or ticket."])},
      "saveButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save and return"])},
      "deleteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete this licence"])},
      "closeBtnLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return without saving"])},
      "name": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licence"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. Driver licence"])},
        "isRequiredError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A licence is required. Please enter text here."])}
      },
      "organisation": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issuing organisation"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. VicRoads"])}
      },
      "issueDate": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date issued"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YYYY or MM/YYYY"])},
        "invalidFormatError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid date issued. Please enter a date in a valid format, year (e.g. 2024) or month and year (e.g. 02/2024)."])},
        "noFutureDateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid date issued. The date cannot be a future date."])}
      },
      "expiryDate": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of expiry"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YYYY or MM/YYYY"])},
        "invalidFormatError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid date of expiry. Please enter a date in a valid format, year (e.g. 2024) or month and year (e.g. 02/2024)."])},
        "noBeforeIssueDateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid date issued or date of expiry. The date of expiry cannot be before the date issued."])}
      },
      "licenceDescription": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write an optional description about your licence here…"])},
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Briefly describe this credential - you can also add a type of URL if applicable."])},
        "characterLimitError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the character limit for the Description."])}
      }
    },
    "personalStatementForm": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My profile statement"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write a short introduction about you and your skills, strengths, or career goals. This statement will appear at the top of your résumé."])},
      "saveButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save and return"])},
      "closeButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return without saving"])},
      "personalStatement": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My statement"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write your profile statement here..."])},
        "invalidCharactersError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certain characters are not allowed. Please edit or remove the highlighted text and try saving again."])},
        "characterLimitError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the character limit for the Profile statement."])}
      }
    },
    "requiredLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["required"])},
    "downloadModal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download my résumé"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save your résumé to your device so you can edit and share it easily."])},
      "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download as Word (.docx)"])},
      "secondaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to page"])}
    },
    "downloadSuccessModal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download successful"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to the résumé helper if you need help opening your document."])},
      "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
    },
    "downloadErrorModal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, your download was unsuccessful"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again or return to page."])},
      "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])},
      "secondaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to page"])}
    },
    "saveFormErrorModal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, your response was not saved"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again or return to page."])},
      "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])},
      "secondaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to page"])}
    },
    "returnWithoutSaveModal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to leave this page?"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there are any unsaved changes, they will be lost."])},
      "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave this page"])},
      "secondaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay on this page"])}
    },
    "deleteFormModal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this?"])},
      "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "secondaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
    }
  },
  "form": {
    "textAreaInput": {
      "charCounterText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["characters"])}
    }
  },
  "month": {
    "january": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January"])},
    "february": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February"])},
    "march": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March"])},
    "april": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
    "may": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
    "june": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June"])},
    "july": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July"])},
    "august": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
    "september": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
    "october": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October"])},
    "november": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
    "december": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])}
  },
  "onboardingSlides": {
    "imageAlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Character"])},
    "skipBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip"])},
    "nextBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "backBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "completeBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get started"])},
    "progressEllipsis": {
      "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page"])},
      "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])}
    }
  }
}