<template>
  <ion-page>
    <MobileHeader></MobileHeader>
    <ion-content>
      <DesktopLocaleSelector></DesktopLocaleSelector>
      <PageBanner
        v-if="sanityStore.getLoadingFor(this.sanityId)"
        :isImageLoading="true"
      ></PageBanner>
      <PageBanner
        v-else
        :imagePath="this.bannerImage"
        data-test="banner-image"
      ></PageBanner>
      <div
        class="page-wrapper"
        id="collection-detail-main-content"
        tabindex="0"
      >
        <Breadcrumbs
          tab="learn-home"
          :parentType="doc?.parent ? doc?.parent[0]?._type : ''"
          :grandparentType="grandparent?._type"
          :parent="doc?.parent ? doc?.parent[0]?.base_id : ''"
          :parentTitle="doc?.parent ? doc?.parent[0]?.title : ''"
          :grandparent="grandparent?.base_id"
          :grandparentTitle="grandparent?.title"
          :doc="doc?.title"
        ></Breadcrumbs>
        <div class="title-wrapper">
          <IonSkeletonText
            style="width: 150px; height: 28px"
            v-if="!doc"
          ></IonSkeletonText>
          <h4 class="page-title">{{ doc?.title }}</h4>
          <div class="badge-wrapper">
            <ion-badge color="secondary">{{
              $t("collection.label")
            }}</ion-badge>
          </div>
          <p class="page-description">{{ doc?.description }}</p>
          <IonSkeletonText
            style="width: 150px; height: 20px"
            v-if="!doc"
          ></IonSkeletonText>
        </div>

        <div class="card-holder" data-test="resource-card">
          <div
            class="card-holder loading"
            v-if="sanityStore.getLoadingFor(this.sanityId)"
          >
            <CardSkeleton
              :smallCard="true"
              class="course-card"
              v-for="index in 3"
              :key="index"
            ></CardSkeleton>
          </div>
          <router-link
            class="card-wrapper"
            v-for="topic in doc?.topics"
            :to="{ name: 'ResourceDetail', params: { id: topic.base_id } }"
            :key="topic._id"
          >
            <ResourceCard :document="topic" :completed="topic.completed">
            </ResourceCard>
          </router-link>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonBadge, IonSkeletonText } from "@ionic/vue";
import MobileHeader from "@/components/Common/MobileHeader/MobileHeader.vue";
import PageBanner from "@/components/Common/PageBanner/PageBanner.vue";
import { useSanityStore, useErrorStore, useLocaleStore } from "@/store";
import { RouterLink } from "vue-router";
import ResourceCard from "@/components/Resource/ResourceCard/ResourceCard.vue";
import { timeOutline } from "ionicons/icons";
import Breadcrumbs from "@/components/Common/Breadcrumbs/Breadcrumbs.vue";
import CardSkeleton from "@/components/Skeletons/CardSkeleton/CardSkeleton.vue";
import { parseImageUrl } from "@/helpers";
import { createLocaleSpecificSanityId } from "@/helpers/CreateLocaleSpecificSanityId.js";
import DesktopLocaleSelector from "@/components/Common/LocaleSelector/DesktopLocaleSelector.vue";

export default {
  name: "collection-detail",
  components: {
    IonPage,
    IonContent,
    MobileHeader,
    RouterLink,
    ResourceCard,
    IonBadge,
    PageBanner,
    Breadcrumbs,
    CardSkeleton,
    IonSkeletonText,
    DesktopLocaleSelector,
  },
  setup() {
    const sanityStore = useSanityStore();
    const localeStore = useLocaleStore();
    const errorStore = useErrorStore();
    return { sanityStore, localeStore, errorStore };
  },
  data() {
    return {
      time: timeOutline,
      sanityId: null,
    };
  },
  ionViewDidEnter() {
    //load the collection using the sanity ID with the correct locale suffix added.
    this.sanityId = this.createLocaleSpecificSanityId(this.$route.params.id);
    this.sanityStore.requestDocument(this.sanityId).catch((error) => {
      if (
        //if the collection specific to the locale selected cannot be accessed, and the locale selected is an english locale, load the global english version.
        error.response.status === 403 &&
        this.localeStore.getCurrentLocaleCode.includes("en")
      ) {
        this.sanityId = this.$route.params.id;
        this.sanityStore.requestDocument(this.sanityId);
      } else {
        //otherwise, if the locale selected is not an english locale and the non english locale version cannot be accessed, load an error state.
        this.sanityStore.$reset();
        this.errorStore.setGenericError(true);
      }
    });
  },
  computed: {
    doc() {
      if (this.sanityStore?.getDocument(this.sanityId)) {
        return this.sanityStore.getDocument(this.sanityId);
      }
      return null;
    },
    grandparent() {
      if (
        this.doc &&
        this.doc.parent &&
        this.doc.parent[0] &&
        this.doc.parent[0].grandparent
      )
        return this.doc.parent[0].grandparent[0];
      return null;
    },
    bannerImage() {
      if (this.doc?.feature_image) {
        return this.parseImageUrl(this.doc?.feature_image, 1000, 2500);
      } else return require("@/assets/collection-thumbnail-placeholder.png");
    },
  },
  methods: {
    parseImageUrl,
    createLocaleSpecificSanityId,
  },
};
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";

ion-content {
  --background: var(--background-white);
}

.page-wrapper {
  z-index: 9;
}
.title-wrapper {
  text-align: left;
}
.page-title {
  margin-bottom: 20px;
  margin-top: 0px;
}
.badge-wrapper {
  margin-bottom: 20px;
  gap: 10px;
}
ion-icon {
  vertical-align: middle;
  font-size: 14px;
  margin-right: 2px;
}
.resource-header {
  text-align: left;
  font-weight: bold;
}
.card-holder {
  & a {
    text-decoration: none;
  }
}
.card-wrapper {
  border-radius: 16px;
  text-decoration: none;
  margin-bottom: 16px;
  transition: box-shadow var(--transition-time);
  &:focus-visible {
    &:focus-visible {
      outline: 2px solid var(--ion-color-secondary);
      box-shadow: inset 0px 0px 0px 1px var(--background-white);
    }
  }
  &:hover {
    box-shadow: var(--shadow-2);
  }
  &:active {
    box-shadow: var(--shadow-1);
  }
  width: 312px;
}
// Handles focus on safari
*:focus {
  outline-width: thin;
  overflow: hidden;
  outline-color: var(--ion-color-secondary);
}
@include breakpoint(medium) {
  .resource-header {
    text-align: left;
  }
  .card-holder {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    gap: 16px;
  }
  .card-wrapper {
    margin-bottom: 0;
  }
  ion-header {
    height: 0;
  }
}
</style>
