<template>
  <ion-accordion-group
    v-if="show"
    id="alternative-activity"
    data-test="alternative-activity-panel"
    @ionChange="accordionGroupChange($event)"
    @click.stop
    @keyup.enter.stop
  >
    <ion-accordion value="first">
      <ion-item slot="header" color="white">
        <ion-label
          :aria-label="`${$t('resource.interactive.link')} ${transcriptLabel}`"
          data-test="accordion-label"
          ><span>
            {{ transcriptLabel }}
          </span></ion-label
        >
      </ion-item>
      <div id="alternative-activity-content" class="ion-padding" slot="content">
        <slot></slot>
      </div>
    </ion-accordion>
  </ion-accordion-group>
</template>

<script>
import { IonAccordion, IonAccordionGroup, IonLabel, IonItem } from "@ionic/vue";
export default {
  name: "alternative-activity",
  components: {
    IonAccordion,
    IonAccordionGroup,
    IonLabel,
    IonItem,
  },
  props: {
    show: { type: Boolean, default: false },
    labelExpanded: { type: String },
    labelCollapsed: { type: String },
  },
  data() {
    return {
      transcriptLabel: this.labelCollapsed,
    };
  },
  methods: {
    accordionGroupChange(e) {
      const selectedValue = e.detail.value;
      this.transcriptLabel = selectedValue
        ? this.labelExpanded
        : this.labelCollapsed;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";

ion-accordion-group {
  margin: -6px 0 0;
  box-shadow: var(--shadow-1);
  width: 100%;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

ion-label {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}

ion-accordion {
  background-color: white;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

ion-accordion.accordion-expanded ion-item,
ion-accordion.accordion-expanding ion-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

#alternative-activity {
  ion-accordion {
    .ion-focused {
      color: var(--ion-color-secondary);
      background-color: rgba(var(--ion-color-secondary-rgb), 0.2);
    }
  }

  .accordion-expanded {
    ion-item {
      color: var(--ion-color-secondary);
      background-color: rgba(var(--ion-color-secondary-rgb), 0.2);
    }
  }
}

#alternative-activity-content :deep(p) {
  margin: revert;
  font-size: 16px;
  color: var(--dark-grey);
}
</style>
