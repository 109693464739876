<template>
  <ResumeBase
    :breadcrumbs="[
      {
        route: 'employment-history',
        label: $t('resume.breadcrumbs.workExperiences'),
      },
    ]"
    mainContentId="resume-work-experiences-main-content"
    :skipOnRouteBeforeLeave="true"
  >
    <template v-slot:title>
      {{ $t("resume.workExperiences.title") }}
    </template>
    <template v-slot:description>
      {{ $t("resume.workExperiences.description") }}
    </template>
    <template v-slot:main-content>
      <div class="card-wrapper" v-if="resumeStore.isResumeLoading">
        <div class="card-item" v-for="item in 2" :key="item">
          <PreviewCard :isLoading="true"></PreviewCard>
        </div>
      </div>
      <div class="card-wrapper" v-else>
        <div
          class="card-item"
          v-for="experience in sortWorkExperiences(
            resumeStore?.resume?.workExperiences
          )"
          :key="experience.id"
        >
          <PreviewCard
            :id="experience.id"
            :title="experience.role"
            :isLoading="resumeStore.isResumeLoading"
            data-test="preview-card"
            :button-text="$t('resume.workExperiences.editButton')"
            @click="editWorkExperience(experience.id)"
            ><template v-slot:metadata>
              <p>{{ experience.business }}</p>
              <p>
                {{ convertToMonthYear(experience.startDate, $t) }}
                <span
                  v-if="
                    experience.startDate &&
                    (experience.endDate || experience.isCurrentRole)
                  "
                >
                  -
                </span>
                <span v-if="experience.isCurrentRole">{{
                  $t("resume.workExperiences.presentLabel")
                }}</span>
                <span v-else-if="experience.endDate">{{
                  convertToMonthYear(experience.endDate, $t)
                }}</span>
              </p>
            </template>
            <template v-slot:description>
              <p class="impact-section">{{ experience.impact }}</p>
            </template>
          </PreviewCard>
        </div>
      </div>
      <div class="buttons-container">
        <AppButton
          size="default"
          type="primary"
          :label="$t('resume.workExperiences.addButton')"
          :ariaLabel="$t('resume.workExperiences.addButton')"
          data-test="add-btn"
          @click="addWorkExperience"
          width="100%"
          :showLoadingSkeleton="resumeStore.isResumeLoading"
        />
        <AppButton
          size="default"
          type="secondary"
          :label="$t('resume.workExperiences.returnButtonText')"
          :ariaLabel="$t('resume.workExperiences.returnButtonText')"
          data-test="return-btn"
          @click="$router.push({ name: 'ResumeOverview' })"
          width="100%"
          :showLoadingSkeleton="resumeStore.isResumeLoading"
        />
      </div>
    </template>
    <template v-slot:resume-helper> </template>
  </ResumeBase>
</template>
<script setup lang="ts">
import ResumeBase from "@/Layouts/ResumeBase/ResumeBase.vue";
import AppButton from "@/components/Common/AppButton/AppButton.vue";
import PreviewCard from "@/components/Resume/PreviewCard/PreviewCard.vue";
import { useResumeStore } from "@/store";
import { onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import {
  convertToMonthYear,
  sortWorkExperiences,
  focusOnMainContent,
} from "@/helpers";
import { onIonViewDidEnter } from "@ionic/vue";
const resumeStore = useResumeStore();
const router = useRouter();
const route = useRoute();

onIonViewDidEnter(() => {
  focusOnMainContent(route?.meta?.mainContentId);
});

onMounted(async () => {
  if (!resumeStore.resumeId) {
    await resumeStore.requestResume();
    if (!resumeStore.resumeId) {
      await resumeStore.createResume();
    }
  }
});

function addWorkExperience() {
  resumeStore.initWorkExperienceForm();
  router.push({
    name: "ResumeWorkExperienceDetail",
    params: { id: "new" },
  });
}

function editWorkExperience(id: string) {
  resumeStore.initWorkExperienceForm(id);
  router.push({
    name: "ResumeWorkExperienceDetail",
    params: { id: id },
  });
}
</script>
<style scoped lang="scss">
.buttons-container {
  max-width: 312px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);
}

.card-wrapper {
  margin-bottom: var(--spacing-24);
  .card-item {
    margin-bottom: var(--spacing-16);
  }
}

.impact-section {
  white-space: pre-wrap;
}
</style>
