<template>
  <div class="container" data-test="skip-container">
    <label class="label">{{ $t("keyboardShortcuts.menuTitle") }}</label>
    <br />
    <button
      class="main-content-button"
      data-test="main-content-button"
      id="main-content-button"
      @click="focusMainContent()"
    >
      {{ $t("keyboardShortcuts.mainContent") }}
    </button>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
const router = useRouter();
const focusMainContent = () => {
  const mainContentId = router.currentRoute.value.meta.mainContentId;
  const mainContentElement = document.getElementById(mainContentId);
  mainContentElement
    ? mainContentElement.focus()
    : console.warn(`Element with ID ${mainContentId} not found`);
};
</script>

<style lang="scss" scoped>
.label {
  padding-left: 15px;
}
.container {
  transition: all 100ms ease-in-out;
  transform: translateY(-1000px);
  color: var(--black);
  font-size: 22px;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  padding: 20px 6px 5px 6px;
  z-index: var(--z-index-skip-container);
  background-color: var(--background-white);
  border: none;
  box-shadow: var(--shadow-2);
  border-radius: 12px;
  font-weight: 800;
  text-decoration: none;
  text-align: left;
  min-width: 200px;
  margin: 10px 0px 0px 40px;
  &:focus-within {
    transform: translateY(0px);
  }
}
.main-content-button {
  color: var(--black);
  font-size: 16px;
  background-color: var(--lighter-grey);
  outline-color: var(--black);
  font-weight: 400;
  margin-top: 3px;
  display: flex;
  width: 100%;
  padding: 13px 30px 13px 15px;
  border-radius: 6px;
  text-decoration: underline;
}
.main-content-button:focus {
  background-color: var(--lighter-grey);
}
@import "@/common/theme/breakpoints.scss";
@include breakpoint(xsmall) {
  .container {
    margin: 35px 30px;
  }
}
</style>
