<template>
  <div v-if="isLoading" data-test="widget-skeleton">
    <ion-skeleton-text
      style="width: 100%; height: 369px"
      :animated="true"
    ></ion-skeleton-text>
  </div>
  <ion-card
    v-else
    @keyup.enter="openModal()"
    @click="openModal()"
    tabindex="0"
    data-test="activity-card"
  >
    <IonCardHeader data-test="activity-card-header">
      <p
        id="resource-name"
        class="name"
        :aria-label="`${t('resource.interactive.button')} ${title || ''}`"
      >
        {{ props.title || "" }}
      </p>
    </IonCardHeader>
    <IonCardContent :class="cardContentClass">
      <div>
        <img :src="thumbnail" :alt="props.altText" data-test="thumbnail" />
        <alternative-activity
          data-test="alternative-activity"
          :show="!!alt_transcript"
          :labelExpanded="t('resource.activity.alternativeExpanded')"
          :labelCollapsed="t('resource.activity.alternativeCollapsed')"
        >
          <SanityBlocks :blocks="alt_transcript" />
        </alternative-activity>
      </div>
    </IonCardContent>
  </ion-card>
  <ion-modal
    :is-open="isOpen"
    ref="modal"
    id="widget-modal"
    data-test="modal-contents"
    @didDismiss="modalDismissed"
    :aria-label="name || ''"
    aria-labelledby="resource-name"
  >
    <div class="wrapper">
      <ion-header>
        <ion-toolbar>
          <ion-button
            data-test="close-button"
            id="closeModalBtn"
            slot="end"
            fill="outline"
            color="darkgrey"
            size="small"
            @click="closeModal()"
            >{{ t("navigation.close") }}
            <ion-icon aria-hidden="true" :icon="closeIcon"></ion-icon
          ></ion-button>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <div class="iframe-wrapper" v-html="embedlink"></div>
      </ion-content>
    </div>
  </ion-modal>
</template>

<script setup lang="ts">
import {
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonHeader,
  IonContent,
  IonButton,
  IonModal,
  IonToolbar,
  IonSkeletonText,
  IonIcon,
} from "@ionic/vue";
import { SanityBlocks } from "sanity-blocks-vue-component";
import AlternativeActivity from "@/components/Common/AlternativeActivity/AlternativeActivity.vue";
import { useI18n } from "vue-i18n";
import { defineProps, ref, onMounted } from "vue";
import { Block } from "sanity-blocks-vue-component/dist/types";

interface Props {
  alt_transcript?: Block[];
  altText?: string;
  cardContentClass: string;
  embedlink: string;
  isLoading: boolean;
  name: string;
  thumbnail: string;
  title: string;
  closeIcon: string;
  widgetRef: string;
}

const props = defineProps<Props>();

const { t } = useI18n<{}>({
  useScope: "global",
});

const isOpen = ref(false);
const modal = ref();

onMounted(() => {
  if (history.state?.[`modalOpen_${props.widgetRef}`]) {
    isOpen.value = true;
  }
});

const openModal = () => {
  isOpen.value = true;
  history.pushState({ [`modalOpen_${props.widgetRef}`]: true }, "");
  window.onpopstate = () => {
    if (isOpen.value) {
      closeModal();
    } else {
      history.back();
    }
  };
};

const closeModal = () => {
  modal.value && modal.value.$el.dismiss(null, "cancel");
  isOpen.value = false;
};
const modalDismissed = () => {
  isOpen.value = false;
  window.onpopstate = null;
  if (history.state?.[`modalOpen_${props.widgetRef}`]) {
    history.back();
  }
};
</script>

<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";
.wrapper {
  height: 100%;
  width: 100%;
  padding: 12px;
  background-color: var(--background-white);
  display: flex;
  flex-direction: column;
  place-content: center;
}
.name {
  font-weight: 700;
  line-height: 22px;
  color: var(--button-text-primary);
  margin-top: 0;
  margin-bottom: 0;
}
ion-card-content {
  padding: 0;
  z-index: -1;
  width: 100%;
  &.no-alternative-activity {
    max-height: 370px;
    height: 370px;
  }
  img {
    max-height: 370px;
    height: 370px;
  }
}
img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

ion-header,
.header-ios ion-toolbar:last-of-type {
  border: none;
  --border-width: 0;
}

ion-header::after {
  width: 0;
}

ion-content {
  --overflow: hidden;
}
ion-toolbar {
  --background: var(--background-white);
}
ion-card {
  max-width: 700px;
  width: 100%;
  margin: 24px auto 24px auto;
  background: var(--background-white);
  box-shadow: var(--shadow-1);
  border-radius: 12px;
  cursor: pointer;
  z-index: 9;
  transition: box-shadow var(--transition-time);
  &:focus-visible,
  &:focus {
    outline-color: var(--ion-color-secondary-shade);
    box-shadow: var(--shadow-0);
  }
  &:hover {
    box-shadow: var(--shadow-2);
  }
  &:active {
    box-shadow: var(--shadow-1);
  }
}
ion-modal {
  --width: 100%;
  --height: 100%;
  --border-radius: none;
}
.iframe-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

ion-skeleton-text {
  max-width: 700px;
  max-height: 370px;
  margin: 24px auto 24px auto;
}

@include breakpoint(medium) {
  ion-modal {
    padding: 50px;
    --border-radius: 16px;
  }
  .wrapper {
    padding: 24px;
  }
}
</style>

<style lang="scss">
iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>
