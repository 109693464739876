<template>
  <button
    class="selector"
    @click="openPopover($event)"
    data-test="locale-selector"
    :aria-label="$t('locale.popoverTitle')"
  >
    <ion-icon
      :icon="selectedLocaleIcon"
      class="logo"
      aria-hidden="true"
    ></ion-icon>
    {{ selectedLocaleDisplayCode }}
  </button>
  <ion-popover
    :is-open="popoverOpen"
    :event="event"
    @didDismiss="popoverOpen = false"
    :dismiss-on-select="true"
    side="bottom"
    size="auto"
    mode="ios"
    data-test="locale-popover"
  >
    <ion-content>
      <h5 class="choose-text">{{ $t("locale.popoverTitle") }}</h5>
      <ion-list>
        <LocaleSelectorItem
          v-for="locale in allowedLocales"
          v-bind="locale"
          :key="locale.code"
          @onSelected="this.switchLocale"
        >
        </LocaleSelectorItem>
      </ion-list>
    </ion-content>
  </ion-popover>
</template>

<script>
import { IonList, IonPopover, IonIcon, IonContent } from "@ionic/vue";
import { locales, localeCodes } from "@/common/locales";
import LocaleSelectorItem from "./LocaleSelectorItem";
import { useLocaleStore } from "@/store";
export default {
  props: {
    selectedLocaleCode: {
      type: String,
      default: "en",
      validator(value) {
        return locales.map((locale) => locale.code).includes(value);
      },
    },
    allowedLocaleCodes: {
      type: Array,
      default: () => locales,
      validator(value) {
        return value.every((locale) => localeCodes.includes(locale));
      },
    },
  },
  data() {
    return {
      popoverOpen: false,
      event: null,
    };
  },
  setup() {
    const localStore = useLocaleStore();
    return { localStore };
  },
  computed: {
    selectedLocaleDisplayCode() {
      return locales.filter((x) => x.code === this.selectedLocaleCode)[0]
        ?.displayCode;
    },
    selectedLocaleIcon() {
      return locales.filter((x) => x.code === this.selectedLocaleCode)[0]?.icon;
    },
    allowedLocales() {
      return locales.filter((x) => this.allowedLocaleCodes.includes(x.code));
    },
  },
  methods: {
    openPopover(e) {
      this.event = e;
      this.popoverOpen = true;
    },
    async switchLocale(locale) {
      this.localStore.setLocale(locale, this.$i18n);
      this.$router.go(0);
    },
  },
  components: {
    IonList,
    IonPopover,
    IonIcon,
    IonContent,
    LocaleSelectorItem,
  },
};
</script>

<style scoped>
.selector {
  background: var(--background-white);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 16px;
  gap: 4px;

  width: 84px;
  height: 42px;

  font-weight: 400;
  font-size: 14px;

  border-radius: 8px;
  letter-spacing: 0.1px;
  color: var(--dark-grey);
}

.selector:hover {
  color: var(--dark-grey);
  text-decoration-line: underline;
}

.selector:active {
  background: #ccd1d9;
}

.selector:focus,
.selector:focus-visible {
  outline: 2px solid var(--ion-color-secondary);
}

.choose-text {
  font-size: 24px;
  text-align: left;
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 400;
}

.logo {
  width: 18px;
  height: 18px;

  background: var(--light-red);
  border: 1px solid var(--light-red);
  border-radius: 16px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

ion-item:last-child {
  --inner-border-width: 0;
}

ion-popover {
  --width: 245px;
}
</style>
