<template>
  <ion-page>
    <MobileHeader></MobileHeader>
    <ion-content>
      <DesktopLocaleSelector></DesktopLocaleSelector>
      <div
        class="page-wrapper"
        id="content-pack-detail-main-content"
        tabindex="0"
      >
        <!-- TODO: Tab parent here -->
        <Breadcrumbs tab="learn-home" :doc="categoryDoc?.title"></Breadcrumbs>
        <!-- Loading Skeleton Wrapper titles -->
        <div class="title-wrapper" v-if="!categoryDoc">
          <IonSkeletonText
            :animated="true"
            style="width: 250px; height: 50px"
          ></IonSkeletonText>
          <IonSkeletonText style="width: 150px"></IonSkeletonText>
          <IonSkeletonText style="width: 150px"></IonSkeletonText>
        </div>
        <div class="title-wrapper">
          <h4 class="page-title">{{ categoryDoc?.title }}</h4>
          <p class="page-description">{{ categoryDoc?.description }}</p>
        </div>
        <div class="title-wrapper">
          <h4
            class="page-title title-md"
            v-if="courseDoc ? courseDoc.length > 0 : false"
          >
            {{ $t("course.title") }}
          </h4>
        </div>
        <div class="card-holder" data-test="card-holder-course">
          <div
            class="card-holder loading"
            v-if="categoryStore.isCategoryLoading"
          >
            <CardSkeleton
              class="course-card"
              v-for="index in 3"
              :key="index"
            ></CardSkeleton>
          </div>
          <router-link
            v-for="item in courseDoc"
            :to="{ name: 'CourseDetail', params: { id: item.base_id } }"
            :key="item.base_id"
            class="card-wrapper"
          >
            <CourseOrCollectionCard
              class="course-card"
              type="course"
              :title="item.title"
              :description="item.description"
              :thumbnail="this.parseImageUrl(item.feature_image, 300, 300)"
              :placeholderImg="
                require('@/assets/course-thumbnail-placeholder.png')
              "
              :courseStatus="this.getCourseProgress(item)?.text"
              :courseStatusIcon="this.getCourseProgress(item)?.icon"
            ></CourseOrCollectionCard>
          </router-link>
        </div>
        <swiper
          class="swiper-cards"
          :slidesPerView="1.6"
          :initialSlide="0"
          :slidesOffsetAfter="24"
          :slidesOffsetBefore="24"
        >
          <swiper-slide
            class="course-slider"
            v-for="item in courseDoc"
            :key="item.base_id"
            data-test="swiper-cards-course"
          >
            <router-link
              :to="{ name: 'CourseDetail', params: { id: item.base_id } }"
              class="card-wrapper"
            >
              <CourseOrCollectionCard
                class="course-card"
                type="course"
                :title="item.title"
                :description="item.description"
                :thumbnail="this.parseImageUrl(item.feature_image, 300, 300)"
                :placeholderImg="
                  require('@/assets/course-thumbnail-placeholder.png')
                "
                :courseStatus="this.getCourseProgress(item)?.text"
                :courseStatusIcon="this.getCourseProgress(item)?.icon"
              ></CourseOrCollectionCard>
            </router-link>
          </swiper-slide>
        </swiper>

        <div class="title-wrapper">
          <h4
            class="page-title"
            data-test="collection-title"
            v-if="collectionDoc ? collectionDoc.length > 0 : false"
          >
            {{ $t("collection.title") }}
          </h4>
        </div>
        <div class="card-holder" data-test="card-holder-collection">
          <div
            class="card-holder loading"
            v-if="categoryStore.isCategoryLoading"
          >
            <CardSkeleton
              class="collection-card"
              v-for="index in 3"
              :key="index"
            ></CardSkeleton>
          </div>
          <router-link
            class="card-wrapper"
            v-for="item in collectionDoc"
            :to="{ name: 'CollectionDetail', params: { id: item.base_id } }"
            :key="item.base_id"
          >
            <ResourceCard :document="item"> </ResourceCard>
            <CourseOrCollectionCard
              class="collection-card"
              type="collection"
              :title="item.title"
              :description="item.description"
              :resourceType="[
                ...new Set(item.topics.map((element) => element.resource_type)),
              ]"
              :thumbnail="this.parseImageUrl(item.feature_image, 500, 500)"
              :placeholderImg="
                require('@/assets/collection-thumbnail-placeholder.png')
              "
            ></CourseOrCollectionCard>
          </router-link>
        </div>

        <swiper
          :breakpoints="swiperBreakpoints"
          :centeredSlides="true"
          :spaceBetween="10"
          class="swiper-cards default-slider"
          data-test="swiper-cards-collection"
        >
          <swiper-slide
            class="collection-swiper-slide"
            v-for="item in collectionDoc"
            :key="item.base_id"
          >
            <router-link
              :to="{ name: 'CollectionDetail', params: { id: item.base_id } }"
              :key="item.base_id"
              class="card-wrapper"
            >
              <CourseOrCollectionCard
                class="collection-card"
                type="collection"
                :title="item.title"
                :description="item.description"
                :resourceType="[
                  ...new Set(
                    item.topics.map((element) => element.resource_type)
                  ),
                ]"
                :thumbnail="this.parseImageUrl(item.feature_image, 500, 500)"
                :placeholderImg="
                  require('@/assets/collection-thumbnail-placeholder.png')
                "
              ></CourseOrCollectionCard>
            </router-link>
          </swiper-slide>
        </swiper>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonSkeletonText } from "@ionic/vue";
import MobileHeader from "@/components/Common/MobileHeader/MobileHeader.vue";
import { useCategoryStore } from "@/store";
import { RouterLink } from "vue-router";
import CourseOrCollectionCard from "@/components/CourseOrCollectionCard/CourseOrCollectionCard.vue";
import { timeOutline } from "ionicons/icons";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/vue";
import Breadcrumbs from "@/components/Common/Breadcrumbs/Breadcrumbs.vue";
import CardSkeleton from "@/components/Skeletons/CardSkeleton/CardSkeleton.vue";
import { getCourseProgress, parseImageUrl } from "@/helpers";
import DesktopLocaleSelector from "@/components/Common/LocaleSelector/DesktopLocaleSelector.vue";
import { createLocaleSpecificSanityId } from "@/helpers/CreateLocaleSpecificSanityId.js";

export default {
  name: "content-pack-detail",
  components: {
    IonPage,
    IonContent,
    MobileHeader,
    RouterLink,
    CourseOrCollectionCard,
    Swiper,
    SwiperSlide,
    Breadcrumbs,
    CardSkeleton,
    IonSkeletonText,
    DesktopLocaleSelector,
  },
  setup() {
    const categoryStore = useCategoryStore();
    return { categoryStore };
  },
  data() {
    return {
      time: timeOutline,
      swiperBreakpoints: {
        // when window width is >= 768px
        768: {
          slidesPerView: 1.06,
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 1.08,
        },
        // when window width is >= 520px
        520: {
          slidesPerView: 1.09,
        },
        // when window width is >= 460px
        460: {
          slidesPerView: 1.11,
        },
        // when window width is >= 380px
        380: {
          slidesPerView: 1.13,
        },
        // when window width is >= 320px
        320: {
          slidesPerView: 1.15,
        },
        // when window width is >= 280px
        280: {
          slidesPerView: 1.19,
        },
      },
    };
  },
  mounted() {
    this.categoryStore.requestCategory(
      this.createLocaleSpecificSanityId(this.$route.params.id)
    );
  },
  computed: {
    categoryDoc() {
      return this.categoryStore?.getCategory ?? null;
    },
    courseDoc() {
      if (
        this.categoryStore?.getCategory &&
        this.categoryStore?.getCategory.content
      ) {
        return this.categoryStore?.getCategory.content.filter(
          (document) => document._type == "course"
        );
      }
      return null;
    },
    collectionDoc() {
      if (
        this.categoryStore?.getCategory &&
        this.categoryStore?.getCategory.content
      ) {
        return this.categoryStore?.getCategory.content.filter(
          (document) => document._type == "collection"
        );
      }
      return null;
    },
  },
  methods: {
    getCourseProgress,
    parseImageUrl,
    createLocaleSpecificSanityId,
  },
};
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";

ion-content {
  --background: var(--lighter-grey);
}

.page-wrapper {
  z-index: -1;
  box-shadow: var(--shadow-0);
  border-radius: 0;
  height: 100%;
}
.swiper {
  margin-right: -24px;
  margin-left: -24px;
}
.title-wrapper {
  text-align: left;
}
.page-title {
  margin-bottom: 20px;
  margin-top: 20px;
}
ion-icon {
  vertical-align: middle;
  font-size: 14px;
  margin-right: 2px;
}
.course-card {
  height: 250px;
  max-width: inherit;
  background: var(--background-white);
  box-shadow: var(--shadow-1);
  border-radius: 8px;
  margin-left: 0;
}
.collection-card {
  padding: 0px;
  height: 300px;
  left: 0px;
  top: 0px;
  background: var(--background-white);
  border-radius: 16px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  box-shadow: 0px 0px 1px white, 0px 0px 1px white,
    2px 1px 4px rgb(153 153 153 / 20%), 7px 6px 1px white, 7px 6px 1px white,
    8px 7px 4px rgb(153 153 153 / 20%), 15px 13px 1px white, 15px 13px 1px white,
    16px 15px 4px rgb(153 153 153 / 20%);
}
.collection-swiper-slide {
  margin-right: 22px !important;
}
.card-holder {
  & a {
    text-decoration: none;
  }
  display: none;
  margin-right: 12px;
}
.card-wrapper {
  border-radius: 8px;
  text-decoration: none;
  margin-bottom: 24px;
  transition: box-shadow var(--transition-time);
  &:focus-visible {
    outline-color: var(--ion-color-secondary);
  }
  &:hover {
    box-shadow: var(--shadow-2);
  }
  &:active {
    box-shadow: var(--shadow-1);
  }
  width: inherit;
}

// handles focus on safari
*:focus {
  outline-width: thin;
  overflow: hidden;
  outline-color: var(--ion-color-secondary);
}
@include breakpoint(medium) {
  .swiper-cards {
    display: none;
  }
  .card-holder {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    gap: 16px;
  }
  ion-header {
    height: 0;
  }
  .swiper-cards {
    display: none;
  }
  .collection-card {
    max-width: 302px;
  }
  .card-holder {
    display: flex;
    flex-wrap: wrap;
    & a {
      text-decoration: none;
    }
    gap: 22px;
  }
  .course-card {
    max-width: 228px;
    height: 250px;
    margin: 0px;
  }

  // .collection-swiper-slide {
  //   margin-right: 32px !important;
  // }
  .title-md {
    margin-bottom: 14px;
  }
}
</style>
