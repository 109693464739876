import { defineStore } from "pinia";
// Import data service
import http from "../http-common";
import { useLocaleStore } from "@/store";

export const useSanityStore = defineStore({
  id: "sanity",
  state: () => ({
    documents: [],
    loading: [],
    terms: {},
  }),
  actions: {
    async requestTermsAndConditionsContent() {
      const response = await http.get(
        `terms-and-conditions-content?locale=${
          useLocaleStore().getCurrentLocaleCode
        }`
      );
      if (response?.data?.data[0]) {
        this.terms = response.data.data[0];
      }
    },
    requestDocument(id) {
      this.loading[id] = true;
      return http
        .get(`sanity/get-document?id=${id}`)
        .then((response) => {
          this.documents[id] = response.data.data[0];
        })
        .catch((e) => {
          throw e;
        })
        .finally(() => {
          this.loading[id] = false;
        });
    },
    requestQuery(type) {
      this.loading[type] = true;
      // Add type + locale
      const path = `sanity/query?type=${type}&locale=${
        useLocaleStore().getCurrentLocaleCode
      }`;

      http
        .get(path)
        .then((response) => {
          response.data.data.forEach((document) => {
            this.loading[type] = false;
            let doc = this.documents.some((doc) => doc._id == document._id);
            if (!doc) {
              this.documents.push(document);
            } else {
              this.documents[this.documents.indexOf(doc)] = document;
            }
          });
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.loading[type] = false;
        });
    },
  },
  getters: {
    getLoadingFor(state) {
      return (type) => state.loading[type] ?? true;
    },
    getContentPackIds(state) {
      const contentPacks = state.documents.filter(
        (document) => document._type == "content_pack"
      );
      return contentPacks.map((doc) => doc._id);
    },
    getDocumentsByType(state) {
      return (type) =>
        state.documents.filter((document) => document._type == type);
    },
    getDocument(state) {
      return (id) => (state.documents[id] ? state.documents[id] : null);
    },
    getTermsAndConditionsContent(state) {
      return state.terms;
    },
  },
});
