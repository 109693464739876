<template>
  <ActivityWidget
    :alt_transcript="document?.alt_transcript"
    :altText="altText"
    :cardContentClass="cardContentClass"
    :description="document?.description"
    :embedlink="document?.embedlink"
    :isLoading="isLoading"
    :name="document?.name"
    :thumbnail="thumbnail"
    :title="document?.title"
    :closeIcon="close"
    :widgetRef="_ref"
  ></ActivityWidget>
</template>

<script>
import { useSanityStore } from "@/store";
import { close } from "ionicons/icons";
import { parseImageUrl } from "@/helpers";
import ActivityWidget from "@/components/Resource/ActivityWidget/ActivityWidget.vue";
export default {
  name: "storyline-serializer",
  components: {
    ActivityWidget,
  },
  props: ["_ref"],
  data: () => {
    return {
      storyline: "",
      isOpen: false,
      close,
      modal: null,
    };
  },

  setup() {
    const store = useSanityStore();
    return { store };
  },
  mounted() {
    this.store.requestDocument(this._ref);
  },
  computed: {
    isLoading() {
      return this.store.getLoadingFor(this._ref);
    },
    document() {
      return this.store.getDocument(this._ref);
    },
    thumbnail() {
      if (this.document?.feature_image) {
        return this.parseImageUrl(this.document?.feature_image, 500, 500);
      }
      return require("@/assets/collection-thumbnail-placeholder.png");
    },
    altText() {
      return (
        this.document?.feature_image?.altText ??
        this.document?.feature_image?.asset.altText
      );
    },
    cardContentClass() {
      return this.document?.alt_transcript === undefined
        ? "no-alternative-activity"
        : undefined;
    },
  },
  methods: {
    parseImageUrl,
  },
};
</script>
