<template>
  <nav aria-label="breadcrumb">
    <ion-breadcrumbs
      mode="ios"
      class="desktop-breadcrumb-wrapper"
      :maxItems="maxBreadcrumbsAmount"
      :itemsBeforeCollapse="1"
      :itemsAfterCollapse="numberOfAfterItemsToShow"
      @ionCollapsedClick="presentPopover($event)"
    >
      <ion-breadcrumb
        data-test="tab-breadcrumb"
        class="previous-breadcrumb"
        v-if="tab"
        href="#"
        :rel="`/tabs/${tab}`"
        @click.prevent="goTo(`/tabs/${tab}`)"
        >{{ $t(tabName) }}
        <ion-icon
          slot="separator"
          :icon="chevronForwardOutline"
          aria-hidden="true"
        ></ion-icon>
      </ion-breadcrumb>
      <ion-breadcrumb
        v-if="grandparent || grandparentType"
        data-test="type-breadcrumb"
        class="previous-breadcrumb"
        href="#"
        :rel="`/tabs/${tab}/${parseType(grandparentType)}/${grandparent || ''}`"
        @click.prevent="grandparentClick"
        :separator="doc !== ' '"
        :active="doc == ' '"
        >{{ grandparentTitle }}
        <ion-icon
          slot="separator"
          :icon="chevronForwardOutline"
          aria-hidden="true"
        ></ion-icon>
      </ion-breadcrumb>
      <ion-breadcrumb
        v-if="parentType"
        data-test="type-breadcrumb"
        :class="currentOrPreviousClass"
        href="#"
        :rel="`/tabs/${tab}/${parseType(parentType)}/${parent}`"
        @click.prevent="goTo(`/tabs/${tab}/${parseType(parentType)}/${parent}`)"
        :separator="doc !== ' '"
        :active="doc == ' '"
        >{{ typeName }}
        <ion-icon
          slot="separator"
          :icon="chevronForwardOutline"
          aria-hidden="true"
        ></ion-icon>
      </ion-breadcrumb>
      <ion-breadcrumb
        class="current-breadcrumb"
        v-if="doc"
        :active="doc !== ' '"
        >{{ doc }}
      </ion-breadcrumb>
      <ion-popover
        :is-open="popoverOpen"
        :event="event"
        @didDismiss="popoverOpen = false"
      >
        <ion-content>
          <ion-list>
            <ion-item
              v-for="(breadcrumb, i) in collapsedBreadcrumbs"
              :key="i"
              @click.prevent="goTo(breadcrumb.rel)"
              :lines="
                i === collapsedBreadcrumbs.length - 1 ? 'none' : undefined
              "
            >
              <ion-label>{{ breadcrumb.textContent }}</ion-label>
              <ion-icon
                :icon="chevronForwardOutline"
                aria-hidden="true"
              ></ion-icon>
            </ion-item>
          </ion-list>
        </ion-content>
      </ion-popover>
    </ion-breadcrumbs>
    <p data-test="mobile-breadcrumb" class="mobile-breadcrumb-wrapper">
      <a
        class="breadcrumb-chevron"
        href="#"
        :rel="`/tabs/${prev}`"
        @click.prevent="goTo(`/tabs/${prev}`)"
        aria-hidden="true"
        ><ion-icon aria-hidden="true" :icon="chevronBackOutline"></ion-icon
      ></a>
      <ion-breadcrumb
        v-if="doc !== ' ' && parentType"
        class="mobile-breadcrumb previous-breadcrumb truncate"
        href="#"
        :rel="`/tabs/${prev}`"
        @click.prevent="goTo(`/tabs/${prev}`)"
        >{{ typeName }}
        <ion-icon
          slot="separator"
          :icon="chevronForwardOutline"
          aria-hidden="true"
        ></ion-icon>
      </ion-breadcrumb>
      <ion-breadcrumb
        v-else
        class="mobile-breadcrumb truncate current-breadcrumb"
        href="#"
        :rel="`/tabs/${tab}`"
        @click.prevent="goTo(`/tabs/${tab}`)"
        >{{ $t(tabName) }}
      </ion-breadcrumb>
    </p>
  </nav>
</template>
<script setup lang="ts">
import {
  IonBreadcrumb,
  IonBreadcrumbs,
  IonPopover,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
} from "@ionic/vue";
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";
import { onMounted, ref, defineProps, withDefaults, computed } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

const { t } = useI18n<{}>({
  useScope: "global",
});
const router = useRouter();
interface Props {
  tab: string;
  parentType?: string;
  grandparentType?: string;
  doc: string;
  parent?: string;
  parentTitle?: string;
  grandparent?: string;
  grandparentTitle?: string;
}
const props = withDefaults(defineProps<Props>(), {
  // For some reason this is the only way styling works nicely, null or "" doesn't work well for some weird reason
  doc: " ",
  parent: "",
});

const maxCharsTwoBreadcrumbs = ref(121);
const maxCharsThreeBreadcrumbs = ref(73);
const popoverOpen = ref(false);
const collapsedBreadcrumbs = ref([]);
const event = ref(null);

const prev = computed(() => {
  return props.doc !== " "
    ? `${props.tab}/${parseType(props.parentType)}${
        props.parent ? `/${props.parent}` : ""
      }`
    : props.tab;
});
const tabName = computed(() => {
  switch (props.tab) {
    case "learn":
      return "pageTitles.learn";

    case "tools":
      return "pageTitles.tools";

    default:
      return "pageTitles.learn";
  }
});
const grandparentName = computed(() => {
  if (props.grandparentTitle) {
    return props.grandparentTitle;
  }
  return null;
});
const typeName = computed(() => {
  if (props.parentTitle) {
    return props.parentTitle;
  } else {
    switch (parseType(props.parentType)) {
      case "content-pack":
        return "Explore content";

      case "course":
        return "Courses";

      case "collection":
        return "Collections";

      case "quiz":
        return "quiz";

      case "resume":
        return "resume";

      default:
        return "Courses";
    }
  }
});
const isTitleFinal = computed(() => {
  const options = ["Explore content", "Courses", "Collections"];
  return options.includes(typeName.value);
});
const crumbLengthTotal = computed(() => {
  return (
    props.doc.length +
    tabName.value.length +
    (grandparentName.value?.length ?? 0) +
    typeName?.value.length
  );
});
const maxBreadcrumbsAmount = computed(() => {
  // Hide one breadcrumb (#2)
  if (crumbLengthTotal.value >= maxCharsTwoBreadcrumbs.value) return 2;
  // Hide two breadcrumbs (#2 and #3)
  else if (crumbLengthTotal.value >= maxCharsThreeBreadcrumbs.value) return 3;
  else return 4;
});
const numberOfAfterItemsToShow = computed(() => {
  return maxBreadcrumbsAmount.value == 2 ? 1 : 2;
});
const currentOrPreviousClass = computed(() => {
  return isTitleFinal.value ? "current-breadcrumb" : "previous-breadcrumb";
});
const isBreadcrumbHidden = computed(() => {
  return maxBreadcrumbsAmount.value < 4;
});

onMounted(() => {
  setTimeout(() => {
    // In Ionic 7 we should be able to set the alt text of the collapsed icon in the html
    // https://github.com/ionic-team/ionic-framework/pull/26615
    const breadcrumbEllipses = document.getElementsByClassName(
      "breadcrumb-collapsed"
    );
    if (breadcrumbEllipses?.length > 0) {
      breadcrumbEllipses[0].setAttribute(
        "aria-label",
        t("breadcrumb.showMoreBreadcrumbs")
      );

      // Stop screen reader adding "image button group".
      const collapsedRef = breadcrumbEllipses[0].collapsedRef;
      if (collapsedRef) {
        collapsedRef.setAttribute("aria-hidden", true);
      }
    }
  });
});
const parseType = (type) => {
  switch (type) {
    case "content_pack":
      return "content-pack";
    default:
      return type ? type : "";
  }
};
const presentPopover = (e) => {
  collapsedBreadcrumbs.value = e.detail.collapsedBreadcrumbs;
  event.value = e;
  popoverOpen.value = true;
  return false;
};
const goTo = (path) => {
  console.log("goTo called", path);
  popoverOpen.value = false;
  router.push({ path: path });
  return false;
};
const grandparentClick = () => {
  if (!isBreadcrumbHidden.value) {
    goTo(
      `/tabs/${props.tab}/${parseType(props.grandparentType)}/${
        props.grandparent || ""
      }`
    );
  }
};
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";

ion-list {
  padding: 0px;
}

.previous-breadcrumb {
  text-decoration: underline;
  cursor: pointer;

  ion-icon {
    cursor: default;
  }
}
.mobile-breadcrumb {
  display: inline-block;
  max-inline-size: 80%;
}
.mobile-breadcrumb-wrapper {
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.desktop-breadcrumb-wrapper {
  display: none;
}
.breadcrumb-chevron {
  color: currentcolor;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.current-breadcrumb {
  color: var(--mid-grey);
  font-weight: unset;
}

.truncate::part(native) {
  /* Truncate text to 1 line */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@include breakpoint(medium) {
  .mobile-breadcrumb-wrapper {
    display: none;
  }
  .desktop-breadcrumb-wrapper {
    display: flex;
  }
}
</style>
