<template>
  <ion-page>
    <MobileHeader></MobileHeader>
    <ion-content>
      <DesktopLocaleSelector></DesktopLocaleSelector>
      <PageBanner
        v-if="courseStore.isCourseDetailLoading"
        :isImageLoading="true"
      ></PageBanner>
      <PageBanner v-else :imagePath="this.bannerImage"></PageBanner>
      <div class="page-wrapper" id="course-detail-main-content" tabindex="0">
        <Breadcrumbs
          tab="learn-home"
          :parentType="doc?.parent ? doc?.parent[0]?._type : ''"
          :grandparentType="grandparent?._type"
          :parent="doc?.parent ? doc?.parent[0]?.base_id : ''"
          :parentTitle="doc?.parent ? doc?.parent[0]?.title : ''"
          :grandparent="grandparent?.base_id"
          :grandparentTitle="grandparent?.title"
          :doc="doc?.title"
        ></Breadcrumbs>
        <StatusBadge
          v-if="courseProgress"
          :title="courseProgress.title"
          :description="courseProgress.desc"
          :icon="courseProgress.icon"
          :loading="this.courseStore?.isCourseDetailLoading"
          :totalResourceCount="this.course?.totalResourceCount"
          :completedResourceCount="this.course?.completedResourceCount"
        ></StatusBadge>
        <div class="title-wrapper">
          <IonSkeletonText
            style="width: 150px; height: 28px"
            v-if="!doc"
          ></IonSkeletonText>
          <h4 class="page-title">{{ doc?.title }}</h4>
          <div class="badge-wrapper">
            <ion-badge color="secondary">{{ $t("course.label") }}</ion-badge>
          </div>
          <p class="page-description" data-test="page-description">
            {{ doc?.description }}
          </p>
          <IonSkeletonText
            style="width: 150px; height: 20px"
            v-if="!doc"
          ></IonSkeletonText>
        </div>

        <h6 class="resource-header">{{ $t("course.activitiesToComplete") }}</h6>
        <div class="card-holder" data-test="resource-card">
          <div class="card-holder loading" v-if="courseStore.isCoursesLoading">
            <CardSkeleton
              :smallCard="true"
              class="course-card"
              v-for="index in 3"
              :key="index"
            ></CardSkeleton>
          </div>
          <router-link
            class="card-wrapper"
            v-for="topic in doc?.topics"
            :to="{ name: 'ResourceDetail', params: { id: topic.base_id } }"
            :key="topic._id"
          >
            <ResourceCard :document="topic" :completed="topic.completed">
            </ResourceCard>
          </router-link>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonBadge, IonSkeletonText } from "@ionic/vue";
import MobileHeader from "@/components/Common/MobileHeader/MobileHeader.vue";
import PageBanner from "@/components/Common/PageBanner/PageBanner.vue";
import { useCourseStore, useLocaleStore, useErrorStore } from "@/store";
import { RouterLink } from "vue-router";
import ResourceCard from "@/components/Resource/ResourceCard/ResourceCard.vue";
import { checkmarkCircleOutline } from "ionicons/icons";
import StatusBadge from "@/components/Common/StatusBadge/StatusBadge.vue";
import Breadcrumbs from "@/components/Common/Breadcrumbs/Breadcrumbs.vue";
import CardSkeleton from "@/components/Skeletons/CardSkeleton/CardSkeleton.vue";
import { parseImageUrl } from "@/helpers";
import DesktopLocaleSelector from "@/components/Common/LocaleSelector/DesktopLocaleSelector.vue";
import { createLocaleSpecificSanityId } from "@/helpers/CreateLocaleSpecificSanityId.js";

export default {
  name: "course-detail",
  components: {
    IonPage,
    IonContent,
    MobileHeader,
    RouterLink,
    ResourceCard,
    IonBadge,
    PageBanner,
    Breadcrumbs,
    CardSkeleton,
    IonSkeletonText,
    StatusBadge,
    DesktopLocaleSelector,
  },
  setup() {
    const courseStore = useCourseStore();
    const localeStore = useLocaleStore();
    return { courseStore, localeStore };
  },
  data() {
    return {
      checkmarkCircleOutline,
    };
  },
  ionViewDidEnter() {
    const sanityId = this.createLocaleSpecificSanityId(this.$route.params.id);
    this.loadCourse(sanityId).catch((error) => {
      if (
        //if the course specific to the locale selected cannot be accessed, and the locale selected is an english locale, load the global english version.
        error.response.status === 403 &&
        this.localeStore.getCurrentLocaleCode.includes("en")
      ) {
        this.loadCourse(this.$route.params.id);
      } else {
        //otherwise, if the locale selected is not an english locale and the non english locale version cannot be accessed, load an error state.
        this.courseStore.$reset();
        const errorStore = useErrorStore();
        errorStore.setGenericError(true);
      }
    });
  },
  computed: {
    themeName() {
      return this.localeStore.getCurrentLocaleTheme.toLowerCase();
    },
    progressEllipseIcon() {
      return require(`@/assets/progressEllipse/progress-ellipse-${this.themeName}.svg`);
    },
    doc() {
      return this.courseStore.getCourseDetail;
    },
    course() {
      return this.courseStore.getCourseDetail ?? null;
    },
    grandparent() {
      if (
        this.doc &&
        this.doc.parent &&
        this.doc.parent[0] &&
        this.doc.parent[0].grandparent
      )
        return this.doc.parent[0].grandparent[0];
      return null;
    },
    bannerImage() {
      if (this.doc?.feature_image) {
        return this.parseImageUrl(this.doc?.feature_image, 1000, 2500);
      } else return require("@/assets/course-thumbnail-placeholder.png");
    },
    courseProgress() {
      const courseProgressMap = {
        inProgress: {
          title: "course.courseInProgress",
          icon: this.progressEllipseIcon,
          desc: "course.activitiesCompleted",
        },
        complete: {
          title: "course.courseComplete",
          icon: checkmarkCircleOutline,
          desc: "course.activitiesCompleted",
        },
      };
      if (
        this.course?.totalResourceCount > 0 &&
        this.course?.totalResourceCount == this.course?.completedResourceCount
      ) {
        return courseProgressMap.complete;
      } else if (this.course?.completedResourceCount > 0) {
        return courseProgressMap.inProgress;
      } else {
        return "";
      }
    },
  },
  methods: {
    async loadCourse(id) {
      return await this.courseStore.requestCourseDetail(id);
    },
    parseImageUrl,
    createLocaleSpecificSanityId,
  },
};
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";

.page-wrapper {
  z-index: 9;
}
.title-wrapper {
  text-align: left;
}
.page-title {
  margin-bottom: 20px;
  margin-top: 0px;
}
.badge-wrapper {
  margin-bottom: 20px;
  gap: 10px;
}
.breadcrumbs {
  display: block;
}
ion-icon {
  vertical-align: middle;
  font-size: 14px;
  margin-right: 2px;
}
.resource-header {
  text-align: left;
  font-weight: bold;
}
.card-holder {
  & a {
    text-decoration: none;
  }
}
.card-wrapper {
  border-radius: 16px;
  text-decoration: none;
  margin-bottom: 16px;
  transition: box-shadow var(--transition-time);
  &:focus-visible {
    outline: 2px solid var(--ion-color-secondary);
    box-shadow: inset 0px 0px 0px 1px var(--background-white);
  }
  &:hover {
    box-shadow: var(--shadow-2);
  }
  &:active {
    box-shadow: var(--shadow-1);
  }
  width: 312px;
}
// handles focus on safari
*:focus {
  outline-width: thin;
  overflow: hidden;
  outline-color: var(--ion-color-secondary);
}

@include breakpoint(medium) {
  .resource-header {
    text-align: left;
  }
  .card-holder {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    gap: 16px;
  }
  .card-wrapper {
    margin-bottom: 0;
  }
  ion-header {
    height: 0;
  }
}
</style>
