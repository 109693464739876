import axios from "axios";
import { useAuthStore, useErrorStore, useMsalStore } from "@/store";
import EnvironmentConfig from "@/EnvironmentConfig";
import router from "@/router/index.js";

const instance = axios.create({
  baseURL: EnvironmentConfig.ApiBaseUrl,
});

instance.interceptors.request.use(
  async (config) => {
    // TODO: refactor to check against a list of endpoints where auths are not-required
    if (!config.url.includes("terms-and-conditions-content")) {
      await useMsalStore().acquireToken();
      config.headers["Content-Type"] = "application/json";
      config.headers["Authorization"] = `Bearer ${
        useAuthStore().getCurrentUserIdToken
      }`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    //TODO: fix cypress e2e tests to intercept all API calls
    if (window.skipApiError === true) {
      return;
    }
    if (error.response) {
      if (error.response.config.url.includes("/page-visits")) {
        console.error("Page visit error", error);
        return;
      }
      // TODO: improve this catch-all error handling to consider component levels
      const errorStore = useErrorStore();
      if (
        error.response.status === 404 &&
        !(router.currentRoute.value.name === "ResourceDetail")
      ) {
        router.push({
          name: "Error",
        });
        return;
      }
      if (
        !(error.response.status === 403) &&
        !(router.currentRoute.value.name === "ResourceDetail")
      ) {
        errorStore.setGenericError(true);
      }
      throw error;
    }
  }
);

export default instance;
