import { defineStore } from "pinia";
import { localeCodes, locales } from "@/common/locales";
import EnvironmentConfig from "@/EnvironmentConfig";
import VueCookies from "vue-cookies";

export const useLocaleStore = defineStore({
  id: "locale",
  state: () => ({
    currentLocale:
      VueCookies.get("locale") ??
      locales.filter(
        (x) =>
          x.description.toLowerCase() ===
          EnvironmentConfig.Locale.Region.toLowerCase()
      )[0]?.code,
  }),
  actions: {
    setLocale(locale, $i18n) {
      this.currentLocale = locale;
      $i18n.locale = locale;
      VueCookies.set("locale", locale);
      document.documentElement.lang = locale;
    },
    initialize() {
      document.documentElement.lang = this.currentLocale;
    },
  },
  getters: {
    getCurrentLocaleTheme() {
      const locale = locales.filter((x) => x.code === this.currentLocale)[0];
      return locale.theme;
    },
    getCurrentLocaleCode() {
      return this.currentLocale;
    },
    getAllowedLocaleCodes() {
      if (EnvironmentConfig.Locale.Region.toLowerCase() === "global") {
        return localeCodes;
      }
      return locales
        .filter(
          (x) =>
            x.description.toLowerCase() ===
            EnvironmentConfig.Locale.Region.toLowerCase()
        )
        .map((x) => x.code);
    },
    getShowLocaleSwitcher() {
      return this.getAllowedLocaleCodes.length > 1;
    },
    getSanityLocaleId() {
      return locales.filter((x) => x.code === this.currentLocale)[0]
        ?.sanityLocaleId;
    },
  },
});
