<template>
  <div class="text-input">
    <div v-if="isLoading" class="skeleton-wrapper" data-test="skeleton-wrapper">
      <ion-skeleton-text :animated="true" class="skeleton-label" />
      <ion-skeleton-text :animated="true" class="skeleton-input" />
    </div>
    <ion-item v-else class="input-item" data-test="input-item" lines="none">
      <ion-label
        v-if="labelText"
        aria-hidden="true"
        position="stacked"
        data-test="input-label"
        class="input-label"
        :class="{ disabled, readonly }"
        >{{ labelText }}
        <span v-if="required">({{ $t("resume.requiredLabel") }})</span>
      </ion-label>
      <ion-input
        class="input-field"
        :class="{ disabled, readonly }"
        :type="inputType"
        :placeholder="placeholderText"
        @ionChange="emit('onChange', $event)"
        @ionBlur="emit('onBlur', $event)"
        :value="value"
        :required="required"
        :disabled="disabled"
        :readonly="readonly"
        data-test="text-input"
        :aria-describedby="`${errorIdPrefix}-${uid}`"
      />
      <ion-note
        v-if="helperText"
        class="helper-text"
        slot="helper"
        data-test="helper-text"
        :class="{ disabled, readonly }"
        >{{ helperText }}</ion-note
      >
      <ion-note
        v-if="errorText && !disabled && !readonly"
        aria-hidden="true"
        :id="`${errorIdPrefix}-${uid}`"
        slot="error"
        class="error-text"
        data-test="error-text"
        >{{ errorText }}</ion-note
      >
    </ion-item>
  </div>
</template>
<script setup lang="ts">
import {
  defineProps,
  withDefaults,
  defineEmits,
  ref,
  onMounted,
  getCurrentInstance,
  onUpdated,
} from "vue";
import {
  IonItem,
  IonLabel,
  IonInput,
  IonNote,
  IonSkeletonText,
} from "@ionic/vue";

import { TextFieldTypes } from "@ionic/core";

interface Props {
  inputType?: TextFieldTypes;
  value: string | number;
  labelText?: string;
  placeholderText?: string;
  helperText?: string;
  errorText?: string;
  required?: boolean;
  isLoading: boolean;
  disabled?: boolean;
  readonly?: boolean;
  errorIdPrefix?: string;
}

withDefaults(defineProps<Props>(), {
  required: false,
  disabled: false,
  readonly: false,
  errorIdPrefix: "error-message",
});

onUpdated(() => {
  emit("onUpdate");
});

const emit = defineEmits(["onChange", "onBlur", "onUpdate"]);

const uid = ref<number | null | undefined>(null);
onMounted(() => {
  uid.value = getCurrentInstance()?.uid;
});
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";
.skeleton-wrapper {
  display: block;
}
.skeleton-label {
  width: 120px;
  height: 20px;
}
.skeleton-input {
  height: 56px;
  border: 2px solid var(--skeleton-border);
  border-radius: 8px;
}
ion-item.input-item {
  --padding-start: var(--spacing-0);
  --inner-padding-end: var(--spacing-0);
  &.ion-invalid {
    ion-input.input-field {
      border-color: 2px solid var(--error);
      --color: var(--error);
      &.has-focus {
        border-color: var(--error);
      }
    }
  }
  &.ion-valid {
    ion-input.input-field {
      border: 2px solid var(--alert-success);
    }
    ion-note.helper-text {
      color: var(--alert-success);
    }
  }
  :deep(input).native-input[disabled] {
    opacity: 1;
    cursor: default;
  }
}
ion-label.input-label {
  --color: var(--text-primary);
  margin-right: var(--spacing-20);
  margin-top: var(--spacing-0);
  margin-bottom: var(--spacing-8);
  font-size: 16px;
  &.disabled {
    --color: var(--text-disabled);
    opacity: 1;
  }
  &.readonly {
    opacity: 1;
  }
}
ion-input.input-field {
  border: 2px solid var(--grey-400);
  border-radius: 8px;
  --padding-bottom: var(--spacing-16);
  --padding-top: var(--spacing-16);
  --padding-start: var(--spacing-24);
  --padding-end: var(--spacing-24);
  --background: var(--background-white);
  --color: var(--text-primary);
  --placeholder-color: var(--text-tertiary);
  --placeholder-opacity: 100;
  &.disabled {
    --color: var(--text-disabled);
    --placeholder-color: var(--text-disabled);
    border: 2px solid var(--surface-lighter-grey);
  }
  &.readonly {
    border: 2px solid var(--surface-lighter-grey);
    --color: var(--text-secondary);
    border: 2px solid var(--surface-lighter-grey);
  }
  &:not(.disabled):not(.readonly).has-focus {
    border-color: var(--ion-color-primary);
  }
}

ion-note.helper-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: var(--text-secondary);
  &.disabled {
    color: var(--text-disabled);
  }
}

ion-note.error-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: var(--error);
}
</style>
