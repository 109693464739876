<template>
  <ion-page>
    <MobileHeader></MobileHeader>
    <ion-content>
      <DesktopLocaleSelector />
      <div class="page-wrapper" :id="mainContentId" tabindex="-1">
        <Breadcrumbs
          tab="tools"
          :grandparentType="grandparentBreadcrumb?.type"
          :grandparentTitle="grandparentBreadcrumb?.label"
          :parentType="parentBreadcrumb?.type"
          :parent="parentBreadcrumb?.route"
          :parentTitle="parentBreadcrumb?.label"
          :doc="breadcrumb?.label"
        ></Breadcrumbs>
        <div>
          <div class="content-frame-wrapper">
            <div class="content-wrapper">
              <h1 class="page-title" data-test="page-title">
                <slot name="title"></slot>
              </h1>
              <div class="description" data-test="description">
                <slot name="description"></slot>
              </div>

              <div
                v-if="$slots['additional-note']"
                class="additional-note"
                data-test="additional-note"
              >
                <slot name="additional-note"></slot>
              </div>
              <slot name="main-content"></slot>
              <slot name="resume-helper"></slot>
            </div>
          </div>
        </div>
      </div>
      <AppModal
        v-if="showReturnWithoutSaveModal"
        :isOpen="showReturnWithoutSaveModal"
        :title="$t('resume.returnWithoutSaveModal.title')"
        :body="$t('resume.returnWithoutSaveModal.body')"
        :primaryButton="{
          label: t('resume.returnWithoutSaveModal.primaryButtonText'),
        }"
        :secondaryButton="{
          label: t('resume.returnWithoutSaveModal.secondaryButtonText'),
        }"
        @onPrimaryButtonClick="userAction = 'confirmed'"
        @onSecondaryButtonClick="userAction = 'cancelled'"
        data-test="return-without-save-modal"
      />
      <AppModal
        v-if="showDeleteModal"
        :isOpen="showDeleteModal"
        :title="$t('resume.deleteFormModal.title')"
        :primaryButton="{
          label: t('resume.deleteFormModal.primaryButtonText'),
        }"
        :secondaryButton="{
          label: t('resume.deleteFormModal.secondaryButtonText'),
        }"
        @onPrimaryButtonClick="userDeleteAction = 'delete'"
        @onSecondaryButtonClick="userDeleteAction = 'cancel'"
        data-test="delete-form-modal"
      />
      <ErrorModal
        v-show="errorStore.hasSaveFormError"
        :hasErrored="errorStore.hasSaveFormError"
        data-test="error-modal-save"
        class="error-modal"
        :title="t('resume.saveFormErrorModal.title')"
        :body="t('resume.saveFormErrorModal.body')"
        :primaryButton="{
          label: t('resume.saveFormErrorModal.primaryButtonText'),
        }"
        :secondaryButton="{
          label: t('resume.saveFormErrorModal.secondaryButtonText'),
        }"
        @onPrimaryButtonClick="formSaveRetryFunction"
        @onSecondaryButtonClick="dismissErrorModal"
        @onDismiss="dismissErrorModal"
      ></ErrorModal>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import { defineProps, onMounted, ref, watch } from "vue";
import { IonPage, IonContent } from "@ionic/vue";
import MobileHeader from "@/components/Common/MobileHeader/MobileHeader.vue";
import DesktopLocaleSelector from "@/components/Common/LocaleSelector/DesktopLocaleSelector.vue";
import Breadcrumbs from "@/components/Common/Breadcrumbs/Breadcrumbs.vue";
import ErrorModal from "@/components/Common/ErrorModal/ErrorModal.vue";
import AppModal from "@/components/Common/AppModal/AppModal.vue";
import { useErrorStore } from "@/store";
import { onBeforeRouteLeave } from "vue-router";
import { useI18n } from "vue-i18n";
const { t } = useI18n<{}>({
  useScope: "global",
});

interface BreadcrumbProp {
  type?: string;
  route?: string;
  label?: string;
}

interface Props {
  breadcrumbs: BreadcrumbProp[];
  mainContentId: string;
  formSaveRetryFunction?: () => void;
  skipOnRouteBeforeLeave?: boolean;
  formDeleteFunction?: () => void;
  dismissDeleteModal?: () => void;
  triggerDeleteModal?: boolean;
}

type userActionType = "confirmed" | "cancelled";
type userDeleteActionType = "delete" | "cancel";

const props = defineProps<Props>();

const breadcrumb = ref<BreadcrumbProp>();

const parentBreadcrumb = ref<BreadcrumbProp>();

const grandparentBreadcrumb = ref<BreadcrumbProp>();

const errorStore = useErrorStore();

const showReturnWithoutSaveModal = ref(false);
const showDeleteModal = ref(false);
const userAction = ref<userActionType>(null);
const userDeleteAction = ref<userDeleteActionType>(null);

watch(
  () => props.triggerDeleteModal,
  async (newVal) => {
    if (newVal === true) {
      openDeleteModal((result) => {
        if (result) {
          props.formDeleteFunction();
        } else {
          props.dismissDeleteModal();
        }
      });
    }
  }
);
onBeforeRouteLeave((to, from, next) => {
  // Skip triggering modal for e2e tests
  if (window.skipReturnWithoutSaveModal === true) {
    next();
    return;
  }
  if (!props.skipOnRouteBeforeLeave) {
    openReturnWithoutSaveModal((result) => {
      if (result) {
        next();
      } else {
        next(false);
      }
    });
  } else {
    next();
  }
});

onMounted(async () => {
  const parent = props.breadcrumbs.find(
    (breadcrumb) => breadcrumb.type === "parent"
  );

  if (parent) {
    parentBreadcrumb.value = {
      ...parent,
      type: "resume",
    };

    grandparentBreadcrumb.value = {
      label: t("resume.breadcrumbs.resume"),
      type: "resume",
    };
  } else {
    parentBreadcrumb.value = {
      label: t("resume.breadcrumbs.resume"),
      type: "resume",
    };
  }

  breadcrumb.value = props.breadcrumbs.find((breadcrumb) => !breadcrumb.type);
});

const dismissErrorModal = () => {
  errorStore.setGenericError(false); //Toggle off to avoid duplicate modals
  errorStore.setSaveFormError(false);
};

const openDeleteModal = (callback) => {
  showDeleteModal.value = true;
  const unwatch = watch(userDeleteAction, (newValue) => {
    if (newValue === "delete") {
      userDeleteAction.value = "";
      showDeleteModal.value = false;
      unwatch(); // Stop the watcher
      callback(true);
    } else if (userDeleteAction.value === "cancel") {
      userDeleteAction.value = "";
      showDeleteModal.value = false;
      unwatch(); // Stop the watcher
      callback(false);
    }
  });
};

const openReturnWithoutSaveModal = (callback) => {
  showReturnWithoutSaveModal.value = true;

  const unwatch = watch(userAction, (newValue) => {
    if (userAction.value) {
      if (newValue === "confirmed") {
        userAction.value = "";
        showReturnWithoutSaveModal.value = false;
        unwatch(); // Stop the watcher
        callback(true);
      } else if (newValue === "cancelled") {
        userAction.value = "";
        showReturnWithoutSaveModal.value = false;
        unwatch(); // Stop the watcher
        callback(false);
      }
    }
  });
};
</script>

<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";

.page-wrapper {
  z-index: 9;
  border-radius: 0;
  box-shadow: none;
  margin-top: var(--spacing-0);
  @include breakpoint(medium) {
    margin-top: 93px;
    padding-top: 0;
  }
}

.content-frame-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: var(--spacing-24);
}

.content-wrapper {
  width: 100%;
  max-width: 584px;
  color: var(--button-text-primary);
  display: block;
  text-align: left;
  margin: auto;
}

.page-title {
  font-size: 36px;
  font-weight: 500;
  line-height: 44px;
  margin: 0 0 var(--spacing-16);
}

.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-bottom: var(--spacing-24);
  color: var(--button-text-primary);
}

.additional-note {
  padding: var(--spacing-16);
  border-radius: 9px;
  background-color: var(--ion-color-system-information);
  color: var(--text-black);
  margin-top: var(--spacing-16);
  margin-bottom: var(--spacing-24);
}
</style>
