import { createApp } from "vue";
import { IonicVue } from "@ionic/vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import i18n from "./i18n";
import VueCookies from "vue-cookies";
import analytics from "./analytics";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";
import "@/common/theme/breakpoints.scss";
import "@/common/theme/layout.scss";
import "@/common/theme/typography.scss";
import "@/common/theme/ion-components.scss";
import "@/common/theme/buttons.scss";
import "@/common/theme/colours.scss";
import "@/common/theme/spacings.css";
import "@/common/theme/swiper.scss";
import "@/common/theme/global-styles.js";

const pinia = createPinia();
const app = createApp(App)
  .use(i18n)
  .use(IonicVue, {
    mode: "ios",
  })
  .use(pinia)
  .use(router)
  .use(analytics as any)
  .use(VueCookies);

router.isReady().then(() => {
  app.mount("#app");
});
