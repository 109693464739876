import {
  Licence,
  Qualification,
  Resume,
  WorkExperience,
} from "@/store/models/resume.models";
import {
  TextRun,
  Paragraph,
  Document,
  HeadingLevel,
  AlignmentType,
  Footer,
  PageNumber,
} from "docx";
import i18n from "@/i18n";
import {
  sortLicences,
  sortWorkExperiences,
  convertToMonthYear,
  sortQualifications,
} from "@/helpers";
export function generateResume(resumeData: Resume | null): Document {
  const doc = new Document({
    styles: {
      default: {
        document: { run: { font: "Open Sans" } },
        heading1: { run: { bold: true, color: "1B365D", size: 32 } }, // 32pts = 16px
        heading2: { run: { bold: true, color: "1B365D", size: 24 } }, // 24pts = 12px
      },
      paragraphStyles: [
        {
          id: "subtitle",
          name: "Subtitle",
          run: {
            size: 22, // 22pts = 11px
          },
        },
      ],
    },
    sections: [
      {
        footers: {
          default: new Footer({
            children: [
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                  new TextRun({
                    children: [
                      resumeData?.participant.givenName +
                        " " +
                        resumeData?.participant.familyName,
                    ],
                  }),
                ],
              }),
              new Paragraph({
                alignment: AlignmentType.RIGHT,
                children: [
                  new TextRun({
                    children: [PageNumber.CURRENT],
                  }),
                ],
              }),
            ],
          }),
        },
        children: [
          createName(
            resumeData?.participant.givenName +
              " " +
              resumeData?.participant.familyName
          ),
          createContactInfo(
            resumeData?.participant.mobilePhone,
            resumeData?.participant.email,
            resumeData?.contactDetails?.linkedinId,
            resumeData?.participant.location
          ),
          createLineBreak(1),
          createPersonalStatement(resumeData?.personalStatement),
          ...createExperience(resumeData?.workExperiences),
          ...createQualification(resumeData?.qualifications),
          ...createLicence(resumeData?.licences),
          createHeading("Referees"),
          createReferees(),
        ],
      },
    ],
  });
  return doc;
}

const createName = (name: string | ""): Paragraph => {
  return new Paragraph({
    text: name,
    heading: HeadingLevel.HEADING_1,
    alignment: AlignmentType.CENTER,
  });
};

const createContactInfo = (
  phoneNumber?: string,
  email?: string,
  linkedinId?: string,
  location?: string | null
): Paragraph => {
  let contactInfo = "";
  if (location) contactInfo += `${location} | `;
  if (phoneNumber) contactInfo += `${phoneNumber} | `;
  if (email) contactInfo += `${email}`;

  return new Paragraph({
    alignment: AlignmentType.CENTER,
    children: [
      new TextRun({ text: contactInfo }),
      new TextRun({ text: `${linkedinId}`, break: 1 }),
    ],
  });
};

const createPersonalStatement = (personalStatement?: string): Paragraph => {
  if (!personalStatement) return new Paragraph({});
  return new Paragraph({
    text: personalStatement,
    alignment: AlignmentType.LEFT,
    children: [new TextRun({ break: 1 })],
  });
};

const createLineBreak = (lines: number): Paragraph => {
  return new Paragraph({
    children: [new TextRun({ break: lines - 1 })],
  });
};

const createHeading = (heading: string): Paragraph => {
  if (!heading) return new Paragraph({});
  return new Paragraph({
    text: heading,
    keepLines: true,
    keepNext: true,
    heading: HeadingLevel.HEADING_2,
  });
};

const createExperience = (experiences?: Array<WorkExperience>): Paragraph[] => {
  if (!experiences || experiences?.length === 0) return [];
  const sortedExperience = sortWorkExperiences(experiences) || [];
  const heading: Paragraph = createHeading("Experience"); // Todo: localisation in another PR

  const result = sortedExperience
    .map((experience) => {
      const arr: Paragraph[] = [];
      if (experience.role) {
        arr.push(
          new Paragraph({
            alignment: AlignmentType.LEFT,
            style: "subtitle",
            keepLines: true,
            keepNext: true,
            children: [
              new TextRun({
                text: experience.role,
                bold: true,
              }),
            ],
          })
        );
      }
      if (experience.business) {
        arr.push(
          new Paragraph({
            alignment: AlignmentType.LEFT,
            style: "subtitle",
            keepLines: true,
            keepNext: true,
            children: [new TextRun({ text: experience.business })],
          })
        );
      }
      arr.push(
        new Paragraph({
          alignment: AlignmentType.LEFT,
          keepLines: true,
          keepNext: true,
          children: [
            new TextRun({
              text: getResumeDate(
                experience.startDate,
                experience.endDate,
                experience.isCurrentRole,
                "resume.workExperiences.presentLabel"
              ),
            }),
          ],
        })
      );
      if (experience.businessDescription) {
        arr.push(
          new Paragraph({
            alignment: AlignmentType.LEFT,
            keepLines: true,
            keepNext: true,
            spacing: {
              before: 200,
            },
            children: [
              new TextRun({
                text: `${experience.businessDescription}`,
              }),
            ],
          })
        );
      }
      if (experience.impact) {
        arr.push(
          new Paragraph({
            alignment: AlignmentType.LEFT,
            keepLines: true,
            keepNext: true,
            spacing: {
              before: 200,
            },
            children: [
              new TextRun({
                text: "Responsibilities and achievements:",
              }),
            ],
          })
        );
      }
      arr.push(...splitParagraphIntoBullets(experience.impact));
      arr.push(createLineBreak(1));
      return arr;
    })
    .reduce((prev, curr) => prev.concat(curr), []);
  return [heading, ...result];
};

const createQualification = (
  qualifications?: Array<Qualification>
): Paragraph[] => {
  if (!qualifications || qualifications.length === 0) return [];
  const sortedQualification = sortQualifications(qualifications) || [];
  const heading: Paragraph = createHeading("Qualifications"); // Todo: localisation in another PR
  const result = sortedQualification
    .map((qualification) => {
      const arr: Paragraph[] = [];
      if (qualification.name) {
        arr.push(
          new Paragraph({
            alignment: AlignmentType.LEFT,
            style: "subtitle",
            keepLines: true,
            keepNext: true,
            children: [
              new TextRun({
                text: qualification.name,
                bold: true,
              }),
            ],
          })
        );
      }
      if (qualification.institution) {
        arr.push(
          new Paragraph({
            style: "subtitle",
            alignment: AlignmentType.LEFT,
            keepLines: true,
            keepNext: true,
            children: [new TextRun({ text: qualification.institution })],
          })
        );
      }
      arr.push(
        new Paragraph({
          alignment: AlignmentType.LEFT,
          keepLines: true,
          keepNext: true,
          children: [
            new TextRun({
              text: getResumeDate(
                undefined,
                qualification.endDate,
                !qualification.isQualificationComplete,
                "resume.qualificationsOverview.inProgressLabel"
              ),
            }),
          ],
        })
      );
      if (qualification.highlights) {
        arr.push(
          new Paragraph({
            alignment: AlignmentType.LEFT,
            keepLines: true,
            children: [new TextRun({ text: qualification.highlights })],
          })
        );
      }
      arr.push(createLineBreak(1));
      return arr;
    })
    .reduce((prev, curr) => prev.concat(curr), []);
  return [heading, ...result];
};

const createLicence = (licences?: Array<Licence>): Paragraph[] => {
  if (!licences || licences.length === 0) return [];
  const sortedLicence = sortLicences(licences) || [];
  const heading: Paragraph = createHeading("Licences"); // Todo: localisation in another PR
  const result = sortedLicence
    .map((licence) => {
      const arr: Paragraph[] = [];
      if (licence.name) {
        arr.push(
          new Paragraph({
            style: "subtitle",
            alignment: AlignmentType.LEFT,
            keepLines: true,
            keepNext: true,
            children: [
              new TextRun({
                text: licence.name,
                bold: true,
              }),
            ],
          })
        );
      }
      if (licence.issuer) {
        arr.push(
          new Paragraph({
            style: "subtitle",
            alignment: AlignmentType.LEFT,
            keepLines: true,
            keepNext: true,
            children: [new TextRun({ text: licence.issuer })],
          })
        );
      }
      arr.push(
        new Paragraph({
          alignment: AlignmentType.LEFT,
          keepLines: true,
          keepNext: true,
          children: [
            new TextRun({
              text: getResumeDate(licence.issueDate, licence.expiryDate, false),
            }),
          ],
        })
      );
      if (licence.description) {
        arr.push(
          new Paragraph({
            alignment: AlignmentType.LEFT,
            keepLines: true,
            children: [new TextRun({ text: licence.description })],
          })
        );
      }
      arr.push(createLineBreak(1));
      return arr;
    })
    .reduce((prev, curr) => prev.concat(curr), []);
  return [heading, ...result];
};

const createReferees = () => {
  return new Paragraph({
    text: "Referees are available upon request.",
    alignment: AlignmentType.LEFT,
    keepLines: true,
  });
};

export const splitParagraphIntoBullets = (
  text: string | undefined
): Paragraph[] => {
  const bulletPoints = text?.split("\n");
  const arr: Paragraph[] = [];
  bulletPoints?.forEach((bulletPoint) => {
    if (bulletPoint.includes("•")) {
      bulletPoint = bulletPoint.slice(2);
    }
    arr.push(createBullet(bulletPoint));
  });
  return arr;
};

export const createBullet = (text: string): Paragraph => {
  return new Paragraph({
    text: text,
    keepLines: true,
    keepNext: true,
    bullet: {
      level: 0,
    },
  });
};

/**
 * Returns a formatted date range based on the provided start date, end date, and current flag.
 *
 * @param {string} startDate - The start date of the date range (optional).
 * @param {string} endDate - The end date of the date range (optional).
 * @param {boolean} isCurrent - Flag indicating if the date range represents the current date (optional).
 * @return {string} The formatted date range.
 */
export const getResumeDate = (
  startDate?: string,
  endDate?: string,
  isCurrent?: boolean,
  label?: string
): string => {
  let formattedDateRange;
  if (isCurrent && startDate && label) {
    formattedDateRange = `${convertToMonthYear(
      startDate,
      i18n.global.t
    )} – ${i18n.global.t(label)}`; // *use En dash (–)
  } else if (isCurrent && label && !startDate) {
    formattedDateRange = `${i18n.global.t(label)}`;
  } else if (!startDate && !endDate) {
    formattedDateRange = "";
  } else if (!startDate && endDate) {
    formattedDateRange = `${convertToMonthYear(endDate, i18n.global.t)}`;
  } else if (startDate && !endDate) {
    formattedDateRange = `${convertToMonthYear(startDate, i18n.global.t)}`;
  } else {
    formattedDateRange = `${convertToMonthYear(
      startDate || "",
      i18n.global.t
    )} – ${convertToMonthYear(endDate || "", i18n.global.t)}`;
  }
  return formattedDateRange;
};
