<template>
  <swiper
    v-if="store.getLoadingFor('content_pack')"
    width="190"
    :spaceBetween="16"
    :initialSlide="0"
    :slidesOffsetAfter="24"
    :slidesOffsetBefore="24"
  >
    <!-- loading state -->
    <swiper-slide v-for="index in 4" :key="index">
      <CategoryCardSkeleton
        class="category-card"
        data-test="course-card-skeleton"
      ></CategoryCardSkeleton>
    </swiper-slide>
  </swiper>
  <!-- loaded state -->
  <swiper
    v-else
    @swiper="onSwiper"
    :initialSlide="0"
    slidesPerView="auto"
    :spaceBetween="16"
    :slidesOffsetAfter="24"
    :slidesOffsetBefore="24"
    :navigation="true"
    :modules="modules"
    :watchSlidesProgress="true"
  >
    <swiper-slide
      v-for="(item, index) in store.getDocumentsByType('content_pack')"
      data-test="swiper-cards-content"
      :key="item._id"
    >
      <router-link
        class="content-pack-wrapper card-wrapper"
        :to="{ name: 'Content Pack Detail', params: { id: item.base_id } }"
        @focus="onSwiperCardFocus(index, this.swiper)"
      >
        <CategoryCard
          data-test="content-pack-card"
          class="category-card"
          :title="item.title"
          :loading="false"
          :courseTotal="contentCount(item.content, 'course')"
          :collectionTotal="contentCount(item.content, 'collection')"
        ></CategoryCard>
      </router-link>
    </swiper-slide>
  </swiper>
</template>

<script>
import CategoryCard from "@/components/CategoryCard/CategoryCard.vue";
import CategoryCardSkeleton from "@/components/Skeletons/CategoryCardSkeleton/CategoryCardSkeleton.vue";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation } from "swiper";
import { useSanityStore } from "@/store";
import { RouterLink } from "vue-router";
import { onSwiperCardFocus } from "@/helpers";
// Enables scrolling using Mousewheel on desktop
SwiperCore.use(Navigation);

export default {
  name: "categoryCards",
  components: {
    Swiper,
    SwiperSlide,
    CategoryCard,
    RouterLink,
    CategoryCardSkeleton,
  },
  data() {
    return {
      swiper: null,
    };
  },

  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    contentCount(content, type) {
      if (!content || content?.length === 0) {
        return 0;
      }
      return content.filter((x) => x?._type === type).length;
    },
    onSwiperCardFocus,
  },
  setup() {
    const store = useSanityStore();
    return { store, modules: Navigation };
  },
};
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";
@import "@/common/theme/swiper.scss";
.card-holder {
  display: none;
}
.card-wrapper {
  border-radius: 8px;
  text-decoration: none;
  transition: box-shadow var(--transition-time);
  width: inherit;
}

.content-pack-wrapper {
  height: 100%;
  border-radius: 16px;
}
.category-card {
  margin-left: 0;
  margin-right: 0;
}

@include breakpoint(medium) {
  .slider-test {
    flex-wrap: wrap;
  }

  .collection-card,
  .content-pack-card {
    max-width: 190px;
  }

  .content-pack-card {
    height: 100%;
    margin: 0;
  }
  .card-holder {
    display: flex;
    flex-wrap: wrap;
    & a {
      text-decoration: none;
    }
    gap: 12px;
  }
  // handles focus on safari
  *:focus {
    outline-width: thin;
    outline-color: var(--ion-color-secondary);
    overflow: hidden;
  }

  .card-wrapper {
    &:focus-visible,
    &:focus {
      outline: none;
      box-shadow: var(--shadow-0);
    }
    &:focus-visible > ion-card,
    &:focus > ion-card {
      box-shadow: none !important;
    }
    &:focus-visible::before,
    &:focus::before {
      content: "";
      position: absolute;
      top: 6px;
      left: -4px;
      right: -4px;
      bottom: 6px;
      border: 2px solid var(--ion-color-secondary);
      z-index: 1;
      border-radius: 20px;
    }
  }
}
.loading-holder > ion-card {
  width: 302px;
  height: 248px;
}

.swiper :deep(.swiper-button-prev),
.swiper :deep(.swiper-button-next) {
  height: 129px;
  top: calc(100% - 124px);
}
</style>
