import { defineStore } from "pinia";
import http from "../http-common";
import { useLocaleStore } from "@/store";

export const useProgramStore = defineStore({
  id: "program",
  state: () => ({
    programCarousel: {
      loading: false,
      document: {},
    },
  }),
  actions: {
    requestProgramCarousel() {
      this.programCarousel.loading = true;
      const path = `program-carousel?locale=${
        useLocaleStore().getCurrentLocaleCode
      }`;

      http
        .get(path)
        .then((response) => {
          this.programCarousel.document = response.data;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.programCarousel.loading = false;
        });
    },
  },
  getters: {
    isProgramCarouselLoading(state) {
      return state.programCarousel.loading;
    },
    getProgramCarousel(state) {
      return state.programCarousel.document || null;
    },
  },
});
