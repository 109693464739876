<template>
  <ion-footer class="ion-no-border" id="footer-wrapper" role="footer">
    <a
      data-test="staff-signin"
      v-if="!isLoggedIn"
      @click="signInAsStaff"
      href="#"
      >{{ $t("signInHome.signInStaff") }}</a
    >
    <router-link
      :to="{ name: 'terms-and-conditions' }"
      target="_blank"
      data-test="t&c-link"
    >
      {{ $t("signInHome.t&c") }}
    </router-link>
    <a
      :href="$t('privacyPolicyUrl')"
      target="_blank"
      data-test="privacy-policy-link"
      >{{ $t("signInHome.privacyPolicy") }}</a
    >
  </ion-footer>
</template>
<script>
import EnvironmentConfig from "@/EnvironmentConfig";
import { useAuthStore, useMsalStore } from "@/store";
import { IonFooter } from "@ionic/vue";

export default {
  setup() {
    const authStore = useAuthStore();
    const msalStore = useMsalStore();
    return { authStore, msalStore };
  },
  components: { IonFooter },
  props: {
    isLoggedIn: { type: Boolean, default: false },
  },
  methods: {
    async signInAsStaff() {
      this.authStore.storeIsConsultant(true);
      let request = {
        authority: EnvironmentConfig.Azure.ADAuthority,
      };
      await this.msalStore.loginRedirect(request);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";
a:hover {
  cursor: pointer;
}
#footer-wrapper {
  position: absolute;
  bottom: 0;
  margin-bottom: 22px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  gap: 8px;
  p,
  a {
    font-size: 14px;
  }
  @include breakpoint(medium) {
    position: relative;
    gap: 16px;
  }
}
</style>
