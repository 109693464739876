<template>
  <ion-card class="card" data-test="profile-skeleton-card">
    <h5>
      <ion-skeleton-text
        :animated="true"
        style="width: 40%; height: 20px"
      ></ion-skeleton-text>
    </h5>
    <p>
      <ion-skeleton-text
        :animated="true"
        style="width: 40%"
      ></ion-skeleton-text>
    </p>
    <p>
      <ion-skeleton-text
        :animated="true"
        style="width: 40%"
      ></ion-skeleton-text>
    </p>
  </ion-card>
</template>
<script>
import { IonCard, IonSkeletonText } from "@ionic/vue";
export default {
  name: "InfoCardSkeleton",
  components: {
    IonCard,
    IonSkeletonText,
  },
};
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";

.card {
  max-width: 100%;
  padding: 20px;
  border-radius: 16px;
  margin: 0 0 16px 0;
}

@include breakpoint(medium) {
  .card {
    margin-right: 12px;
    max-width: 312px;
  }
}
</style>
