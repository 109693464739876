<template>
  <div v-if="showLoadingSkeleton">
    <!-- TODO: Display loading skeleton for all buttons sizes -->
    <ion-skeleton-text
      :animated="true"
      style="width: 312px; height: 44px"
    ></ion-skeleton-text>
  </div>
  <ion-button
    v-else
    :size="size"
    :class="[typeClass, disabledClass, isLoadingClass, buttonCustomClass]"
    :disabled="disabled"
    :id="`button-${uid}`"
    @click="emits('click')"
    data-test="button"
    class="ion-text-wrap app-button"
  >
    <ion-icon
      v-if="icon"
      :icon="icon"
      :slot="iconPosition"
      :class="iconPosition"
      aria-hidden="true"
      data-test="icon"
    />
    <div aria-hidden="true" class="label-content">
      <span class="label" :id="`button-label-${uid}`" data-test="label">
        {{ label }}
      </span>

      <ion-spinner
        v-if="isLoading"
        name="lines-small"
        aria-hidden="true"
        class="loading-icon"
        :color="spinnerColour"
        :class="[spinnerPositionClass]"
        data-test="loading-icon"
      ></ion-spinner>
    </div>
  </ion-button>
</template>
<script setup lang="ts">
import {
  computed,
  defineEmits,
  defineProps,
  withDefaults,
  ref,
  onMounted,
  getCurrentInstance,
  watch,
  nextTick,
} from "vue";
import { IonButton, IonIcon, IonSpinner } from "@ionic/vue";

export type Button = "primary" | "secondary" | "tertiary" | "textOnly";
type Size = "default" | "small" | "large";
type IconPostion = "start" | "end";

interface Props {
  size: Size;
  type: Button;
  width?: string;
  label: string;
  ariaLabel?: string;
  icon?: string;
  iconPosition?: IconPostion;
  disabled?: boolean;
  isLoading?: boolean;
  ariaRoleDescription?: string;
  showLoadingSkeleton?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  size: "default",
  type: "primary",
  disabled: false,
  isLoading: false,
  ariaRoleDescription: "",
});

const uid = ref<number | null | undefined>(null);

onMounted(async () => {
  uid.value = getCurrentInstance()?.uid;
  setTimeout(() => {
    setNativeButtonAttribute("aria-label", props.ariaLabel || "");
  }, 1500);
});

watch(
  () => props.ariaRoleDescription,
  (newVal) => {
    setNativeButtonAttribute("aria-description", newVal || "");
  }
);

watch(
  () => props.ariaLabel,
  async (newVal) => {
    setNativeButtonAttribute("aria-label", newVal || "");
  }
);

watch(
  () => props.showLoadingSkeleton,
  async (isShow) => {
    if (!isShow) {
      await nextTick(() => {
        setTimeout(() => {
          setNativeButtonAttribute("aria-label", props.ariaLabel || "");
        }, 1500);
      });
    }
  }
);

/**
 * Sets the attribute of the native button inside the ion-button element.
 * This is done mainly for a11y purposes.
 * Otherwise, an update to an ARIA attribute would be done to the ion-button element,
 * but not to the native button element.
 * @param attr The attribute name
 * @param value The value of the attribute
 */
const setNativeButtonAttribute = (attr: string, value: string): void => {
  const btnInnerEl = document
    .getElementById(`button-${uid.value}`)
    ?.shadowRoot?.querySelector("button");
  if (btnInnerEl) {
    btnInnerEl.setAttribute(attr, value);
  }
};

const emits = defineEmits(["click"]);

const cssWidth = computed(() => props.width);

const disabledClass = computed(() => (props.disabled ? "disabled" : undefined));
const isLoadingClass = computed(() =>
  props.isLoading ? "is-loading" : undefined
);
const buttonCustomClass = computed(() =>
  props.width ? "button-custom" : undefined
);
const typeClass = computed(() => props.type);

const spinnerColour = computed(() => {
  switch (props.type) {
    case "primary":
      return undefined;
    case "secondary":
      return "primary";
    case "tertiary":
      return "tertiary";
    default:
      return undefined;
  }
});

const spinnerPositionClass = computed(() => {
  if (!props.icon && !props.iconPosition) {
    return undefined;
  }
  switch (props.iconPosition) {
    case "start":
      return "shift-left";
    case "end":
      return "shift-right";
    default:
      return undefined;
  }
});
</script>

<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";

ion-button.app-button {
  --border-radius: 12px;
  margin: var(--spacing-0);
  &.button-small {
    --border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
  }
  &.button-small.ion-focused {
    outline: 2px solid var(--ion-color-secondary);
  }

  --background-focused: var(--ion-color-primary-shade);
  --background-activated: var(--ion-color-primary-shade);

  &::part(native) {
    padding: var(--spacing-12) var(--spacing-6);
  }

  &.button-custom {
    width: v-bind(cssWidth);
  }

  &.secondary {
    --color: var(--ion-color-primary);
    --border-style: solid;
    --border-width: 1px;
    --border-color: var(--ion-color-primary);
    --background: var(--background-white);
    --background-hover: var(--ion-color-secondary-hover);
    --background-focused: var(--ion-color-secondary-hover);
    --background-activated: var(--background-white);
  }

  &.tertiary {
    color: var(--ion-color-secondary);
    --border-style: solid;
    --border-width: 1px;
    --border-color: var(--ion-color-secondary);
    --background: var(--background-white);
    --background-hover: var(--ion-color-tertiary-hover);
    --background-focused: var(--ion-color-tertiary-hover);
    --background-activated: var(--background-white);
  }

  &.textOnly {
    border: none;
    text-decoration: underline;
    font-weight: 400;
    letter-spacing: 0.5px;
    line-height: 24px;
    --color: var(--text-tertiary);
    --background-hover: transparent;
    --background: transparent;
    --background-activated: transparent;
    --background-focused: transparent;
    &:hover {
      --box-shadow: none;
    }
    &:focus {
      --border-width: 2px;
      --border-color: var(--ion-color-secondary);
    }
  }

  &.disabled {
    --color: var(--dark-grey);
    --border-style: solid;
    --border-width: 1px;
    --border-color: var(--light-grey-2);
    &::part(native) {
      background: var(--background-white);
    }
  }

  &.is-loading {
    .label {
      visibility: hidden;
    }
    .loading-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: opacity 0.3s ease;
    }
  }
  .label-content {
    position: relative;
    padding: var(--spacing-0) var(--spacing-16) var(--spacing-0)
      var(--spacing-16);
  }
  ion-icon.start {
    padding-left: 16px;
    & + .label-content {
      padding-left: var(--spacing-0);
      .loading-icon {
        left: calc(50% - 8px);
      }
    }
  }
  ion-icon.end {
    padding-right: 16px;
    & + .label-content {
      padding-right: var(--spacing-0);
      .loading-icon {
        left: calc(50% + 8px);
      }
    }
  }
}
</style>
