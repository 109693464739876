<template>
  <ion-skeleton-text
    data-test="description-skeleton"
    class="description-md"
    :animated="true"
  >
  </ion-skeleton-text>
</template>
<script>
import { IonSkeletonText } from "@ionic/vue";
export default {
  name: "DescriptionSkeleton",
  components: {
    IonSkeletonText,
  },
};
</script>

<style lang="scss" scoped>
.description-md {
  margin-bottom: 24px;
  height: 28px;
  max-width: 700px;
}
</style>
