import { Qualification, Licence } from "@/store/models/resume.models";
import {
  format,
  differenceInMinutes,
  differenceInHours,
  differenceInCalendarDays,
  differenceInWeeks,
  differenceInMonths,
  differenceInYears,
} from "date-fns";

export const defaultDateFormat = "dd/MM/yyyy";

/**
 * Converts strings to date
 * @param {*} date, string containg date value
 * @returns Date object or 'invalid date'
 */
export const convertDateStringToDate = (date: string) => {
  return new Date(date);
};

/**
 * Formats date strings
 * @param {*} date, string containg date value
 * @param {*} toFormat, string containg format for returned date
 * @returns formatted date string
 */
export const formatDateString = (date: string, toFormat?: string): string => {
  return toFormat
    ? format(convertDateStringToDate(date), toFormat)
    : format(convertDateStringToDate(date), defaultDateFormat);
};

/**
 * Returns difference between passed date and now.
 * @param {*} date, string containg date value
 * @param {*} i18n , i18n package
 * @returns string containing difference between date and current date. The string contains the i18n key
 */
export const timeDiffFromNow = (date: string, i18n: any): string => {
  const then = date.endsWith("Z") ? date : date.replace(" ", "T") + "Z";

  const diffInMinutes = differenceInMinutes(new Date().toISOString(), then, {
    roundingMethod: "floor",
  });

  if (diffInMinutes <= 0) {
    return i18n("assessment.lessThan1MinuteAgo");
  } else if (diffInMinutes > 0 && diffInMinutes < 60) {
    return diffInMinutes === 1
      ? `${diffInMinutes} ${i18n("assessment.minuteAgo")}`
      : `${diffInMinutes} ${i18n("assessment.minutesAgo")}`;
  } else {
    const diffInHours = differenceInHours(new Date().toISOString(), then, {
      roundingMethod: "floor",
    });
    const diffInDays = differenceInCalendarDays(new Date().toISOString(), then);

    if (diffInHours < 24 && diffInDays == 0) {
      return diffInHours === 1
        ? `${diffInHours} ${i18n("assessment.hourAgo")}`
        : `${diffInHours} ${i18n("assessment.hoursAgo")}`;
    } else {
      if (diffInDays < 7) {
        return diffInDays === 1
          ? `${diffInDays} ${i18n("assessment.dayAgo")}`
          : `${diffInDays} ${i18n("assessment.daysAgo")}`;
      } else if (diffInDays < 28) {
        const diffInWeeks = differenceInWeeks(new Date(), then);
        return diffInWeeks === 1
          ? `${diffInWeeks} ${i18n("assessment.weekAgo")}`
          : `${diffInWeeks} ${i18n("assessment.weeksAgo")}`;
      } else {
        const diffInMonths = differenceInMonths(new Date(), then);
        if (diffInMonths < 12) {
          return diffInMonths === 1
            ? `${diffInMonths} ${i18n("assessment.monthAgo")}`
            : `${diffInMonths} ${i18n("assessment.monthsAgo")}`;
        } else {
          const diffInYears = differenceInYears(new Date(), then);
          return diffInYears === 1
            ? `${diffInYears} ${i18n("assessment.yearAgo")}`
            : `${diffInYears} ${i18n("assessment.yearsAgo")}`;
        }
      }
    }
  }
};

/**
 * Parses the input date from string to an actual date to enable date comparison
 * @param dateString the input date with format YYYY or MM/YYYY
 */
export const parseDate = (dateString: string): Date | null => {
  if (!dateString) {
    return null;
  }
  const parts = dateString.split("/");
  let year: number;
  let month: number = 0;

  if (parts.length === 2) {
    // The date format is MM/YYYY
    month = parseInt(parts[0], 10) - 1;
    year = parseInt(parts[1], 10);
  } else if (parts.length === 1) {
    // The date format is YYYY
    year = parseInt(parts[0], 10);
  } else {
    console.error("Invalid date format");
    return null;
  }

  return new Date(year, month);
};

/**
 * Converts the input date string to a month and year format.
 *
 * @param {string} dateString - The input date string to be converted
 * @param {*} i18n , i18n package
 * @return {string | undefined} The formatted month and year string or undefined if the input is invalid
 */
export const convertToMonthYear = (
  dateString: string,
  i18n: Function
): string | undefined => {
  if (!dateString) return;

  const months = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];
  if (dateString.includes("/")) {
    const [month, year] = dateString?.split("/");
    const monthName = months[parseInt(month) - 1];
    const monthI18n = i18n(`month.${monthName}`);
    return `${monthI18n} ${year}`;
  }
  return dateString;
};

/**
 * Sorts an array of objects by a specified date property in chronological order.
 *
 * @param {arrayToSort[]} array - The array of objects to be sorted.
 * @param {string} property - The name of the date property to sort by.
 * @return {arrayToSort[] | undefined} - The sorted array of objects, or undefined if the input array is empty.
 */
export const sortByDate = <T>(
  array: T[],
  property: keyof T
): T[] | undefined => {
  if (!array || array.length === 0) return;

  return array.sort((a, b) => {
    const dateA = parseDate(a[property as keyof T] as unknown as string); // casting to unknown is necessary because TS cannot automatically infer that the property accessed via property as keyof T is a string.
    const dateB = parseDate(b[property as keyof T] as unknown as string);

    if (!dateA) return 1;
    if (!dateB) return -1;

    return dateB.getTime() - dateA.getTime();
  });
};

/**
 * Sorts an array of objects by a specified ISO date property in chronological order.
 *
 * @param {arrayToSort[]} array - The array of objects to be sorted.
 * @param {string} property - The name of the date property to sort by.
 * @return {arrayToSort[] | undefined} - The sorted array of objects, or undefined if the input array is empty.
 */
export const sortISODate = <T>(
  array: T[],
  property: keyof T
): T[] | undefined => {
  if (!array || array.length === 0) return;

  return array.sort((a, b) => {
    const dateA = new Date(a[property as keyof T] as unknown as string); // casting to unknown is necessary because TS cannot automatically infer that the property accessed via property as keyof T is a string.
    const dateB = new Date(b[property as keyof T] as unknown as string);

    if (!dateA) return 1;
    if (!dateB) return -1;

    return dateB.getTime() - dateA.getTime();
  });
};

/**
 * Validates the format of a date string.
 * The date string can be in the format "MM/YYYY" or "YYYY".
 *
 * @param {string} dateString - The date string to validate.
 * @return {boolean} True if the date string has a valid format, false otherwise.
 * @example
 * const isValidDateFormat = validateDateFormat("01/2023");
 * console.log(isValidDateFormat); // Output: true
 */
export const validateDateFormat = (dateString: string): boolean => {
  const regex = /^(0[1-9]|1[0-2])\/\d{4}$|^\d{4}$/;
  return regex.test(dateString);
};
