import { ImageUrl } from "./models/imageUrl";

export function parseImageUrl(
  imageUrl?: ImageUrl,
  height?: number,
  width?: number
) {
  if (!imageUrl || imageUrl == null || !height || !width) return null;
  const url = new URL(imageUrl.asset.url);
  url.searchParams.set("h", height.toString());
  url.searchParams.set("w", width.toString());
  return url.toString();
}
