<template>
  <ResumeBase
    :breadcrumbs="[
      {
        route: 'contact-details',
        label: t('resume.breadcrumbs.contactDetails'),
      },
    ]"
    mainContentId="resume-contact-details-main-content"
    :formSaveRetryFunction="saveForm"
    :skipOnRouteBeforeLeave="skipOnRouteBeforeLeave"
  >
    <template v-slot:title>
      {{ t("resume.contactDetailsForm.title") }}
    </template>
    <template v-slot:description>
      {{ t("resume.contactDetailsForm.description") }}
    </template>
    <template v-slot:additional-note>
      {{ t("resume.contactDetailsForm.additionalNote") }}
    </template>
    <template v-slot:main-content>
      <form :aria-label="t('resume.contactDetailsForm.title')">
        <TextInput
          data-test="name-input"
          :labelText="t('resume.contactDetailsForm.nameInputLabel')"
          :value="`${firstName} ${lastName}`"
          :isLoading="resumeStore.isResumeLoading"
          :readonly="true"
        />
        <TextInput
          data-test="email-input"
          :labelText="t('resume.contactDetailsForm.emailInputLabel')"
          :value="`${email}`"
          :isLoading="resumeStore.isResumeLoading"
          :readonly="true"
        />
        <TextInput
          data-test="phone-input"
          :labelText="t('resume.contactDetailsForm.phoneInputLabel')"
          :value="`${phone}`"
          :isLoading="resumeStore.isResumeLoading"
          :readonly="true"
        />
        <TextInput
          data-test="location-input"
          class="location-input"
          :labelText="t('resume.contactDetailsForm.locationInputLabel')"
          :value="location || ''"
          :placeholderText="
            t('resume.contactDetailsForm.locationInputPlaceholder')
          "
          :isLoading="resumeStore.isResumeLoading"
          :readonly="true"
        />
        <CheckboxInput
          data-test="hide-location-input"
          :checked="hideLocation || false"
          :disabled="!location"
          property="hideLocation"
          :labelText="t('resume.contactDetailsForm.hideLocationInputLabel')"
          :isLoading="resumeStore.isResumeLoading"
          @onChange="(value) => onInputChange('hideLocation', value)"
        />
        <TextInput
          data-test="linkedin-input"
          property="linkedinId"
          :labelText="t('resume.contactDetailsForm.linkedinInputLabel')"
          inputType="url"
          :placeholderText="
            t('resume.contactDetailsForm.linkedinInputPlaceholder')
          "
          :value="linkedinId || ''"
          :errorText="errorMessageMap.get('linkedinId')"
          :isLoading="resumeStore.isResumeLoading"
          :errorIdPrefix="errorIdPrefix"
          @onUpdate="updateErrorDescription"
          @onChange="(event) => onInputChange('linkedinId', event.detail.value)"
          @onBlur="linkedinIdValidation"
        />
        <div class="save-btn-container">
          <AppButton
            size="default"
            type="primary"
            :label="t('resume.contactDetailsForm.saveButtonText')"
            :ariaLabel="t('resume.contactDetailsForm.saveButtonText')"
            @click="saveForm"
            data-test="save-form-btn"
            class="save-form-btn"
            width="100%"
            :isLoading="isFormSaving"
            :disabled="!isFormSavingEnabled"
            :ariaRoleDescription="saveButtonDescription"
          />
          <AppButton
            size="default"
            type="textOnly"
            :label="t('resume.contactDetailsForm.closeButtonText')"
            :ariaLabel="t('resume.contactDetailsForm.closeButtonText')"
            data-test="close-form-btn"
            class="close-form-btn"
            id="closeBtn"
            @click="returnToPage"
            width="100%"
          />
        </div>
      </form>
    </template>
    <template v-slot:resume-helper> </template>
  </ResumeBase>
</template>
<script setup lang="ts">
import ResumeBase from "@/Layouts/ResumeBase/ResumeBase.vue";
import TextInput from "@/components/Common/Inputs/TextInput/TextInput.vue";
import CheckboxInput from "@/components/Common/Inputs/CheckboxInput/CheckboxInput.vue";
import AppButton from "@/components/Common/AppButton/AppButton.vue";
import { useResumeStore, useErrorStore } from "@/store";
import { ContactDetails } from "@/store/models/resume.models";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, onBeforeRouteLeave, useRoute } from "vue-router";
import { onIonViewDidEnter } from "@ionic/vue";
import { handleBeforeUnload, focusOnMainContent } from "@/helpers";
const { t } = useI18n<{}>({
  useScope: "global",
});
import { IonInputCustomEvent } from "@ionic/core/components";
import { collateErrorsInDescription } from "@/helpers";

const resumeStore = useResumeStore();
const errorStore = useErrorStore();
const router = useRouter();
const route = useRoute();
const skipOnRouteBeforeLeave = ref(false);

onBeforeRouteLeave(() => {
  resumeStore.setContactDetailsFormInit(false);
  window.removeEventListener("beforeunload", handleBeforeUnload);
});

onMounted(async () => {
  if (!resumeStore.resumeId) {
    await resumeStore.requestResume();
    if (!resumeStore.resumeId) {
      await resumeStore.createResume();
    }
  }
  resumeStore.initContactDetailsForm();
});

onIonViewDidEnter(() => {
  focusOnMainContent(route?.meta?.mainContentId);
  skipOnRouteBeforeLeave.value = false;
  window.addEventListener("beforeunload", handleBeforeUnload);
});

const firstName = computed(() => {
  return resumeStore.resume?.participant?.givenName;
});

const lastName = computed(() => {
  return resumeStore.resume?.participant?.familyName;
});

const email = computed(() => {
  return resumeStore.resume?.participant?.email;
});

const phone = computed(() => {
  return resumeStore.resume?.participant?.mobilePhone;
});

const location = computed(() => {
  return resumeStore.resume?.participant?.location;
});

const hideLocation = computed(() => {
  return resumeStore.contactDetailsFormData?.hideLocation;
});

const linkedinId = computed(() => {
  return resumeStore.contactDetailsFormData?.linkedinId;
});

const isFormSaving = ref(false);

const onInputChange = <K extends keyof ContactDetails>(
  property: K,
  value: ContactDetails[K] | null | undefined
) => {
  if (value == null || !property) return;
  resumeStore.setContactDetailsFormValue(property, value);
};

const saveForm = async () => {
  skipOnRouteBeforeLeave.value = true;
  errorStore.setSaveFormError(false);
  const contactDetailsId = resumeStore.resumeContactDetails?.id;
  try {
    isFormSaving.value = true;
    if (contactDetailsId) {
      await resumeStore.updateContactDetails(contactDetailsId);
    } else {
      await resumeStore.createContactDetails();
    }
    await resumeStore.updateResumeProperty("isContactDetailsCompleted", true);
    router.push({
      name: "ResumeOverview",
    });
  } catch (error) {
    console.error("An error occured saving the form: ", error);
    errorStore.setSaveFormError(true);
  } finally {
    isFormSaving.value = false;
  }
};

const errorIdPrefix = ref("error-message-disabler");
const saveButtonDescription = ref("");
const updateErrorDescription = () => {
  saveButtonDescription.value = collateErrorsInDescription(
    document,
    errorIdPrefix.value
  );
};

const returnToPage = () => {
  router.push({
    name: "ResumeOverview",
  });
};

/* VALIDATION */

const rules = {
  linkedinId: {
    invalid: t("resume.contactDetailsForm.linkedinInputError"),
  },
};

const validationMap = ref<Map<keyof ContactDetails, boolean>>(
  new Map([["linkedinId", true]])
);

const errorMessageMap = ref<Map<keyof ContactDetails, string>>(
  new Map([["linkedinId", ""]])
);

const isFormSavingEnabled = computed(() => {
  for (let isValid of validationMap.value.values()) {
    if (!isValid) return false;
  }
  return true;
});

const setValidity = (
  property: keyof ContactDetails,
  valid: boolean,
  error?: string
): void => {
  validationMap.value.set(property, valid);
  errorMessageMap.value.set(property, error ?? "");
  return;
};

const linkedinIdValidation = (event: IonInputCustomEvent<FocusEvent>) => {
  const itemEl = event.target.parentElement;
  itemEl?.classList.remove("ion-invalid");
  const regex = /^(https?:\/\/)?(www\.)?linkedin\.com\/.*$/;
  const value = event.target.value as string;
  if (value && !regex.test(value)) {
    itemEl?.classList.add("ion-invalid");
    setValidity("linkedinId", false, rules.linkedinId.invalid);
  } else {
    setValidity("linkedinId", true);
  }
};
</script>

<style lang="scss" scoped>
.save-form-btn {
  margin-top: var(--spacing-16);
}

.text-input,
.checkbox-input {
  margin-bottom: var(--spacing-24);
}

.checkbox-input {
  margin-left: var(--spacing-4);
}

.text-input.location-input {
  margin-bottom: var(--spacing-4);
}

.save-btn-container {
  max-width: 312px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
  margin-top: var(--spacing-48);
}
</style>
