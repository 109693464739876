import http from "../http-common";
import { Course } from "@/store";

export const RetrieveCourseDetail = async (
  sanityDocumentId: string
): Promise<Course> => {
  return http
    .get(`course/${sanityDocumentId}`)
    .then((response) => ({
      _id: response.data.sanityData[0]._id,
      base_id: response.data.sanityData[0].base_id,
      description: response.data.sanityData[0].description,
      feature_image: response.data.sanityData[0].feature_image,
      name: response.data.sanityData[0].name,
      title: response.data.sanityData[0].title,
      _type: response.data.sanityData[0]._type,
      topics: response.data.sanityData[0].topics,
      parent: response.data.sanityData[0].parent,
      completedResourceCount: response.data.completedResourceCount,
      totalResourceCount: response.data.totalResourceCount,
    }))
    .catch((e) => {
      throw e;
    });
};
