<template>
  <ion-card>
    <IonCardHeader>
      <ion-badge
        v-if="assessment.isCompleted"
        class="status-badge complete-label"
        data-test="completion-badge"
      >
        <ion-icon :icon="checkmarkCircleOutline" aria-hidden="true"></ion-icon>
        <span>
          <p class="title">{{ $t("statusLabel.completed") }}</p>
        </span>
      </ion-badge>
      <h3
        v-if="assessment.title"
        class="title inner-title truncate"
        data-test="title"
      >
        {{ assessment.title }}
      </h3>
      <p
        v-if="assessment.shortDescription"
        class="short-description truncate"
        data-test="short-description"
      >
        {{ assessment.shortDescription }}
      </p>
      <div class="badge-wrapper">
        <ion-badge color="secondary" data-test="assessment-badge">
          {{ assessment.badge }}
        </ion-badge>
        <ion-badge
          v-if="assessment.time"
          class="time-badge"
          data-test="time-badge"
        >
          {{ assessment.time + " " + $t("assessment.mins") }}</ion-badge
        >
      </div>
    </IonCardHeader>
    <IonCardContent>
      <img :src="getThumbnail" alt="" data-test="thumbnail" />
    </IonCardContent>
  </ion-card>
</template>
<script setup lang="ts">
import { defineProps, computed } from "vue";
import {
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonBadge,
  IonIcon,
} from "@ionic/vue";
import { checkmarkCircleOutline } from "ionicons/icons";
import { SanityAssessment } from "@/store";

interface Props {
  assessment: SanityAssessment;
  thumbnail?: string;
  placeholderImg?: string;
}
const props = defineProps<Props>();

const getThumbnail = computed(() => {
  return props.thumbnail || props.placeholderImg;
});
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";
ion-card-header {
  padding: 10px;
  padding-bottom: 8px;
  text-align: left;
  width: 100%;
}
ion-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0px;
  height: inherit;
  min-width: 252px;
  background-color: var(--background-white);
  box-shadow: var(--shadow-1);
  border-radius: 8px;
  height: 262px;
  max-width: 252px;
  margin-bottom: 16px;
}
ion-card-content {
  padding: 0;
  height: 100%;
  width: 100%;
}
.title {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  letter-spacing: 0.012em;
  color: var(--text-black);
}
.collection-card .title {
  margin-top: 0px;
}
.inner-title {
  font-size: 18px;
  margin: 8px 0 6px;
  line-height: 24px;
}
.short-description {
  margin-top: 0;
  font-weight: 400;
  line-height: 20px;
  color: var(--dark-grey);
  padding-left: 0;
  margin-left: 0;
  margin-bottom: 8px;
  font-size: 14px;
}

img {
  width: inherit;
  height: 100%;
  object-fit: cover;
}

.truncate {
  /* Truncate text to 2 lines */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.time-badge {
  background: var(--light-grey);
  color: black;
}
</style>
<!-- This is to override the stylings from the SanityBlocks component -->
<style lang="scss">
.short-description p {
  margin-top: 6px;
}
</style>
