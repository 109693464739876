<template>
  <ion-page>
    <MobileHeader></MobileHeader>
    <ion-content data-test="profile-page">
      <DesktopLocaleSelector></DesktopLocaleSelector>
      <div class="page-wrapper" id="profile-info-main-content" tabindex="0">
        <div class="title-wrapper">
          <ion-icon :icon="peopleOutline" aria-hidden="true"></ion-icon>
          <p class="page-title">{{ $t("pageTitles.info") }}</p>
        </div>
        <InfoCardSkeleton
          v-if="participantStore.getLoadingForParticipant"
          data-test="profile-skeleton-card"
        />
        <InfoCard
          v-if="
            !participantStore.getLoadingForParticipant &&
            !isEmpty(participantProfile)
          "
          title="info.profileTitle"
          :profileInfo="participantProfile"
        />
        <InfoCardSkeleton
          v-if="participantStore.getLoadingForParticipant"
          data-test="profile-skeleton-card"
        />
        <InfoCard
          v-if="
            !participantStore.getLoadingForParticipant && !isEmpty(siteProfile)
          "
          title="info.siteTitle"
          :profileInfo="siteProfile"
        />
        <div class="card-holder" data-test="card-holder">
          <InfoCardSkeleton
            v-if="participantStore.getLoadingForParticipant"
            data-test="profile-skeleton-card"
          />
          <InfoCard
            :key="consultant.id"
            title="info.consultantTitle"
            :profileInfo="consultant"
            v-for="consultant in participantStore.participant.consultants"
          />
        </div>

        <!-- Mobile view -->
        <InfoCardSkeleton
          v-if="participantStore.getLoadingForParticipant"
          data-test="profile-skeleton-card"
          class="swiper-cards"
        />
        <swiper
          data-test="swiper-cards"
          :breakpoints="swiperBreakpoints"
          :centeredSlides="true"
          :space-between="10"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
          class="swiper-cards default-slider"
          v-else
        >
          <swiper-slide
            v-for="consultant in participantStore.participant.consultants"
            :key="consultant.id"
          >
            <InfoCard
              :key="consultant.id"
              title="Consultant Details"
              :profileInfo="consultant"
            />
          </swiper-slide>
        </swiper>
        <!-- <HelpCard /> -->
        <div class="logout-button-wrapper">
          <ion-button
            size="default"
            color="secondary"
            fill="outline"
            id="open-modal"
          >
            {{ $t("signout.label") }}
          </ion-button>
        </div>
        <div class="logout-button-wrapper">
          <ion-button
            size="default"
            color="secondary"
            fill="outline"
            id="open-modal-mobile"
          >
            {{ $t("signout.label") }}
          </ion-button>
        </div>
        <ion-modal
          ref="modal"
          trigger="open-modal"
          @didPresent="setChatIframeVisibility('none')"
          @didDismiss="setChatIframeVisibility('block')"
          role="dialog"
        >
          <ion-content
            class="ion-padding"
            data-test="modal-contents"
            scrollY="false"
          >
            <div class="hero-character-cropped"></div>
            <div class="text-wrapper">
              <ion-title id="signoutTitle" size="small">{{
                $t("signout.prompt")
              }}</ion-title>
              <p>{{ $t("signout.confirm") }}</p>
            </div>
            <ion-button size="default" color="primary" @click="cancel">
              {{ $t("signout.no") }}
            </ion-button>
            <ion-button
              data-test="yes-sign-out"
              fill="outline"
              color="secondary"
              size="default"
              @click="signOut"
            >
              {{ $t("signout.yes") }}
            </ion-button>
          </ion-content>
        </ion-modal>

        <ion-modal
          ref="modalMobile"
          trigger="open-modal-mobile"
          @didPresent="setChatIframeVisibility('none')"
          @didDismiss="setChatIframeVisibility('block')"
          :initial-breakpoint="0.75"
          :breakpoints="[0, 0.75, 1]"
          role="dialog"
        >
          <ion-content class="ion-padding" data-test="modal-contents">
            <div class="hero-character-cropped"></div>
            <div class="text-wrapper">
              <ion-title id="signoutTitleMobile" size="small">{{
                $t("signout.prompt")
              }}</ion-title>
              <p>{{ $t("signout.confirm") }}</p>
            </div>
            <ion-button @click="cancel"> {{ $t("signout.no") }} </ion-button>
            <ion-button
              data-test="yes-sign-out"
              fill="outline"
              color="secondary"
              size="default"
              @click="signOut"
            >
              {{ $t("signout.yes") }}
            </ion-button>
          </ion-content>
        </ion-modal>
      </div>
    </ion-content>
    <div class="footer-links">
      <hr />
      <Footer :isLoggedIn="true"></Footer>
    </div>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonIcon,
  IonButton,
  IonModal,
  IonTitle,
} from "@ionic/vue";
import InfoCard from "@/components/InfoCard/InfoCard.vue";
import MobileHeader from "@/components/Common/MobileHeader/MobileHeader.vue";
import Footer from "@/components/Common/GlobalFooter/GlobalFooter.vue";
import InfoCardSkeleton from "@/components/Skeletons/InfoCardSkeleton/InfoCardSkeleton.vue";
import { peopleOutline } from "ionicons/icons";
// Import Swiper styles
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/vue";
import { useParticipantStore, useMsalStore } from "@/store";
import { setChatIframeVisibility } from "@/helpers";
import DesktopLocaleSelector from "@/components/Common/LocaleSelector/DesktopLocaleSelector.vue";

export default {
  name: "profile-info",
  components: {
    IonPage,
    InfoCard,
    IonContent,
    IonIcon,
    DesktopLocaleSelector,
    MobileHeader,
    Swiper,
    SwiperSlide,
    IonButton,
    IonModal,
    IonTitle,
    InfoCardSkeleton,
    Footer,
  },
  data() {
    return {
      peopleOutline,
      swiperBreakpoints: {
        // when window width is >= 768px
        768: {
          slidesPerView: 1.06,
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 1.08,
        },
        // when window width is >= 520px
        520: {
          slidesPerView: 1.09,
        },
        // when window width is >= 460px
        460: {
          slidesPerView: 1.11,
        },
        // when window width is >= 380px
        380: {
          slidesPerView: 1.13,
        },
        // when window width is >= 320px
        320: {
          slidesPerView: 1.15,
        },
        // when window width is >= 280px
        280: {
          slidesPerView: 1.19,
        },
      },
    };
  },
  setup() {
    const participantStore = useParticipantStore();
    const msalStore = useMsalStore();
    return { participantStore, msalStore };
  },
  computed: {
    participantProfile() {
      var visibleName = this.participantStore.participant?.givenName;
      if (this.participantStore.participant?.familyName) {
        visibleName += " " + this.participantStore.participant?.familyName;
      }
      return {
        name: visibleName,
        mobile: this.participantStore.participant?.mobilePhone,
        email: this.participantStore.participant?.email,
      };
    },
    siteProfile() {
      return {
        name: this.participantStore.participant?.site?.name,
        mobile: this.participantStore.participant?.site?.contactNumber,
      };
    },
  },
  mounted() {
    this.participantStore.participantRequest();
  },
  methods: {
    signOut() {
      this.msalStore.logoutRedirect();
      this.cancel();
    },
    isEmpty(object) {
      return Object.values(object).every(
        (x) => x === null || x === "" || x === undefined
      );
    },
    cancel() {
      this.$refs.modal.$el.dismiss(null, "cancel");
      this.$refs.modalMobile.$el.dismiss(null, "cancel");
    },
    setChatIframeVisibility,
  },
};
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";
.page-wrapper {
  box-shadow: var(--shadow-0);
  border-radius: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  padding-right: 12px;
}

ion-modal > ion-content > ion-button {
  width: 140px;
  margin: 8px;
}
.title-wrapper {
  display: flex;
  margin-bottom: 16px;
  vertical-align: middle;
  ion-icon {
    font-size: 20px;
  }
}

.text-wrapper {
  padding: 24px;
}

ion-title {
  font-size: 22px;
}
.page-title {
  font-family: "SF Pro Display", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: var(--ion-color-secondary);
  text-align: left;
  margin-top: 0;
  line-height: 150%;
}
ion-icon {
  margin-right: 8px;
  font-size: 18px;
}
.card-holder {
  display: none;
}

.swiper {
  margin-right: -24px;
  margin-left: -24px;
}
#open-modal-mobile {
  margin: 16px;
  justify-content: center;
}
#open-modal {
  margin: 16px 0px;
  float: unset;
  display: none;
}
.footer-links {
  margin-bottom: 56px;
}

// Override footer component
#footer-wrapper {
  margin-top: 24px;
}

hr {
  border: none;
  background: var(--light-grey);
  width: 100%;
  margin: 0;
}

@include breakpoint(medium) {
  .page-wrapper {
    padding-left: 50px;
    padding-right: 50px;
  }
  #open-modal {
    display: block;
    float: left;
  }
  #open-modal-mobile {
    display: none;
  }

  .swiper-cards {
    display: none;
  }
  .card-holder {
    display: flex;

    flex-wrap: wrap;
  }
}
</style>

<style scoped>
/*Second tag because this svg import failes with Sass during storybook.*/
.hero-character-cropped {
  background: url("@/assets/em-platformGuide.svg");
  height: 250px;
  width: 226px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: relative;
  margin: auto;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 87%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 75%, 0% 86%);
  top: 25px;
}
</style>
