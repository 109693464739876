<template>
  <SanityBlocks :blocks="this.store.getDocument(this._ref)?.text" />
</template>

<script>
import { useSanityStore } from "@/store";

import { SanityBlocks } from "sanity-blocks-vue-component";
export default {
  props: ["_ref"],
  data: () => {
    return {
      video: "",
    };
  },
  setup() {
    const store = useSanityStore();
    return { store };
  },
  mounted() {
    this.store.requestDocument(this._ref);
  },
  components: {
    SanityBlocks,
  },
};
</script>
<style lang="scss">
@import "@/common/theme/breakpoints.scss";
</style>
