<template>
  <ion-card>
    <img :src="featureImage" alt="" />

    <IonCardHeader>
      <ion-badge
        v-if="completed"
        class="status-badge complete-label"
        data-test="completion-badge"
      >
        <ion-icon :icon="checkmarkCircleOutline" aria-hidden="true"></ion-icon>
        <span>
          <p class="title">{{ $t("statusLabel.completed") }}</p>
        </span>
      </ion-badge>
      <p class="title truncate">{{ document.title }}</p>
      <p class="description truncate">{{ description }}</p>
      <div class="badge-wrapper">
        <ion-badge
          class="type-badge"
          v-if="mapResourceType(document.resource_type)"
          color="secondary"
          data-test="badge"
          >{{ $t(mapResourceType(document.resource_type)) }}</ion-badge
        >
        <ion-badge
          class="time-badge"
          v-if="document.time"
          color="secondary"
          data-test="badge"
        >
          {{ document.time + " " + $t("resource.readingTimeMins") }}
        </ion-badge>
      </div>
    </IonCardHeader>
  </ion-card>
</template>
<script setup lang="ts">
import { computed, defineProps } from "vue";
import { IonCard, IonCardHeader, IonIcon, IonBadge } from "@ionic/vue";
import { checkmarkCircleOutline } from "ionicons/icons";
import { parseImageUrl, mapResourceType } from "@/helpers";
import { Resource } from "@/store";

interface Props {
  document: Resource;
  completed: boolean;
}
const props = defineProps<Props>();

const featureImage = computed(() => {
  return props.document.feature_image
    ? (parseImageUrl(props.document.feature_image, 600, 600) as string)
    : "https://cdn.sanity.io/images/2i80ck01/~production/8135cdce59aec36c855d65c473f944f115bca081-4032x3024.jpg?auto=format&fit=max&w=500";
});

const description = computed(() => {
  return props.document.description
    ? props.document.description
    : "Description here";
});
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";
ion-card-header {
  padding: 10px;
  text-align: left;
}
ion-icon {
  vertical-align: middle;
  font-size: 14px;
  margin-right: 2px;
}
.badge-wrapper {
  left: 10px;
  bottom: 10px;
  position: absolute;
}
.type-badge {
  border-radius: 9.5px;
  background: var(--ion-color-secondary);
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  bottom: 10px;
  padding: 1px 7px 1px 7px;
}
.time-badge {
  border-radius: 9.5px;
  background: var(--light-grey);
  color: black;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  bottom: 10px;
  margin-left: 4px;
  padding: 1px 7px 1px 7px;
}

.title {
  padding-left: 0;
  margin-left: 0;
  margin-top: 0;
  font-size: 18px;
  margin-bottom: 8px;
  letter-spacing: 0.216px;
}
.description {
  padding-left: 0;
  margin-left: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
}
p {
  padding-left: 10px;
}
ion-card {
  display: flex;
  height: 176px;
  min-height: 176px;
  width: 312px;
  max-width: 312px;
  min-width: 312px;
  margin-left: 0;
  margin-bottom: 16px;
  margin-right: 0px;
  box-shadow: var(--shadow-1);
  border-radius: 16px;
  background: var(--background-white);
  transition: box-shadow var(--transition-time);
  &:hover {
    box-shadow: var(--shadow-2);
  }
  &:active {
    box-shadow: var(--shadow-1);
  }
}
img {
  width: 109px;
  object-fit: cover;
}

.truncate {
  /* Truncate text to 2 lines */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Desktop layout */
@include breakpoint(medium) {
  ion-card {
    margin: 0;
  }
}
</style>
