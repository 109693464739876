<template>
  <ion-card data-test="category-skeleton">
    <IonCardHeader>
      <h5>
        <ion-skeleton-text
          :animated="true"
          style="width: 100px; height: 15px"
        ></ion-skeleton-text>
        <ion-skeleton-text
          :animated="true"
          style="width: 80px; height: 15px"
        ></ion-skeleton-text>
      </h5>
    </IonCardHeader>
    <IonCardContent>
      <div class="badge-wrapper" data-test="badge-skeleton">
        <ion-skeleton-text
          :animated="true"
          style="width: 40%"
        ></ion-skeleton-text>

        <ion-skeleton-text
          :animated="true"
          style="width: 40%"
        ></ion-skeleton-text>
      </div>
    </IonCardContent>
  </ion-card>
</template>
<script>
import {
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonSkeletonText,
} from "@ionic/vue";
export default {
  name: "CategoryCardSkeleton",
  components: {
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonSkeletonText,
  },
};
</script>
<style lang="scss" scoped>
ion-card-header {
  padding: 0px;
  text-align: left;
  width: 100%;
}
ion-card-content {
  padding: 0;
  width: 100%;
}
ion-card {
  height: 144px;
  border-radius: 16px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  margin-left: 0;
  width: 190px;
}
</style>
