<template>
  <ion-app
    :class="[localeStore.getCurrentLocaleTheme, { isKeyboardOpen }]"
    v-if="isMsalReady"
  >
    <ion-router-outlet />
    <TermsAndConditionsModal
      v-show="this.showTCModal"
      :isOpen="this.showTCModal"
      :title="termsDoc?.title"
      :intro="termsDoc?.introText"
      @onAcceptTC="acceptTermsAndConditions"
      @onSignOut="signOut"
      class="terms-modal"
    />
    <ErrorModal
      ref="errorModal"
      v-show="hasErrored"
      :hasErrored="hasErrored"
      @onDismiss="dismiss"
      @onPrimaryButtonClick="errorModalReload"
      @onSecondaryButtonClick="errorModalReturn"
      data-test="error-modal-generic"
      class="error-modal"
    ></ErrorModal>
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet, useKeyboard } from "@ionic/vue";
import { defineComponent } from "vue";
import TermsAndConditionsModal from "@/components/Common/TermsAndConditionsModal/TermsAndConditionsModal.vue";
import ErrorModal from "@/components/Common/ErrorModal/ErrorModal.vue";

import {
  useParticipantStore,
  useSanityStore,
  useErrorStore,
  useLocaleStore,
  useMsalStore,
} from "@/store";

import { useFavicon } from "@vueuse/core";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    TermsAndConditionsModal,
    ErrorModal,
  },
  setup() {
    const participantStore = useParticipantStore();
    const sanityStore = useSanityStore();
    const errorStore = useErrorStore();
    const localeStore = useLocaleStore();
    const msalStore = useMsalStore();
    const { isOpen, keyboardHeight } = useKeyboard();

    return {
      participantStore,
      sanityStore,
      errorStore,
      localeStore,
      msalStore,
      isKeyboardOpen: isOpen,
      keyboardHeight,
    };
  },
  data() {
    return {
      requestModalOpen: false,
    };
  },
  async mounted() {
    this.localeStore.initialize();

    // Check terms acceptance while not at "/", authFinalize or onboarding
    const isAuthFinalize = window.location.pathname.startsWith("/authfinalize");
    const isOnboarding = window.location.pathname.startsWith("/onboarding");
    const isBasePath = window.location.pathname === "/";
    const isTermsAndConditions = window.location.pathname.startsWith(
      "/terms-and-conditions"
    );

    // TODO: Refactor into an event handler or Pinia with a state subscription
    const shouldGetTermsAndConditions =
      this.msalStore.isReady &&
      !isAuthFinalize &&
      !isBasePath &&
      !isTermsAndConditions &&
      !isOnboarding;

    if (shouldGetTermsAndConditions) {
      if (await this.participantStore.getTermsAndConditionsRequired()) {
        try {
          await this.sanityStore.requestTermsAndConditionsContent();
          this.requestModalOpen = true;
        } catch (err) {
          console.error("App: Unable to request terms and conditions");
        }
      }
    }
  },
  watch: {
    locale: {
      handler() {
        const themeName = this.localeStore.getCurrentLocaleTheme;
        useFavicon(`${themeName}.ico`, {
          baseUrl: "/",
          rel: "icon",
        });
      },
      immediate: true,
    },
  },
  methods: {
    acceptTermsAndConditions() {
      this.requestModalOpen = false;
      this.participantStore.acceptTermsAndConditions();
    },
    signOut() {
      this.requestModalOpen = false;
      this.msalStore.logoutRedirect();
    },
    dismiss() {
      this.errorStore.setGenericError(false);
    },
    errorModalReload() {
      this.$router.go();
    },
    errorModalReturn() {
      this.$router.go(-1);
      this.$refs.errorModal.$el.dismiss(null, "cancel");
    },
  },
  computed: {
    isMsalReady() {
      return this.msalStore.isReady;
    },
    locale() {
      return this.localeStore.currentLocale;
    },
    termsDoc() {
      return this.sanityStore.getTermsAndConditionsContent;
    },
    hasErrored() {
      return (
        this.errorStore.hasGenericError && !this.errorStore.hasNonGenericError
      );
    },
    showTCModal() {
      return (
        Object.keys(this.termsDoc).length !== 0 &&
        !this.hasErrored &&
        this.requestModalOpen
      );
    },
    keyboardHeightPx() {
      return `${this.keyboardHeight}px`;
    },
  },
});
</script>

<style lang="scss">
#app {
  height: 100vh;
  width: 100vw;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.isKeyboardOpen .page-wrapper {
  padding-bottom: v-bind(keyboardHeightPx);
}

.error-modal {
  --background: var(--background-white);
  z-index: var(--z-index-error-modal) !important;
}

.terms-modal {
  z-index: var(--z-index-modal) !important;
}
</style>
