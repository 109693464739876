export interface Resource {
  _id: string;
  base_id: string;
  name: string;
  title: string;
  description: any;
  resource_type: ResourceType;
  time: number;
  feature_image: any;
  body: any;
  completed: boolean;
  parent: any;
  resourceSiblings: any;
}

export enum ResourceType {
  Article,
  Audio,
  Checklist,
  Game,
  Interactive,
  Quiz,
  Video,
}
