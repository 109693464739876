<template>
  <div class="page-banner-wrapper">
    <ion-skeleton-text
      v-if="isImageLoading"
      class="page-banner-image"
      :animated="true"
    ></ion-skeleton-text>
    <img
      v-else
      class="page-banner-image"
      :src="getImageSrc()"
      alt=""
      data-test="banner-img"
    />
  </div>
</template>

<script>
import { IonSkeletonText } from "@ionic/vue";
export default {
  data() {
    return {};
  },
  components: {
    IonSkeletonText,
  },
  props: {
    imagePath: { type: String },
    isImageLoading: { type: Boolean },
  },
  methods: {
    getImageSrc() {
      return this.imagePath;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";
.page-banner-wrapper {
  height: 223px; // linked to min-height in .page-wrapper (see layout.scss)
  width: 100%;
}
.page-banner-image {
  left: 0%;
  height: auto;
  width: 100%;
  position: absolute;
  object-fit: cover;
  @include breakpoint(large) {
    height: 100%;
  }
}

ion-skeleton-text {
  margin: 0;
}
</style>
