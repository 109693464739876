/**
 * Handles VoiceOver screen reader focus not working correctly on main content during page transition.
 * Voice-over does not detect the correct contents of the page and keeps focusing on the recently clicked element from the previous page.
 * This is a band-aid fix to unblock the user when they navigate between pages using screen reader.
 * This issue is fixed in Ionic v8 and the helper function can be safely deleted in the future.
 *
 * @param {string | undefined} mainContentId - The ID of the main content element.
 * @return {void} No return value.
 */
export const focusOnMainContent = (mainContentId: string | undefined): void => {
  mainContentId ? document.getElementById(mainContentId)?.focus() : null;
};
