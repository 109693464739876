<template>
  <ion-page class="signin-homepage">
    <div v-if="showLogo === true">
      <div class="outer-trapezoid">
        <div class="inner-trapezoid"></div>
      </div>
      <div class="logo-box">
        <ion-label class="watermark">
          {{ $t("loadingScreen.poweredBy") }}
        </ion-label>
        <div class="apm-logo"></div>
      </div>
    </div>
  </ion-page>
</template>

<script>
import { toastController, createAnimation } from "@ionic/vue";
import { warningOutline, checkmarkOutline } from "ionicons/icons";
import {
  useAuthStore,
  useFeatureFlagStore,
  useMsalStore,
  useOnboardingSlidesStore,
} from "@/store";

import { IonLabel } from "@ionic/vue";
import EnvironmentConfig from "@/EnvironmentConfig";
import http from "@/http-common";
export default {
  name: "AuthFinalize",
  components: {
    IonLabel,
  },
  setup() {
    const authStore = useAuthStore();
    const msalStore = useMsalStore();
    const featureFlagStore = useFeatureFlagStore();
    const onboardingSlidesStore = useOnboardingSlidesStore();
    return { authStore, msalStore, featureFlagStore, onboardingSlidesStore };
  },
  mounted() {
    let delayToastId = "";
    setTimeout(() => {
      clearTimeout(delayToastId);
      this.presentToast();
    }, this.animationDuration);
  },
  data() {
    return {
      toastDuration:
        EnvironmentConfig.FeatureToggles.BrandShowLogo === true ? 3500 : 0,
      animationDuration: 500,
      showLogo: EnvironmentConfig.FeatureToggles.BrandShowLogo,
    };
  },
  methods: {
    async presentToast() {
      const hasAccessDeniedHash = window.location.hash.startsWith(
        "#error=access_denied"
      );

      // TODO: Refactor into a navigation guard
      if (hasAccessDeniedHash || !this.authStore.userInfo) {
        if (!this.authStore.userInfo) {
          console.error("AuthFinalize: Unexpected userInfo null");
        }
        if (hasAccessDeniedHash) {
          console.error("AuthFinalize: Unexpected access denied hash");
        }
        this.msalStore.logoutRedirect();
        return;
      }

      const hasErrorHash = window.location.hash.startsWith("#error");
      const toastMessage = hasErrorHash
        ? this.$t("loadingScreen.toastError")
        : this.$t("loadingScreen.toastSuccess");
      const toastCss = hasErrorHash ? "error-toast" : "success-toast";
      const toastIcon = hasErrorHash ? warningOutline : checkmarkOutline;
      const redirectionDelay = this.toastDuration + this.animationDuration;

      const toast = await toastController.create({
        message: toastMessage,
        duration: this.toastDuration,
        position: "bottom",
        cssClass: toastCss,
        icon: toastIcon,
      });

      const animation = createAnimation()
        .addElement(document.querySelector(".signin-homepage"))
        .duration(this.animationDuration)
        .delay(this.toastDuration)
        .fromTo("opacity", "1", "0");

      animation.play();
      const timeoutId = setTimeout(async () => {
        clearTimeout(timeoutId);

        http.post(`/reporting/login`).catch((e) => {
          console.error(e);
        });
        await this.redirectTo();
      }, redirectionDelay);
      await toast.present();
    },
    async redirectTo() {
      // Hard redirect to the page so that T&C's inside APP.vue mount is triggered
      // TODO: Refactor into a router.replace and refactor App.vue mount T&C into an emit event or Pinia
      let route = window.sessionStorage.returnUrl ?? "/tabs/home";

      if (
        this.featureFlagStore.isFlagEnabled("release-page-onboarding", true)
      ) {
        await this.onboardingSlidesStore.getOnboardingSlides();
        if (this.onboardingSlidesStore.getIncompleteSlides?.length > 0) {
          route = "/onboarding";
        }
      }

      window.location.href = EnvironmentConfig.UiBaseUrl + route;
    },
  },
};
</script>

<style>
ion-toast {
  --border-radius: 13px;
  font-weight: 700;
  position: fixed;
  top: -50px;
}

ion-toast.success-toast {
  --background: var(--success);
  --color: var(--button-text-primary);
  --width: 194px;
}

ion-toast.error-toast {
  --background: var(--error);
  --color: var(--lighter-grey);
  --width: 300px;
}
</style>

<style scoped>
ion-item {
  --background: transparent;
}
ion-input {
  --background: var(--background-white);
}

.signin-homepage {
  background: var(--background-white);
  height: 100%;
}
ion-list {
  background-color: var(--background-white);
  padding: 2px; /* prevents borders from clipping */
}

.inner-trapezoid {
  clip-path: polygon(36% 0, 100% 0, 100% 100%, 10% 100%);
  background: linear-gradient(
    184.3deg,
    var(--ion-color-secondary-tint) 2.46%,
    var(--ion-color-secondary-shade) 96.11%
  );
  height: 100vh;
  display: none;
}
.outer-trapezoid {
  clip-path: polygon(34% 0, 100% 0, 100% 100%, 0% 100%);
  background: var(--ion-color-secondary-shade);
  height: 100vh;
  display: none;
}
.logo-box {
  width: 100vw;
  height: 100vh;
  background: var(--background-white);
  position: absolute;
  top: 0%;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.apm-logo {
  background: url("../../assets/logos/apm.svg");
  height: 70px;
  background-repeat: round;
  width: 112px;
  display: flex;
  margin: 0 auto;
}

ion-button {
  margin: auto;
  width: 252px;
  height: 44px;
}

#warning {
  vertical-align: middle;
}

span {
  text-align: left;
  width: 100%;
  font-weight: 700;
}
ion-label.watermark {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.015em;
  color: var(--label-text);
}
/* Galaxy fold device */
@media screen and (min-width: 280px) and (max-width: 370px) {
  .welcome {
    width: 130px;
    left: 135px;
  }
  .outer-trapezoid {
    width: 0%;
    display: none;
  }
  .inner-trapezoid {
    display: none;
  }
}
/* Tablets  */
@media screen and (min-width: 680px) and (max-width: 768px) {
  .welcome {
    position: relative;
    top: 10%;
  }
  .outer-trapezoid {
    width: 0%;
    display: none;
  }
  .inner-trapezoid {
    display: none;
  }
}
/* Desktop Only */
@media screen and (min-width: 769px) {
  .logo-box {
    width: 470px;
    height: 550px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 16px;
  }
  .region-logo {
    display: flex;
    margin: 149.5px auto 24px auto;
  }
  .signin-homepage {
    display: flex;
    flex-direction: row;
  }
  .outer-trapezoid {
    width: 100%;

    display: block;
  }
  .inner-trapezoid {
    display: block;
  }
}
</style>
