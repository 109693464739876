/**
 * Maps all the error messages in the form that have the provided id prefix and collate them into a description.
 * This is needed to provide a description to the disabled save button.
 * @param doc the HTML document
 * @param idSelector the id prefix to identify all error messages in the page
 * @returns a description with all collated error messages in the page
 */
export const collateErrorsInDescription = (
  doc: Document,
  idSelector: string
): string => {
  let description = "";
  const errorEls = doc.querySelectorAll(`[id^='${idSelector}']`);
  errorEls.forEach((el) => {
    if (description) description += ", ";
    description += el.textContent;
  });
  return description;
};
