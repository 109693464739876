/**
 * Calculates the total score for each criterion
 * @param {*} assessmentItems - The assessmentItems with the fulfilled responses
 * @returns an object where the keys are the criteria and the values the sum of its corresponding scores
 */
export function calculateTotalScore(
  assessmentItems: { score: number; criterion: string }[]
): any {
  const result = new Map();

  assessmentItems.forEach((item: { score: number; criterion: string }) => {
    if (!result.has(item.criterion)) {
      result.set(item.criterion, item.score);
    } else {
      result.set(item.criterion, result.get(item.criterion) + item.score);
    }
  });

  return Object.fromEntries(result);
}
