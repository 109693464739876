<template>
  <!-- loading state - text -->
  <div class="title-wrapper">
    <!-- TODO: Use TitleSkeleton Component -->
    <ion-skeleton-text
      v-if="!isCoursesInProgressLoaded"
      class="page-title title-md"
      style="height: 28px; width: 150px"
      :animated="true"
    >
    </ion-skeleton-text>
    <h5
      v-if="coursesInProgressExist"
      class="page-title title-md"
      data-test="in-progress-title"
    >
      {{ $t("dashboard.courseInProgressTitle") }}
    </h5>
  </div>
  <ion-skeleton-text
    v-if="!isCoursesInProgressLoaded"
    class="in-progress-description truncate"
    style="height: 19px; width: 300px"
    :animated="true"
  ></ion-skeleton-text>
  <!-- loaded state - text -->
  <p
    v-if="coursesInProgressExist"
    data-test="in-progress-description"
    class="in-progress-description truncate"
  >
    {{
      $t("dashboard.courseInProgressDescription", coursesInProgress.length, {
        count: coursesInProgress.length,
      })
    }}
  </p>
  <!-- loading state - swiper -->
  <swiper
    v-if="!isCoursesInProgressLoaded"
    width="295"
    :spaceBetween="16"
    :initialSlide="0"
    :slidesOffsetAfter="48"
    :slidesOffsetBefore="48"
  >
    <swiper-slide v-for="index in 4" :key="index">
      <CarouselCardSkeleton
        class="in-progress-card"
        data-test="in-progress-card-skeleton"
      ></CarouselCardSkeleton>
    </swiper-slide>
  </swiper>
  <!-- loaded state - swiper -->
  <swiper
    v-if="coursesInProgressExist"
    @swiper="onSwiper"
    :initialSlide="0"
    slidesPerView="auto"
    :spaceBetween="16"
    :slidesOffsetAfter="48"
    :slidesOffsetBefore="48"
    :navigation="true"
    :modules="modules"
    :watchSlidesProgress="true"
  >
    <swiper-slide
      v-for="(item, index) in coursesInProgress"
      data-test="swiper-cards-in-progress"
      :key="item.base_id"
      tabindex="-1"
    >
      <router-link
        :to="{ name: 'CourseDetail', params: { id: item.base_id } }"
        class="card-wrapper"
        @focus="onSwiperCardFocus(index, swiperfocus.value)"
      >
        <ion-card
          button="true"
          class="in-progress-card"
          data-test="in-progress-card"
          tabindex="-1"
        >
          <h6 class="card-header truncate">
            {{ item.title }}
          </h6>
          <ion-card-content data-test="swiper-card-progress-message"
            ><ion-icon
              :icon="progressEllipseIcon"
              aria-hidden="true"
            ></ion-icon>
            {{
              $t("dashboard.courseProgressText", {
                completedResource: item.completedResourceCount,
                totalResource: item.totalResourceCount,
              })
            }}
          </ion-card-content>
          <ion-button
            data-test="in-progress-button"
            size="default"
            disabled="true"
            tabindex="-1"
            >{{
              $t("dashboard.continueCourseInProgressButtonText")
            }}</ion-button
          >
        </ion-card>
      </router-link>
    </swiper-slide>
  </swiper>
</template>

<script setup>
import "swiper/css";
import "swiper/css/navigation";
import {
  IonCard,
  IonSkeletonText,
  IonIcon,
  IonButton,
  IonCardContent,
} from "@ionic/vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation } from "swiper";
import { useCourseStore, useLocaleStore } from "@/store";
import { RouterLink } from "vue-router";
import CarouselCardSkeleton from "../Skeletons/CarouselCardSkeleton/CarouselCardSkeleton.vue";
import { onSwiperCardFocus } from "@/helpers";
import { computed, ref } from "vue";

// Enables scrolling using Mousewheel on desktop.
SwiperCore.use(Navigation);
const courseStore = useCourseStore();
const localeStore = useLocaleStore();

const swiperfocus = ref(null);

const themeName = computed(() => {
  return localeStore.getCurrentLocaleTheme.toLowerCase();
});

const progressEllipseIcon = computed(() => {
  return require(`@/assets/progressEllipse/progress-ellipse-${themeName.value}.svg`);
});

const isCoursesInProgressLoaded = computed(() => {
  return courseStore.isCoursesInProgressLoaded;
});

const coursesInProgressExist = computed(() => {
  return courseStore.getCoursesInProgress?.length > 0;
});

const coursesInProgress = computed(() => {
  return courseStore.getCoursesInProgress;
});

const onSwiper = (swiper) => {
  swiper.value = swiper;
};
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";
@import "@/common/theme/swiper.scss";
.swiper {
  margin-right: -23px;
  margin-left: -48px;
}
.title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
}
.card-wrapper {
  display: block;
  border-radius: 8px;
  width: 100%;
  max-width: 295px;
  text-decoration: none;
  transition: box-shadow var(--transition-time);
  width: inherit;
}
.in-progress-card::part(native) {
  cursor: unset;
}
.ion-activated {
  transform: scale3d(1, 1, 1);
}

.in-progress-description {
  margin-top: 0;
  padding-left: 0;
  margin-left: 0;
  margin-bottom: 8px;
}
.in-progress-card {
  width: 100%;
  max-width: 295px;
  height: 127px;
  display: inline-block;
  padding: 10px;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 26px;
  box-shadow: var(--shadow-1);
  border-radius: 16px;
  background: var(--background-white);

  ion-card-content,
  .card-header {
    letter-spacing: 0.25px;
    text-align: left;
    margin: 0px 0px 0px 6px;
  }

  ion-card-content {
    text-align: left;
    margin: 9.5px auto auto 16px;
    padding: 0;
  }

  ion-button {
    margin: 9.5px 0 0 0;
    width: 275px;
    cursor: pointer;
    &:hover,
    &[hover] {
      // Dont apply the default hover on this button.
      --box-shadow: var(--shadow-0);
      --background-hover: var(--ion-colour-primary);
    }
    &[disabled] {
      // Dont apply the default disabled on this button.
      opacity: 1;
    }
  }
  h6 {
    color: var(--button-text-primary);

    ion-icon {
      font-size: 24px;
      margin-top: -2px;
      height: 40px;
      display: block;
    }

    .card-header {
      display: inline-block;
      padding-left: 9px;
    }
  }
  b {
    font-weight: 500;
  }
}

.truncate {
  /* Truncate text to 2 lines */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@include breakpoint(medium) {
  .swiper :deep(.swiper-button-prev),
  .swiper :deep(.swiper-button-next) {
    height: 129px;
    top: calc(100% - 135px);
  }

  // handles focus on safari
  *:focus {
    outline-width: thin;
    outline-color: var(--ion-color-secondary);
    overflow: hidden;
  }
  .title-md {
    margin-bottom: 14px;
  }
  .in-progress-card {
    transition: box-shadow var(--transition-time);

    &:hover {
      box-shadow: var(--shadow-2);
    }

    &:focus-visible {
      background-color: var(--ion-color-secondary-tint);
      outline: 2px solid var(--ion-color-secondary);
      box-shadow: inset 0px 0px 0px 1px var(--background-white);
    }
  }
  .card-wrapper {
    &:focus-visible,
    &:focus {
      outline: none;
      box-shadow: var(--shadow-0);
    }
    &:focus-visible > ion-card,
    &:focus > ion-card {
      box-shadow: none !important;
    }
    &:focus-visible::before,
    &:focus::before {
      content: "";
      position: absolute;
      top: 2px;
      left: -2px;
      right: -2px;
      bottom: 24px;
      border: 2px solid var(--ion-color-secondary);
      z-index: 1;
      border-radius: 16px;
    }
  }
}
.loading-holder > ion-card {
  width: 302px;
  height: 248px;
}
</style>
