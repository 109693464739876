import { defineStore } from "pinia";
// Import data service
import http from "../http-common";
import { useAuthStore } from "@/store";

export const useParticipantStore = defineStore({
  id: "participant",
  state: () => ({
    participant: {}, // Blank default state
    loading: false,
  }),
  actions: {
    participantRequest() {
      this.loading = true;
      const authStore = useAuthStore();
      if (authStore.getCurrentUserId) {
        http
          .get(`/participant/view-my-details`)
          .then((response) => {
            this.participant = response.data.data;
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        console.error("ERROR: no current participant found");
        this.loading = false;
      }
    },
    async getTermsAndConditionsRequired() {
      let required = true;
      try {
        const response = await http.get(`/participant/terms-and-conditions`);
        required = response.data.termsAcceptanceRequired;
        window.localStorage.setItem(
          "termsAcceptanceRequired",
          JSON.stringify(required)
        );
      } catch (e) {
        console.warn("participantStore: Unable to fetch latest terms required");
        const requiredStr = window.localStorage.getItem(
          "termsAcceptanceRequired"
        );
        if (requiredStr) required = JSON.parse(requiredStr);
      }
      return required;
    },
    async acceptTermsAndConditions() {
      try {
        const response = await http.post(`/participant/terms-and-conditions`);
        if (response.status == 200)
          window.localStorage.setItem(
            "termsAcceptanceRequired",
            JSON.stringify(false)
          );
      } catch (e) {
        console.error("participantStore: Unable to accept terms");
      }
    },
  },
  getters: {
    getLoadingForParticipant(state) {
      return state.loading;
    },
  },
});
