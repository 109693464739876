import http from "../http-common";
import { Resource } from "@/store";

export const RetrieveResourceDetail = async (
  sanityDocumentId: string
): Promise<Resource> => {
  return http
    .get(`resource/${sanityDocumentId}`)
    .then((response) => ({
      completed: response.data.completed,
      _id: response.data.sanityData?.[0]._id,
      base_id: response.data.sanityData?.[0].base_id,
      name: response.data.sanityData?.[0].name,
      title: response.data.sanityData?.[0].title,
      description: response.data.sanityData?.[0].description,
      resource_type: response.data.sanityData?.[0].resource_type,
      time: response.data.sanityData?.[0].time,
      feature_image: response.data.sanityData?.[0].feature_image,
      body: response.data.sanityData?.[0].body,
      parent: response.data.sanityData?.[0].parent,
      resourceSiblings: response.data.sanityData?.[0].resourceSiblings,
    }))
    .catch((e) => {
      throw e;
    });
};
