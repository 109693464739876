<template>
  <div class="title-wrapper">
    <h4 class="page-title title-md">
      {{ title }}
    </h4>
    <div v-if="destination !== null">
      <router-link :to="{ name: destination }">
        <p class="see-all-link">
          {{ $t("learnHome.seeAllContentLink") }}
        </p>
      </router-link>
    </div>
  </div>
</template>

<script>
import { RouterLink } from "vue-router";

export default {
  name: "cardsTitle",
  components: {
    RouterLink,
  },
  props: {
    title: { type: String },
    destination: { type: String, default: null },
  },
};
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";

.title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@include breakpoint(medium) {
  .title-md {
    margin-bottom: 14px;
  }
}
</style>
