<template>
  <ResumeBase
    :breadcrumbs="[
      {
        label: $t('resume.breadcrumbs.qualifications'),
      },
    ]"
    mainContentId="resume-qualifications-main-content"
    :skipOnRouteBeforeLeave="true"
  >
    <template v-slot:title>
      {{ $t("resume.qualificationsOverview.title") }}
    </template>
    <template v-slot:description>{{
      $t("resume.qualificationsOverview.description")
    }}</template>
    <template v-slot:main-content>
      <div class="qualifications-container" v-if="resumeStore.isResumeLoading">
        <div class="qualification-item" v-for="item in 2" :key="item">
          <PreviewCard :isLoading="true"></PreviewCard>
        </div>
      </div>
      <div class="qualifications-container" v-else>
        <div
          class="qualification-item"
          v-for="qual in sortQualifications(
            resumeStore?.resume?.qualifications
          )"
          :key="qual.id"
        >
          <PreviewCard
            :id="qual.id"
            :title="qual.name"
            :isLoading="resumeStore.isResumeLoading"
            data-test="preview-card"
            :button-text="$t('resume.qualificationsOverview.editButton')"
            @click="editQualification(qual.id)"
          >
            <template v-slot:metadata>
              <p>{{ qual.institution }}</p>
              <p v-if="qual.endDate">
                {{ convertToMonthYear(qual.endDate, $t) }}
              </p>
              <p v-if="!qual.isQualificationComplete">
                {{ $t("resume.qualificationsOverview.inProgressLabel") }}
              </p>
            </template>
            <template v-slot:description>
              <p>{{ qual.highlights }}</p>
            </template>
          </PreviewCard>
        </div>
      </div>
      <div class="buttons-container">
        <AppButton
          size="default"
          type="primary"
          :label="$t('resume.qualificationsOverview.addQualificationBtnLabel')"
          :ariaLabel="
            $t('resume.qualificationsOverview.addQualificationBtnLabel')
          "
          data-test="add-qualification-btn"
          @click="addQualification"
          class="add-qualification-btn"
          width="100%"
          :showLoadingSkeleton="resumeStore.isResumeLoading"
        />
        <AppButton
          size="default"
          type="secondary"
          :label="$t('resume.qualificationsOverview.returnBtnLabel')"
          :ariaLabel="$t('resume.qualificationsOverview.returnBtnLabel')"
          data-test="return-btn"
          @click="$router.push({ name: 'ResumeOverview' })"
          class="return-btn"
          width="100%"
          :showLoadingSkeleton="resumeStore.isResumeLoading"
        />
      </div>
    </template>
    <template v-slot:resume-helper> </template>
  </ResumeBase>
</template>
<script setup lang="ts">
import ResumeBase from "@/Layouts/ResumeBase/ResumeBase.vue";
import AppButton from "@/components/Common/AppButton/AppButton.vue";
import PreviewCard from "@/components/Resume/PreviewCard/PreviewCard.vue";
import { useResumeStore } from "@/store";
import { useRouter, useRoute } from "vue-router";
import { onMounted } from "vue";
import {
  convertToMonthYear,
  sortQualifications,
  focusOnMainContent,
} from "@/helpers";
import { onIonViewDidEnter } from "@ionic/vue";

const resumeStore = useResumeStore();
const router = useRouter();
const route = useRoute();

onMounted(async () => {
  if (!resumeStore.resumeId) {
    await resumeStore.requestResume();
    if (!resumeStore.resumeId) {
      await resumeStore.createResume();
    }
  }
});
onIonViewDidEnter(() => {
  focusOnMainContent(route?.meta?.mainContentId);
});

function addQualification() {
  resumeStore.initQualificationForm();
  router.push({
    name: "ResumeQualificationDetail",
    params: { id: "new" },
  });
}

function editQualification(id: string) {
  resumeStore.initQualificationForm(id);
  router.push({
    name: "ResumeQualificationDetail",
    params: { id: id },
  });
}
</script>
<style scoped lang="scss">
.qualifications-container {
  margin-bottom: var(--spacing-32);
  .qualification-item {
    margin-bottom: var(--spacing-16);
  }
}
.buttons-container {
  max-width: 312px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);
}
</style>
