<template>
  <ion-page data-test="version-page">
    <MobileHeader></MobileHeader>
    <ion-content>
      <DesktopLocaleSelector></DesktopLocaleSelector>
      <ion-button @click="copy">
        <ion-icon
          :icon="copyOutline"
          color="secondary"
          class="copyIcon"
          aria-hidden="true"
        ></ion-icon>
        <ion-label>{{ version }}</ion-label>
      </ion-button>

      <ion-button @click="reload" color="secondary">
        <ion-icon
          :icon="refreshOutline"
          color="primary"
          aria-hidden="true"
        ></ion-icon>
      </ion-button>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonButton, IonLabel, IonIcon } from "@ionic/vue";
import { refreshOutline, copyOutline } from "ionicons/icons";
import DesktopLocaleSelector from "@/components/Common/LocaleSelector/DesktopLocaleSelector.vue";
import MobileHeader from "@/components/Common/MobileHeader/MobileHeader.vue";
import EnvironmentConfig from "@/EnvironmentConfig";

export default {
  name: "version-page",
  components: {
    IonPage,
    IonIcon,
    IonLabel,
    IonContent,
    IonButton,
    MobileHeader,
    DesktopLocaleSelector,
  },
  setup() {
    return { refreshOutline, copyOutline };
  },
  mounted() {
    console.log(EnvironmentConfig);
  },
  data() {
    return {
      version: EnvironmentConfig.Version,
    };
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(this.version);
    },
    reload() {
      window.location.reload();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";

ion-content {
  display: flex;
}
ion-button {
  align-items: center;
  font-size: 16px;
}
.copyIcon {
  margin-right: 4px;
}
</style>
