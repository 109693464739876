import { defineStore } from "pinia";
import { ref, computed } from "vue";
import http from "../http-common";
import { Course, Resource, useLocaleStore } from "@/store";
import { calculateTotalScore } from "@/helpers/assessmentResponse";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";
import {
  UserQuestionResponse,
  SanityAssessment,
  SanityAssessmentCriteria,
  SanityAssessmentItem,
  AssessmentRecommendation,
  RecommendationType,
  GetAssessmentRecommendationResponse,
  UserAssessmentResponse,
  UserCompletedAssessment,
  CreateAssessmentItemDto,
  UpdateAssessmentItemDto,
  CompleteAssessmentDto,
  GenerateAssessmentRecommendationsDto,
  UserIncompleteAssessment,
} from "./models/assessment.models";
import { RetrieveCourseDetail, RetrieveResourceDetail } from "@/helpers";

export const useAssessmentStore = defineStore("assessment", () => {
  // State
  const assessments = ref({
    loaded: false,
    documents: [] as Array<SanityAssessment>,
  });
  const assessmentDetail = ref({
    loaded: false,
    document: {} as SanityAssessment,
    started: false,
    completed: false,
    exiting: false,
    responses: [] as Array<UserQuestionResponse>,
  });
  const latestCompletedAssessment = ref({
    loaded: false,
    data: null as UserCompletedAssessment | null,
    criteria: [] as Array<SanityAssessmentCriteria>,
    completedDate: null,
  });

  const assessmentRecommendations = ref({
    loaded: false,
    recommendations: [] as Array<AssessmentRecommendation>,
  });

  const assessmentResponse = ref<
    UserCompletedAssessment | UserIncompleteAssessment | null
  >(null);
  const currentSavedResponses = ref<Array<number>>([]);
  const currentItem = ref<CreateAssessmentItemDto | null>(null);
  const incompleteAssessment = ref<UserIncompleteAssessment | null>(null);

  // Getters
  const isAssessmentsLoaded = computed(() => {
    return assessments.value.loaded;
  });
  const getAssessments = computed(() => {
    return assessments.value.documents;
  });
  const isAssessmentDetailLoaded = computed(() => {
    return assessmentDetail.value.loaded;
  });
  const getAssessmentDetail = computed(() => {
    return assessmentDetail.value.document;
  });
  const getAssessmentResponses = computed(() => {
    return assessmentDetail.value.responses;
  });
  const isAssessmentStarted = computed(() => {
    return assessmentDetail.value?.started;
  });
  const isAssessmentCompleted = computed(() => {
    return assessmentDetail.value?.completed;
  });
  const getAssessmentResults = computed(() => {
    return latestCompletedAssessment.value;
  });
  const isAssessmentResultsLoaded = computed(() => {
    return latestCompletedAssessment.value.loaded;
  });
  const getAssessmentRecommendations = computed(() => {
    return assessmentRecommendations.value.recommendations;
  });
  const isAssessmentRecommendationsLoaded = computed(() => {
    return assessmentRecommendations.value.loaded;
  });
  const getCurrentItem = computed(() => {
    return currentItem.value;
  });
  const getIncompleteAssessment = computed<UserIncompleteAssessment | null>(
    () => {
      return incompleteAssessment.value;
    }
  );
  const getCurrentAssessmentResponse = computed(() => {
    return assessmentResponse.value;
  });
  const getCurrentItemIndex = computed(() => {
    return currentItem.value?.index || 0;
  });
  const getCurrentSavedResponses = computed(() => {
    return currentSavedResponses.value;
  });

  // Actions
  const requestAssessments = async (): Promise<void> => {
    assessments.value.loaded = false;
    const path = `assessments?locale=${useLocaleStore().getCurrentLocaleCode}`;

    http
      .get(path)
      .then((response) => {
        assessments.value.documents = response.data.assessments.map(
          (assessment: any) => mapAssessmentResponse(assessment)
        );
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        assessments.value.loaded = true;
      });
  };

  const requestAssessmentDetail = async (
    assessmentId: string
  ): Promise<void> => {
    assessmentDetail.value.loaded = false;
    const path = `assessments/${assessmentId}?locale=${
      useLocaleStore().getCurrentLocaleCode
    }`;
    return http
      .get(path)
      .then((response) => {
        assessmentDetail.value.document = mapAssessmentResponse(
          response.data.assessment
        );
        initialiseAssessmentResults();
        initialiseAssessmentRecommendations();
      })
      .catch((e) => {
        throw e;
      })
      .finally(() => {
        assessmentDetail.value.loaded = true;
      });
  };

  const startAssessment = async (): Promise<void> => {
    const doc = assessmentDetail.value.document;
    if (
      incompleteAssessment.value !== null &&
      incompleteAssessment.value.items.length <= doc.items.length
    ) {
      assessmentDetail.value.started = true;
      assessmentDetail.value.completed = false;
      assessmentResponse.value = incompleteAssessment.value;
      incompleteAssessment.value.items.forEach(
        (item) => (currentSavedResponses.value[item.index] = Number(item.score))
      );
      setCurrentItem(incompleteAssessment.value.items.length);
    } else {
      const initialAssessmentResponse = await initiateAssessmentRequest(
        doc.baseId,
        doc.name,
        doc.languageCode
      );

      if (initialAssessmentResponse) {
        assessmentResponse.value = initialAssessmentResponse;
        assessmentResponse.value.items = [];
        currentSavedResponses.value = [];
        setCurrentItem(0);
      }
    }
  };

  const initiateAssessmentRequest = async (
    baseSanityId: string,
    sanityResourceName: string,
    languageCode: string
  ): Promise<UserCompletedAssessment | void> => {
    const startedDate = new Date().toISOString();
    const path = "assessment-response";
    const body = {
      baseSanityId,
      sanityResourceName,
      languageCode,
      startedDate,
    };
    try {
      const res = await http.post(path, body);
      assessmentDetail.value.started = true;
      assessmentDetail.value.completed = false;
      return res?.data as UserCompletedAssessment;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const setCurrentItem = (index: number) => {
    if (
      !assessmentResponse.value ||
      !assessmentDetail.value.document?.items ||
      isEmpty(assessmentDetail.value.document?.items)
    ) {
      currentItem.value = null;
      return;
    }
    const sanityItems = assessmentDetail.value.document.items;
    const isItemInDb = assessmentResponse.value.items.find(
      (item) => item.baseSanityId === sanityItems[index]._id
    );
    currentItem.value = {
      assessmentId: assessmentResponse.value.id,
      baseSanityId: sanityItems[index]._id,
      name: sanityItems[index].name,
      response: isItemInDb?.response || "",
      criterion: sanityItems[index].criteria.criterion_id,
      score: Number(isItemInDb?.score) || 0,
      index,
    };
  };

  const mapAssessmentResponse = (assessment: any): SanityAssessment => {
    return {
      title: assessment.title,
      name: assessment.name,
      id: assessment._id,
      baseId: assessment.base_id,
      type: assessment._type,
      badge: assessment.badge,
      shortDescription: assessment.short_description,
      time: assessment.time,
      contentTitle: assessment.content_title,
      contentDescription: assessment.content_description,
      description: assessment.description,
      assessmentInfo: assessment.assessment_info,
      featureImage: assessment.feature_image,
      items: assessment.items,
      resultsTitle: assessment.results_title,
      resultsUnderstanding: assessment.results_understanding,
      resultsInformation: assessment.results_information,
      retakeUnderstanding: assessment.retake_understanding,
      languageCode: assessment.__i18n_lang,
      isCompleted: assessment.isCompleted,
    };
  };

  const saveItemAndContinue = async () => {
    if (
      !assessmentResponse.value ||
      !currentItem.value ||
      !currentItem.value.response
    ) {
      console.error("Current Assessment Item is not loaded");
      return;
    }

    const currentItemValue = currentItem.value;
    const dbItems = assessmentResponse.value.items;
    let updatedAssessment;

    const existingDbItem = dbItems.find(
      (item) => item.baseSanityId === currentItemValue.baseSanityId
    );

    if (existingDbItem) {
      if (Number(existingDbItem.score) === currentItemValue.score) {
        /* No changes in response. Continue. */
        return;
      }
      /* The item already exists in the Database and needs to be updated */
      const updatedItemDto: UpdateAssessmentItemDto = {
        assessmentItemId: existingDbItem.id,
        response: currentItemValue.response,
        criterion: currentItemValue.criterion,
        score: currentItemValue.score,
      };
      updatedAssessment = await updateItem(updatedItemDto);
    } else {
      /* A new assessment record needs to be created */
      updatedAssessment = await createItem(currentItemValue);
    }

    if (updatedAssessment) {
      assessmentResponse.value = updatedAssessment;
    }
  };

  const createItem = async (
    item: CreateAssessmentItemDto
  ): Promise<UserCompletedAssessment | void> => {
    const path = "assessment-item";
    try {
      const res = await http.post(path, item);
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const updateItem = async (
    item: UpdateAssessmentItemDto
  ): Promise<UserCompletedAssessment | void> => {
    const path = "assessment-item";
    try {
      const res = await http.put(path, item);
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const saveUserQuestionResponse = (response: UserQuestionResponse): void => {
    if (!assessmentResponse.value || !currentItem.value) {
      console.error("Values not properly set");
      return;
    }

    currentItem.value.response = response.responseLabel;
    currentItem.value.score = response.score;
    currentSavedResponses.value[response.index] = Number(response.score);
  };

  const completeAssessment = async (): Promise<void> => {
    if (!assessmentResponse.value || !currentItem.value?.score) return;

    const completedDate = new Date().toISOString();
    const totalItems = [
      ...assessmentResponse.value.items.map((item) => {
        return { score: Number(item.score), criterion: item.criterion };
      }),
      {
        score: currentItem.value.score,
        criterion: currentItem.value.criterion,
      },
    ];
    const totalScore = calculateTotalScore(totalItems);
    const path = "complete-assessment";
    const body: CompleteAssessmentDto = {
      assessmentId: assessmentResponse.value.id,
      completedDate,
      totalScore,
      itemBaseSanityId: currentItem.value.baseSanityId,
      itemName: currentItem.value.name,
      itemResponse: currentItem.value.response,
      itemCriterion: currentItem.value.criterion,
      itemScore: currentItem.value.score.toString(),
      itemIndex: currentItem.value.index,
    };
    try {
      const res = await http.post(path, body);
      assessmentDetail.value.started = false;
      assessmentDetail.value.completed = true;
      assessmentResponse.value = res.data;
      incompleteAssessment.value = null;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const initialiseAssessment = (): void => {
    assessmentDetail.value = {
      loaded: false,
      document: {} as SanityAssessment,
      started: false,
      completed: false,
      exiting: false,
      responses: [],
    };
    initialiseAssessmentResults();
    initialiseAssessmentRecommendations();
    currentSavedResponses.value = [];
    setCurrentItem(0);
  };

  const initialiseAssessmentResults = (): void => {
    latestCompletedAssessment.value = {
      criteria: [],
      loaded: false,
      data: null,
      completedDate: null,
    };
  };

  const initialiseAssessmentRecommendations = (): void => {
    assessmentRecommendations.value = {
      loaded: false,
      recommendations: [],
    };
  };

  const requestAssessmentResults = async (
    assessmentBaseSanityId: string
  ): Promise<void> => {
    latestCompletedAssessment.value.loaded = false;
    const path = `assessment-response/${assessmentBaseSanityId}`;

    try {
      const res: { data: UserAssessmentResponse } = await http.get(path);
      incompleteAssessment.value = res.data.incompleteAssessment;
      if (res.data?.latestCompletedAssessment !== null) {
        const completedAssessment = res.data.latestCompletedAssessment;
        latestCompletedAssessment.value.data = completedAssessment;
        if (completedAssessment.totalScore) {
          mergeAssessmentCriteriaAndTotalScores(completedAssessment.totalScore);
        }
        if (!isEmpty(completedAssessment.recommendedContent)) {
          requestAssessmentRecommendations(
            completedAssessment.recommendedContent
          );
        } else {
          generateAssessmentRecommendations({
            assessmentId: completedAssessment.id,
            baseSanityId: completedAssessment.baseSanityId,
            totalScore: completedAssessment.totalScore,
          });
        }
      } else {
        assessmentRecommendations.value.loaded = true;
      }
    } catch (error) {
      console.error(error);
      assessmentRecommendations.value.loaded = true;
      throw error;
    } finally {
      latestCompletedAssessment.value.loaded = true;
    }
  };

  const generateAssessmentRecommendations = async (
    body: GenerateAssessmentRecommendationsDto
  ): Promise<void> => {
    const path = "assessment-response/recommendations";
    try {
      const res = await http.put(path, body);
      if (!isEmpty(res.data) && !isEmpty(res.data.recommendedContent)) {
        await requestAssessmentRecommendations(res.data.recommendedContent);
      }
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      assessmentRecommendations.value.loaded = true;
    }
  };

  const mergeAssessmentCriteriaAndTotalScores = (totalScores: any): void => {
    if (
      !assessmentDetail.value.document?.items ||
      !totalScores ||
      isEmpty(totalScores)
    ) {
      return;
    }

    const criterionIds = Object.keys(totalScores);

    const criterias: SanityAssessmentCriteria[] = criterionIds.map(
      (criterionId: string) =>
        assessmentDetail.value.document.items
          .map((item: SanityAssessmentItem) => ({
            title: item.criteria.title[0].value,
            criterion_id: item.criteria.criterion_id,
            name: item.criteria.name,
            description: item.criteria.description[0].value,
            score: item.criteria.score,
          }))
          .find(
            (criteria: any) => criteria.criterion_id === criterionId
          ) as SanityAssessmentCriteria
    );

    latestCompletedAssessment.value.criteria = orderBy(
      criterias
        .filter((criteria: SanityAssessmentCriteria) => criteria)
        .map((criteria) => ({
          ...criteria,
          score: totalScores[criteria.criterion_id],
        })),
      "title",
      "asc"
    );
  };

  const requestAssessmentRecommendations = async (
    recommendedContent: Array<GetAssessmentRecommendationResponse>
  ): Promise<void> => {
    assessmentRecommendations.value.loaded = false;

    try {
      const courseIds = recommendedContent
        .filter((recommendation) => recommendation.type === "course")
        .map((recommendation) => recommendation.id);

      const resourceIds = recommendedContent
        .filter((recommendation: any) => recommendation.type === "topic")
        .map((recommendation: any) => recommendation.id);

      const getCourseRecommendations = courseIds.map((id: string) =>
        RetrieveCourseDetail(id)
      );
      const getResourceRecommendations = resourceIds.map((id: string) =>
        RetrieveResourceDetail(id)
      );

      const getAllRecommendations = await Promise.all([
        ...getCourseRecommendations,
        ...getResourceRecommendations,
      ]);

      assessmentRecommendations.value.recommendations = recommendedContent
        .map((recommendation) => ({
          baseSanityId: recommendation.id,
          type:
            recommendation.type === "topic"
              ? RecommendationType.Resource
              : RecommendationType.Course,
          content: getAllRecommendations.find(
            (item) => item.base_id === recommendation.id
          ) as Course & Resource,
        }))
        .filter(
          (recommendation: AssessmentRecommendation) =>
            !isEmpty(recommendation.content)
        );
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      assessmentRecommendations.value.loaded = true;
    }
  };

  const markAssessmentRecommendedResourceComplete = (
    baseId: string,
    completed: boolean
  ) => {
    const resource = assessmentRecommendations.value.recommendations.find(
      (recommendation) =>
        recommendation.type === RecommendationType.Resource &&
        recommendation.content.base_id === baseId
    );
    if (resource) {
      resource.content.completed = completed;
    }
  };

  return {
    assessments,
    assessmentDetail,
    latestCompletedAssessment,
    assessmentRecommendations,
    requestAssessments,
    requestAssessmentDetail,
    startAssessment,
    saveUserQuestionResponse,
    completeAssessment,
    initialiseAssessment,
    initialiseAssessmentResults,
    initialiseAssessmentRecommendations,
    requestAssessmentResults,
    isAssessmentsLoaded,
    getAssessments,
    getCurrentItem,
    currentItem,
    isAssessmentDetailLoaded,
    getAssessmentDetail,
    getAssessmentResponses,
    assessmentResponse,
    isAssessmentStarted,
    isAssessmentCompleted,
    getAssessmentResults,
    isAssessmentResultsLoaded,
    getAssessmentRecommendations,
    isAssessmentRecommendationsLoaded,
    requestAssessmentRecommendations,
    saveItemAndContinue,
    setCurrentItem,
    markAssessmentRecommendedResourceComplete,
    generateAssessmentRecommendations,
    getIncompleteAssessment,
    incompleteAssessment,
    getCurrentAssessmentResponse,
    getCurrentItemIndex,
    getCurrentSavedResponses,
  };
});
