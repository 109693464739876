<template>
  <div v-if="assessment">
    <div v-if="assessmentScoresExist">
      <h2
        aria-level="2"
        class="page-title"
        :class="assessmentScoresExist ? 'no-margin' : undefined"
        data-test="retake-title"
      >
        <span v-if="assessmentScoresExist">
          {{ $t("assessment.retake") }}
        </span>
        {{ assessment.title }}
      </h2>
      <div class="badge-wrapper" data-test="assessment-badges">
        <assessment-badges
          :label="assessment.badge"
          :time="assessment.time"
          :timeUnit="$t('assessment.mins')"
        />
      </div>
    </div>
    <div class="assessment-description" data-test="assessment-description">
      <SanityBlocks :blocks="assessment.description" />
    </div>
    <AppButton
      size="default"
      :type="
        assessmentScoresExist && !incompleteAssessmentExist
          ? 'secondary'
          : 'primary'
      "
      :label="buttonLabel"
      :ariaLabel="buttonLabel"
      @click="startQuiz()"
      data-test="start-button"
      class="start-button"
    />
    <div
      v-if="assessmentScoresExist && assessment.retakeUnderstanding"
      class="retake_understanding"
      data-test="retake_understanding"
    >
      <SanityBlocks :blocks="assessment.retakeUnderstanding" />
    </div>
    <div data-test="assessment-info">
      <ion-accordion-group
        class="assessment-info"
        :class="assessmentScoresExist ? 'no-padding' : undefined"
        id="accordion-component"
        data-test="accordion-component-panel"
        @click.stop
        @keyup.enter.stop
      >
        <ion-accordion value="first">
          <ion-item slot="header" color="white">
            <h2
              :aria-label="$t('assessment.aboutAccordion')"
              data-test="accordion-component-label"
            >
              {{ $t("assessment.aboutAccordion") }}
            </h2>
          </ion-item>
          <div slot="content" class="accordion-content">
            <slot> <SanityBlocks :blocks="assessment.assessmentInfo" /></slot>
          </div>
        </ion-accordion>
      </ion-accordion-group>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import { defineProps, computed } from "vue";
import { useAssessmentStore } from "@/store";
import AppButton from "@/components/Common/AppButton/AppButton.vue";
import { SanityBlocks } from "sanity-blocks-vue-component";
import { IonAccordionGroup, IonAccordion, IonItem } from "@ionic/vue";
import AssessmentBadges from "@/components/Assessment/AssessmentBadges/AssessmentBadges.vue";
import { useI18n } from "vue-i18n";

const router = useRouter();
const assessmentStore = useAssessmentStore();
const i18n = useI18n();
const props = defineProps({
  assessment: { type: Object, required: true },
  assessmentScoresExist: { type: Boolean, required: true },
  incompleteAssessmentExist: { type: Boolean, required: true },
});

const buttonLabel = computed(() => {
  if (props.incompleteAssessmentExist) return i18n.t("assessment.continueQuiz");
  return props.assessmentScoresExist
    ? `${i18n.t("assessment.retake")} ${
        props.assessment.badge?.toLowerCase() || "quiz"
      }`
    : i18n.t("assessment.startQuiz");
});
const startQuiz = async (): Promise<void> => {
  await assessmentStore.startAssessment();
  router.push({
    name: "AssessmentQuestion",
    params: { id: props.assessment.baseId },
  });
};
</script>

<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";

.page-title {
  font-size: 28px;
  color: var(--color-text-text-primary, #16161d);
  line-height: 36px;
  font-weight: 500;
}

.no-margin {
  margin: 0;
}

.badge-wrapper {
  margin: 24px 0;
}
.assessment-info {
  padding-top: 48px;

  ion-accordion {
    background: transparent;
    border-bottom: 1px solid var(--light-grey);
    position: static;
  }
  ion-item {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;

    &::part(native) {
      padding-left: 8px;
    }

    :deep(ion-icon) {
      margin-right: -8px;
    }
  }

  h2 {
    text-align: left;
    color: black;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
  }
  .accordion-content {
    padding: 8px 8px 0;
  }
}

.no-padding {
  padding-top: 0;
}

.assessment-info,
.retake_understanding,
.assessment-description {
  color: var(--button-text-primary);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.retake_understanding {
  color: var(--ion-color-darkgrey);
}

.start-button {
  width: 100%;
  margin: 8px 0;
}

//Desktop
@include breakpoint(medium) {
  .start-button {
    width: fit-content;
    min-width: 312px;
  }
}
</style>
<!-- This is to override the stylings from the SanityBlocks component -->
<style lang="scss">
.new-tag {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.001em;
  /* NZ Orange */
  color: var(--ion-color-primary);
}

#accordion-component {
  ion-accordion.accordion-expanding ion-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
}
</style>
