<template>
  <ion-page>
    <SkipLink></SkipLink>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <!--  Home | Learn | Jobs | Fav’s | Plan | Info -->
      <div class="navigation-container">
        <ion-tab-bar :class="tabbarImage" data-test="tab-bar" slot="bottom"
          ><nav aria-label="global">
            <ion-tab-button
              @keydown.enter="$router.push({ name: 'Home' })"
              tab="home"
              href="/tabs/home"
              data-test="home-tab"
            >
              <ion-icon aria-hidden="true" :icon="homeOutline"></ion-icon>
              <ion-label aria-label="Home">{{ $t("tabs.home") }}</ion-label>
            </ion-tab-button>

            <ion-tab-button
              data-test="learn-tab"
              v-if="
                featureFlagStore.isFlagEnabled(
                  'release-page-learn-and-grow-home',
                  true
                )
              "
              tab="learn"
              href="/tabs/learn-home"
              @keydown.enter="$router.push({ name: 'Learn and Grow' })"
            >
              <ion-icon aria-hidden="true" :icon="trophyOutline" />
              <ion-label aria-label="Learn">{{ $t("tabs.learn") }}</ion-label>
            </ion-tab-button>

            <ion-tab-button
              v-if="featureFlagStore.isFlagEnabled('release-page-jobs-home')"
              @keydown.enter="$router.push({ name: 'Jobs' })"
              tab="jobs"
              href="/tabs/jobs"
            >
              <ion-icon :icon="briefcaseOutline" />
              <ion-label>Jobs</ion-label>
            </ion-tab-button>

            <ion-tab-button
              data-test="tools-tab"
              v-if="
                featureFlagStore.isFlagEnabled('release-page-tools-home', true)
              "
              tab="tools"
              href="/tabs/tools"
              @keydown.enter="$router.push({ name: 'Tools' })"
            >
              <ion-icon aria-hidden="true" :icon="compassOutline" />
              <ion-label aria-label="Tools">{{ $t("tabs.tools") }}</ion-label>
            </ion-tab-button>

            <ion-tab-button
              @keydown.enter="$router.push({ name: 'Information' })"
              tab="info"
              href="/tabs/info"
            >
              <ion-icon aria-hidden="true" :icon="peopleOutline" />
              <ion-label aria-label="Info">{{ $t("tabs.info") }}</ion-label>
            </ion-tab-button>
            <div
              v-if="
                featureFlagStore.isFlagEnabled(
                  'configure-component-chat',
                  false
                )
              "
              class="chat-placeholder"
            ></div>
          </nav>
        </ion-tab-bar>
      </div>
    </ion-tabs>

    <ChatIcon></ChatIcon>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import SkipLink from "@/components/SkipLink/SkipLink.vue";
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
  IonRouterOutlet,
} from "@ionic/vue";
import ChatIcon from "@/components/ChatIcon/ChatIcon";
import {
  homeOutline,
  trophyOutline,
  briefcaseOutline,
  clipboardOutline,
  bookmarksOutline,
  peopleOutline,
  compassOutline,
} from "ionicons/icons";
import { useFeatureFlagStore } from "@/store";
import { useLocaleStore } from "@/store";

export default defineComponent({
  name: "TabsPage",
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonRouterOutlet,
    ChatIcon,
    SkipLink,
  },
  setup() {
    const featureFlagStore = useFeatureFlagStore();
    const localeStore = useLocaleStore();
    return {
      homeOutline,
      trophyOutline,
      briefcaseOutline,
      clipboardOutline,
      bookmarksOutline,
      peopleOutline,
      compassOutline,
      featureFlagStore,
      localeStore,
    };
  },
  computed: {
    themeName() {
      return this.localeStore.getCurrentLocaleTheme.toLowerCase();
    },
    tabbarImage() {
      return this.themeName === "sg" ? "ion-tab-bar-sg" : "ion-tab-bar-global";
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";

.chat-placeholder {
  min-width: 76px;
  max-width: 168px;
}

/* Mobile Design elements */
ion-tab-bar {
  height: 65px;
  @media (max-width: 650px) {
    justify-content: start;
  }
}
ion-tab-button {
  --background-focused: var(--ion-color-secondary);
  --ripple-color: var(--ion-color-secondary);
  --color-selected: var(--ion-color-secondary);
  --background-focused-opacity: 0.2;
  --color-focused: var(--ion-color-secondary);
}
// Indicator for mobile
ion-tab-button.ion-selectable::after {
  content: "";
  opacity: 0;
}
ion-tab-button.tab-selected::after,
ion-tab-button.ion-focused::after {
  content: "";
  transition: opacity 0.3s ease-out;
  width: 38px;
  height: 5px;
  background: var(--ion-color-secondary);
  border-radius: 0px 0px 4px 4px;
  display: block;
  position: relative;
  bottom: 65px;
  opacity: 1;
  margin: auto;
}

ion-tab-button.tab-selected {
  color: var(--ion-color-secondary);
  --background-focused: var(--ion-color-secondary);
  --ripple-color: var(--ion-color-secondary);
  --color-selected: var(--ion-color-secondary);
  --background-focused-opacity: 0.2;
}

ion-label {
  margin-bottom: 6px;
  font-size: 12px;
}

ion-icon {
  max-height: 22px;
  max-width: 22px;
  margin: 6px 0px 2px 0px;
}

nav {
  display: contents;
}
/* Desktop layout */
/* Using SCSS Medium breakpoint, Only show vertical nav on iPad landscape and up*/
@include breakpoint(medium) {
  .navigation-container {
    position: absolute;
  }
  ion-tab-bar {
    background: white;
  }
  ion-tab-button {
    background: white;
    --padding-start: var(--spacing-12);
    --padding-end: var(--spacing-12);
  }
  ion-tab-button.tab-selected {
    height: 50px;
  }
  .ion-tab-bar-global::before {
    content: url("../../assets/logos/apm.svg");
    transform: scale(0.383, 0.383) translate(-38.3%, -38.3%);
    height: 69px;
    width: 100%;
    padding-top: 9px;
    padding-bottom: 11px;
    border-bottom: 1px solid var(--lighter-grey);
  }
  .ion-tab-bar-sg::before {
    content: url("../../assets/logos/sg.svg");
    transform: scale(0.75, 0.75);
    height: 69px;
    width: 100%;
    padding-top: 9px;
    padding-bottom: 11px;
    border-bottom: 1px solid var(--lighter-grey);
  }
  ion-tab-bar {
    position: fixed;
    left: 0;
    width: var(--desktop-nav-width);
    height: 100vh;
    flex-direction: column;
    place-content: flex-start;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  ion-tab-button {
    flex-direction: row;
    width: 100%;
    height: 50px;
    flex-grow: 0;
    place-content: flex-start;
    margin: 10px 0;
    --background-focused: var(--ion-color-secondary);
    --ripple-color: var(--ion-color-secondary);
    --color-selected: var(--ion-color-secondary);
    --background-focused-opacity: 0.2;
    display: inline-block;
  }
  //Tab indicator for desktop
  ion-tab-button.tab-selected::after,
  ion-tab-button.ion-focused::after {
    content: "";
    width: 5px;
    height: 38px;
    background: var(--ion-color-secondary);
    border-radius: 4px 0px 0px 4px;
    display: block;
    position: relative;
    left: 168px;
    bottom: 44px;
    margin: unset;
  }
  //This is just to make the iOS indicator show ligned up, its default host css makes the buttons 10px bigger.
  ion-tab-button.ios::after {
    left: 173px;
  }

  ion-tab-button > ion-icon {
    padding: 10%;
  }
  .tabs-inner {
    margin-left: var(--desktop-nav-width);
  }
  ion-label {
    font-size: 16px;
    margin-bottom: -3px;
  }
}
</style>

<!-- unscoped style to affect generated, due to it being a shadow dom element, scoped won't work. -->
<style lang="scss">
@import "@/common/theme/breakpoints.scss";
@include breakpoint(medium) {
  .tabs-inner {
    margin-left: var(--desktop-nav-width);
  }
}
</style>
