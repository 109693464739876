<template>
  <ion-page>
    <MobileHeader></MobileHeader>
    <ion-content>
      <DesktopLocaleSelector></DesktopLocaleSelector>
      <ContentBanner
        data-test="user-banner"
        :message="welcomeMessage"
        :imagePath="require('@/assets/em-platformGuide-banner.svg')"
        imageWidthMobile="185px"
        imageMarginLeftMobile="-12px"
        :title="pageTitle"
      ></ContentBanner>
      <div class="page-bg">
        <div class="page-wrapper" id="dashboard-main-content" tabindex="0">
          <!-- Swiper -->
          <InProgressCourseCarouselVue></InProgressCourseCarouselVue>
          <!-- Call To Action -->
          <ion-card
            color="secondary"
            class="call-to-action"
            data-test="call-to-action"
            tabindex="0"
            @click="gotoLearnAndGrow"
            @keydown.enter="gotoLearnAndGrow"
          >
            <h4>
              <ion-icon
                :icon="trophyOutline"
                slot="start"
                aria-hidden="true"
              ></ion-icon>
              <div class="card-header">
                {{ $t("dashboard.callToActionTitle1") }}
                <b>{{ $t("dashboard.callToActionTitle2") }}</b>
              </div>
            </h4>
            <ion-card-content
              >{{ $t("dashboard.callToActionBody") }}
            </ion-card-content>
            <ion-button
              size="default"
              disabled="true"
              :aria-label="$t('dashboard.callToActionButtonText')"
            >
              {{ $t("dashboard.callToActionButtonText") }}</ion-button
            >
          </ion-card>
          <!--Program Carousel-->
          <ProgramCarousel
            v-if="
              featureFlagStore.isFlagEnabled(
                'release-component-program-carousel',
                false
              )
            "
            data-test="program-carousel"
            :key="programCarouselReloadKey"
          ></ProgramCarousel>

          <!-- Featured Courses -->
          <div v-if="courseStore.getCourses.length">
            <div class="title-wrapper">
              <h5 class="page-title title-md" data-test="featured-course-title">
                {{ $t("learnHome.courseTitle") }}
              </h5>
              <div>
                <router-link :to="{ name: 'Course' }">
                  <p class="see-all-link">
                    {{ $t("learnHome.seeAllContentLink") }}
                  </p>
                </router-link>
              </div>
            </div>
            <CourseCards
              :show="
                featureFlagStore.isFlagEnabled(
                  'release-component-course-carousel',
                  true
                )
              "
            ></CourseCards>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import mobileLogo from "@/assets/mobile-logo.svg";
import {
  IonPage,
  IonContent,
  IonCard,
  IonCardContent,
  IonButton,
  IonIcon,
} from "@ionic/vue";
import MobileHeader from "@/components/Common/MobileHeader/MobileHeader.vue";
// Import Swiper styles
import "swiper/css";
import {
  useCourseStore,
  useParticipantStore,
  useFeatureFlagStore,
} from "@/store";
import ContentBanner from "@/components/Common/ContentBanner/ContentBanner.vue";
import { RouterLink } from "vue-router";
import { trophyOutline } from "ionicons/icons";
import CourseCards from "@/components/LearnHome/CourseCards.vue";
import InProgressCourseCarouselVue from "@/components/InProgressCourseCarousel/InProgressCourseCarousel.vue";
import DesktopLocaleSelector from "@/components/Common/LocaleSelector/DesktopLocaleSelector.vue";
import ProgramCarousel from "@/components/ProgramCarousel/ProgramCarousel.vue";

export default {
  name: "DashboardHome",
  components: {
    IonContent,
    IonPage,
    IonCard,
    IonCardContent,
    IonButton,
    IonIcon,
    MobileHeader,
    ContentBanner,
    RouterLink,
    CourseCards,
    InProgressCourseCarouselVue,
    DesktopLocaleSelector,
    ProgramCarousel,
  },
  computed: {
    participantName() {
      return this.participantStore.participant?.givenName;
    },
    welcomeMessage() {
      const message = this.$t("dashboard.contentBannerWelcomeText", {
        name: this.participantName,
      });
      return this.participantStore.loading ? "" : message;
    },
  },
  methods: {
    gotoLearnAndGrow() {
      this.$router.push({ name: "Learn and Grow" });
    },
  },
  data() {
    return {
      mobileLogo: mobileLogo,
      trophyOutline,
      programCarouselReloadKey: 0,
      pageTitle: this.$route?.meta.title || "",
    };
  },
  setup() {
    const participantStore = useParticipantStore();
    const courseStore = useCourseStore();
    const featureFlagStore = useFeatureFlagStore();
    return { participantStore, courseStore, featureFlagStore };
  },
  mounted() {
    this.participantStore.participantRequest();
    this.courseStore.requestCourses();
    this.courseStore.requestCoursesInProgress("latestDate");
  },

  ionViewDidEnter() {
    this.pageTitle = this.$route?.meta.title || "";
    //TODO: Delete this once we move to Custom Desktop Routing Navigation
    this.courseStore.requestCoursesInProgress("latestDate");
    // Increment a random number every time the user enters the page.
    // This random number is used to reload the program carousel, so updated data is rendered via a new program carousel API request.
    this.programCarouselReloadKey++;
  },
};
</script>

<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";
.page-wrapper {
  z-index: 9;
  text-align: left;
  min-height: 80vh;
  box-shadow: none;
  border-radius: unset;
}
.page-bg {
  background: var(
    --ion-color-primary-banner-bg
  ); // this is to prevent the grey corners under the banner
}
.title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
}

.call-to-action {
  width: 100%;
  max-width: 443px;
  display: inline-block;
  padding: 16px;
  margin-left: 0;
  margin-bottom: 26px;
  box-shadow: var(--shadow-1);
  border-radius: 16px;
  cursor: pointer;
  transition: box-shadow var(--transition-time);

  &:hover {
    box-shadow: var(--shadow-2);
  }

  &:focus-visible {
    background-color: var(--ion-color-secondary-tint);
    outline: 2px solid var(--ion-color-secondary);
    box-shadow: inset 0px 0px 0px 1px var(--background-white);
  }

  ion-card-content,
  .card-header {
    letter-spacing: 0.25px;
    width: 100%;
  }

  ion-card-content {
    text-align: left;
    padding: 0 16px 0 32px;
  }

  ion-button {
    margin-top: 24px;
    width: 100%;

    &:hover,
    &[hover] {
      // Dont apply the default hover on this button
      --box-shadow: var(--shadow-0);
      --background-hover: var(--ion-colour-primary);
    }
    &[disabled] {
      // Dont apply the default disabled on this button
      opacity: 1;
    }
  }
  h4 {
    margin-top: 0;
    font-weight: 300;
    display: flex;

    ion-icon {
      font-size: 24px;
      margin-top: -2px;
      height: 40px;
      display: block;
    }

    .card-header {
      display: inline-block;
      padding-left: 9px;
    }
  }
  b {
    font-weight: 500;
  }
}

@include breakpoint(medium) {
  .page-wrapper {
    padding-left: 50px;
    padding-top: 27px;
  }
  .title-md {
    margin-bottom: 14px;
  }
}

@include breakpoint(large) {
  .call-to-action {
    max-width: 443px;
  }
}
</style>
