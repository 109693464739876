<template>
  <ion-item
    :button="true"
    :detail="false"
    @click="this.selectedItem()"
    data-test="locale-item"
  >
    <div class="logo-container">
      <ion-icon
        :icon="icon"
        class="logo"
        data-test="locale-icon"
        aria-hidden="true"
      ></ion-icon>
    </div>
    <div class="info">
      <div>
        {{ displayName }}
      </div>
      <div class="desc">
        {{ description }}
      </div>
    </div>
  </ion-item>
</template>

<script>
import { IonItem, IonIcon } from "@ionic/vue";

export default {
  props: {
    icon: {
      type: String,
    },
    displayName: {
      type: String,
    },
    description: {
      type: String,
    },
    code: {
      type: String,
    },
  },
  components: {
    IonItem,
    IonIcon,
  },
  methods: {
    selectedItem() {
      this.$emit("onSelected", this.code);
    },
  },
};
</script>

<style scoped>
.logo {
  width: 18px;
  height: 18px;

  background: var(--light-red);
  border: 1px solid var(--light-red);
  border-radius: 16px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.logo-container {
  padding: 24px 20px;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  margin-left: 8px;
}

.desc {
  color: var(--dark-grey);
}
</style>
