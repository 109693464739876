<template>
  <div class="content-wrapper">
    <h2
      v-if="assessment.contentTitle"
      class="page-title"
      aria-level="2"
      data-test="title"
    >
      {{ assessment.contentTitle }}
    </h2>
    <div
      v-if="assessment.contentDescription"
      class="content-description"
      data-test="description"
    >
      <SanityBlocks :blocks="assessment.contentDescription" />
    </div>
  </div>
  <swiper
    @swiper="onSwiper"
    :initialSlide="0"
    slidesPerView="auto"
    :spaceBetween="16"
    :slidesOffsetAfter="48"
    :slidesOffsetBefore="48"
    :navigation="true"
    :modules="modules"
    :watchSlidesProgress="true"
    id="recommendations-swiper"
  >
    <swiper-slide
      v-for="(recommendation, index) in assessmentRecommendations"
      :key="recommendation.baseSanityId"
      data-test="recommendation-carousel"
    >
      <router-link
        class="card-wrapper"
        :to="{
          name:
            recommendation.type === RecommendationType.Resource
              ? 'ResourceDetail'
              : 'CourseDetail',
          params: { id: recommendation.content.base_id },
        }"
        @focus="onSwiperCardFocus(index, swiper)"
      >
        <ResourceCard
          v-if="recommendation.type === RecommendationType.Resource"
          data-test="resource-card"
          :document="recommendation.content"
          :completed="recommendation.content.completed"
        />
        <CourseOrCollectionCard
          v-else
          data-test="course-card"
          class="course-card"
          type="course"
          :title="recommendation.content.title"
          :description="recommendation.content.description"
          :thumbnail="parseFeatureImage(recommendation.content.feature_image)"
          :placeholderImg="require('@/assets/course-thumbnail-placeholder.png')"
          :courseStatus="getCourseProgress(recommendation.content)?.text"
          :courseStatusIcon="getCourseProgress(recommendation.content)?.icon"
        ></CourseOrCollectionCard>
      </router-link>
    </swiper-slide>
  </swiper>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { SanityBlocks } from "sanity-blocks-vue-component";
import { useAssessmentStore, RecommendationType } from "@/store";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation } from "swiper";
import { ImageUrl, onSwiperCardFocus } from "@/helpers";
import ResourceCard from "@/components/Resource/ResourceCard/ResourceCard.vue";
import CourseOrCollectionCard from "@/components/CourseOrCollectionCard/CourseOrCollectionCard.vue";
import { parseImageUrl, getCourseProgress } from "@/helpers";

SwiperCore.use(Navigation);

const assessmentStore = useAssessmentStore();

const assessment = computed(() => {
  return assessmentStore.getAssessmentDetail;
});

const assessmentRecommendations = computed(() => {
  return assessmentStore.getAssessmentRecommendations;
});

const parseFeatureImage = (image: ImageUrl): string | undefined => {
  return parseImageUrl(image, 300, 300) || undefined;
};
</script>

<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";
@import "@/common/theme/swiper.scss";

.content-wrapper {
  display: inline-flex;
  flex-direction: column;
  text-align: left;
  max-width: 700px;
  color: var(--dark-grey);
  @include breakpoint(medium) {
    width: calc(100% - 48px);
  }
}
.page-title {
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
  color: var(--button-text-primary);
  margin: 0;
}

.content-description {
  color: var(--ion-color-darkgrey);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.card-wrapper {
  height: 100%;
  width: inherit;
  border-radius: 16px;
  text-decoration: none;
  transition: box-shadow var(--transition-time);
  transition: box-shadow var(--transition-time);
  &:hover {
    box-shadow: var(--shadow-2);
  }
  &:active {
    box-shadow: var(--shadow-1);
  }
  &:focus-visible {
    outline-color: var(--ion-color-secondary);
  }
}

.course-card {
  height: 250px;
  max-width: 302px;
  background: var(--background-white);
  box-shadow: var(--shadow-1);
  border-radius: 8px;
  margin-left: 0;
  margin-right: 0;
  @include breakpoint(medium) {
    max-width: 228px;
    margin: 0px;
  }
}

#recommendations-swiper {
  margin-top: 20px;
  position: relative;
  min-height: 190px;
  margin-left: -48px;
  overflow: hidden;
  @include breakpoint(medium) {
    margin-left: -24px;
  }
  @include breakpoint(large) {
    padding-left: calc(((100% - 750px) * 25 / 50));
  }
}

.swiper :deep(.swiper-button-next) {
  transform: translateX(0px);
  height: 130px;
  top: calc(100% - 144px);
}
.swiper :deep(.swiper-button-prev) {
  height: 130px;
  top: calc(100% - 144px);
}
</style>
