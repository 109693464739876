<template>
  <ion-page>
    <MobileHeader></MobileHeader>
    <ion-content>
      <DesktopLocaleSelector></DesktopLocaleSelector>
      <ContentBanner
        :message="$t('learnHome.bannerWelcomeText')"
        :imagePath="require('@/assets/em-learngrowGuide-banner.svg')"
        :title="pageTitle"
        imageWidthDesktop="220px"
        imageMarginLeftDesktop="5px"
        imageWidthMobile="250px"
        imageMarginLeftMobile="-65px"
      ></ContentBanner>
      <div class="page-bg">
        <div class="page-wrapper" id="learn-home-main-content" tabindex="0">
          <div v-if="store.getDocumentsByType('content_pack').length">
            <CardsTitle
              :title="$t('learnHome.categoryTitle')"
              data-test="category-cards-title"
            ></CardsTitle>
            <CategoryCards data-test="category-cards-holder"></CategoryCards>
          </div>
          <!--Program Carousel-->
          <ProgramCarousel
            data-test="program-carousel"
            :key="programCarouselReloadKey"
            v-if="
              featureFlagStore.isFlagEnabled(
                'release-component-program-carousel',
                false
              )
            "
          ></ProgramCarousel>
          <div v-if="courseStore.getCourses.length">
            <CardsTitle
              destination="Course"
              :title="$t('learnHome.courseTitle')"
              data-test="course-cards-title"
            ></CardsTitle>
            <CourseCards
              :show="
                featureFlagStore.isFlagEnabled(
                  'release-component-course-carousel',
                  true
                )
              "
              data-test="course-cards-holder"
            ></CourseCards>
          </div>
          <div v-if="store.getDocumentsByType('collection').length">
            <CardsTitle
              destination="Collection"
              :title="$t('learnHome.collectionTitle')"
              data-test="collection-cards-title"
            ></CardsTitle>
            <CollectionCards
              data-test="collection-cards-holder"
            ></CollectionCards>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";

import MobileHeader from "@/components/Common/MobileHeader/MobileHeader.vue";
import DesktopLocaleSelector from "@/components/Common/LocaleSelector/DesktopLocaleSelector.vue";
import CategoryCards from "@/components/LearnHome/CategoryCards.vue";
import CollectionCards from "@/components/LearnHome/CollectionCards.vue";
import CardsTitle from "@/components/LearnHome/CardsTitle.vue";
import CourseCards from "@/components/LearnHome/CourseCards.vue";
import {
  useSanityStore,
  useCourseStore,
  useLocaleStore,
  useFeatureFlagStore,
} from "@/store";
import ContentBanner from "@/components/Common/ContentBanner/ContentBanner.vue";
import ProgramCarousel from "@/components/ProgramCarousel/ProgramCarousel.vue";

export default {
  name: "learn-home",
  components: {
    IonPage,
    IonContent,
    MobileHeader,
    CollectionCards,
    CourseCards,
    CategoryCards,
    ContentBanner,
    CardsTitle,
    DesktopLocaleSelector,
    ProgramCarousel,
  },
  data() {
    return {
      programCarouselReloadKey: 0,
      pageTitle: this.$route?.meta.title || "",
    };
  },
  methods: {
    contentCount(content, type) {
      if (!content || content?.length === 0) {
        return 0;
      }
      return content.filter((x) => x?._type === type).length;
    },
  },
  setup() {
    const store = useSanityStore();
    const courseStore = useCourseStore();
    const localeStore = useLocaleStore();
    const featureFlagStore = useFeatureFlagStore();
    return { store, courseStore, localeStore, featureFlagStore };
  },
  mounted() {
    this.store.requestQuery("collection");
    this.store.requestQuery("content_pack");
    this.courseStore.requestCourses();
  },
  ionViewDidEnter() {
    this.pageTitle = this.$route?.meta.title || "";
    // Increment a random number every time the user enters the page.
    // This random number is used to reload the program carousel, so updated data is rendered via a new program carousel API request.
    this.programCarouselReloadKey++;
  },
};
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";
.page-wrapper {
  z-index: 9;
  box-shadow: none;
  border-radius: unset;
}

.page-bg {
  background: var(
    --ion-color-primary-banner-bg
  ); // this is to prevent the grey corners under the banner
}
@include breakpoint(medium) {
  ion-header {
    height: 0;
  }
  .swiper-cards {
    display: none;
  }
}
</style>
