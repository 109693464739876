<template>
  <ion-page>
    <MobileHeader></MobileHeader>
    <ion-content>
      <DesktopLocaleSelector />
      <div class="page-wrapper" data-test="page-wrapper">
        <Breadcrumbs
          tab="tools"
          :doc="t('resume.breadcrumbs.resume')"
          data-test="breadcrumbs"
        ></Breadcrumbs>

        <div id="resume-overview-main-content" tabindex="-1">
          <div class="content-frame-wrapper" data-test="resume-overview">
            <div class="content-card-wrapper">
              <TitleSkeleton
                v-if="resumeStore.isResumeLoading"
                class="page-title"
                data-test="title-skelton"
              ></TitleSkeleton>
              <h1
                v-else
                class="page-title"
                aria-level="1"
                data-test="page-title"
              >
                {{ t("resume.overview.title") }}
              </h1>
              <TitleSkeleton
                v-if="resumeStore.isResumeLoading"
                class="description"
                style="width: 50%"
                data-test="description-skelton"
              ></TitleSkeleton>
              <div v-else class="description" data-test="description">
                {{ t("resume.overview.description") }}
              </div>

              <div class="card-wrapper" data-test="card-wrapper">
                <ContentCard
                  v-for="card in resumeCardContent"
                  :key="card.routeName"
                  :id="card.routeName"
                  :title="card.title"
                  :description="card.description"
                  :buttonText="card.buttonText"
                  :buttonType="card.buttonType"
                  :icon="card.icon"
                  :isLoading="resumeStore.isResumeLoading"
                  data-test="content-card"
                  @card-clicked="handleClick"
                ></ContentCard>
              </div>
              <div v-if="resumeStore.isResumeLoading">
                <ion-skeleton-text
                  :animated="true"
                  style="width: 312px; height: 44px"
                ></ion-skeleton-text>
              </div>
              <AppButton
                v-else
                size="default"
                :disabled="disableDownload"
                type="primary"
                width="312px"
                :label="t('resume.overview.downloadMyResume')"
                :ariaLabel="t('resume.overview.downloadMyResume')"
                data-test="download-button"
                @click="triggerDownload"
              />
            </div>
          </div>
        </div>
        <AppModal
          v-if="openDownloadModal"
          :isOpen="openDownloadModal"
          :title="t('resume.downloadModal.title')"
          :body="t('resume.downloadModal.body')"
          :primaryButton="{
            label: t('resume.downloadModal.primaryButtonText'),
            isLoading: isDownloading,
          }"
          :secondaryButton="{
            label: t('resume.downloadModal.secondaryButtonText'),
          }"
          @onPrimaryButtonClick="downloadResume"
          @onSecondaryButtonClick="openDownloadModal = false"
          data-test="download-modal"
        />
        <AppModal
          v-if="openSuccessModal"
          :isOpen="openSuccessModal"
          :title="t('resume.downloadSuccessModal.title')"
          :body="t('resume.downloadSuccessModal.body')"
          :primaryButton="{
            label: t('resume.downloadSuccessModal.primaryButtonText'),
          }"
          @onPrimaryButtonClick="openSuccessModal = false"
          :icon="checkmarkCircle"
        />
        <ErrorModal
          v-show="errorStore.hasDownloadResumeError"
          :hasErrored="errorStore.hasDownloadResumeError"
          @onDismiss="errorStore.setDownloadResumeError(false)"
          data-test="error-modal"
          class="error-modal"
          :title="t('resume.downloadErrorModal.title')"
          :body="t('resume.downloadErrorModal.body')"
          :primaryButton="{
            label: t('resume.downloadErrorModal.primaryButtonText'),
          }"
          :secondaryButton="{
            label: t('resume.downloadErrorModal.secondaryButtonText'),
          }"
          @onPrimaryButtonClick="retryDownload"
          @onSecondaryButtonClick="errorStore.setDownloadResumeError(false)"
        ></ErrorModal>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { IonPage, IonContent } from "@ionic/vue";
import MobileHeader from "@/components/Common/MobileHeader/MobileHeader.vue";
import DesktopLocaleSelector from "@/components/Common/LocaleSelector/DesktopLocaleSelector.vue";
import Breadcrumbs from "@/components/Common/Breadcrumbs/Breadcrumbs.vue";
import AppButton from "@/components/Common/AppButton/AppButton.vue";
import AppModal from "@/components/Common/AppModal/AppModal.vue";
import ErrorModal from "@/components/Common/ErrorModal/ErrorModal.vue";
import ContentCard from "@/components/Resume/ContentCard/ContentCard.vue";
import { useI18n } from "vue-i18n";
import { call, business, ribbon, briefcase, person } from "ionicons/icons";
import type { Button } from "@/components/Common/AppButton/AppButton.vue";
import TitleSkeleton from "@/components/Skeletons/TitleSkeleton/TitleSkeleton.vue";
import { useResumeStore, useErrorStore } from "@/store";
import { useRouter, useRoute } from "vue-router";
import { checkmarkCircle } from "ionicons/icons";
import { focusOnMainContent } from "@/helpers";
import { onIonViewDidEnter } from "@ionic/vue";
const { t } = useI18n<{}>({
  useScope: "global",
});
const router = useRouter();
const route = useRoute();
const openDownloadModal = ref(false);
const openSuccessModal = ref(false);
const isDownloading = ref(false);

interface CardContent {
  title: string;
  description: string;
  buttonText: string;
  buttonType: Button;
  icon: string;
  routeName: string;
}
const resumeStore = useResumeStore();
const errorStore = useErrorStore();

const disableDownload = computed(() => {
  if (
    resumeStore.isDetailsCompleted ||
    resumeStore.isExperienceCompleted ||
    resumeStore.isQualificationsCompleted ||
    resumeStore.isLicencesCompleted ||
    resumeStore.isPersonalStatementCompleted
  )
    return false;
  else return true;
});

onMounted(async () => {
  await resumeStore.requestResume();
});

onIonViewDidEnter(() => {
  focusOnMainContent(route?.meta?.mainContentId);
});

const resumeCardContent = computed((): CardContent[] => {
  return [
    {
      title: "resume.resumeContentCard.contactDetails.title",
      description: "resume.resumeContentCard.contactDetails.description",
      buttonText: resumeStore.isDetailsCompleted
        ? "resume.resumeContentCard.contactDetails.editButton"
        : "resume.resumeContentCard.contactDetails.addButton",
      buttonType: resumeStore.isDetailsCompleted ? "tertiary" : "secondary",
      icon: call,
      routeName: "ResumeContactDetails",
    },
    {
      title: "resume.resumeContentCard.experience.title",
      description: "resume.resumeContentCard.experience.description",
      buttonText: resumeStore.isExperienceCompleted
        ? "resume.resumeContentCard.experience.editButton"
        : "resume.resumeContentCard.experience.addButton",
      buttonType: resumeStore.isExperienceCompleted ? "tertiary" : "secondary",
      icon: business,
      routeName: "ResumeWorkExperiences",
    },
    {
      title: "resume.resumeContentCard.qualifications.title",
      description: "resume.resumeContentCard.qualifications.description",
      buttonText: resumeStore.isQualificationsCompleted
        ? "resume.resumeContentCard.qualifications.editButton"
        : "resume.resumeContentCard.qualifications.addButton",
      buttonType: resumeStore.isQualificationsCompleted
        ? "tertiary"
        : "secondary",
      icon: ribbon,
      routeName: "ResumeQualifications",
    },
    {
      title: "resume.resumeContentCard.licences.title",
      description: "resume.resumeContentCard.licences.description",
      buttonText: resumeStore.isLicencesCompleted
        ? "resume.resumeContentCard.licences.editButton"
        : "resume.resumeContentCard.licences.addButton",
      buttonType: resumeStore.isLicencesCompleted ? "tertiary" : "secondary",
      icon: briefcase,
      routeName: "ResumeLicences",
    },
    {
      title: "resume.resumeContentCard.personalStatement.title",
      description: "resume.resumeContentCard.personalStatement.description",
      buttonText: resumeStore.isPersonalStatementCompleted
        ? "resume.resumeContentCard.personalStatement.editButton"
        : "resume.resumeContentCard.personalStatement.addButton",
      buttonType: resumeStore.isPersonalStatementCompleted
        ? "tertiary"
        : "secondary",
      icon: person,
      routeName: "ResumePersonalStatement",
    },
  ];
});

const handleClick = (routeName: string) => {
  router.push({ name: routeName });
};

const triggerDownload = () => {
  openDownloadModal.value = true;
};
const downloadResume = async () => {
  errorStore.setDownloadResumeError(false);
  isDownloading.value = true;
  try {
    const downloadSuccess = await resumeStore.downloadResumeToDoc();
    if (downloadSuccess) {
      openSuccessModal.value = true;
    }
  } catch (error) {
    errorStore.setDownloadResumeError(true);
    console.error("Failed to download resume:", error);
  } finally {
    isDownloading.value = false;
    openDownloadModal.value = false;
  }
};
const retryDownload = () => {
  errorStore.setDownloadResumeError(false);
  triggerDownload();
};
</script>

<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";

.page-wrapper {
  z-index: 9;
  border-radius: 0;
  box-shadow: none;
  margin-top: var(--spacing-0);
  @include breakpoint(medium) {
    margin-top: 84px;
  }
}

.content-frame-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: var(--spacing-24);
}

.content-card-wrapper {
  max-width: 700px;
  color: var(--button-text-primary);
  display: block;
  margin: auto;
}

.page-title {
  font-size: 36px;
  font-weight: 500;
  line-height: 44px;
  margin: 0 0 var(--spacing-16);
}

.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-bottom: var(--spacing-24);
}
.card-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
  margin-bottom: var(--spacing-24);
  text-align: left;
}
</style>
