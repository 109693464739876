<template>
  <div class="checkbox-input">
    <div v-if="isLoading" class="skeleton-wrapper" data-test="skeleton-wrapper">
      <ion-skeleton-text :animated="true" class="skeleton-checkbox" />
      <ion-skeleton-text :animated="true" class="skeleton-detail" />
    </div>
    <ion-item v-else lines="none">
      <ion-checkbox
        slot="start"
        :checked="checked"
        :disabled="disabled"
        @ionChange="$emit('onChange', $event.detail.checked)"
        data-test="checkbox-input"
        :id="`checkbox-input-${uid}`"
        :aria-checked="checked"
        :aria-labelledby="`checkbox-label-${uid}`"
      ></ion-checkbox>
      <ion-label
        :id="`checkbox-label-${uid}`"
        class="checkbox-label"
        data-test="checkbox-label"
        aria-hidden="true"
        >{{ labelText }}</ion-label
      >
    </ion-item>
  </div>
</template>
<script setup lang="ts">
import {
  defineProps,
  withDefaults,
  getCurrentInstance,
  ref,
  onMounted,
} from "vue";
import { IonCheckbox, IonLabel, IonItem, IonSkeletonText } from "@ionic/vue";

interface Props {
  checked: boolean;
  labelText: string;
  disabled: boolean;
  isLoading?: boolean;
}
withDefaults(defineProps<Props>(), {
  disabled: false,
  isLoading: false,
});

const uid = ref<number | null | undefined>(null);
onMounted(() => {
  uid.value = getCurrentInstance()?.uid;
  setTimeout(() => {
    // ion-checkbox has accessibility issues and this needs to be done to solve them
    const checkboxInput = document.querySelector(
      `#checkbox-input-${uid.value}`
    );
    if (checkboxInput?.shadowRoot) {
      const elements = checkboxInput.shadowRoot.querySelectorAll("*");
      elements.forEach((element) => {
        element.setAttribute("aria-hidden", "true");
      });
    }
  }, 500);
});
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";
ion-item {
  --padding-start: var(--spacing-4);
  --padding-end: var(--spacing-0);
  --min-height: 0px;
  --background-hover: transparent;
  --background-focused: transparent;
  &.ion-focused {
    outline: 2px solid var(--ion-color-secondary);
    box-shadow: inset 0px 0px 0px 1px var(--background-white);
    border-radius: var(--spacing-4);
  }
}

ion-checkbox {
  --size: 16px;
  --checkmark-width: 2px;
  --checkmark-color: var(--background-white);
  --border-width: 1px;
  --border-style: solid;
  --border-radius: 2px;
  --background: var(--background-white);
  --border-color: var(--checkbox-border);
  --background-checked: var(--ion-color-primary);
  --border-color-checked: transparent;
  margin: var(--spacing-0) var(--spacing-12) var(--spacing-0) var(--spacing-0);
}
ion-label.checkbox-label {
  margin: var(--spacing-12) var(--spacing-0) var(--spacing-12) var(--spacing-0);
  --color: var(--text-primary);
}
ion-checkbox.checkbox-disabled {
  opacity: 1;
  --background: var(--lighter-grey);
  --border-color: var(--light-grey);
}
.skeleton-wrapper {
  display: flex;
  align-items: center;
  height: 24px;
  gap: var(--spacing-8);
}
.skeleton-detail {
  width: 240px;
  height: 20px;
}
.skeleton-checkbox {
  width: 16px;
  height: 16px;
  border: 1px solid var(--skeleton-border);
  border-radius: 2px;
}
</style>
