<template>
  <div class="breadcrumbs-wrapper">
    <ion-skeleton-text
      style="height: 16px"
      :animated="true"
    ></ion-skeleton-text>
  </div>
  <div class="content-frame-wrapper">
    <div class="content-wrapper">
      <div class="completion-badge-wrapper">
        <ion-skeleton-text
          style="height: 24px"
          :animated="true"
        ></ion-skeleton-text>
      </div>
      <h4 class="title-wrapper">
        <ion-skeleton-text
          style="height: 24px"
          :animated="true"
        ></ion-skeleton-text>
        <ion-skeleton-text
          style="width: 50%; height: 24px"
          :animated="true"
          class="mobile-lines-hide"
        ></ion-skeleton-text>
      </h4>
      <div class="badge-wrapper">
        <ion-skeleton-text
          style="width: 20%; height: 18px"
          :animated="true"
        ></ion-skeleton-text>
        <ion-skeleton-text
          style="width: 20%; height: 18px"
          :animated="true"
        ></ion-skeleton-text>
      </div>
      <div class="paragraph-wrapper">
        <ion-skeleton-text
          style="height: 16px"
          :animated="true"
        ></ion-skeleton-text>
        <ion-skeleton-text
          style="height: 16px"
          :animated="true"
        ></ion-skeleton-text>
        <ion-skeleton-text
          style="height: 16px"
          :animated="true"
          class="mobile-lines-short"
        ></ion-skeleton-text>
        <ion-skeleton-text
          style="height: 16px"
          :animated="true"
          class="mobile-lines-hide"
        ></ion-skeleton-text>
        <ion-skeleton-text
          style="width: 70%; height: 16px"
          :animated="true"
          class="mobile-lines-hide"
        ></ion-skeleton-text>
      </div>
      <div class="task-wrapper">
        <ion-skeleton-text
          style="height: 16px"
          :animated="true"
        ></ion-skeleton-text>
        <ion-skeleton-text
          style="height: 16px"
          :animated="true"
          class="mobile-lines-short"
        ></ion-skeleton-text>
        <ion-skeleton-text
          style="width: 70%; height: 16px"
          :animated="true"
          class="mobile-lines-hide"
        ></ion-skeleton-text>
      </div>
      <div class="interactive-wrapper">
        <ion-skeleton-text :animated="true"></ion-skeleton-text>
      </div>
      <div class="next-text-wrapper">
        <ion-skeleton-text
          style="height: 16px"
          :animated="true"
        ></ion-skeleton-text>
        <ion-skeleton-text
          style="height: 16px"
          :animated="true"
          class="mobile-lines-short"
        ></ion-skeleton-text>
        <ion-skeleton-text
          style="width: 70%; height: 16px"
          :animated="true"
          class="mobile-lines-hide"
        ></ion-skeleton-text>
      </div>
      <div class="completion-button-wrapper">
        <ion-skeleton-text
          style="width: 70%; height: 46px"
          :animated="true"
        ></ion-skeleton-text>
      </div>
      <div class="button-wrapper">
        <ion-skeleton-text
          style="width: 50%; height: 46px"
          :animated="true"
        ></ion-skeleton-text>
      </div>
    </div>
  </div>
</template>
<script>
import { IonSkeletonText } from "@ionic/vue";

export default {
  name: "TextSkeleton",
  components: {
    IonSkeletonText,
  },
};
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";

.badge-wrapper {
  margin-bottom: 19px;
  max-width: 148px;
  gap: 8px;
  ion-skeleton-text {
    min-width: 70px;
    --border-radius: 12px;
  }
}

.button-wrapper {
  overflow: hidden;
  margin-top: 19px;
  ion-skeleton-text {
    float: right;
    --border-radius: 10px;
    min-width: 125px;
    max-width: 153px;
  }
}

.completion-button-wrapper {
  overflow: hidden;
  margin-top: 19px;
  display: flex;
  justify-content: center;
  ion-skeleton-text {
    --border-radius: 10px;
    max-width: 312px;
    min-width: 200px;
  }
}

.title-wrapper,
.paragraph-wrapper,
.task-wrapper,
.interactive-wrapper {
  margin-bottom: 19px;
  ion-skeleton-text {
    height: 179px;
  }
}

.completion-badge-wrapper {
  ion-skeleton-text {
    width: 30%;
  }
}

.breadcrumbs-wrapper {
  margin-bottom: 27px;
  ion-skeleton-text {
    width: 45%;
  }
}

.interactive-wrapper {
  max-height: 402px;
}

//Desktop
@include breakpoint(medium) {
  .breadcrumbs-wrapper {
    margin-left: 30px;
    margin-bottom: 34px;
    ion-skeleton-text {
      width: 75%;
    }
  }
  .content-wrapper {
    width: 100%;
    max-width: 700px;
  }

  .mobile-lines-hide {
    display: none;
  }

  ion-skeleton-text.mobile-lines-short {
    width: 60%;
  }

  .title-wrapper {
    ion-skeleton-text {
      width: 70%;
    }
  }

  .interactive-wrapper {
    ion-skeleton-text {
      height: 402px;
    }
  }

  .content-frame-wrapper {
    padding-right: 10%;
    padding-left: 28px; // Prevent context passing breadcrumbs
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
