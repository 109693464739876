<template>
  <ion-card>
    <IonCardHeader>
      <h5 v-if="title" class="page-title truncate">
        {{ title }}
      </h5>
    </IonCardHeader>
    <IonCardContent>
      <div class="badge-wrapper">
        <ion-badge
          v-if="courseTotal > 0"
          color="secondary"
          data-test="courseTotal"
          >{{ courseTotalLabel }}</ion-badge
        >
        <ion-badge
          v-if="collectionTotal > 0"
          color="secondary"
          data-test="collectionTotal"
          >{{ collectionTotalLabel }}</ion-badge
        >
      </div>
    </IonCardContent>
  </ion-card>
</template>
<script>
import { IonCard, IonCardHeader, IonCardContent, IonBadge } from "@ionic/vue";
export default {
  name: "CategoryCard",
  components: {
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonBadge,
  },

  computed: {
    courseTotalLabel() {
      const value = this.courseTotal >= 100 ? 99 : this.courseTotal;
      const label =
        value > 1
          ? this.$t("categoryCard.coursesBadge")
          : this.$t("categoryCard.courseBadge");

      return `${value} ${label}`;
    },

    collectionTotalLabel() {
      const value = this.collectionTotal >= 100 ? 99 : this.collectionTotal;
      const label =
        value > 1
          ? this.$t("categoryCard.collectionsBadge")
          : this.$t("categoryCard.collectionBadge");

      return `${value} ${label}`;
    },
  },
  props: {
    title: { type: String },
    courseTotal: { type: Number },
    collectionTotal: { type: Number },
  },
};
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";
ion-card-header {
  padding: 0px;
  text-align: left;
  width: 100%;
}
.page-title {
  margin-top: 0;
}
ion-card-content {
  padding: 0;
  width: 100%;
}
ion-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;
  margin-left: 0;
  height: 144px;
  width: 190px;
  background: linear-gradient(
      0deg,
      rgba(var(--ion-color-secondary-rgb), 0.2),
      rgba(var(--ion-color-secondary-rgb), 0.2)
    ),
    var(--ion-color-secondary-contrast);
  /* Base02 */
  box-shadow: 0px 2px 2px rgba(153, 153, 153, 0.14),
    0px 3px 1px rgba(153, 153, 153, 0.12), 0px 1px 5px rgba(153, 153, 153, 0.2);
  border-radius: 16px;
  transition: box-shadow var(--transition-time);
  &:hover {
    box-shadow: var(--shadow-2);
  }
  &:active {
    box-shadow: var(--shadow-1);
    background: var(--ion-color-secondary-shade-1);
  }
}
.truncate {
  /* Truncate text to 2 lines */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
