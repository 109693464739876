<template>
  <div class="welcome">
    <ion-card-content data-test="speech-bubble">
      <span :class="isSignInDesktop ? ' message-md' : ''" class="message">
        <slot></slot>
      </span>
    </ion-card-content>
  </div>
</template>

<script>
import { IonCardContent } from "@ionic/vue";
export default {
  components: {
    IonCardContent,
  },
  props: ["pageType"],
  computed: {
    isSignInDesktop() {
      if (this.pageType == "sign-in-md") {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";

.welcome {
  background: var(--background-white);
  color: var(--dark-grey);
  position: relative;
  max-width: inherit;
  height: fit-content;
  text-align: start;
  border: 0.5px solid var(--ion-color-primary-bubble-shade);
  box-shadow: 4px 4px 0px 0px var(--ion-color-primary-bubble-shade);
  overflow: visible;
  border-radius: 16px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;
}
/* Arrow in CSS */
.welcome:before {
  content: "";
  top: 24px;
  left: -9.5px;
  position: absolute;
  display: block;
  width: 20px;
  height: 20px;
  background-color: white;
  border: 0.5px solid var(--ion-color-primary-bubble-shade);
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  clip-path: polygon(-1% 4%, 95% 100%, 0% 100%);
  border-radius: 0 0 0 0.25em;
  transform: rotate(45deg);
}

ion-card-content {
  padding: 10px;
}

.banner-bubble .message {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  font-weight: 400;
}

@include breakpoint(medium) {
  .banner-bubble .message {
    font-size: 22px;
    line-height: 28px;
  }
}
</style>
