<template>
  <ion-skeleton-text
    data-test="title-skeleton"
    class="title-md"
    :animated="true"
  >
  </ion-skeleton-text>
</template>
<script>
import { IonSkeletonText } from "@ionic/vue";
export default {
  name: "TitleSkeleton",
  components: {
    IonSkeletonText,
  },
};
</script>

<style lang="scss" scoped>
.title-md {
  margin-bottom: 14px;
  height: 28px;
  width: 150px;
}
</style>
