<template>
  <div
    class="card-holder loading-holder"
    v-if="store.getLoadingFor('collection')"
  >
    <CardSkeleton
      class="collection-card"
      v-for="index in 4"
      :key="index"
    ></CardSkeleton>
  </div>
  <div v-else class="card-holder">
    <router-link
      v-for="item in store.getDocumentsByType('collection').slice(0, 4)"
      :to="{ name: 'CollectionDetail', params: { id: item.base_id } }"
      :key="item._id"
      class="collection-wrapper"
    >
      <CourseOrCollectionCard
        data-test="card-holder-collection-desktop"
        class="collection-card"
        type="collection"
        :title="item.title"
        :description="item.description"
        :resourceType="[
          ...new Set(item.topics.map((element) => element.resource_type)),
        ]"
        :thumbnail="this.parseFeatureImage(item.feature_image)"
        :placeholderImg="
          require('@/assets/collection-thumbnail-placeholder.png')
        "
      ></CourseOrCollectionCard>
    </router-link>
  </div>
  <!-- render mobile version -->
  <swiper
    v-if="store.getLoadingFor('collection')"
    class="swiper-cards default-slider"
    :spaceBetween="16"
    :slidesPerView="1.19"
    :initialSlide="0"
    :slidesOffsetAfter="24"
    :slidesOffsetBefore="24"
  >
    <swiper-slide v-for="index in 4" :key="index">
      <CardSkeleton
        class="collection-card"
        data-test="course-card-skeleton"
      ></CardSkeleton
    ></swiper-slide>
  </swiper>
  <swiper
    v-else
    class="swiper-cards default-slider"
    :spaceBetween="16"
    :slidesPerView="1.19"
    :initialSlide="0"
    :slidesOffsetAfter="24"
    :slidesOffsetBefore="24"
  >
    <swiper-slide
      v-for="item in store.getDocumentsByType('collection').slice(0, 4)"
      :key="item._id"
      data-test="swiper-cards-collection"
    >
      <router-link
        :to="{ name: 'CollectionDetail', params: { id: item.base_id } }"
        class="collection-wrapper"
      >
        <CourseOrCollectionCard
          class="collection-wrapper collection-card"
          type="collection"
          :title="item.title"
          :description="item.description"
          :resourceType="[
            ...new Set(item.topics.map((element) => element.resource_type)),
          ]"
          :thumbnail="this.parseFeatureImage(item.feature_image)"
          :placeholderImg="
            require('@/assets/collection-thumbnail-placeholder.png')
          "
          data-test="card-holder-collection-mobile"
        ></CourseOrCollectionCard>
      </router-link>
    </swiper-slide>
  </swiper>
</template>

<script>
import CourseOrCollectionCard from "@/components/CourseOrCollectionCard/CourseOrCollectionCard.vue";
import CardSkeleton from "@/components/Skeletons/CardSkeleton/CardSkeleton.vue";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/vue";
import { useSanityStore } from "@/store";
import { RouterLink } from "vue-router";
import { parseImageUrl } from "@/helpers";

export default {
  name: "collectionCards",
  components: {
    CourseOrCollectionCard,
    Swiper,
    SwiperSlide,
    RouterLink,
    CardSkeleton,
  },
  setup() {
    const store = useSanityStore();
    return { store };
  },
  methods: {
    parseImageUrl,
    parseFeatureImage(image) {
      return this.parseImageUrl(image, 500, 500);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";

.collection-card {
  padding: 0px;
  height: 300px;
  left: 0px;
  top: 0px;
  background: var(--background-white);
  border-radius: 16px;
  z-index: -1;
  box-shadow: var(--shadow-0);
}

.swiper {
  margin-right: -24px;
  margin-left: -24px;
}

.card-holder {
  display: none;
  margin-right: 12px;
}
// handles focus on safari
*:focus {
  outline-width: thin;
  outline-color: var(--ion-color-secondary);
  overflow: hidden;
}

.collection-wrapper {
  border-radius: 16px;
  margin-bottom: 12px;
  margin-right: 14px;
  position: relative;
  text-decoration: none;
  transition: box-shadow var(--transition-time);
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1), 6px 6px 0px #ffffff,
    8px 8px 3px rgba(0, 0, 0, 0.1), 12px 12px 0px #ffffff,
    14px 15px 2px rgba(0, 0, 0, 0.1);
  &:hover {
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1), 6px 6px 0px #ffffff,
      8px 8px 3px rgba(0, 0, 0, 0.1), 12px 12px 0px #ffffff,
      18px 18px 14px rgba(0, 0, 0, 0.2);
  }
  &:focus-visible,
  &:focus {
    border-radius: 16px !important;
    outline-color: var(--ion-color-secondary);
    overflow: unset !important;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1), 6px 6px 0px #ffffff,
      8px 8px 3px rgba(0, 0, 0, 0.1), 12px 12px 0px #ffffff,
      14px 15px 2px rgba(0, 0, 0, 0.1);
  }
  &:active {
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1), 6px 6px 0px #ffffff,
      8px 8px 3px rgba(0, 0, 0, 0.1), 12px 12px 0px #ffffff,
      14px 15px 2px rgba(0, 0, 0, 0.1);
  }
}

@include breakpoint(medium) {
  .swiper-cards {
    display: none;
  }
  .collection-card,
  .content-pack-card {
    max-width: 302px;
  }
  .collection-card {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    border-radius: 16px;
  }
  .collection-wrapper {
    margin-right: 16px;
  }
  .content-pack-card {
    height: 100%;
    margin: 0;
  }
  .card-holder {
    display: flex;
    flex-wrap: wrap;
    & a {
      text-decoration: none;
    }
    gap: 12px;
  }
  .title-md {
    margin-bottom: 14px;
  }
}
// Overrides for loading content cards to be a larger size.
.loading-holder > ion-card {
  width: 302px;
  height: 248px;
}
</style>
