export function mapResourceType(type) {
  const resourceTypeMap = {
    Article: "Article",
    Video: "Video",
    Game: "Game",
    Interactive: "Interactive",
    Checklist: "Checklist",
    Audio: "Audio",
    Quiz: "Quiz",
  };
  if (resourceTypeMap[type]) {
    return "resource.types." + type;
  }
  return "";
}
