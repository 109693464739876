import { defineStore } from "pinia";
// Import data service
import http from "../http-common";

export const useCategoryStore = defineStore({
  id: "category",
  state: () => ({
    category: {
      loading: false,
      document: {},
    },
  }),
  actions: {
    requestCategory(sanityDocumentId) {
      this.category.loading = true;
      http
        .get(`category/${sanityDocumentId}`)
        .then((response) => {
          this.category.document = response.data.data[0];
          this.category.loading = false;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.category.loading = false;
        });
    },
  },
  getters: {
    isCategoryLoading(state) {
      return state.category.loading;
    },
    getCategory(state) {
      return state.category.document || null;
    },
  },
});
