<template>
  <ion-page>
    <MobileHeader></MobileHeader>
    <ion-content data-test="learn-detail" class="page-wrapper">
      <DesktopLocaleSelector></DesktopLocaleSelector>
      <PageBanner
        v-if="!resourceStore.isResourceLoaded"
        :isImageLoading="true"
      ></PageBanner>
      <PageBanner v-else :imagePath="bannerImage"></PageBanner>
      <div v-if="!resourceStore.isResourceLoaded" class="page-wrapper">
        <ResourceDetailSkeleton></ResourceDetailSkeleton>
      </div>
      <div
        v-else
        class="page-wrapper"
        id="resource-detail-main-content"
        tabindex="0"
      >
        <Breadcrumbs
          tab="learn-home"
          :parentType="doc?.parent ? doc?.parent[0]?._type : ''"
          :grandparentType="grandparent?._type"
          :parent="doc?.parent ? doc?.parent[0]?.base_id : ''"
          :parentTitle="doc?.parent ? doc?.parent[0]?.title : ''"
          :grandparent="grandparent?.base_id"
          :grandparentTitle="grandparent?.title"
          :doc="doc?.title"
        ></Breadcrumbs>
        <div class="content-frame-wrapper">
          <div class="content-wrapper">
            <Transition name="slide-fade">
              <div
                v-if="isResourceCompleted && !completeHasBeenToggled"
                class="completion-badge-wrapper"
              >
                <ion-badge
                  color="primary"
                  id="completion-badge"
                  data-test="completion-badge"
                >
                  <ion-icon
                    :icon="checkmarkCircleOutline"
                    aria-hidden="true"
                  ></ion-icon>
                  {{ $t("resource.activityCompleted") }}
                </ion-badge>
              </div>
            </Transition>
            <div
              v-if="doc?.title"
              class="title-wrapper"
              data-test="title-wrapper"
            >
              <h4>{{ doc?.title }}</h4>
            </div>
            <div v-if="badges" class="badge-wrapper">
              <ion-badge
                v-if="mapResourceType(doc?.resource_type)"
                color="secondary"
              >
                {{ $t(mapResourceType(doc?.resource_type)) }}</ion-badge
              >
              <ion-badge v-if="doc?.time" id="timer">
                <ion-icon :icon="timeOutline" aria-hidden="true"></ion-icon>
                <p id="timer-text">
                  {{ doc?.time + " " + $t("resource.readingTimeMins") }}
                </p>
              </ion-badge>
            </div>
            <div class="page-content" data-test="page-content">
              <SanityBlocks :blocks="doc?.body" :serializers="serializers" />
              <div v-if="doc" class="completion-button-wrapper">
                <AppButton
                  size="default"
                  :type="isResourceCompleted ? 'primary' : 'tertiary'"
                  width="312px"
                  :label="$t(completedButtonText)"
                  :ariaLabel="$t(completedButtonText)"
                  :icon="checkmarkCircleOutline"
                  iconPosition="start"
                  @click="toggleCompletion"
                  data-test="complete-button"
                />
              </div>
              <div v-if="isTypeCourse" class="button-wrapper">
                <AppButton
                  v-if="prevResource"
                  size="default"
                  type="tertiary"
                  :label="$t('resource.activity.previous')"
                  :ariaLabel="$t('resource.activity.previous')"
                  :icon="chevronBackOutline"
                  iconPosition="start"
                  @click="
                    $router.replace({
                      name: 'ResourceDetail',
                      params: { id: prevResource },
                    })
                  "
                  data-test="previous-activity"
                />
                <AppButton
                  v-if="nextResource"
                  size="default"
                  type="primary"
                  :label="$t('resource.activity.next')"
                  :ariaLabel="$t('resource.activity.next')"
                  :icon="chevronForwardOutline"
                  iconPosition="end"
                  @click="
                    $router.replace({
                      name: 'ResourceDetail',
                      params: { id: nextResource },
                    })
                  "
                  class="next-button"
                  data-test="next-activity"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import { computed, ref, defineProps, onMounted } from "vue";
import { IonPage, IonContent, IonBadge, IonIcon } from "@ionic/vue";
import MobileHeader from "@/components/Common/MobileHeader/MobileHeader.vue";
import { SanityBlocks } from "sanity-blocks-vue-component";
import VideoSerializer from "@/components/Resource/Serializers/VideoSerializer/VideoSerializer.vue";
import StorylineSerializer from "@/components/Resource/Serializers/StorylineSerializer/StorylineSerializer.vue";
import BookwidgetSerializer from "@/components/Resource/Serializers/BookwidgetSerializer/BookwidgetSerializer.vue";
import SectionReference from "@/components/Serializers/SectionReference.vue";
import ImageSerializer from "@/components/Serializers/ImageSerializer.vue";
import LinkSerializer from "@/components/Serializers/LinkSerializer.vue";
import {
  chevronForwardOutline,
  chevronBackOutline,
  timeOutline,
  checkmarkCircleOutline,
} from "ionicons/icons";
import {
  useResourceStore,
  useErrorStore,
  useLocaleStore,
  useAssessmentStore,
  RecommendationType,
} from "@/store";
import PageBanner from "@/components/Common/PageBanner/PageBanner.vue";
import Breadcrumbs from "@/components/Common/Breadcrumbs/Breadcrumbs.vue";
import ResourceDetailSkeleton from "@/components/Skeletons/ResourceDetailSkeleton/ResourceDetailSkeleton.vue";
import { parseImageUrl, mapResourceType } from "@/helpers";
import DesktopLocaleSelector from "@/components/Common/LocaleSelector/DesktopLocaleSelector.vue";
import { createLocaleSpecificSanityId } from "@/helpers/CreateLocaleSpecificSanityId.js";
import AppButton from "@/components/Common/AppButton/AppButton.vue";

const resourceStore = useResourceStore();
const localeStore = useLocaleStore();
const assessmentStore = useAssessmentStore();

const props = defineProps({
  id: { type: String, required: true },
});

const completeHasBeenToggled = ref(false);
const incompleteText = "resource.activityInComplete";
const completedText = "resource.activityCompleted";
const serializers = {
  types: {
    video_reference: VideoSerializer,
    section_reference: SectionReference,
    storyline_reference: StorylineSerializer,
    bookwidget_reference: BookwidgetSerializer,
    image: ImageSerializer,
  },
  styles: {
    h1: "h1",
    h2: "h2",
    h3: "h3",
    h4: "h4",
    h5: "h5",
    h6: "h6",
    h7: "h7",
    h8: "h8",
    normal: "p",
  },
  marks: {
    link: LinkSerializer,
  },
};

const doc = computed(() => {
  return resourceStore.getResource;
});

const grandparent = computed(() => {
  return doc.value.parent?.[0]?.grandparent?.[0];
});

const badges = computed(() => {
  return doc.value.resource_type || doc.value.time;
});

const bannerImage = computed(() => {
  return doc.value.feature_image
    ? (parseImageUrl(doc.value.feature_image, 1000, 2500) as string)
    : "https://cdn.sanity.io/images/2i80ck01/~production/8135cdce59aec36c855d65c473f944f115bca081-4032x3024.jpg?auto=format&fit=max&w=500";
});
const isTypeCourse = computed(() => {
  return doc.value.parent?.[0]?._type === "course";
});

const currentResourceIndex = computed(() => {
  return doc.value.resourceSiblings[0]?.topics.findIndex(
    (resource: any) => props.id == resource.base_id
  );
});

const nextResource = computed(() => {
  if (
    currentResourceIndex.value === -1 ||
    currentResourceIndex.value ===
      doc.value.resourceSiblings?.[0]?.topics.length - 1
  ) {
    return "";
  }
  return (
    doc.value.resourceSiblings?.[0]?.topics[currentResourceIndex.value + 1]
      ?.base_id || ""
  );
});

const prevResource = computed(() => {
  if (currentResourceIndex.value === -1) {
    return "";
  }
  return (
    doc.value.resourceSiblings?.[0]?.topics[currentResourceIndex.value - 1]
      ?.base_id || ""
  );
});
const isResourceCompleted = computed(() => {
  return resourceStore.getResource.completed;
});
const completedButtonText = computed(() => {
  return isResourceCompleted.value ? completedText : incompleteText;
});
const assessmentRecommendedResource = computed(() => {
  return assessmentStore.getAssessmentRecommendations.find(
    (recommendation) =>
      recommendation.type === RecommendationType.Resource &&
      recommendation.content.base_id === doc.value.base_id
  );
});

onMounted(async (): Promise<void> => {
  try {
    const sanityId = createLocaleSpecificSanityId(props.id);
    await resourceStore.requestResource(sanityId);
  } catch (error: any) {
    if (error.response && error.response.status === 403) {
      if (localeStore.getCurrentLocaleCode.includes("en")) {
        await resourceStore.requestResource(props.id as string);
      } else {
        resourceStore.$reset();
        const errorStore = useErrorStore();
        errorStore.setGenericError(true);
      }
    }
  }
});

const toggleCompletion = (): void => {
  resourceStore.resource.document.completed = !isResourceCompleted.value;
  completeHasBeenToggled.value = true;
  resourceStore.markResourceComplete(isResourceCompleted.value);
  resourceStore.updateResourceCompletion(isResourceCompleted.value);
  console.log(
    "toggleCompletion",
    isResourceCompleted.value,
    assessmentRecommendedResource.value
  );
  if (assessmentRecommendedResource.value) {
    assessmentStore.markAssessmentRecommendedResourceComplete(
      doc.value.base_id,
      isResourceCompleted.value
    );
  }
};
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";

.page-wrapper {
  z-index: 9;
}

.page-content,
.title-wrapper,
.badge-wrapper {
  text-align: left;
  max-width: 700px;
}

ion-badge > ion-icon {
  vertical-align: middle;
  font-size: 14px;
  margin-right: 2px;
}

ion-badge {
  vertical-align: middle;
  line-height: 14px;
}

#completion-badge {
  max-width: 184px;
  height: 42px;
  border-radius: 42px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  display: flex;
  align-items: center;
  padding: 9px 19px 9px 10px;
  gap: 8px;
  justify-content: space-evenly;
  ion-icon {
    font-size: 22px;
  }
}

.button-wrapper {
  justify-content: space-between;
  overflow: hidden;
  clear: both;
}

.completion-button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 28px;
}

.completion-badge-wrapper {
  text-align: left;
}

.next-button {
  float: right;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-leave-to {
  transform: translateY(-42px);
  opacity: 0;
}

//Desktop
@include breakpoint(medium) {
  ion-header {
    height: 0;
  }

  .content-frame-wrapper {
    padding-top: 50px;
    padding-right: 10%;
    padding-left: 28px; // Prevent context passing breadcrumbs
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
<!-- This is to override the stylings from the SanityBlocks component -->
<style lang="scss">
.new-tag {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.001em;
  /* NZ Orange */
  color: var(--ion-color-primary);
}

.page-content {
  line-height: 24px;
}

.content-wrapper {
  color: var(--text-black);
}
</style>
