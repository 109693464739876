import { sortByDate, sortISODate } from "@/helpers";
import {
  Licence,
  Qualification,
  WorkExperience,
} from "@/store/models/resume.models";

export const sortLicences = (
  licences: Licence[] | undefined
): Licence[] | undefined => {
  if (!licences) {
    return [];
  }
  const licencesCopy = licences?.slice() || [];
  const sortedByCreated = sortISODate(licencesCopy, "createdAt");
  return sortedByCreated;
};

export const sortWorkExperiences = (
  workExperiences: WorkExperience[] | undefined
): WorkExperience[] | undefined => {
  if (!workExperiences) {
    return [];
  }
  const workExperiencesCopy = workExperiences?.slice() || [];
  const withCurrentRole = workExperiencesCopy.filter(
    (e) => !e.endDate && !!e.isCurrentRole
  );
  const sortCurrentRoleWithStartDate =
    sortByDate<WorkExperience>(withCurrentRole, "startDate") || [];

  const withEndDate = workExperiencesCopy.filter(
    (e) => !!e.endDate && !e.isCurrentRole
  );
  const withoutEndDate = workExperiencesCopy.filter(
    (e) => !e.endDate && !e.isCurrentRole
  );
  const withEndDateSorted =
    sortByDate<WorkExperience>(withEndDate, "endDate") || [];
  const withoutEndDateSorted =
    sortISODate<WorkExperience>(withoutEndDate, "createdAt") || [];
  const sortedByMostRecent = [
    ...sortCurrentRoleWithStartDate,
    ...withEndDateSorted,
    ...withoutEndDateSorted,
  ];
  return sortedByMostRecent;
};

export const sortQualifications = (
  qualifications: Qualification[] | undefined
): Qualification[] | undefined => {
  if (!qualifications) {
    return [];
  }
  const qualificationsCopy = qualifications?.slice() || [];
  const withoutDate = qualificationsCopy.filter(
    (e) => !e.endDate && !!e.isQualificationComplete
  );
  const withEndDate = qualificationsCopy.filter((e) => !!e.endDate);
  const withCompletedDate = qualificationsCopy.filter(
    (e) => !e.isQualificationComplete
  );
  const withEndDateSorted =
    sortByDate<Qualification>(withEndDate, "endDate") || [];
  const withoutEndDateSorted =
    sortISODate<Qualification>(withoutDate, "createdAt") || [];
  const sortedByMostRecent = [
    ...withCompletedDate,
    ...withEndDateSorted,
    ...withoutEndDateSorted,
  ];
  return sortedByMostRecent;
};
