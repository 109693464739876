import { Course } from "./course.models";
import { Resource } from "./resource.models";

// type any is used for the Sanity type Block which cannot be imported
export interface SanityAssessment {
  title: string;
  name: string;
  id: string;
  baseId: string;
  type: string;
  badge: string;
  shortDescription: string;
  time?: number;
  contentTitle: string;
  contentDescription: any;
  description: any;
  assessmentInfo: any;
  featureImage: any;
  items: SanityAssessmentItem[];
  resultsTitle: string;
  resultsUnderstanding: any;
  resultsInformation: any;
  retakeUnderstanding: any;
  languageCode: string;
  isCompleted: boolean;
}

export interface SanityAssessmentItem {
  name: string;
  item: any[]; // Question
  responses: ItemResponse[];
  criteria: SanityAssessmentCriteria;
  __i18n_lang: string;
  _id: string;
}

export interface SanityAssessmentCriteria {
  criterion_id: string;
  description: any;
  name: string;
  title: any;
  score?: number;
}

export interface UserAssessmentResponse {
  latestCompletedAssessment: UserCompletedAssessment | null;
  incompleteAssessment: UserIncompleteAssessment | null;
}

export interface UserCompletedAssessment {
  id: string;
  updatedAt: string;
  createdAt: string;
  participant: string;
  baseSanityId: string;
  sanityResourceName: string;
  languageCode: string;
  completedDate: string;
  startedDate: string;
  totalScore: { [key: string]: number };
  recommendedContent: GetAssessmentRecommendationResponse[];
  items: UserAssessmentItem[];
}

export interface UserIncompleteAssessment {
  id: string;
  updatedAt: string;
  createdAt: string;
  participant: string;
  baseSanityId: string;
  sanityResourceName: string;
  languageCode: string;
  completedDate: null;
  startedDate: string;
  totalScore: "[]";
  recommendedContent: "[]";
  items: UserAssessmentItem[];
}

/* The Database User's response AssessmentItem model */
export interface UserAssessmentItem {
  id: string;
  createdAt: string;
  updatedAt: string;
  assessment: string;
  baseSanityId: string;
  name: string;
  response: string;
  criterion: string;
  score: string;
  index: number;
}

export interface CreateAssessmentItemDto {
  assessmentId: string;
  baseSanityId: string;
  name: string;
  response: string;
  criterion: string;
  score: number;
  index: number;
}

export interface UpdateAssessmentItemDto {
  assessmentItemId: string;
  response: string;
  criterion: string;
  score: number;
}

export interface CompleteAssessmentDto {
  assessmentId: string;
  completedDate: string;
  totalScore: { [key: string]: number };
  itemBaseSanityId: string;
  itemName: string;
  itemResponse: string;
  itemCriterion: string;
  itemScore: string;
  itemIndex: number;
}

export interface GenerateAssessmentRecommendationsDto {
  assessmentId: string;
  baseSanityId: string;
  totalScore: { [key: string]: number };
}

export interface UserQuestionResponse {
  index: number;
  score: number;
  responseLabel: string;
}

export interface ItemResponse {
  baseSanityId: string;
  criterion: string;
  name: string;
  response?: string;
  score?: number;
}

export interface GetAssessmentResultsResponse {
  createdAt: string;
  updatedAt: string;
  id: string;
  baseSanityId: string;
  sanityResourceName: string;
  languageCode: string;
  completedDate: string;
  startedDate: string;
  totalScore: { [key: string]: number };
  recommendedContent: Array<GetAssessmentRecommendationResponse>;
  participant: string;
}

export interface GetAssessmentRecommendationResponse {
  id: string;
  type: string;
}

export interface AssessmentRecommendation {
  baseSanityId: string;
  type: RecommendationType;
  content: Course & Resource;
}

export enum RecommendationType {
  Course,
  Resource,
}
