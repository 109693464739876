<template>
  <ResumeBase
    :breadcrumbs="[
      {
        route: 'my-licences',
        label: $t('resume.breadcrumbs.licences'),
        type: 'parent',
      },
      {
        label: $t('resume.licencesForm.title'),
      },
    ]"
    mainContentId="resume-licence-detail-main-content"
    :formSaveRetryFunction="saveForm"
    :skipOnRouteBeforeLeave="skipOnRouteBeforeLeave"
    :formDeleteFunction="deleteLicence"
    :triggerDeleteModal="triggerDeleteModal"
    :dismissDeleteModal="dismissDeleteModal"
  >
    <template v-slot:title> {{ $t("resume.licencesForm.title") }} </template>
    <template v-slot:description>
      {{ $t("resume.licencesForm.description") }}
    </template>
    <template v-slot:main-content>
      <form :aria-label="$t('resume.licencesForm.title')">
        <TextInput
          :errorText="errorMessageMap.get('name')"
          :isLoading="resumeStore.isResumeLoading"
          :labelText="$t('resume.licencesForm.name.label')"
          :placeholderText="$t('resume.licencesForm.name.placeholder')"
          :required="true"
          :value="name || ''"
          :errorIdPrefix="errorIdPrefix"
          @onChange="
            (event) => {
              validateName(event);
              setValue('name', event.detail.value);
            }
          "
          data-test="name-input"
          @onBlur="validateName"
          @onUpdate="updateErrorDescription"
        />
        <TextInput
          :isLoading="resumeStore.isResumeLoading"
          :labelText="$t('resume.licencesForm.organisation.label')"
          :placeholderText="$t('resume.licencesForm.organisation.placeholder')"
          :value="issuer || ''"
          @onChange="(event) => setValue('issuer', event.detail.value)"
          data-test="organisation-input"
        />
        <TextInput
          :errorText="errorMessageMap.get('issueDate')"
          :isLoading="resumeStore.isResumeLoading"
          :labelText="$t('resume.licencesForm.issueDate.label')"
          :placeholderText="$t('resume.licencesForm.issueDate.placeholder')"
          :value="issueDate || ''"
          :errorIdPrefix="errorIdPrefix"
          @onChange="(event) => setValue('issueDate', event.detail.value)"
          data-test="issue-date-input"
          @onBlur="validateIssueDate"
          @onUpdate="updateErrorDescription"
        />
        <TextInput
          :errorText="errorMessageMap.get('expiryDate')"
          :isLoading="resumeStore.isResumeLoading"
          :labelText="$t('resume.licencesForm.expiryDate.label')"
          :placeholderText="$t('resume.licencesForm.expiryDate.placeholder')"
          :value="expiryDate || ''"
          id="expiry-date-input"
          :errorIdPrefix="errorIdPrefix"
          @onChange="(event) => setValue('expiryDate', event.detail.value)"
          data-test="expiry-date-input"
          @onBlur="validateExpiryDate"
          @onUpdate="updateErrorDescription"
        />
        <TextAreaInput
          :autogrow="true"
          :errorText="errorMessageMap.get('description')"
          :isLoading="resumeStore.isResumeLoading"
          :labelText="$t('resume.licencesForm.licenceDescription.label')"
          :placeholderText="
            $t('resume.licencesForm.licenceDescription.placeholder')
          "
          :value="description || ''"
          :maxLength="600"
          data-test="description-input"
          @onChange="
            (event) => {
              setValue('description', event.detail.value);
              validateDescription(event);
            }
          "
        />
        <div class="buttons-container">
          <AppButton
            :ariaLabel="$t('resume.licencesForm.saveButton')"
            :isLoading="isFormSaving"
            :label="$t('resume.licencesForm.saveButton')"
            @click="saveForm"
            class="save-form-btn"
            data-test="save-form-btn"
            size="default"
            type="primary"
            :disabled="!isFormSavingEnabled"
            :ariaRoleDescription="saveButtonDescription"
          />
          <AppButton
            :isLoading="isLicenceDeleting"
            :label="$t('resume.licencesForm.deleteButton')"
            @click="triggerDelete"
            ariaLabel="$t('resume.licencesForm.deleteButton')"
            class="delete-form-btn"
            data-test="delete-form-btn"
            size="default"
            type="secondary"
            :disabled="!licenceId"
          />
          <AppButton
            @click="returnToPage"
            :ariaLabel="$t('resume.licencesForm.closeBtnLabel')"
            class="close-form-btn"
            data-test="close-form-btn"
            id="closeBtn"
            :label="$t('resume.licencesForm.closeBtnLabel')"
            size="default"
            type="textOnly"
          />
        </div>
      </form>
    </template>
    <template v-slot:resume-helper> </template>
  </ResumeBase>
</template>
<script setup lang="ts">
import { onMounted, ref } from "vue";
import ResumeBase from "@/Layouts/ResumeBase/ResumeBase.vue";
import AppButton from "@/components/Common/AppButton/AppButton.vue";
import TextInput from "@/components/Common/Inputs/TextInput/TextInput.vue";
import TextAreaInput from "@/components/Common/Inputs/TextAreaInput/TextAreaInput.vue";
import { useResumeStore, useErrorStore } from "@/store";
import { computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { onBeforeRouteLeave } from "vue-router";
import { Licence } from "@/store/models/resume.models";
import { useI18n } from "vue-i18n";
import { handleBeforeUnload, focusOnMainContent } from "@/helpers";
import {
  collateErrorsInDescription,
  parseDate,
  validateDateFormat,
} from "@/helpers";
import { IonInputCustomEvent, IonInput } from "@ionic/core/components";
import { onIonViewDidEnter } from "@ionic/vue";
const { t } = useI18n<{}>({
  useScope: "global",
});
const router = useRouter();
const route = useRoute();
const isFormSaving = ref(false);
const isLicenceDeleting = ref(false);
const skipOnRouteBeforeLeave = ref(false);
const triggerDeleteModal = ref(false);

const resumeStore = useResumeStore();
const errorStore = useErrorStore();

onBeforeRouteLeave(() => {
  resumeStore.setLicenceFormInit(false);
  window.removeEventListener("beforeunload", handleBeforeUnload);
});

onMounted(async () => {
  if (!resumeStore.resumeId) {
    await resumeStore.requestResume();
    if (!resumeStore.resumeId) {
      await resumeStore.createResume();
    }
  }
  const licenceId = router.currentRoute.value.params.id as string;
  licenceId !== "new"
    ? resumeStore.initLicenceForm(licenceId)
    : resumeStore.initLicenceForm();
  initValidationMap();
});

onIonViewDidEnter(() => {
  focusOnMainContent(route?.meta?.mainContentId);
  skipOnRouteBeforeLeave.value = false;
  window.addEventListener("beforeunload", handleBeforeUnload);
});

const setValue = <K extends keyof Licence>(
  property: K,
  value: Licence[K] | null | undefined
) => {
  if (value == null || !property) return;
  resumeStore.setLicenceFormValue(property, value);
};
const licenceId = computed(() => {
  return resumeStore.licenceFormData?.id;
});
const name = computed(() => {
  return resumeStore.licenceFormData?.name;
});
const issuer = computed(() => {
  return resumeStore.licenceFormData?.issuer;
});
const issueDate = computed(() => {
  return resumeStore.licenceFormData?.issueDate;
});
const expiryDate = computed(() => {
  return resumeStore.licenceFormData?.expiryDate;
});
const description = computed(() => {
  return resumeStore.licenceFormData?.description;
});

const saveForm = async () => {
  skipOnRouteBeforeLeave.value = true;
  errorStore.setSaveFormError(false);
  try {
    isFormSaving.value = true;
    if (licenceId.value) {
      await resumeStore.updateLicence(licenceId.value);
    } else {
      await resumeStore.createLicence();
      await resumeStore.updateResumeProperty("isLicencesCompleted", true);
    }
    router.push({
      name: "ResumeLicences",
    });
  } catch (error) {
    console.error("An error occured saving the form: ", error);
    errorStore.setSaveFormError(true);
  } finally {
    isFormSaving.value = false;
  }
};

const isFormSavingEnabled = computed(() => {
  for (let isValid of validationMap.value.values()) {
    if (!isValid) return false;
  }
  return true;
});

const triggerDelete = () => {
  triggerDeleteModal.value = true;
};

const deleteLicence = async () => {
  try {
    skipOnRouteBeforeLeave.value = true;
    isLicenceDeleting.value = true;
    if (licenceId.value) {
      await resumeStore.deleteLicence(licenceId.value);
    } else {
      throw new Error("Something went wrong");
    }
    router.push({
      name: "ResumeLicences",
    });
  } finally {
    isLicenceDeleting.value = false;
    triggerDeleteModal.value = false;
  }
};

const dismissDeleteModal = () => {
  triggerDeleteModal.value = false;
};

const errorIdPrefix = ref("error-message-disabler");
const saveButtonDescription = ref("");
const updateErrorDescription = () => {
  saveButtonDescription.value = collateErrorsInDescription(
    document,
    errorIdPrefix.value
  );
};

const returnToPage = () => {
  router.push({
    name: "ResumeLicences",
  });
};

/* VALIDATION */
const rules = {
  name: {
    required: t("resume.licencesForm.name.isRequiredError"),
  },
  issueDate: {
    invalidFormat: t("resume.licencesForm.issueDate.invalidFormatError"),
    noFutureDate: t("resume.licencesForm.issueDate.noFutureDateError"),
  },
  expiryDate: {
    invalidFormat: t("resume.licencesForm.expiryDate.invalidFormatError"),
    noBeforeIssueDate: t(
      "resume.licencesForm.expiryDate.noBeforeIssueDateError"
    ),
  },
  description: {
    characterLimit: t(
      "resume.licencesForm.licenceDescription.characterLimitError"
    ),
  },
};
const validationMap = ref<Map<keyof Licence, boolean>>(new Map([]));

const initValidationMap = () => {
  validationMap.value
    .set("name", name.value !== undefined && name.value.length > 0)
    .set("issueDate", true)
    .set("expiryDate", true);
};

const errorMessageMap = ref<Map<keyof Licence, string>>(
  new Map([
    ["name", ""],
    ["issueDate", ""],
    ["expiryDate", ""],
    ["description", ""],
  ])
);

const setValidity = (
  property: keyof Licence,
  valid: boolean,
  error?: string
): void => {
  validationMap.value.set(property, valid);
  errorMessageMap.value.set(property, error ?? "");
  return;
};

const validateName = (event: IonInputCustomEvent<FocusEvent>) => {
  const itemEl = event.target.parentElement;
  itemEl?.classList.remove("ion-invalid");
  const name = event.target.value;
  if (!name) {
    itemEl?.classList.add("ion-invalid");
    setValidity("name", false, rules.name.required);
  } else {
    setValidity("name", true);
  }
};

const validateDescription = (event: IonInputCustomEvent<FocusEvent>) => {
  const itemEl = event.target.parentElement;
  itemEl?.classList.remove("ion-invalid");
  const description = event.target.value as string;
  if (description && description.length >= 600) {
    itemEl?.classList.add("ion-invalid");
    errorMessageMap.value.set("description", rules.description.characterLimit);
  }
};

const validateIssueDate = (event: IonInputCustomEvent<FocusEvent>) => {
  const itemEl = event.target.parentElement;
  itemEl?.classList.remove("ion-invalid");
  const date = event.target.value as string;
  if (!date) {
    setValidity("issueDate", true);
    return;
  }

  const isValidFormat = validateDateFormat(date);
  if (!isValidFormat) {
    itemEl?.classList.add("ion-invalid");
    setValidity("issueDate", false, rules.issueDate.invalidFormat);
    return;
  }

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const inputDate = parseDate(date);
  if (inputDate && inputDate > today) {
    itemEl?.classList.add("ion-invalid");
    setValidity("issueDate", false, rules.issueDate.noFutureDate);
  } else {
    setValidity("issueDate", true);
  }

  // This is required to dynamically validate the expiry date when the issue date changes
  const expiryDateElement = document.querySelector(
    "#expiry-date-input .input-field"
  ) as unknown as IonInput;
  if (expiryDateElement) {
    expiryDateElement.setFocus();
    expiryDateElement.setBlur();
  }
};

const validateExpiryDate = (event: IonInputCustomEvent<FocusEvent>) => {
  const itemEl = event.target.parentElement;
  itemEl?.classList.remove("ion-invalid");
  const date = event.target.value as string;
  if (!date) {
    setValidity("expiryDate", true);
    return;
  }

  const isValidFormat = validateDateFormat(date);
  if (!isValidFormat) {
    itemEl?.classList.add("ion-invalid");
    errorMessageMap.value.set("expiryDate", rules.expiryDate.invalidFormat);
    return;
  }
  if (!issueDate.value) {
    setValidity("expiryDate", true);
    return;
  }
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const parsedIssueDate = parseDate(issueDate.value);
  const inputDate = parseDate(date);

  if (inputDate && parsedIssueDate && inputDate < parsedIssueDate) {
    itemEl?.classList.add("ion-invalid");
    setValidity("expiryDate", false, rules.expiryDate.noBeforeIssueDate);
  } else {
    setValidity("expiryDate", true);
  }
};
</script>
<style lang="scss" scoped>
.text-input {
  margin-bottom: var(--spacing-24);
}
.buttons-container {
  max-width: 312px;
  display: flex;
  margin: auto;
  flex-direction: column;
  gap: var(--spacing-16);
  margin-top: var(--spacing-48);
}
</style>
