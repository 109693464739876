<template>
  <ion-page>
    <MobileHeader></MobileHeader>
    <ion-content>
      <DesktopLocaleSelector></DesktopLocaleSelector>
      <div class="page-wrapper" id="collection-home-main-content" tabindex="0">
        <Breadcrumbs tab="learn-home" parentType="collection"></Breadcrumbs>
        <div class="title-wrapper">
          <h4 class="page-title">{{ $t("collection.title") }}</h4>
          <p class="page-description">
            {{ $t("collection.description") }}
          </p>
        </div>

        <div class="card-holder" data-test="card-holder-collection">
          <div
            class="card-holder loading-holder"
            v-if="store.getLoadingFor('collection')"
          >
            <CardSkeleton
              class="content-pack-card"
              v-for="index in 3"
              :key="index"
            ></CardSkeleton>
          </div>
          <router-link
            v-for="item in store.getDocumentsByType('collection')"
            :to="{ name: 'CollectionDetail', params: { id: item.base_id } }"
            :key="item.base_id"
            class="collection-wrapper"
          >
            <CourseOrCollectionCard
              data-test="collection-card"
              class="collection-card"
              type="collection"
              :title="item.title"
              :description="item.description"
              :resourceType="[
                ...new Set(item.topics.map((element) => element.resource_type)),
              ]"
              :thumbnail="this.parseImageUrl(item.feature_image, 500, 500)"
              :placeholderImg="
                require('@/assets/collection-thumbnail-placeholder.png')
              "
            ></CourseOrCollectionCard>
          </router-link>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import CourseOrCollectionCard from "@/components/CourseOrCollectionCard/CourseOrCollectionCard.vue";
import MobileHeader from "@/components/Common/MobileHeader/MobileHeader.vue";
import CardSkeleton from "@/components/Skeletons/CardSkeleton/CardSkeleton.vue";
import { useSanityStore } from "@/store";
import { RouterLink } from "vue-router";
import Breadcrumbs from "@/components/Common/Breadcrumbs/Breadcrumbs.vue";
import { parseImageUrl } from "@/helpers";
import DesktopLocaleSelector from "@/components/Common/LocaleSelector/DesktopLocaleSelector.vue";

export default {
  name: "collection-home",
  components: {
    IonPage,
    IonContent,
    MobileHeader,
    CourseOrCollectionCard,
    RouterLink,
    Breadcrumbs,
    CardSkeleton,
    DesktopLocaleSelector,
  },
  data() {
    return {};
  },
  setup() {
    const store = useSanityStore();
    return { store };
  },
  mounted() {
    this.store.requestQuery("collection");
  },
  methods: {
    parseImageUrl,
  },
};
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";
.page-wrapper {
  box-shadow: var(--shadow-0);
  border-radius: 0;
}
.page-title {
  margin-bottom: 22px;
}
.title-wrapper {
  text-align: left;
}

.collection-wrapper {
  border-radius: 16px;
  margin-bottom: 12px;
  margin-right: 16px;
  transition: box-shadow var(--transition-time);
  text-decoration: none;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1), 6px 6px 0px #ffffff,
    8px 8px 3px rgba(0, 0, 0, 0.1), 12px 12px 0px #ffffff,
    14px 15px 2px rgba(0, 0, 0, 0.1);
  &:hover {
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1), 6px 6px 0px #ffffff,
      8px 8px 3px rgba(0, 0, 0, 0.1), 12px 12px 0px #ffffff,
      18px 18px 14px rgba(0, 0, 0, 0.2);
  }
  &:focus-visible,
  &:focus {
    border-radius: 16px !important;
    outline-color: var(--ion-color-secondary);
    overflow: unset !important;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1), 6px 6px 0px #ffffff,
      8px 8px 3px rgba(0, 0, 0, 0.1), 12px 12px 0px #ffffff,
      14px 15px 2px rgba(0, 0, 0, 0.1);
  }
  &:active {
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1), 6px 6px 0px #ffffff,
      8px 8px 3px rgba(0, 0, 0, 0.1), 12px 12px 0px #ffffff,
      14px 15px 2px rgba(0, 0, 0, 0.1);
  }
}

.collection-card {
  padding: 0px;
  margin: 0px;
  width: 302px;
  height: 300px;
  left: 0px;
  top: 0px;
  background: var(--background-white);
  border-radius: 16px;
  box-shadow: var(--shadow-0);
}
.card-holder {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  gap: 18px;
  & a {
    text-decoration: none;
  }
}

// handles focus on safari
*:focus {
  outline-width: thin;
  overflow: hidden;
  outline-color: var(--ion-color-secondary);
}

//Desktop
@include breakpoint(medium) {
  .page-wrapper {
    padding-left: 82px;
  }
}
</style>
