<template>
  <ion-modal
    ref="modal"
    :is-open="hasErrored"
    :backdrop-dismiss="false"
    @didPresent="setChatIframeVisibility('none')"
    @didDismiss="dismissHandler"
    aria-labelledby="error-title"
    aria-describedby="error-body"
    aria-busy="false"
    role="dialog"
  >
    <div class="content-wrapper">
      <div class="image-wrapper"></div>
      <div class="text-wrapper">
        <ion-title
          id="error-title"
          class="title"
          size="small"
          role="heading"
          aria-level="2"
          >{{ title }}</ion-title
        >
        <p id="error-body" class="body">{{ body }}</p>
      </div>
      <div class="button-wrapper">
        <AppButton
          data-test="primary-button"
          type="primary"
          size="default"
          width="100%"
          :label="primaryButton.label"
          :ariaLabel="primaryButton.label"
          :icon="primaryButton.icon"
          iconPosition="end"
          @click="emit('onPrimaryButtonClick')"
        >
        </AppButton>
        <AppButton
          v-if="secondaryButton"
          data-test="secondary-button"
          size="default"
          width="100%"
          type="secondary"
          :label="secondaryButton.label"
          :ariaLabel="secondaryButton.label"
          :icon="secondaryButton.icon"
          iconPosition="end"
          @click="emit('onSecondaryButtonClick')"
        >
        </AppButton>
      </div>
    </div>
  </ion-modal>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, withDefaults, ref, computed } from "vue";
import { IonModal, IonTitle } from "@ionic/vue";
import AppButton from "../AppButton/AppButton.vue";
import { setChatIframeVisibility } from "@/helpers";
import { useI18n } from "vue-i18n";

interface Props {
  title?: string;
  body?: string;
  hasErrored?: boolean;
  imageKey?: imageKey;
  primaryButton?: { label: string; icon?: string };
  secondaryButton?: { label: string; icon?: string };
}

const props = withDefaults(defineProps<Props>(), {
  title: () => useI18n().t("error.title"),
  body: () => useI18n().t("error.body"),
  primaryButton: () => ({
    label: useI18n().t("navigation.reloadPage"),
  }),
  secondaryButton: () => ({
    label: useI18n().t("navigation.back"),
  }),
  imageKey: "confusedCharacter",
  hasErrored: false,
});
const modal = ref(null);
const emit = defineEmits([
  "onDismiss",
  "onPrimaryButtonClick",
  "onSecondaryButtonClick",
]);

const dismissHandler = () => {
  setChatIframeVisibility("block");
  emit("onDismiss");
};

type imageKey = "confusedCharacter";
const confusedCharacterImage = require("@/assets/em-platformGuide-confused.svg");
const imageMap: Map<imageKey, any> = new Map([
  ["confusedCharacter", confusedCharacterImage],
]);

const imageUrl = computed(() => {
  return "url(" + imageMap.get(props.imageKey) + ")";
});
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";
/* T&C's modal */
ion-modal {
  --height: auto;
  --width: 100%;
  --border-radius: 24px;
  --background: var(--background-white);
  display: flex;
  --max-width: 312px;
  @include breakpoint(medium) {
    --max-width: 404px;
  }
  /* Galaxy fold device */
  @include breakpoint(xxsmall) {
    padding-left: 12px;
    padding-right: 12px;
  }
}
.content-wrapper {
  padding: var(--spacing-24);
}
.image-wrapper {
  background: v-bind(imageUrl);
  height: 215px;
  width: 180px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: relative;
  margin: auto;
  margin-bottom: 24px;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 87%, 0% 100%);
  clip-path: polygon(
    50% 0%,
    100% 0,
    100% 43%,
    100% 96%,
    68% 98%,
    32% 98%,
    0 96%,
    0% 43%,
    0 0
  );
  top: 25px;
}
.button-wrapper {
  display: flex;
  flex-direction: column;
  place-content: center;
  gap: 16px;
}
ion-button.button-round {
  width: 40px;
  height: 40px;
  float: right;
  margin-right: -8px;
}
.text-wrapper {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-24) var(--spacing-0);
  gap: var(--spacing-8);
  text-align: center;
}
.body {
  margin: var(--spacing-0);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: var(--text-secondary);
}
.title {
  padding: var(--spacing-0);
  margin: var(--spacing-0);
  letter-spacing: 0.012em;
  line-height: 32px;
  font-size: 24px;
  color: var(--text-primary);
}
</style>
