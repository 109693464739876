// This function is called when a swiper card within a carousel is focused.
// The current swiper version does not automatically slide partially visible slides
// and so the below method uses the slideTo() method to achieve the behaviour.
export function onSwiperCardFocus(index, swiper) {
  const navDisabled =
    swiper.params.navigation.nextEl.classList.contains("swiper-button-lock") &&
    swiper.params.navigation.prevEL.classList.contains("swiper-button-lock");
  // This prevents the slideTo() from being called when all cards are visible within the current view
  if (!navDisabled) {
    const visibleSlides = swiper.visibleSlides.length;
    const focusedSlideIndex = index - Math.floor(visibleSlides / 3);
    swiper.slideTo(focusedSlideIndex);
  }
}
