<template>
  <SignInHomePage />
</template>

<script>
import SignInHomePage from "@/views/SignInHome/SignInHome.vue";

export default {
  name: "App",
  components: {
    SignInHomePage,
  },
};
</script>
