<template>
  <CardPortraitSkeleton
    v-if="!isLoaded"
    data-test="card-portrait-skeleton"
  ></CardPortraitSkeleton>
  <div v-else>
    <router-link
      class="router-link"
      :to="{ name: url }"
      data-test="router-link"
    >
      <ion-card>
        <ion-card-header>
          <div
            v-if="cardProps.icon"
            class="icon-background"
            data-test="card-icon-thumbnail"
          >
            <ion-icon
              :icon="cardProps.icon"
              aria-hidden="true"
              data-test="card-icon"
            ></ion-icon>
          </div>
          <img v-else :src="featureImage" alt="" data-test="card-thumbnail" />
        </ion-card-header>
        <div class="status-badge-wrapper">
          <ion-badge
            v-if="statusBadge"
            class="status-badge complete-label"
            data-test="status-badge"
          >
            <span>
              <p class="title" data-test="status-badge">
                {{ statusBadge }}
              </p>
            </span>
          </ion-badge>
        </div>
        <ion-card-content>
          <h3 class="title" data-test="card-title">
            {{ props.content.title }}
          </h3>
          <p class="description" data-test="card-description">
            {{ props.content.description }}
          </p>
          <div class="badge-wrapper" v-if="timeBadge || typeBadge">
            <ion-badge
              class="type-badge"
              color="secondary"
              v-if="typeBadge"
              data-test="type-badge"
            >
              {{ typeBadge }}
            </ion-badge>
            <ion-badge
              class="time-badge"
              v-if="timeBadge"
              data-test="time-badge"
            >
              {{ timeBadge + " " + $t("resource.readingTimeMins") }}
            </ion-badge>
          </div>
        </ion-card-content>
      </ion-card>
    </router-link>
  </div>
</template>
<script setup lang="ts">
import { defineProps, computed } from "vue";
import {
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonIcon,
  IonBadge,
} from "@ionic/vue";
import { CardContent } from "@/store/models/card.models";
import { SanityAssessment } from "@/store/models/assessment.models";
import { parseImageUrl } from "@/helpers";
import { RouterLink } from "vue-router";
import CardPortraitSkeleton from "@/components/Skeletons/CardPortraitSkeleton/CardPortraitSkeleton.vue";
interface Props {
  typeBadge?: string;
  timeBadge?: number;
  statusBadge?: string;
  content: CardContent | SanityAssessment;
  url: string;
  isLoaded: boolean;
}
const props = defineProps<Props>();

const cardProps = computed(() => {
  return props.content as CardContent;
});

const featureImage = computed(() => {
  return props.content.featureImage
    ? parseImageUrl(props.content.featureImage, 300, 300)
    : require("@/assets/course-thumbnail-placeholder.png");
});
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";
ion-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 284px;
  height: 340px;
  margin: var(--spacing-0);
  background-color: var(--background-white);
  border: 2px solid var(--light-grey-border);
  border-radius: 24px;
  box-shadow: none;

  &:hover {
    box-shadow: 0px 7px 8px 0px var(--shadow-color-3),
      0px 5px 22px 0px var(--shadow-color-1),
      0px 12px 17px 0px var(--shadow-color-2);
  }
}

ion-card-header {
  padding: var(--spacing-0);
  width: 100%;
  height: fit-content;
}
ion-card-content {
  padding: var(--spacing-16);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.icon-background {
  background: var(--ion-color-secondary-hover);
  border-radius: 0px 0px 24px 24px;
  height: 153px; //the height is set in the child as the header grows to fit content
  width: 100%;
  padding: 15px 87px;
  display: flex;
  justify-content: center;
  align-items: center;
  ion-icon {
    color: var(--ion-color-primary);
    font-size: 88px;
  }
}
.status-badge-wrapper {
  margin-top: calc(var(--spacing-12) * -1);
  padding-left: var(--spacing-16);
  padding-right: var(--spacing-16);
  height: 24px;
  min-height: 24px;
  width: 100%;
  z-index: 5;
}
/* TODO: Temporary, delete this and make a general badge component */
.status-badge {
  width: fit-content;
  padding: var(--spacing-2) var(--spacing-8) var(--spacing-2) var(--spacing-8);
  border-color: var(--ion-color-primary-banner-bg);
}
.title {
  /* Title/Medium/em-fb-title-700--medium */
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.012em;
  color: var(--text-black);
  text-overflow: ellipsis;
  text-align: left;
  margin-top: var(--spacing-0);
  margin-bottom: var(--spacing-8);
  /* Truncate settings */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.description {
  /* Body/Regular/em-fb-body-700 */
  margin: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: var(--dark-grey);
  text-overflow: ellipsis;
  text-align: left;
  /* Truncate settings */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  margin-bottom: auto;
}
img {
  width: 100%;
  height: 153px;
  object-fit: cover;
  border-radius: 0px 0px 24px 24px;
}
.time-badge {
  background-color: var(--light-grey);
  color: var(--text-black);
}
.router-link {
  text-decoration: unset;
  display: inherit;
  width: fit-content;

  //Override vue router link focus css
  &:focus-visible,
  &:focus {
    outline: none;
    ion-card {
      border: 2px solid var(--ion-color-secondary);
      border-radius: 24px;
    }
  }

  &:active {
    ion-card {
      border-color: var(--light-grey-border);
      box-shadow: unset;
    }
  }
}
</style>
<!-- This is to override the stylings from the SanityBlocks component -->
<style lang="scss"></style>
