<template>
  <div
    v-if="assessment.resultsInformation"
    class="results-information"
    data-test="results-information"
  >
    <SanityBlocks :blocks="assessment.resultsInformation" />
  </div>
  <h2 class="page-title" aria-level="2" data-test="title">
    {{ assessment.resultsTitle }}
  </h2>
  <div
    v-if="assessment.resultsUnderstanding"
    class="results-understanding"
    data-test="results-understanding"
  >
    <SanityBlocks :blocks="assessment.resultsUnderstanding" />
  </div>

  <ion-accordion-group
    :multiple="true"
    class="results-accordion-group"
    data-test="results-accordion-group"
  >
    <ion-accordion
      v-for="result in assessmentResults.criteria"
      :key="result.criterion_id"
      data-test="results-accordion"
    >
      <ion-item slot="header">
        <h3 class="accoridon-title" data-test="results-label">
          {{ result.title }}: {{ result.score }}
        </h3>
      </ion-item>
      <div slot="content" data-test="results-content">
        <SanityBlocks :blocks="result.description" />
      </div>
    </ion-accordion>
  </ion-accordion-group>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { IonAccordion, IonAccordionGroup, IonItem } from "@ionic/vue";
import { SanityBlocks } from "sanity-blocks-vue-component";
import { useAssessmentStore } from "@/store";

const assessmentStore = useAssessmentStore();

const assessment = computed(() => {
  return assessmentStore.getAssessmentDetail;
});

const assessmentResults = computed(() => {
  return assessmentStore.getAssessmentResults;
});
</script>

<style lang="scss" scoped>
.results-information {
  color: var(--button-text-primary);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.page-title {
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
  color: var(--button-text-primary);
  margin: 48px 0 16px;
}

.results-understanding {
  color: var(--ion-color-darkgrey);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.results-accordion-group {
  margin: 48px 0 0;
}

ion-accordion {
  ion-item {
    overflow: visible;

    .accoridon-title {
      color: var(--button-text-primary);
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.027px;
      margin-left: -8px;
      margin-top: 18px;
    }

    ion-icon {
      &::part(end) {
        margin-right: -7px;
      }
    }

    &:hover {
      background-color: var(--grey-25);
      border-bottom: solid 1px var(--light-grey);
    }

    &:active {
      background-color: var(--grey-100);
    }
  }

  &::part(content) {
    color: var(--ion-color-dark-grey);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px !important;
    margin-left: 8px;
    margin-right: 8px;
  }
}

ion-accordion-group > ion-accordion:last-of-type ion-item[slot="header"] {
  --border-width: 0 0 1px;
}
</style>
