import { defineStore } from "pinia";
import {
  AppConfigurationClient,
  parseFeatureFlag,
} from "@azure/app-configuration";
import EnvironmentConfig from "@/EnvironmentConfig";

export const useFeatureFlagStore = defineStore({
  id: "feature-flag",
  state: () => ({
    isInitialized: false,
    featureFlags: [],
    client: null,
  }),
  actions: {
    async initialize() {
      if (this.isInitialized) {
        return;
      }
      const connectionString = EnvironmentConfig.FeatureFlag.ConnectionString;

      try {
        if (connectionString) {
          this.client = new AppConfigurationClient(connectionString);
        }
        if (!this.client) {
          console.error(
            "featureFlagStore: AppConfigurationClient is not initialized "
          );
          // Allow feature flags to be used but fallback to defaultFlags.
          this.isInitialized = true;
        } else {
          await this.fetchAllFeatureFlags();
          this.isInitialized = true;
        }
      } catch (error) {
        console.error(
          "featureFlagStore: Error initialising feature flags",
          error
        );
      }
    },

    async fetchAllFeatureFlags() {
      if (!this.client) {
        return;
      }
      try {
        const featureFlags = this.client.listConfigurationSettings({
          keyFilter: ".appconfig.featureflag*",
          labelFilter: EnvironmentConfig.FeatureFlag.Environment,
        });

        this.featureFlags = []; // Initialize the array to avoid duplicates

        for await (const featureFlag of featureFlags) {
          const parsedFeatureFlag = parseFeatureFlag(featureFlag);
          this.featureFlags.push(parsedFeatureFlag);
        }
      } catch (error) {
        console.error("Error fetching or parsing feature flags:", error);
        throw error;
      }
    },
  },
  getters: {
    isFlagEnabled:
      (state) =>
      (flagKey, defaultFlag = false) => {
        if (!state.isInitialized) {
          console.error(
            "featureFlagStore: Getting isFlagEnabled when store not initialised."
          );
        }
        flagKey = ".appconfig.featureflag/" + flagKey;
        const featureFlag = state.featureFlags.find(
          (flag) => flag.key === flagKey
        );
        if (featureFlag) {
          return featureFlag.value.enabled;
        } else {
          // Fallback to defaultFlag
          console.error(`Unable to find feature flag with key: ${flagKey}`);
          return defaultFlag;
        }
      },
  },
});
