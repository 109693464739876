<template>
  <a :href="href" target="blank_" rel="nofollow noopener"><slot /></a>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
    },
  },
};
</script>
