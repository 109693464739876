export default {
  "verificationPage": {
    "regionLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APM Employable Me Logo"])}
  },
  "signInHome": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
    "welcome1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue à"])},
    "welcome2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Employable Me"])},
    "phonePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+61 411 223 344"])},
    "signInCta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur Se connecter pour accéder à votre compte."])},
    "t&c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termes et conditions"])},
    "signInStaff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous pour le personnel"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])},
    "footerCredits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construit par le groupe APM"])}
  },
  "tabs": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
    "learn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apprendre"])},
    "tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outils"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])}
  },
  "pageTitles": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
    "learn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apprendre et Grandir"])},
    "jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobs"])},
    "tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outils"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "t&c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termes et conditions"])}
  },
  "dashboard": {
    "contentBannerWelcomeText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Salut <b>", _interpolate(_named("name")), "</b>, bienvenue à Employable Me. Commencez par explorer Apprendre et Grandir."])},
    "callToActionTitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorez"])},
    "callToActionTitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apprendre et Grandir"])},
    "callToActionBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développez vos compétences et vos connaissances en complétant des activités et des cours."])},
    "callToActionButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez à apprendre"])},
    "continueCourseInProgressButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
    "courseProgressText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("completedResource")), " of ", _interpolate(_named("totalResource")), " activités terminées"])},
    "courseInProgressTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer l'apprentissage"])},
    "courseInProgressDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Vous avez ", _interpolate(_named("count")), " cours actuellement en cours."]), _normalize(["Vous avez ", _interpolate(_named("count")), " cours actuellement en cours."])])}
  },
  "loadingScreen": {
    "poweredBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alimenté par"])},
    "toastSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous avec succès"])},
    "toastError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous a échoué, veuillez réessayer."])}
  },
  "categoryCard": {
    "courseBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cours"])},
    "coursesBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cours"])},
    "collectionBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["collection"])},
    "collectionsBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["collections"])}
  },
  "statusLabel": {
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminé"])},
    "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])}
  },
  "learnHome": {
    "bannerWelcomeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue à Apprendre et Grandir. Développez vos compétences et vos connaissances avec les activités, les collections et les cours ci-dessous."])},
    "categoryTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcourir le contenu"])},
    "courseTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorez les cours"])},
    "collectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorez les collections"])},
    "seeAllContentLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir tout"])}
  },
  "course": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cours"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cours"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cours offrent une gamme d'activités pour développer vos compétences et vos connaissances autour de divers sujets."])},
    "courseComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cours terminé"])},
    "courseInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cours en cours"])},
    "activitiesCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activités terminées"])},
    "activitiesToComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités à compléter"])}
  },
  "collection": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collections"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les collections contiennent des ensembles d'activités similaires que vous pouvez explorer à votre propre rythme."])}
  },
  "resource": {
    "activityCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité terminée"])},
    "activityInComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marquer comme terminé"])},
    "video": {
      "transcriptExpanded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masquer la transcription de la vidéo"])},
      "transcriptCollapsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir la transcription de la vidéo"])}
    },
    "activity": {
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prochaine activité"])},
      "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédent"])},
      "alternativeExpanded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masquer l'activité alternative"])},
      "alternativeCollapsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir l'activité alternative"])}
    },
    "interactive": {
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bouton"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien"])}
    },
    "readingTimeMins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mins"])},
    "types": {
      "Article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article"])},
      "Video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vidéo"])},
      "Game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeu"])},
      "Interactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interactif"])},
      "Checklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste de contrôle"])},
      "Audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio"])},
      "Quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])}
    }
  },
  "info": {
    "profileTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du profil"])},
    "siteTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du site"])},
    "consultantTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du consultant"])}
  },
  "TermsAndConditionsModal": {
    "acceptButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter et continuer"])},
    "signOutButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])}
  },
  "signout": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])},
    "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sur le point de vous déconnecter"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir le faire?"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])}
  },
  "navigation": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourne"])},
    "reloadPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réessayer"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner à l'accueil"])}
  },
  "error": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, cette page n'a pas chargé correctement"])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essayez de recharger cette page ou revenez à la page précédente."])},
    "supportText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez de la difficulté à trouver ce dont vous avez besoin? Pour obtenir de l'aide, contactez le support technique à l'adresse"])},
    "pageNotFound": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page introuvable"])},
      "friendlyText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oups, la page que vous cherchiez n'a pas pu être trouvée"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, le lien vers cette page peut être incorrect."])},
      "solutionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez que vous avez le bon lien ou retournez à l'accueil pour essayer de trouver ce que vous cherchiez."])}
    }
  },
  "privacyPolicyUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://wcgservices.com/privacy-policy"])},
  "supportEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["support", "@", "employableme.ca"])},
  "locale": {
    "popoverTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez une langue"])}
  },
  "b2cSignIn": {
    "signInTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
    "verificationCodeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code de vérification"])},
    "disclaimerHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez de la difficulté à vous connecter?"])},
    "disclaimerContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour obtenir de l'aide, contactez le support technique à:"])}
  },
  "keyboardShortcuts": {
    "menuTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passer à:"])},
    "mainContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenu principal"])}
  },
  "breadcrumb": {
    "showMoreBreadcrumbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher plus de hiérarchie de navigation"])}
  },
  "tools": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outils"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma découverte de soi"])},
    "pageDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorons davantage vos intérêts professionnels, vos valeurs et votre bien-être avec ces quiz."])},
    "pageTitleEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oups, la page des outils est indisponible."])},
    "pageDescriptionEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous disposons d'une gamme d'autres ressources pour vous soutenir. Retournez à la page d'accueil pour les explorer."])},
    "returnHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour Accueil"])},
    "bannerWelcomeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ces outils et quiz peuvent aider vous à differentes phases de votre carrière."])}
  },
  "assessment": {
    "aboutAccordion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À propos de ce quiz"])},
    "startQuiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démarrer le quiz"])},
    "continueQuiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reprendre le quiz"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
    "mins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mins"])},
    "questionNumber": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Question ", _interpolate(_named("index")), " de ", _interpolate(_named("count"))])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédent"])},
    "completeQuiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz complet"])},
    "selectYourResponse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez votre réponse (obligatoire)"])},
    "returnToQuiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour au quiz"])},
    "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitter"])},
    "quizCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz terminé"])},
    "lastCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière fois terminé"])},
    "lessThan1MinuteAgo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a moins de 1 minute"])},
    "minuteAgo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["il y a quelques minutes"])},
    "minutesAgo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["il y a quelques minutes"])},
    "hourAgo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["il y a une heure"])},
    "hoursAgo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["il y a quelques heures"])},
    "dayAgo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["il y a un jour"])},
    "daysAgo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["il y a quelques jours"])},
    "weekAgo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["il y a une semaine"])},
    "weeksAgo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["il y a quelques semaines"])},
    "monthAgo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mois il y a"])},
    "monthsAgo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["il y a quelques mois"])},
    "yearAgo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["il y a un an"])},
    "yearsAgo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["il y a des années"])},
    "retake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refaire le"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reprendre le"])}
  },
  "assessmentExitWarningModal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz de sortie"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En laissant le quiz maintenant sans le compléter, vos réponses seront perdues. Voulez-vous sortir ?"])}
  },
  "assessmentSavingInProgressModal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrement de votre réponse"])}
  },
  "resume": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes outils de carrière"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préparez-vous pour les candidatures d'emploi en utilisant ce créateur de CV."])},
    "resumeCard": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créateur de CV"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez et téléchargez un CV professionnel que vous pouvez utiliser pour postuler à des emplois."])},
      "badgeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À faire"])}
    },
    "overview": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon CV"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez votre CV en saisissant vos coordonnées dans les sections ci-dessous."])},
      "downloadMyResume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger mon CV"])}
    },
    "resumeContentCard": {
      "contactDetails": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes coordonnées"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez vos coordonnées et ajoutez éventuellement une URL LinkedIn."])},
        "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter mes coordonnées"])},
        "editButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier mes coordonnées"])}
      },
      "experience": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon expérience"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre historique professionnel. Incluez tout travail bénévole ou autre expérience que vous souhaitez inclure dans votre CV."])},
        "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter mon expérience"])},
        "editButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier mon expérience"])}
      },
      "qualifications": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes qualifications"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez les certificats, formations, diplômes ou cours que vous avez suivis ou que vous êtes en train de suivre."])},
        "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter mes qualifications"])},
        "editButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier mes qualifications"])}
      },
      "licences": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes licences"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez toutes les licences, vérifications ou contraventions que vous avez."])},
        "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter mes licences"])},
        "editButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier mes licences"])}
      },
      "personalStatement": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma déclaration de profil"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rédigez une brève introduction vous concernant, vos compétences, vos points forts ou vos objectifs de carrière."])},
        "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter ma déclaration de profil"])},
        "editButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier ma déclaration de profil"])}
      }
    },
    "breadcrumbs": {
      "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon CV"])},
      "contactDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coordonnées-de-contact"])},
      "workExperiences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon expérience"])},
      "workExperienceDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expérience"])},
      "qualifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes qualifications"])},
      "qualificationDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualification"])},
      "licences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes licences"])},
      "personalStatement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma présentation de profil"])}
    },
    "contactDetailsForm": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes détails"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez que vos coordonnées sont correctes et ajoutez une URL LinkedIn en option ci-dessous."])},
      "additionalNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez votre Employment Consultant si vous devez changer ou ajouter votre nom, adresse email, numéro de téléphone, ou localisation."])},
      "nameInputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon nom"])},
      "emailInputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon adresse email"])},
      "phoneInputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon numéro de téléphone"])},
      "locationInputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma localisation"])},
      "locationInputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non fourni"])},
      "hideLocationInputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cacher ma localisation sur mon CV"])},
      "linkedinInputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL de mon profil LinkedIn (facultatif)"])},
      "linkedinInputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.linkedin.com/in/monprofil"])},
      "saveButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer et revenir"])},
      "linkedinInputError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier que l'URL du profil LinkedIn que vous avez saisie est correcte."])},
      "closeButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner sans sauvegarder"])}
    },
    "workExperiences": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon expérience"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre historique d'emploi. Incluez le travail bénévole et toute autre expérience que vous souhaitez inclure dans votre CV."])},
      "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une expérience"])},
      "editButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier mon expérience"])},
      "presentLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["actuellement"])},
      "returnButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner à mon CV"])}
    },
    "workExperienceForm": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expérience"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre historique d'emploi. Incluez le travail bénévole et toute autre expérience que vous souhaitez inclure dans votre CV."])},
      "roleInput": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon intitulé de poste"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par exemple 'Réceptionniste'"])},
        "isRequiredError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un poste ou un titre de rôle est requis. Veuillez entrer du texte ici."])}
      },
      "businessInput": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'organisation"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par exemple 'Myer'"])}
      },
      "startDateInput": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AAAA ou MM/AAAA"])},
        "invalidFormatError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début invalide. Veuillez entrer une date dans un format valide, année (ex. 2024) ou mois et année (ex. 02/2024)."])},
        "noFutureDateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début invalide. La date ne peut pas être une date future."])}
      },
      "isCurrentRoleInput": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je suis toujours dans ce rôle"])}
      },
      "endDateInput": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AAAA ou MM/AAAA"])},
        "invalidFormatError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin invalide. Veuillez entrer une date dans un format valide, année (ex. 2024) ou mois et année (ex. 02/2024)."])},
        "noFutureDateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin invalide. La date ne peut pas être une date future."])},
        "noBeforeStartDateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début ou de fin invalide. La date de fin ne peut pas être antérieure à la date de début."])}
      },
      "businessDescriptionInput": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À propos de l'organisation"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écrivez ici une description facultative de l'organisation…"])},
        "characterLimitError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez atteint la limite de caractères pour À propos de l'organisation."])}
      },
      "impactInput": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes responsabilités et réalisations"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• Écrivez ici vos responsabilités, tâches ou réalisations pour ce poste…"])},
        "characterLimitError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez atteint la limite de caractères pour Mes responsabilités et réalisations."])}
      },
      "saveBtnLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder et retourner"])},
      "deleteBtnLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer cette expérience"])},
      "closeBtnLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner sans sauvegarder"])}
    },
    "qualificationsOverview": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes qualifications"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez tout certificat, formation, diplôme ou cours que vous avez effectués ou êtes en train de compléter."])},
      "editButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier ma qualification"])},
      "addQualificationBtnLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une qualification"])},
      "returnBtnLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner à mon CV"])},
      "inProgressLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])}
    },
    "qualificationForm": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualification"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez les détails concernant votre cours ou qualification."])},
      "nameInput": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cours ou qualification"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par exemple 'Certificat III en Horticulture'"])},
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurez-vous d'inclure le niveau de qualification dans le titre (par exemple, 'Certificat III')"])},
        "isRequiredError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un cours ou une qualification est requis. Veuillez entrer du texte ici."])}
      },
      "institutionInput": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par exemple 'TAFE NSW'"])}
      },
      "isCompletedInput": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je suis encore en train de le compléter"])}
      },
      "endDateInput": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AAAA ou MM/AAAA"])},
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format de la date (AAAA ou MM/AAAA)"])},
        "invalidFormatError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'achèvement invalide. Veuillez entrer une date dans un format valide, année (p. ex. 2024) ou mois et année (p. ex. 02/2024)."])}
      },
      "highlightsInput": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écrivez ici une description facultative sur votre qualification, vos notes ou vos réalisations…"])},
        "characterLimitError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez atteint la limite de caractères pour la Description."])}
      },
      "saveBtnLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder et retourner"])},
      "deleteBtnLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer cette qualification"])},
      "closeBtnLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner sans sauvegarder"])}
    },
    "licences": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes licences"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez toutes les licences, chèques ou billets dont vous disposez."])},
      "additionalNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une licence"])},
      "editButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier mes licences"])},
      "returnButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenir à Mon CV"])}
    },
    "licencesForm": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licence"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez les détails de votre permis, de votre chèque ou de votre billet."])},
      "saveButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer et retourner"])},
      "deleteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer cette licence"])},
      "closeBtnLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner sans sauvegarder"])},
      "name": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licence"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ex. Permis de conduire"])},
        "isRequiredError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un permis est requis. Veuillez entrer du texte ici."])}
      },
      "organisation": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisme émetteur"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ex. VicRoads"])}
      },
      "issueDate": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de délivrance"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YYYY ou MM/YYYY"])},
        "invalidFormatError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'émission invalide. Veuillez entrer une date au format valide, année (ex. 2024) ou mois et année (ex. 02/2024)."])},
        "noFutureDateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'émission invalide. La date ne peut pas être une date future."])}
      },
      "expiryDate": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'expiration"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YYYY ou MM/YYYY"])},
        "invalidFormatError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'expiration invalide. Veuillez entrer une date au format valide, année (ex. 2024) ou mois et année (ex. 02/2024)."])},
        "noBeforeIssueDateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'émission ou date d'expiration invalide. La date d'expiration ne peut pas être antérieure à la date d'émission."])}
      },
      "licenceDescription": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écrivez ici une description facultative de votre licence…"])},
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décrivez brièvement cette attestation - vous pouvez également ajouter un type d'URL si applicable."])},
        "characterLimitError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez atteint la limite de caractères pour la description."])}
      }
    },
    "personalStatementForm": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma présentation de profil"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rédigez une brève introduction à propos de vous-même, de vos compétences, forces ou objectifs professionnels. Cette déclaration apparaîtra en haut de votre CV."])},
      "saveButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer et revenir"])},
      "closeButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner sans sauvegarder"])},
      "personalStatement": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma déclaration"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rédigez votre présentation de profil ici..."])},
        "invalidCharactersError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certains caractères ne sont pas autorisés. Veuillez modifier ou supprimer le texte en surbrillance et essayer de sauvegarder à nouveau."])},
        "characterLimitError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez atteint la limite de caractères pour la déclaration de profil."])}
      }
    },
    "requiredLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["obligatoire"])},
    "downloadModal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger mon CV"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrez votre CV sur votre appareil afin de pouvoir le modifier et le partager facilement."])},
      "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger au format Word (.docx)"])},
      "secondaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner à la page"])}
    },
    "downloadSuccessModal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargement réussi"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accédez à l'assistant CV si vous avez besoin d'aide pour ouvrir votre document."])},
      "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])}
    },
    "downloadErrorModal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, votre téléchargement a échoué"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réessayez ou revenez à la page."])},
      "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réessayer"])},
      "secondaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenir à la page"])}
    },
    "saveFormErrorModal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, votre réponse n'a pas été enregistrée"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez réessayer ou retourner à la page."])},
      "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réessayer"])},
      "secondaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner à la page"])}
    },
    "returnWithoutSaveModal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étes-vous sur de vouloir quitter cette page?"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous avez des modifications non enregistées, elles seront perdues."])},
      "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitter cette page"])},
      "secondaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rentrer sur cette page"])}
    },
    "deleteFormModal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étes-vous sur de vouloir supprimer cet enregistrement?"])},
      "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
      "secondaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])}
    }
  },
  "form": {
    "textAreaInput": {
      "charCounterText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["caractères"])}
    }
  },
  "month": {
    "january": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Janvier"])},
    "february": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Février"])},
    "march": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mars"])},
    "april": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avril"])},
    "may": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai"])},
    "june": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juin"])},
    "july": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juillet"])},
    "august": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Août"])},
    "september": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Septembre"])},
    "october": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Octobre"])},
    "november": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novembre"])},
    "december": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décembre"])}
  },
  "onboardingSlides": {
    "imageAlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnage"])},
    "skipBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passer"])},
    "nextBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
    "backBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
    "completeBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer"])},
    "progressEllipsis": {
      "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page"])},
      "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])}
    }
  }
}