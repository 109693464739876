<template>
  <ion-page>
    <MobileHeader></MobileHeader>
    <ion-content>
      <DesktopLocaleSelector></DesktopLocaleSelector>
      <div class="page-wrapper" id="course-home-main-content" tabindex="0">
        <Breadcrumbs tab="learn-home" parentType="course"></Breadcrumbs>
        <div class="title-wrapper">
          <h4 class="page-title">{{ $t("course.title") }}</h4>
          <p class="page-description">
            {{ $t("course.description") }}
          </p>
        </div>

        <div class="card-holder">
          <div class="card-holder" v-if="courseStore.isCoursesLoading">
            <CardSkeleton
              class="course-card"
              v-for="index in 3"
              :key="index"
            ></CardSkeleton>
          </div>
          <router-link
            v-for="item in courseStore.getCourses"
            :to="{
              name: 'CourseDetail',
              params: { id: item.base_id },
            }"
            :key="item.base_id"
            class="card-wrapper"
          >
            <CourseOrCollectionCard
              data-test="course-card"
              class="course-card"
              type="course"
              :title="item.title"
              :description="item.description"
              :thumbnail="this.parseImageUrl(item.feature_image, 300, 300)"
              :placeholderImg="
                require('@/assets/course-thumbnail-placeholder.png')
              "
              :courseStatus="this.getCourseProgress(item)?.text"
              :courseStatusIcon="this.getCourseProgress(item)?.icon"
            ></CourseOrCollectionCard>
          </router-link>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import CourseOrCollectionCard from "@/components/CourseOrCollectionCard/CourseOrCollectionCard.vue";
import MobileHeader from "@/components/Common/MobileHeader/MobileHeader.vue";
import { useCourseStore } from "@/store";
import { RouterLink } from "vue-router";
import Breadcrumbs from "@/components/Common/Breadcrumbs/Breadcrumbs.vue";
import CardSkeleton from "@/components/Skeletons/CardSkeleton/CardSkeleton.vue";
import { getCourseProgress, parseImageUrl } from "@/helpers";
import DesktopLocaleSelector from "@/components/Common/LocaleSelector/DesktopLocaleSelector.vue";

export default {
  name: "course-home",
  components: {
    IonPage,
    IonContent,
    MobileHeader,
    CourseOrCollectionCard,
    RouterLink,
    Breadcrumbs,
    CardSkeleton,
    DesktopLocaleSelector,
  },
  setup() {
    const courseStore = useCourseStore();
    return { courseStore };
  },
  mounted() {
    this.courseStore.requestCourses();
  },
  methods: {
    getCourseProgress,
    parseImageUrl,
  },
};
</script>
<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";
.page-wrapper {
  box-shadow: var(--shadow-0);
  border-radius: 0;
}
.page-title {
  margin-bottom: 22px;
}
.title-wrapper {
  text-align: left;
}
.course-card {
  height: 250px;
  background: var(--background-white);
  box-shadow: var(--shadow-1);
  border-radius: 8px;
  width: 144px;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
}
.card-holder {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  gap: 18px;
  & a {
    text-decoration: none;
  }
}

.card-wrapper {
  border-radius: 8px;
  text-decoration: none;
  transition: box-shadow var(--transition-time);
  &:focus-visible {
    outline-color: var(--ion-color-secondary);
  }
  &:hover {
    box-shadow: var(--shadow-2);
  }
  &:active {
    box-shadow: var(--shadow-1);
  }
}
// handles focus on safari
*:focus {
  outline-width: thin;
  overflow: hidden;
  outline-color: var(--ion-color-secondary);
}

@include breakpoint(medium) {
  .course-card {
    width: 228px;
  }
  .page-wrapper {
    padding-left: 82px;
  }
}
</style>
