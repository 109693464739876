import { defineStore } from "pinia";
import * as msal from "@azure/msal-browser";
import { useAuthStore, useLocaleStore } from "@/store";
import EnvironmentConfig from "@/EnvironmentConfig";
import { getAzureAuthority } from "@/helpers";

// Config object to be passed to Msal on creation
let getMsalConfig = () => {
  return {
    auth: {
      clientId: EnvironmentConfig.Azure.ClientId,
      authority: getAzureAuthority(),
      knownAuthorities: [EnvironmentConfig.Azure.KnownAuthority],
      redirectUri: EnvironmentConfig.Azure.RedirectUri,
      navigateToLoginRequestUrl: false,
      postLogoutRedirectUri: "/",
    },
    cache: {
      cacheLocation: "localStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        logLevel: parseInt(EnvironmentConfig.Azure.LogLevel),
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            console.debug(message);
            return;
          }
          switch (level) {
            case msal.LogLevel.Error:
              console.error(message);
              return;
            case msal.LogLevel.Warning:
              console.warn(message);
              return;
            case msal.LogLevel.Info:
              console.info(message);
              return;
            case msal.LogLevel.Verbose:
              console.debug(message);
              return;
          }
        },
        piiLoggingEnabled: EnvironmentConfig.Azure.PiiLogging === "true",
      },
    },
  };
};

export const useMsalStore = defineStore({
  id: "msal",
  state: () => ({
    isReady: false,
    isInitialized: false,
    accountId: null,
    client: new msal.PublicClientApplication(getMsalConfig()),
  }),
  actions: {
    /**
     * Must be called but only once on app lifecycle
     */
    async initialize() {
      // Set authority
      const authStore = useAuthStore();

      // The default accountId is the previous user id
      this.accountId = authStore.getCurrentUserId;

      console.log(
        "msalStore: Initializing on window.location.pathname: ",
        window.location.pathname
      );
      const hasErrorHash = window.location.hash.startsWith(
        "#error=server_error"
      );
      const isOnAuthFinalize =
        window.location.pathname.startsWith("/authfinalize");

      if (hasErrorHash && !isOnAuthFinalize) {
        console.error("msalStore: Error with path: ", window.location.pathname);
        window.location.href = "/";
      } else {
        await this.client.initialize();
        this.isInitialized = true;

        try {
          const response = await this.client.handleRedirectPromise();
          // Response is available following auth redirect
          if (response != null) {
            this.accountId = response.account.homeAccountId;
            await this.acquireToken();
            this.isReady = true;
          }
          // No redirect response which means probably not an auth redirect
          else {
            const currentAccounts = this.client.getAllAccounts();

            if (
              currentAccounts.length === 0 &&
              window.location.pathname !== "/"
            ) {
              console.error("msalStore: Unexpected no accounts");
            } else if (currentAccounts.length > 1) {
              // Add your account choosing logic here
              console.warn("msalStore: Multiple accounts detected");
              // Use the accountId that was set on the initial login
              this.accountId = authStore.getCurrentUserId;
            } else if (currentAccounts.length === 1) {
              this.accountId = currentAccounts[0].homeAccountId;
            }
            this.isReady = true;
          }
        } catch (error) {
          console.log("msalStore: error on handleRedirectPromise", error);
          if (error.errorCode === "access_denied") {
            console.warn("msalStore: access denied");
            // Force logout redirection. Potentially future improve UX by displaying alert.
            this.logoutRedirect();
          }

          if (this.accountId) {
            // Okay to make the app ready as long there is an accountId.
            this.isReady = true;
          } else {
            // If there is no accountId, we're not at a good state and best to start over.
            this.logoutRedirect();
          }
        }
      }
    },
    /**
     * Retrieve a non-expired token silently if valid.
     * Otherwise, the user will be required to enter their credentials.
     */
    async acquireToken() {
      // Bypass token retrieval or validation for tests.
      // TODO: Mock this method using cypress stubs.
      if (window.skipAuth === true) {
        console.log("msalStore: Skipping auth for test execution");
        return;
      }
      if (!this.isInitialized) {
        console.error("msalStore: MSAL is not initialized on acquireToken");
        return;
      }

      const authStore = useAuthStore();
      const localeStore = useLocaleStore();
      const tokenRequest = {
        authority: authStore.getIsConsultant
          ? EnvironmentConfig.Azure.ADAuthority
          : getAzureAuthority(),
        scopes: [EnvironmentConfig.Azure.TokenScope, "openid"],
        forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
        account: this.client.getAccountByHomeId(this.accountId),
        extraQueryParameters: {
          ui_locales: localeStore?.currentLocale ?? "en",
        },
      };

      try {
        const res = await this.client.acquireTokenSilent(tokenRequest);
        authStore.storeAuthInfo(this.accountId, res.accessToken);
        if (!res.accessToken) {
          console.error(
            "msalStore: No access token returned from B2C. Is the correct B2C token scope configured?"
          );
        }
      } catch (err) {
        console.warn("msalStore: Silent token acquisition fails. Error:", err);
        if (err instanceof msal.InteractionRequiredAuthError) {
          console.log("msalStore: Acquiring token using redirect");
          try {
            // Interaction required
            this.client.acquireTokenRedirect(tokenRequest);
          } catch (err) {
            console.error(
              "msalStore: Error on acquire token using redirect with error: ",
              err
            );
            this.logoutRedirect();
          }
        } else {
          // Other error issue, logout and force redirect
          this.logoutRedirect();
        }
      }
    },
    /**
     * Clear authInfo and logout redirect
     */
    async logoutRedirect() {
      const authStore = useAuthStore();
      authStore.clearAuthInfo();
      await this.client.logoutRedirect();
    },
    async loginRedirect(request) {
      if (!this.isReady) {
        console.error("msalStore: MSAL is not ready on loginRedirect");
        return;
      }
      try {
        await this.client.loginRedirect(request);
      } catch (err) {
        console.error("msalStore: Error on loginRedirect with error: ", err);
      }
    },
  },
});
