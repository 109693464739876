import { defineStore } from "pinia";
import http from "../http-common";
import { computed, ref } from "vue";
import { OnboardingSlide } from "./models/onboardingSlide.models";
import { useLocaleStore } from "./locale";

export const useOnboardingSlidesStore = defineStore("onboarding-slides", () => {
  const slides = ref<Array<OnboardingSlide> | null>(null);
  const areSlidesLoading = ref(false);
  const areSlidesUpdating = ref(false);

  /* Getters */
  const getIncompleteSlides = computed(() => {
    return slides.value?.filter((slide) => slide.completed === false);
  });

  const getOnboardingSlides = async (): Promise<void> => {
    areSlidesLoading.value = true;
    const path = `onboarding-slides?locale=${
      useLocaleStore().getCurrentLocaleCode
    }`;
    try {
      const result: { data: Array<OnboardingSlide> } = await http.get(path);
      slides.value = result.data;
    } catch (err) {
      console.error("Could not retrieve onboarding slides", err);
    } finally {
      areSlidesLoading.value = false;
    }
  };

  /* Setters */
  const updateOnboardingSlides = async (ids: Array<string>): Promise<void> => {
    areSlidesUpdating.value = true;
    try {
      await http.put("onboarding-slides", { ids });
    } finally {
      areSlidesUpdating.value = false;
    }
  };

  return {
    slides,
    getOnboardingSlides,
    updateOnboardingSlides,
    getIncompleteSlides,
    areSlidesLoading,
    areSlidesUpdating,
  };
});
