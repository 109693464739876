<template>
  <ion-thumbnail
    style="height: 48px; width: 215px; margin: 32px auto 16px auto"
    v-if="loading"
  >
    <ion-skeleton-text
      style="border-radius: 48px"
      :animated="true"
    ></ion-skeleton-text>
  </ion-thumbnail>
  <ion-badge v-else class="course-status" data-test="course-badge">
    <ion-icon :icon="icon" aria-hidden="true"></ion-icon>
    <span>
      <p class="title">{{ $t(title) }}</p>
      <p class="desc">
        {{
          completedResourceCount +
          "/" +
          totalResourceCount +
          " " +
          $t(description)
        }}
      </p>
    </span>
  </ion-badge>
</template>

<script>
import { IonBadge, IonIcon, IonSkeletonText, IonThumbnail } from "@ionic/vue";

export default {
  name: "status-badge",
  components: {
    IonBadge,
    IonIcon,
    IonSkeletonText,
    IonThumbnail,
  },
  props: {
    title: { type: String },
    description: { type: String },
    icon: { type: String },
    loading: { type: Boolean },
    completedResourceCount: { type: Number },
    totalResourceCount: { type: Number },
  },
};
</script>
<style lang="scss" scoped>
.course-status {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 10px;
  width: 215px;
  height: 48px;
  background: var(--ion-color-secondary-contrast);
  border: 1px solid var(--ion-color-primary);
  border-radius: 42px;
  color: var(--ion-color-secondary);
  margin-top: 32px;
  margin-bottom: 16px;
  & span {
    text-align: left;
  }
  & ion-icon {
    color: var(--ion-color-primary);
    font-size: 24px;
  }
  & .title {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--ion-color-secondary);
    margin: 0;
  }
  & .desc {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.004em;
    color: var(--dark-grey);
    margin: 0;
  }
}
</style>
